import React from 'react'

import useStyles from '../../lib/useStyles'

import { Box, Flex, Grid, Card, Text, IconResolver } from '../Base'
import ControlButton from '../ControlComps/ControlButton'
import Link from '@material-ui/core/Link'

import useModel from './model'

const sizes = {
  small: '394px',
  medium: '640px',
  large: '992px',
  fit: 'auto',
  full: '92%',
}

const Modal = React.memo(({ variant, variantOpts, openRef, TitleIcon, text, Content, contentProps, children, ...props }) => {
  const classes = useStyles()

  const [ state, methods ] = useModel({ variant, variantOpts })

  React.useEffect(() => {
    openRef.current = methods.open
  }, [
    openRef,
    methods,
  ])

  if (!state.isOpen) {
    return null
  }

  let onOutsideClick = null
  if (state.closeOnOutside) {
    if (state.hasCancel) {
      onOutsideClick = state.onCancel || methods.close
    }
    else {
      onOutsideClick = state.onConfirm || methods.close
    }
  }

  let child = false
  if (children) {
    child = React.Children.only(children)
    child = React.cloneElement(child, {
      formMethods: methods
    })
  }
  

  return (
    <Flex
      // TODO test with base comp props
      className={state.isWarning ? classes.scrimDark : classes.scrim}
      onClick={onOutsideClick}
    >
      <Card
        variant='modal'
        width={sizes[state.size]}
        onClick={(e) => e.stopPropagation()}
      >
        <Grid
          minWidth='346px'
          maxHeight='98vh'
          width='100%'
          gridTemplateRows={
            state.showHeader
              ? 'min-content 1fr min-content'
              : '1fr min-content'
          }
        >
          {
            state.showHeader &&
              <Flex
                p='16px 24px'
                alignItems='center'
                borderBottom={state.isWarning ? 'none' : '1px solid #e8e8e8'}
                overflow='hidden'
              >
                {
                  (state.TitleIcon || TitleIcon) &&
                    <Box mr='12px'>
                      <IconResolver icon={state.TitleIcon || TitleIcon}/>
                    </Box>
                }
                <Text
                  variant='h5'
                  color='text.primary'
                  whiteSpace='nowrap'
                  overflow='hidden'
                  textOverflow='ellipsis'
                >
                  {state.title}
                </Text>
              </Flex>
          }
          <Grid
            gridGap='24px'
            p='24px'
            gridAutoFlow='row'
            width='100%'
            overflow='auto'
          >
            { text || state.text}
            { Content && <Content {...contentProps} formMethods={methods}/> }
            { child }
          </Grid>
          <Grid
            gridGap='16px'
            gridAutoFlow='column'
            justifyContent={state.isWarning ? 'end' : 'end'}
            p='16px 24px'
            borderTop={state.isWarning ? 'none' : '1px solid #e8e8e8'}
          >
            {
              state.hasCancel &&
                <ControlButton
                  color='primary'
                  // variant={state.isWarning ? 'default' : 'default'}
                  variant='outlinedSmall'
                  state={state.controls.cancel}
                  onClick={state.onCancel || methods.close}
                  // methods={methods}
                />
            }
            <ControlButton
              variant='solidSmall'
              color={state.isWarning ? 'error' : 'primary'}
              state={state.controls.confirm}
              onClick={state.onConfirm || methods.close}
              // methods={methods}
            />
          </Grid>
        </Grid>
      </Card>
    </Flex>
  )
})

export default Modal

export const ModalButton = React.memo(({ modalProps, children, icon, ...buttonProps }) => {
  const openModalRef = React.useRef()

  return (
    <>
      <ControlButton
        color='primary'
        {...buttonProps}
        startIcon={icon}
        onClick={() => openModalRef.current()}
      />
      <Modal
        {...modalProps}
        TitleIcon={icon}
        openRef={openModalRef}
      >
        { children }
      </Modal>
    </>
  )
})

export const ModalLink = React.memo(({ modalProps, children, label, icon, image }) => {
  const openModalRef = React.useRef()
  // const DEFAULT_IMG = "/media/users/default.jpg"

  return (
    <>
      <Link
        component='button'
        onClick={() => openModalRef.current()}
      >
        <Flex alignItems='center'>
          {/* <Box mr='12px' height='32px' width='32px' borderRadius='50%' overflow='hidden'>
            <img alt='img' src={image || DEFAULT_IMG} height='32px'/>
          </Box> */}
          {label}
        </Flex>
      </Link>
      <Modal
        {...modalProps}
        TitleIcon={icon}
        openRef={openModalRef}
      >
        { children }
      </Modal>
    </>
  )
})

export const ModalIcon = React.memo(({ modalProps, children, icon, ...buttonProps }) => {
  const openModalRef = React.useRef()
  const classes = useStyles()

  return (
    <>
      <Flex
        className={classes.iconButton}
        onClick={() => openModalRef.current()}
        {...buttonProps}
      >
        <i className={icon}/>
      </Flex>
      <Modal
        {...modalProps}
        openRef={openModalRef}
      >
        { children }
      </Modal>
    </>
  )
})

export const ModalLinkSmall = React.memo(({ modalProps, children, label, icon, ...buttonProps }) => {
  const openModalRef = React.useRef()
  const classes = useStyles()

  return (
    <>
      <Link
        component='button'
        onClick={() => openModalRef.current()}
        className={classes.linkUnderButton}
        {...buttonProps}
      >
        <Flex alignItems='center'>
          {label}
        </Flex>
      </Link>
      <Modal
        {...modalProps}
        TitleIcon={icon}
        openRef={openModalRef}
      >
        { children }
      </Modal>
    </>
  )
})