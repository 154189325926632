import { useModel, objectVariantOrPassthrough, initControls, setControlVal, selectedValAndAllowedItemsFromCfg } from '../../../../../lib/state'
import stateVariants from './variants'

const __buldVal = (state) => {

  const _val = {
    searchType: state.searchType,
    search: state.controls.search.val,
    negate: state.controls.negate.items[state.controls.negate.val].val,
    target: state.target,
  }

  state._builtVal = _val
}

///////////
// MODEL //
///////////

const model = {
  ///////////
  // STATE //
  ///////////
  stateInit(args) {
    let ret = {
      ...objectVariantOrPassthrough(stateVariants, args),
      //
    }

    ret.target = ret.target || 'appusers'

    ret.controls = {
      search: {
        _validateAllowNotChanged: true,
        val: {
          ...ret.search,
          target: ret.target,
        },
      },
      searchType_eventsAgg: {
        text: '...performed Event',
        methodKey: 'setSearchType',
        val: 'eventsAgg',
        _validateAllowNotChanged: true,
      },
      searchType_property: {
        text: '...had Property',
        methodKey: 'setSearchType',
        val: 'properties',
        _validateAllowNotChanged: true,
      },
      clear: {
        methodKey: 'clear',
        _validateAllowNotChanged: true,
      },
      // selector
      negate: selectedValAndAllowedItemsFromCfg({
        itemsCfg: {
          'false': {
            label: 'And also',
            val: false,
          },
          'true': {
            label: 'And not who',
            val: true,
          },
        },
        firstIsDefault: true,
        sortByKey: false,
        defaultVal: ret.negate || null,
      }),
    }

    ret.controls.negate._validateAllowNotChanged = true

    initControls(ret)
  
    return ret
  },
  /////////////
  // METHODS //
  /////////////
  methodsInit(state) {
    return {
      setSearchType(val, opts) {
        state.searchType = val
        setControlVal(state)
        __buldVal(state)
      },
      setNegate(val, i) {
        setControlVal(state, state.controls.negate, val)
      },
      clear(val, i) {
        state.searchType = null
        setControlVal(state, state.controls.searchType, null)
        setControlVal(state, state.controls.negate, false)
        __buldVal(state)
      },
      //
      setSearch(val, opts) {
        setControlVal(state, state.controls.search, val)
      },
      //
      _setShouldValidate(val, opts) {
        state.shouldValidate = val
        setControlVal(state)
      },
      _buildVal(val, opts) {
        __buldVal(state)
      },
    }
  },
  ///////////
  // HOOKS //
  ///////////
  hooks: {
  }
}

// returns [ state, methods ]
export default (...args) => {
  return useModel(model, ...args)
}