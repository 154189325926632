import React from 'react'

import { useSelector } from 'react-redux'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { tryExpandCohort } from '../util'

import { Box, Text, Flex } from '../../Base'
import { SwitchToggleTextVertical } from '../../ControlComps/SwitchToggle'
import ControlButton from '../../ControlComps/ControlButton'
import SelectorButton from '../../ControlComps/SelectorButton'

import { HighlightOffOutlined, CallSplitOutlined } from '@material-ui/icons'


const _bracketDangleWidth = '8px'
const _dash = false

// const TextCharControlButton = React.memo(({ size='2rem', ...props }) => {
//   return (
//     <ControlButton
//       fontSize={size}
//       bshadow='none'
//       padding='0px'
//       width={size}
//       height={size}
//       maxwidth={size}
//       minwidth={size}
//       lineheight={size}
//       variant='control'
//       {...props}
//     />
//   )
// })

// const ChipTextControlButton = React.memo((props) => {
//   return (
//     <TextCharControlButton
//       fontSize='0.875rem'
//       size='18px'
//       borderradius='50%'
//       {...props}
//     />
//   )
// })

//
// Secondary Comps
//

const HorizontalDash = React.memo(() => {
  return (
    <Box
      height='1px'
      minWidth={_bracketDangleWidth}
      bgcolor={fade('#000000', 0.33)}
    />
  )
})

const HorizontalBrace = React.memo(() => {
  return (
    <Box
      height='100%'
      minWidth={_bracketDangleWidth}
      border={`1px solid ${fade('#000000', 0.33)}`}
      borderRight='none'
    />
  )
})

// const HorizontalBraceClose = React.memo(() => {
//   return (
//     <Box
//       height='100%'
//       minWidth={_bracketDangleWidth}
//       border={`1px solid ${fade('#000000', 0.33)}`}
//       borderLeft='none'
//     />
//   )
// })

const ItemCapsule = (props) => {
  return (
    <Box
      borderRadius='999px'
      px='16px'
      py='7px'
      height='34px'
      alignItems='center'
      {...props}
    />
  )
}

const GroupOpCompFixed = ({ textColor = '#ffffff', val }) => {
  return (
    <ItemCapsule
      bgcolor='secondary.main'
    >
      <Text color={textColor} variant='subtitle2'>
        {val}
      </Text>
    </ItemCapsule>
  )
}

const GroupOpCompTwoChoices = (props) => {
  return (
    <SwitchToggleTextVertical
      color='secondary'
      {...props}
    />
  )
}

const GroupOpCompMulti = (props) => {
  return (
    <SelectorButton
      variant='outlined'
      color='secondary'
      {...props}
    />
  )
}

//
// Main Comps
//

export const QueryGroup = React.memo(({ methods, id, groupOp, groupOpProps, hasBrace = true, hideFixedGroupOp, children, ...props }) => {
  let GroupOpComp = null
  if (!groupOpProps.items || groupOpProps.items.length === 1) {
    if (!hideFixedGroupOp) {
      GroupOpComp = GroupOpCompFixed
    }
  }
  else if (groupOpProps.items.length === 2) {
    GroupOpComp = GroupOpCompTwoChoices
  }
  else {
    GroupOpComp = GroupOpCompMulti
  }

  return (
    <Flex
      alignItems='center'
      py='3px'
      {...props}
    >
      {
        GroupOpComp &&
          <GroupOpComp
            {...groupOpProps}
            methods={methods}
            id={id}
            val={groupOp}
          />
      }
      <Flex alignSelf='stretch'>
        {
          hasBrace && _dash &&
            <HorizontalDash/>
        }
        {
          hasBrace &&
            <HorizontalBrace/>
        }
      </Flex>
      <Flex
        ml={(hasBrace && `-${_bracketDangleWidth}`) || undefined}
        flexWrap='wrap'
        alignItems='center'
        pl={(hasBrace && '8px') || undefined}
      >
        {children}
      </Flex>
      {/* {
        hasBrace &&
          <Flex alignSelf='stretch' ml='-8px' pr='8px'>
            <HorizontalBraceClose/>
          </Flex>
      } */}
    </Flex>
  )
})

export const QueryItem = React.memo(({ theme, methods, id, field, op, val, textColor, controls, spacing, ...props }) => {
  const fieldLabel = field.split('.').pop()

  textColor = textColor || theme.palette.primary.main

  return (
    <ItemCapsule
      //bgcolor={fade(textColor, 0.1)}
      border={`1px solid ${textColor}`}
      display='flex'
      mr='8px'
      my='2px'
      {...props}
    >
      <Text color={textColor} variant='subtitle2'>
        {fieldLabel} <Text display='inline' variant='subtitle2' color={fade(textColor, 0.54)}>{op}</Text> {val}
      </Text>
      {
        <Flex ml='8px' mr='-8px' flexDirection='row'>
          <ControlButton
            methods={methods}
            methodKey='remove'
            title='Remove'
            id={id}
            color={'primary'}
            variant='icon'
            icon={HighlightOffOutlined}
          />
        </Flex>
      }
    </ItemCapsule>
  )
})

export const QueryCohort = React.memo(({ theme, methods, id, field, op, val: cohortId, textColor, controls, spacing, ...props }) => {
  textColor = textColor || theme.palette.secondary.main

  // TODO expansion control + auto expansion

  const cohorts = useSelector((store) => store.dbEntities.lists.cohorts)
  const cohort = React.useMemo(() => cohorts.find(item => item.id === cohortId) || {}, [ cohorts, cohortId ])

  React.useEffect(() => {
    methods.setByPath(cohort, { path: '_cohort', isSilent: true }, id)
  }, [ methods, id, cohort ])

  return (
    <ItemCapsule
      //bgcolor={fade(textColor, 0.1)}
      border={`1px solid ${textColor}`}
      display='flex'
      mr='8px'
      my='2px'
      title={cohort.description}
      {...props}
    >
      <Text color={textColor} variant='subtitle2'>
        {`Cohort - ${cohort.name}`}
      </Text>
      {
        <Flex ml='8px' mr='-8px' flexDirection='row'>
          <ControlButton
            onClick={React.useCallback(() => methods.transform(tryExpandCohort, null, id), [ methods, id ])}
            title='Expand'
            color={'secondary'}
            variant='icon'
            icon={CallSplitOutlined}
          />
          <ControlButton
            methods={methods} methodKey='remove' id={id}
            title='Remove'
            color={'secondary'}
            variant='icon'
            icon={HighlightOffOutlined}
          />
        </Flex>
      }
    </ItemCapsule>
  )
})