import React from 'react'

import { useApi_OrgId_AppId } from '../../lib/hooks'
import useStyles from '../../lib/useStyles'

import { Text, Flex, Box, IconResolver } from '../../components/Base'

import Modal from '../../components/Modal'

import PushCredentialsFcm from '../../components/PushCredentials/Fcm'
import PushCredentialsApns from '../../components/PushCredentials/Apns'

const PushCredentialModalContents = React.memo(({ pushPlatform, onCompleted, formMethods }) => {
  const props = {
    onTriggerSetter: formMethods.setOnConfirm,
    onHasChanged: formMethods.setConfirmEnabled,
    onCompleted: React.useCallback(() => {
      formMethods.close()
      if (onCompleted) onCompleted()
    }, [
      formMethods,
      onCompleted,
    ]),
  }

  if (pushPlatform ==='firebase') {
    return <PushCredentialsFcm {...props}/>
  }
  else if (pushPlatform === 'apns') {
    return <PushCredentialsApns {...props}/>
  }
})

const PushCredentialButton = React.memo(({ pushPlatform, icon, label, isSetUp, onCompleted, btnLabel }) => {
  const classes = useStyles()
  const openModalRef = React.useRef()
  return (
    <>
      <Flex
        className={classes.muteButton}
        onClick={() => openModalRef.current()}
      >
        <Flex
          justifyContent='space-between'
          alignItems='center'
        >
          <Flex alignItems='center' pr='16px'>
            <Box mr='16px'>
              <IconResolver icon={icon} fontSize='1rem' color='primary.mute'/>
            </Box>
            <Text color='primary.mute' variant='small1'>
              {btnLabel}
            </Text>
          </Flex>
          {
            isSetUp
              ? <IconResolver icon={'fas fa-check'} fontSize='12px' color='success.main'/>
              : <IconResolver icon={'fas fa-exclamation-triangle'} fontSize='12px' color='warning.main'/>
          }
        </Flex>
      </Flex>
      <Modal
        variant='edit'
        variantOpts={React.useMemo(() => ({
          title: `${label} Configuration`,
          confirmLabel: 'Save',
          size: 'medium',
          closeOnOutside: false,
        }), [ label ])}
        TitleIcon={icon}
        openRef={openModalRef}
        Content={PushCredentialModalContents}
        contentProps={React.useMemo(() => ({
          pushPlatform,

          onCompleted,
        }), [ pushPlatform, onCompleted ])}
      />
    </>
  )
})
export const PushCredentials = React.memo(() => {
  const [ data, setData ] = React.useState({})
  const [ api, organizationId, appId ] = useApi_OrgId_AppId()
  
  const [ refreshToken, setRefreshToken ] = React.useState({})
  const _setRefreshToken = React.useCallback(() => setRefreshToken(Math.random()), [
    setRefreshToken,
  ])

  React.useEffect(() => {
    api({
      method: 'post',
      url: '/saas/v1/notifications/v1/pushCredentialStatus',
      data: {
        appId,
        organizationId,
      },
      handler: (res) => {
        setData(res)
      },
    })
  }, [
    api,
    organizationId,
    appId,
    refreshToken,
  ])

  return (
    <>
      <PushCredentialButton
        pushPlatform='firebase'
        isSetUp={data.firebase}
        label='Google Android (FCM)'
        btnLabel='Google Android'
        icon={'fab fa-android'}
        onCompleted={_setRefreshToken}
      />
      <PushCredentialButton
        pushPlatform='apns'
        isSetUp={data.apns}
        label='Apple iOS (APNs)'
        btnLabel='Apple iOS'
        icon={'fab fa-apple'}
        onCompleted={_setRefreshToken}
      />
    </>
  )
})

// export const PushCredentials = React.memo(() => {
//   const [ data, setData ] = React.useState({})
//   const [ api, organizationId, appId ] = useApi_OrgId_AppId()
  
//   const [ refreshToken, setRefreshToken ] = React.useState({})
//   const _setRefreshToken = React.useCallback(() => setRefreshToken(Math.random()), [
//     setRefreshToken,
//   ])

//   React.useEffect(() => {
//     api({
//       method: 'post',
//       url: '/saas/v1/notifications/v1/pushCredentialStatus',
//       data: {
//         appId,
//         organizationId,
//       },
//       handler: (res) => {
//         setData(res)
//       },
//     })
//   }, [
//     api,
//     organizationId,
//     appId,
//     refreshToken,
//   ])

//   return (
//     <Box mb='40px' width='100%'>
//       {/* <Text variant='h5' mb='32px'>
//         {'Push Credentials'}
//       </Text> */}
//       <Grid
//         gridGap='8px'
//         // gridTemplateColumns='repeat(4, 1fr)'
//         gridTemplateColumns='min-content min-content'
//       >
//         <PushCredentialButton
//           pushPlatform='firebase'
//           isSetUp={data.firebase}
//           label='FCM'
//           icon={'fab fa-android'}
//           onCompleted={_setRefreshToken}
//         />
//         <PushCredentialButton
//           pushPlatform='apns'
//           isSetUp={data.apns}
//           label='APNs'
//           icon={'fab fa-apple'}
//           onCompleted={_setRefreshToken}
//         />
//       </Grid>
//     </Box>
//   )
// })
