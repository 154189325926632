import React from "react"
import ManageDbEntity from '../../components/DbEntities/Manage'
import { keyedHooks } from '../../lib/hooks'
import { PushCredentials } from './credentials'

const Campaigns = () => {
  keyedHooks.useDbApi_getList(React.useMemo(() => {
    return {
      dbEntityType: 'campaigns',
      reqData: {
        order: 'updatedAt DESC',
        include: [
          {
            relation: 'saasuser',
            scope: {
              fields: [ 'name', 'email' ],
            }
          },
        ],
      }
    }
  }, []))
  // keyedHooks.useDbApi_getList({
  //   dbEntityType: 'campaigns',
  // })

  return (
    <>
      <ManageDbEntity
        dbEntityType='campaigns'
        basePath='/campaigns'
      >
        <PushCredentials/>
      </ManageDbEntity>
    </>
  )
}

export default Campaigns
