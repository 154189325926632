import React from 'react'

import { useMergedRolePermissionCfg, useMergedEntityPermissionCfg } from '../lib/hooks'
import { get } from 'lodash'

const Permitted = React.memo(({ permsCfg, computedRolePerms, computedEntityPerms, path, entity, children, notPermitted = null }) => {
  if (permsCfg) {
    if (!computedRolePerms) {
      computedRolePerms = useMergedRolePermissionCfg(permsCfg.role)[0]
    }
    if (!computedEntityPerms && permsCfg.entity && entity) {
      computedEntityPerms = useMergedEntityPermissionCfg(permsCfg.entity, entity)[0]
    }
  }

  if (get(computedRolePerms, path, false) || (computedEntityPerms && get(computedEntityPerms, path, false))) {
    return children
  }

  return notPermitted
})

export default Permitted