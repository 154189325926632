import { useModel, objectVariantOrPassthrough, validateAll, validationReset, selectedValAndAllowedItemsFromCfg } from '../../../lib/state'
import modelVariants from './QueryEditorModelStateVariants'
import moment from 'moment-timezone'

//
// Util
//

function _setConditionValState(state, val, opts) {
  state.currentDefaultConditionValState = val
  state.controls.conditionVal = {
    ...state.defaultConditionValState,
    ...val,
  }
  _validateAll(state)
}

const _validateAll = (state) => {
  validateAll(state, Object.values(state.controls))
}

const _validationReset = (state) => {
  validationReset(state, Object.values(state.controls))
}

// TODO
const _detectFieldType = (sampleStr, sampleCount) => {

  const isMomentIso = sampleStr.indexOf
    && sampleStr.indexOf('-') >= 0
    && moment(sampleStr, "YYYY-MM-DDTHH:mm:ss", false).isValid()

  const isPlaceholderIso = sampleStr === 'never' && sampleCount === 1

  if (isMomentIso || isPlaceholderIso) {
    return [ 'iso', isPlaceholderIso ]
  }

  if (!isNaN(sampleStr)) {
    return [ 'numeric', false ]
  }

  return [ null, false ]
}

const _setValueOptions = (state) => {
  const fieldControl = state.controls.field
  const fieldItem = fieldControl.items[fieldControl.val]
  let cached = state._optionsCache[fieldControl.val]

  const lastFieldValType = state.fieldValType
  let newFieldValType = null
  let stripOptions = false

  let _items = []

  if (fieldItem.isInvalid) {}
  else if (cached) {
    _items = cached.items.filter((item) => item.val !== '')

    if (_items.length > 0) {
      [ newFieldValType, stripOptions ] = _detectFieldType(_items[0].val.toString(), _items.length)

      if (stripOptions) {
        _items = []
      }
    }
  }
  else {
    state.needOptionsForFieldItem = fieldItem
  }

  if (lastFieldValType !== newFieldValType) {
    state.fieldValType = newFieldValType
    if (newFieldValType == null) {
      _setConditionValState(state, state.defaultConditionValState)
    }
  }

  state.controls.conditionVal.options = _items
  state._tempConditionValOptions = _items

  if (cached) {
    const restrictToOptions = Boolean(cached.restrictToOptions)
    state.controls.conditionVal.restrictToOptions = restrictToOptions
    state.controls.conditionOp.disabled = restrictToOptions
  }

  _setConditionValState(state, state.controls.conditionVal)
}

//
// model
//

const model = {
  //
  // STATE
  //
  stateInit: (stateIntializerArgs) => {
    stateIntializerArgs = objectVariantOrPassthrough(modelVariants, stateIntializerArgs)
    const {
      controls,
      onExecute,
      clearOnExecute = true,
      grid,
      defaultIsValid = true,
      fieldsOpts,
      conditionalValCompResolver,
      allowCohorts,
      _hooks,
    } = stateIntializerArgs

    // TODO warn on unused Args, or allow them

    controls.field.methodKey = 'setField'
    controls.conditionOp.methodKey = 'setConditionOp'
    controls.conditionVal.methodKey = 'setConditionVal'
    controls.conditionVal.defaultval = controls.conditionVal.val
    if (controls.execute) {
      controls.execute.methodKey = 'execute'
    }

    const ret = {
      controls,
      onExecute,
      clearOnExecute,
      defaultIsValid,
      grid,
      fieldsOpts,
      conditionalValCompResolver,
      defaultConditionValState: controls.conditionVal,
      fieldType: null,
      allowCohorts,
      _tempConditionValOptions: [],
      _optionsCache: {},
      _hooks,
    }

    _setValueOptions(ret)
    _validationReset(ret)

    return ret
  },
  //
  // METHODS
  //
  methodsInit: (state) => {
    return {
      //
      // control val setters
      //
      setField(val, opts) {
        state.shouldValidate = false
        state.controls.field.val = val
        _setValueOptions(state)
        _validateAll(state)
      },
      setConditionOp(val, opts) {
        state.controls.conditionOp.val = val
        // _setConditionOp(state, val)
        _validateAll(state)
      },
      setConditionVal(val, opts) {
        state.controls.conditionVal.val = val
        _validateAll(state)
      },
      //
      // other
      //
      setConditionValState(val, opts) {
        _setConditionValState(state, val, opts)
      },
      setConditionValOptions(val, opts) {
        state.controls.conditionVal.options = val
      },
      setFields(val, opts) {
        const { firstIsDefault, placeholderLabel } = state.fieldsOpts
        
        state.controls.field = {
          ...state.controls.field,
          ...selectedValAndAllowedItemsFromCfg({
            itemsCfg: val,
            getKey: (item) => item.id,
            placeholderLabel,
            firstIsDefault,
          })
        }
        _setValueOptions(state)
        _validateAll(state)
      },
      cacheFieldOptions({res: val, meta: opts}) {
        state._optionsCache[opts.id] = {
          ...opts,
          items: val,
        }
        _setValueOptions(state)
        _validateAll(state)
      },
      setOnExecute(val, opts) {
        state.onExecute = val
      },
      execute(val, opts) {
        state.shouldValidate = true
        _validateAll(state)
        if (state.isValid) {
          const fieldControl = state.controls.field
          const fieldItem = fieldControl.items[fieldControl.val]

          const valControl = state.controls.conditionVal
          let conditionaValOrItem = valControl.val
          if (valControl._other && valControl._other.executeOutputTransformer) {
            conditionaValOrItem = valControl._other.executeOutputTransformer(conditionaValOrItem)
          }

          state.onExecute(
            {
              field: fieldItem.path,
              op: state.controls.conditionOp.val,
              val: conditionaValOrItem.val || conditionaValOrItem,
            }
          )

          if (state.clearOnExecute) {
            state.controls.field.val = state.controls.field.defaultVal
            state.controls.conditionOp.val = state.controls.conditionOp.defaultVal
            state.controls.conditionVal = state.currentDefaultConditionValState
            _setValueOptions(state)
            _validationReset(state)
          }
        }
      }
    }
  },
  //
  // Hooks
  //
  hooks: {
    useDbEntityFields: {
      getHookArgs: (state) => {
        return {
          // TODO rename usages to target
          target: state.fieldsOpts.sourceStr,
          allowCohorts: state.allowCohorts,
        }
      },
      methodKey: 'setFields',
    },
    useDbEntityFieldValues: {
      getHookArgs: (state) => {
        return state.needOptionsForFieldItem
      },
      methodKey: 'cacheFieldOptions',
    },
  }
}

export default (stateInitializerArgs) => {
  return useModel(model, stateInitializerArgs)
}