import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";

// Use to authorize routes based on logged in user's role
//
// <AuthorizedRoute path="/react-bootstrap" roles={["zhortech:admin", "entity:admin"]} component={ReactBootstrapPage} />
//
const AuthorizedRoute = ({component: Component, roles, ...rest}) => {
  const user = useSelector(store => store.auth.user);
  return(
    <Route {...rest} render={props => {
        if (roles && roles.indexOf(user.role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/'}} />
        }
        // authorised so return component
        return <Component {...props} />
      }} />
  );
}

export default AuthorizedRoute;
