import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { ErrorPage1 } from "./ErrorPage1"

export default function ErrorsPage() {
  return (
      <Switch>
        <Redirect from="/error" exact={true} to="/error/error-v1" />
        <Route path="/error/error-v1" component={ErrorPage1} />
      </Switch>
  )
}
