import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Logout: "[Logout] Action",
  SetUser: "[Set User] Auth API",
  SetOrganization: "[Set Organization] Action",
  ResetOrganization: "[Reset Organization] Action",
  AddChildOrganization: "[Add Child Organization] Action",
  SetApp: "[Set App] Action",
  StashOrganization: "[Stash Organization] Action",
  RestoreOrganization: "[Restore Organization] Action",
  OrgAddApp: "[Organization] Add App",
  OrgRemoveApp: "[Organization] Remove App",
  OrgReplaceApp: "[Organization] Replace App",
  OrgSetApps: "[Organization] Set Apps",
  OrgAppSetStatus: "[Organization] Set App Status",
  OrgSetSubApps: "[Organization] Set Sub Apps",
  SetCurrentAppuser: "[Appuser] Set current appuser",
};

const initialAuthState = {
  user: undefined,
  organization: {
    name: "Unknown",
    description: "Unknown"
  },
  // currentorg: undefined,
  app: undefined,
  subApps: undefined,
  appuser: undefined, // will be set to last selected appuser (from Explore)
};

let idx;

export const reducer = persistReducer(
      { storage, key: "zt-saas-auth", whitelist: ["user", "organization", "currentorg", "app", "appuser"] },
      (state = initialAuthState, action) => {
      switch (action.type) {

        case actionTypes.Logout: {
          routerHelpers.forgotLastLocation();
          //return initialAuthState;
          return {
            ...state,
            user: undefined,
            app: undefined,
          };
        }

        case actionTypes.SetUser: {
          const { user } = action.payload;

          // If the user has an organization attached AND the organization name
          // does not match the current organization in redux, then change the
          // current organization to the user organization.
          if (user.organization && user.organization.id) {
            if (user.organization.name !== state.organization.name) {
              state = {
                ...state,
                organization: {
                  ...user.organization,
                },
              };
            }
          }

          return {
            ...state,
            user,
          };
        }

        case actionTypes.SetOrganization: {
          return {
            ...state,
            organization: action.organization
          };
        }

        case actionTypes.ResetOrganization: {
          return {
            ...state,
            organization: {
              ...state.user.organization,
            },
          };
        }

        case actionTypes.AddChildOrganization: {
          return {
            ...state,
            organization: {
              ...state.organization,
              organizations: [
                ...state.organization.organizations,
                action.organization
              ]
            }
          };
        }

        case actionTypes.SetApp: {
          return {
            ...state,
            app: action.app
          };
        }

        // case actionTypes.StashOrganization: {
        //   // save the current organization away in prep for adding a new sub-org
        //   return {
        //     ...state,
        //     currentorg: {
        //       ...state.organization
        //     }
        //   };
        // }

        // case actionTypes.RestoreOrganization: {
        //   // restore the saved org in case we cancel editing a new sub-org
        //   return {
        //     ...state,
        //     organization: {
        //       ...state.currentorg
        //     },
        //     currentorg: undefined
        //   };
        // }

        case actionTypes.OrgAddApp: {
          return {
            ...state,
            organization: {
              ...state.organization,
              apps: [
                ...state.organization.apps,
                action.app
              ]
            }
          };
        }

        case actionTypes.OrgRemoveApp: {
          idx = state.organization.apps.findIndex(a => a.id === action.app.id);
          return {
            ...state,
            organization: {
              ...state.organization,
              apps: [
                ...state.organization.apps.slice(0, idx),
                ...state.organization.apps.slice(idx+1),
              ]
            }
          };
        }

        case actionTypes.OrgReplaceApp: {
          idx = state.organization.apps.findIndex(a => a.id === action.app.id);
          return {
            ...state,
            organization: {
              ...state.organization,
              apps: [
                ...state.organization.apps.slice(0, idx),
                action.app,
                ...state.organization.apps.slice(idx+1),
              ]
            }
          };
        }

        case actionTypes.OrgSetApps: {
          return {
            ...state,
            organization: {
              ...state.organization,
              apps: action.apps
            }
          };
        }

        case actionTypes.OrgAppSetStatus: {
          idx = state.organization.apps.findIndex(a => a.id === action.app.id);
          return {
            ...state,
            organization: {
              ...state.organization,
              apps: [
                ...state.organization.apps.slice(0, idx),
                {
                  ...state.organization.apps[idx],
                  status: action.status
                },
                ...state.organization.apps.slice(idx+1),
              ]
            }
          };
        }

        case actionTypes.OrgSetSubApps: {
          return {
            ...state,
            organization: {
              ...state.organization,
              subApps: action.subApps,
            }
          }
        }

        case actionTypes.SetCurrentAppuser: {
          return {
            ...state,
            appuser: action.appuser
          }
        }

        default: {
          return state;
        }
      }
    }
);

export const actions = {
  logout: () => ({ type: actionTypes.Logout }),
  setUser: user => ({ type: actionTypes.SetUser, payload: { user } }),
  setOrganization: (organization) => ({ type: actionTypes.SetOrganization, organization }),
  resetOrganization: (organization) => ({ type: actionTypes.ResetOrganization, organization }),
  addChildOrganization: (organization) => ({ type: actionTypes.AddChildOrganization, organization }),
  setApp: (app) => ({ type: actionTypes.SetApp, app }),
  // stashOrganization: () => ({ type: actionTypes.StashOrganization }),
  // restoreOrganization: () => ({ type: actionTypes.RestoreOrganization }),
  orgAddApp: (app) => ({ type: actionTypes.OrgAddApp, app }),
  orgRemoveApp: (app) => ({ type: actionTypes.OrgRemoveApp, app }),
  orgReplaceApp: (app) => ({ type: actionTypes.OrgReplaceApp, app }),
  orgSetApps: (apps) => ({ type: actionTypes.OrgSetApps, apps }),
  orgSetSubApps: (subApps) => ({ type: actionTypes.OrgSetSubApps, subApps }),
  orgAppSetStatus: (app, status) => ({ type: actionTypes.OrgAppSetStatus, app, status }),
  setCurrentAppuser: appuser => ({ type: actionTypes.SetCurrentAppuser, appuser }),
};

