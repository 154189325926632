import React from 'react'

import deepMerge from './deepMerge'
import { sortedObjectByKeys } from './utils'
import { keyedHooks } from './hooks'
import { isString, isEqual, set } from 'lodash'

import useMethods from 'use-methods'

//
// Util
//
// TODO MOVE

export const updateObject = (target, ...others) => {
  others.forEach((o) => {
    Object.entries(o).forEach(([key, val]) => {
      target[key] = val
    })
  })
}

//
// Use Model
//
// TODO MOVE

const _statePassthrough = (state) => state
function _processHookCfgs(items, methods) {
  return Object.entries(items).map(([key, val], i) => {
    let {
      useHook,
      // OR
      getHookArgs,
      methodKey, // TODO remove
      methodOpts, // TODO remove
      callback: _callback, // instead of methodKey, methodOpts
    } = val

    const isLocalHook = Boolean(useHook)

    const hook = useHook || keyedHooks[key]
    getHookArgs = getHookArgs || _statePassthrough

    // callback
    //
    let callback
    if (!isLocalHook) {
      if (_callback) {
        callback = (res) => _callback(res, methods)
      }
      else if (methodKey) {
        const method = methods[methodKey]
        if (methodOpts) {
          callback = (res) => method(res, methodOpts)
        }
        else {
          callback = method
        }
      }
    }
    else {
      // setting callbacks to default to methods is sort of dirty since methods is not a function
      // - it is done to enable locally defined model hooks that look like (state, methods) => {}
      // - compared to keyedHooks which look like (args, callback) => {}
      if (_callback) {
        callback = (res) => _callback(res, methods)
      }
      else {
        callback = methods
      }
    }

    // ret
    return { key, hook, callback, getHookArgs }
  })
}

// TODO have all models use this (PresetDateRangePicker, ChartJs, Tree), all state initalizers should pass through state._hooks somehow
// maybe export an initModel function instead
export const useModel = (model, initalState, shouldValidate, onBuiltVal, onBuiltValOpts) => {
  const {
    stateInit,
    methodsInit,
    hooks,
  } = model

  let [ state, methods ] = useMethods(methodsInit, initalState, stateInit)

  // Hooks
  const processedHookCfgs = React.useMemo(
    () => {
      return [
        ..._processHookCfgs(hooks || {}, methods),
        ..._processHookCfgs(state._hooks || {}, methods)
      ]
    },
    [
      methods,
      hooks,
      state._hooks,
    ]
  )

  processedHookCfgs.forEach(({ hook, getHookArgs, callback }) => {
    hook(getHookArgs(state), callback)
  })

  React.useEffect(() => {
    methods._setShouldValidate && shouldValidate !== undefined && methods._setShouldValidate(shouldValidate)
  }, [
    methods,
    shouldValidate,
  ])

  React.useEffect(() => {
    methods._buildVal && methods._buildVal(state.controls)
  }, [
    state.controls,
    methods,
  ])

  React.useEffect(() => {
    onBuiltVal 
      && state._builtVal !== undefined
      && onBuiltVal(state._builtVal, onBuiltValOpts)
  }, [
    onBuiltVal,
    state._builtVal,
    onBuiltValOpts,
  ])

  return [ state, methods ]
}

//
const dynamicDataModel_stateInit = (initalState) => initalState || {}
const dynamicDataModel_methodsInit = (state) => ({
  setAtPath(path, val) {
    set(state, path, val)
  },
})
export const useDynamicDataModel = (initalState) => {
  let [ state, methods ] = useMethods(dynamicDataModel_methodsInit, initalState, dynamicDataModel_stateInit)
  return [ state, methods.setAtPath ]
}
//

//
// Model state initialization
// TODO MOVE

export const objectVariantOrPassthrough = (variantHandler, args) => {
  let ret = args

  if (!args) return {}

  if (isString(args)) {
    args = {
      variant: args,
    }
  }
  
  if (args.variant || args.variantChoices || args.variant === null) {
    let {
      variant,
      variantOpts = {},
      variantChoices = [],
      merge,
      spread
    } = args

    let stateVariants = variantHandler.stateVariants || variantHandler

    ret = (variantHandler.base && variantHandler.base(variantOpts)) || {}

    if (variant) {
      ret = deepMerge(ret, stateVariants[variant](variantOpts))
    }

    if (ret._variantChoices) {
      variantChoices = [...ret._variantChoices, ...variantChoices]
    }

    if (variantChoices.length) {
      ret = deepMerge(ret, ...variantChoices.map((key) => variantHandler.stateChoices[key](variantOpts)))
    }

    if (merge) {
      ret = deepMerge(ret, merge)
    }

    if (spread) {
      ret = { ...ret, ...spread }
    }

    if (variantHandler.postProcessor) {
      ret = variantHandler.postProcessor(ret, variantOpts || {})
    }
  }
  
  return ret
}

//
// List control state initialization
//

// TODO this probably handles too many cases? do we even use itemTransformer here?
export const selectedValAndAllowedItemsFromCfg = (
  {
    itemsCfg,
    allowedItemKeys,
    defaultVal,
    placeholderLabel = '',
    firstIsDefault,
    itemTransformer,
    getKey,
    sortByKey = true,
    startValid = true,
    tryGetDefault,
  }
) => {
  itemsCfg = itemsCfg || []
  const cfgIsArray = Array.isArray(itemsCfg)
  let itemKeys =
    (itemsCfg == null && [])
    || (cfgIsArray && itemsCfg)
    || Object.keys(itemsCfg).filter(k => k !== '_placeholder')

  allowedItemKeys = allowedItemKeys || itemKeys

  let ret = {
    items: {},
  }

  itemKeys.forEach((key) => {
    if (allowedItemKeys.includes(key)) {
      let item = (cfgIsArray && key) || itemsCfg[key]

      if (itemTransformer) item = itemTransformer(item)
      if (getKey) key = getKey(item)

      ret.items[key] = deepMerge(item) // deepCopy
    }
  })

  if (sortByKey) {
    ret.items = sortedObjectByKeys(ret.items)
  }

  itemKeys = Object.keys(ret.items)

  if (!defaultVal && firstIsDefault && itemKeys.length) {
    defaultVal = itemKeys[0]
  }

  let hasPlaceholder = 
    !itemKeys.length
    || defaultVal === undefined
    || !ret.items[defaultVal]

  if (tryGetDefault) {
    defaultVal = tryGetDefault(ret.items)
    hasPlaceholder = defaultVal === undefined
  }

  if (hasPlaceholder) {
    defaultVal = '_placeholder'

    const _placeholder = itemsCfg['_placeholder'] || { isInvalid: true, label: placeholderLabel }
    ret.items = {
      _placeholder,
      ...ret.items,
    }
  }

  ret.val = defaultVal
  ret.defaultVal = defaultVal
  ret.isValid = startValid || !ret.items[ret.val].isInvalid

  return ret
}

//
// All controls state initialization
//

export const initControls = (state, toInit, resetValidation = true) => {
  toInit = toInit || Object.values(state.controls || {})
  toInit.forEach(initControl)
  if (resetValidation) {
    validationReset(state, toInit)
  }
}

export const initControl = (control) => {
  if (control.menuCfg) {
    updateObject(control, selectedValAndAllowedItemsFromCfg({
      ...control.menuCfg,
      defaultVal: control.defaultVal,
    }))

    delete control.menuCfg
  }

  if (control._validator || control.isValid != null) {
    control.isValid = control.isValid || false

    // if (!control._validateAllowNotChanged) {
    //   control.hasChanged = control.hasChanged || false
    // }
  }

  control.hasChanged = control.hasChanged || false

  control.val = control.val || control.defaultVal

  return control
}

//
// Set a controls value, automatically checking if changed and enabling + checking validation
//

export const setControlVal = (state, control, val, doValidateAll = true, doCheckHasChangedAll = true, subPath) => {
  if (control) {
    if (subPath) {
      control.val[subPath] = val
    } else {
      control.val = val
    }
    

    if (control.hasChanged != null && control.defaultVal != null) {
      control.hasChanged = !isEqual(control.val, control.defaultVal)
    }
  }

  if (doCheckHasChangedAll) {
    checkHasChanged(state)
  }

  if (doValidateAll) {
    validateAll(state)
  }
}

//
// Validation
//

export const validateItem = (itemState, parentState) => {
  const state = itemState

  if (state.hasChanged === false && !state._validateAllowNotChanged) {
    state.isValid = false
    state.validationhint = state._notChangedValidationHint || 'Must differ from original value'
  }
  // list of items
  else if (state.items) {
    const item = state.items[state.val]
    state.isValid = !item.isInvalid
    // console.log(state.methodKey, state.isValid)
  }
  // custom validator
  else if (state._validator) {
    const vres = state._validator(state.val, (state.items && state.items[state.val]), parentState)
    if (vres === false || vres === true) {
      state.isValid = vres
    }
    else {
      state.isValid = false
      state.validationhint = vres || state._notChangedValidationHint
    }
  }
  // otherwise assume is valid
  else {
    state.isValid = true
  }

  if (state.isValid) state.validationhint = undefined
}

export const validateAll = (state, toValidate) => {
  toValidate = toValidate || Object.values(state.controls || {})

  if (state.shouldValidate === false) {
    toValidate.forEach((itemState) => {
      itemState.isValid = true
    })
    return
  }

  let allValid = true
  let validationhint

  toValidate.forEach((itemState) => {
    validateItem(itemState, state)
    allValid = allValid && itemState.isValid
    if (itemState.validationhint && !validationhint) validationhint = itemState.validationhint
  })
  
  state.isValid = allValid
  state.validationhint = validationhint

  if (state._afterValidateAll) {
    state._afterValidateAll(state)
  }
}

export const validationReset = (state, toValidate) => {
  toValidate = toValidate || Object.values(state.controls || {})

  if (state.defaultIsValid !== false) {
    state.shouldValidate = false
    state.isValid = true
    toValidate.forEach((itemState) => {
      itemState.isValid = true
    })
  }
  else {
    state.shouldValidate = true
    validateAll(state, toValidate)
  }

  // TODO_MAYBE deal with  hasChanged
}

//
// Has changed
//

export const checkHasChanged = (state, toCheck) => {
  toCheck = toCheck || Object.values(state.controls || {})

  state.hasChanged = false

  if (state._forceHasChanged) {
    state.hasChanged = true
    return
  }

  for (let i = 0; i < toCheck.length; i++) {
    if (toCheck[i].hasChanged || toCheck[i]._forceHasChanged) {
      state.hasChanged = true
      return
    }
  }
}

// RETURNS
// {
//   isValid: BOOL,
//   val: placeholderLabel || defaultVal || allowedItemKeys[0],
//   items: [
//     ?placeholderLabel: {
//       isInvalid: true,
//       label: placeholderLabel,
//     },
//     ...itemsCfg[allowedItemKeys]
//   ]
// }