import React from 'react';

import { Text, Flex, Card } from '../../components/Base'
import Button from '@material-ui/core/Button';

import { useHistory } from "react-router-dom";
import useService from '../../lib/useService';
import { useSelector } from "react-redux";

const Account = React.memo(() => {

  const history = useHistory()
  const service = useService()
  const user = useSelector(store => store.auth.user)
  
  const logout = () => {
    service.logout().then(() => {
      history.push('/auth')
    })
  }
  return (
    <>
      <Card>
        <Text variant='h6' color='text.secondary' mb='24px'>My Account</Text>
        <Text variant='small2' color='text.disabled' mb='8px'>Username</Text>
        <Text variant='body1' color='text.primary'>{user.name || 'Unknown'}</Text>
        <Flex mt='24px' justifyContent='end'>
          <Button onClick={logout} color='primary' variant='outlined'>Sign Out</Button>
        </Flex>
      </Card>
    </>
  )
})

export default Account
