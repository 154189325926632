// for componentDidMount and component state management
import React, {useEffect, useState } from 'react';

import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';

import {useSelector} from 'react-redux';
import moment from 'moment-timezone';

import useService from '../../../lib/useService';
import useApi from '../../../lib/useApi';
import {parseRole} from '../../../lib/utils';
import defaultTableOptions from "./defaultTableOptions";

import {Text} from '../../../components/Base';

import NewUserDialog from '../../../pages/orgs/team/NewUser';
import EditUserDialog from '../../../pages/orgs/team/EditUser';
import ChangePasswordDialog from '../../../pages/orgs/team/ChangePassword';
import Confirm from '../../Confirm';

const Team = () => {

  const loggedInUser = useSelector(store => store.auth.user);
  const organization = useSelector(store => store.auth.organization);
  const api = useApi();
  const {notify} = useService();
  
  // Loading the table
  const [isLoading, setIsLoading] = React.useState(false);

  // For checking what the user can do
  const urole = parseRole(loggedInUser.role);
  
  // Used to manage the actions menus 
  const [anchors, setAnchors] = React.useState({});
  const setAnchor = (a, v) => {
    setAnchors({
      ...anchors,
      [a]: v
    });
  }

  // When an action is picked from the actions menu on a portal user, this
  // us used to pass the "selected" portal user to the action dialog
  const [selectedUser, setSelectedUser] = React.useState({});

  // All of the supported portal user action handlers.  This structure will
  // also contain the dialog "open" state and open state setter functions
  const UserOperations = {
    'create': {
      handle: (data) => {
        UserOperations.create.trigger(false);
        if ( ! data ) return;

        let opts = {
          organizationId: organization.id,
          email: data.email,
          role: data.role,
          saasInvite: data.sendInvite,
          name: data.name,
          password: data.password
        };
        api({ method: "post", url: "/saas/v1/users/v1/create", data: opts, isLoading: setIsLoading, handler: (data) => {
          setUsers([
            data,
            ...users
          ]);
          notify("success", "Notification sent!");
        }});
      }
    },
    'edit': {
      handle: (data) => {
        UserOperations.edit.trigger(false);
        if ( ! data ) return;
        let opts = {
          id: data.id,
          name: data.name,
          role: data.role
        };
        api({ method: "post", url: "/saas/v1/users/v1/updateAttributes", data: opts, isLoading: setIsLoading, handler: (user) => {
          const idx = users.findIndex((u) => { return u.id === data.id });
          setUsers([
            ...users.slice(0,idx),
            user,
            ...users.slice(idx+1)
          ]);
          notify("success", "User updated!");
        }});
      }
    },
    'endis': {
      handle: (okied, data) => {
        UserOperations.endis.trigger(false);
        if ( ! okied ) return;
        let ep;
        if ( data.enabled ) {
          ep = "/saas/v1/users/v1/disableUser";
        }
        else if ( ! data.enabled ) {
          ep = "/saas/v1/users/v1/enableUser";
        }
        api({ method: "post", url: ep, data: {id: data.id}, isLoading: setIsLoading, handler: (user) => { 
          const idx = users.findIndex((u) => { return u.id === data.id });
          setUsers([
            ...users.slice(0,idx),
            user,
            ...users.slice(idx+1)
          ]);
          notify("success", "User updated!");
        }});
      }
    },
    'remove': {
      handle: (okied, data) => {
        UserOperations.remove.trigger(false);
        if ( ! okied ) return;
        api({ method: "post", url: "/saas/v1/users/v1/deleteUser", data: {id: data.id}, isLoading: setIsLoading, handler: () => {
          const idx = users.findIndex((u) => { return u.id === data.id });
          setUsers([
            ...users.slice(0,idx),
            ...users.slice(idx+1)
          ]);
          notify("success", "User removed!");

        }});
      }
    },
    'invite': {
      handle: (okied, data) => {
        UserOperations.invite.trigger(false);
        if ( ! okied ) return;
        api({ method: "post", url: "/saas/v1/users/v1/resendInvite", data: {id: data.id}, isLoading: setIsLoading, handler: () => { 
          notify("success", "Notification sent!");
        }});
      }
    },
    'pwd': {
      handle: (data) => {
        UserOperations.pwd.trigger(false);
        if ( ! data ) return;
        api({ method: "post", url: "/saas/v1/users/v1/changePassword", data: {id: data.id, password: data.password, name: data.name}, isLoading: setIsLoading, handler: (user) => {
          notify("success", "Password changed!");
          const idx = users.findIndex((u) => { return u.id === data.id });
          setUsers([
            ...users.slice(0,idx),
            user,
            ...users.slice(idx+1)
          ]);
          notify("success", "Password changed!");
        }});
      }
    },
  };
  [UserOperations.create.open, UserOperations.create.trigger] = React.useState(false);
  [UserOperations.edit.open, UserOperations.edit.trigger] = React.useState(false);
  [UserOperations.endis.open, UserOperations.endis.trigger] = React.useState(false);
  [UserOperations.remove.open, UserOperations.remove.trigger] = React.useState(false);
  [UserOperations.invite.open, UserOperations.invite.trigger] = React.useState(false);
  [UserOperations.pwd.open, UserOperations.pwd.trigger] = React.useState(false);

  const handleUserAction = (op, user) => {
    setAnchor(user.id, null);
    setSelectedUser(user);
    UserOperations[op].trigger(true);
  }
  
  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Email', field: 'email' },
    { title: 'Role',
      field: 'role',
      lookup: {
        "zhortech:admin": "ZT Admin",
        "zhortech:user": "ZT User",
        "org:admin": "Admin",
        "org:user": "User",
      }
    },
    { title: 'Status', field: 'status' },
    { title: 'Enabled', field: 'enabled', type: 'boolean' },
    { title: 'Last Login', field: 'lastLogin', type: 'date', render: (data) => (
      <div>{data.lastLogin ? moment(data.lastLogin).format('lll') : ''}</div>
    )}
  ];

  const actionMenu = (rowData) => {
    return(
      <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => setAnchor(rowData.id, e.currentTarget)} size='small'>
        <MenuIcon/>
      </Button>
      <Menu
          id="simple-menu"
          anchorEl={anchors[rowData.id]}
          open={Boolean(anchors[rowData.id])}
          onClose={() => setAnchor(rowData.id, null)}
          keepMounted
      >
        <MenuItem onClick={() => handleUserAction('edit', rowData)}>Edit User</MenuItem>
        <MenuItem onClick={() => handleUserAction('endis', rowData)}>{rowData.enabled ? 'Disable' : 'Enable'} User</MenuItem>
        <MenuItem onClick={() => handleUserAction('remove', rowData)}>Remove User</MenuItem>
        <MenuItem onClick={() => handleUserAction('invite', rowData)}>Re-send Invite</MenuItem>
        <MenuItem onClick={() => handleUserAction('pwd', rowData)}>Change Password</MenuItem>
      </Menu>
      </>
    );
  }

  // Only show actions if the logged in user is an admin
  if ( urole.admin ) columns.push({
    title: 'Actions',
    sorting: false,
    render: actionMenu
  });

  // Establishing a state variable and a setter function
  const [users, setUsers] = useState([]);

  const getUsers = React.useCallback(() => {
    if ( ! organization.id ) return;
    api({ method: "get", url: "/saas/v1/users/v1/detailedUsersForOrganization", data: {organizationId: organization.id}, isLoading: setIsLoading, handler: (data) => {
      setUsers(data);
    }});
  }, [api, organization] );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const addUser = () => {
    UserOperations.create.trigger(true);
  }

  // Only show a "add user" button if the logged in user is an admin
  const actions = [];
  if ( urole.admin ) actions.push({
    icon: "add",
    tooltip: "Add User",
    isFreeAction: true,
    onClick: (e) => addUser()
  });

  // Let all users refresh.
  actions.push({
    icon: "refresh",
    tooltip: "Refresh",
    isFreeAction: true,
    onClick: (e) => getUsers()
  });

  const title = (<Text variant='h6'>Team Members</Text>)
  
  return(
    <>
    <MaterialTable
        isLoading={isLoading}
        title={title}
        columns={columns}
        data={users}
        actions={actions}
        options={{
          ...defaultTableOptions
        }}
    />

    <NewUserDialog open={UserOperations.create.open} onClose={UserOperations.create.handle} user={selectedUser} />
    <EditUserDialog open={UserOperations.edit.open} onClose={UserOperations.edit.handle} user={selectedUser} />
    <ChangePasswordDialog open={UserOperations.pwd.open} onClose={UserOperations.pwd.handle} user={selectedUser} />

    <Confirm
        size="sm"
        open={UserOperations.remove.open}
        onClose={UserOperations.remove.handle}
        title="Remove Portal User"
        passthru={selectedUser}
    >
      Are you sure you want to remove <strong>{selectedUser.email}</strong> from the
      <strong>{organization.name}</strong> organization?
    </Confirm>

    <Confirm
        size="sm"
        open={UserOperations.invite.open}
        onClose={UserOperations.invite.handle}
        title="Re-Invite Portal User"
        passthru={selectedUser}
    >
      This operation will resend the invite email to <strong>{selectedUser.email}</strong>.  Ok
      to proceed?
    </Confirm>

    <Confirm
        size="sm"
        open={UserOperations.endis.open}
        onClose={UserOperations.endis.handle}
        title="Enable/Disable Portal User"
        passthru={selectedUser}
    >
      <strong>{selectedUser.email}</strong> is currently <strong>{selectedUser.enabled ? 'ENABLED' : 'DISABLED'}</strong>.
      Do you wish to <strong>{selectedUser.enabled ? 'DISABLE' : 'ENABLE'}</strong> them?
    </Confirm>
    </>
  );
}

export default Team;