// import deepMerge from '../../../lib/deepMerge'
import { RestoreOutlined, ClearOutlined } from '@material-ui/icons'

import QueryEditor from '../CohortQueryEditor/QueryEditor'
import queryEditorModel from '../CohortQueryEditor/QueryEditorModel'
import { tryExpandCohort } from '../util'

import ObjectEditor from '../ObjectEditor/Editor'
import objectEditorModel from '../ObjectEditor/EditorModel'

const base = (variantOpts) => {
  let {
    editorOpts = {},
    target,
  } = variantOpts

  editorOpts = {
    variant: 'default',
    variantOpts: {
      clearOnExecute: true,
      fieldsOpts: {
        sourceStr: target,
      },
    },
  }

  return {
    controls: {
      execute: {
        color: 'primary',
        title: 'Apply',
        val: 'Apply',
        variant: 'solid',
      },
      revert: {
        color: 'primary',
        title: 'Restore',
        variant: 'icon',
        icon: RestoreOutlined,
      },
      clear: {
        color: 'primary',
        title: 'Clear ALL',
        variant: 'icon',
        icon: ClearOutlined,
      },
      save: false,
    },
    editorOpts,
    treeModelOpts: {},
  }
}

const cohortQueryBase = (variantOpts, { allowCohorts }) => {
  const {
    target,
  } = variantOpts

  return {
    Editor: QueryEditor,
    editorModel: queryEditorModel,
    editorOpts: {
      variant: 'default',
      variantOpts: {
        clearOnExecute: true,
        fieldsOpts: {
          sourceStr: target,
        },
        allowCohorts,
      },
    },
    treeModelOpts: {
      variant: 'query',
      variantOpts: {
        data: {
          target,
        },
        isFixedGroupOp: false,
        hideFixedGroupOp: false,
        hasBrace: true,
      },
    }
  }
}

const stateVariants = {
  default (variantOpts) {
    return {
    }
  },
  // inlineList (variantOpts) {
  //   return {
  //     treeModelOpts: {
  //       variantOpts: {
  //         isFixedGroupOp: true,
  //         hideFixedGroupOp: true,
  //         hasBrace: false,
  //       },
  //       merge: {
  //         treeCompProps: {
  //           leaf: {
  //             mt: '0px',
  //           }
  //         },
  //       },
  //     },
  //   }
  // },
  object (variantOpts) {
    return {
      controls: {
        execute: false,
      },
      Editor: ObjectEditor,
      editorModel: objectEditorModel,
      editorOpts: {
        variant: 'default',
        variantOpts: {
          clearOnExecute: true,
        },
      },
      treeModelOpts: {
        variant: 'object',
        variantOpts: {
          data: {},
          hasBrace: true,
        },
      }
    }
  },
  cohortQuery (variantOpts) {
    return {
      ...cohortQueryBase(variantOpts, { allowCohorts: true }),
      controls: {
        save: {
          color: 'primary',
          title: 'Save as Cohort',
          dbEntityType: 'cohorts',
          transformer: (data) => ({ query: tryExpandCohort(data) }),
        },
        ...(variantOpts?.controls?.execute != null && {
          execute: variantOpts?.controls?.execute
        })
      },
    }
  },
  cohortQueryEditor (variantOpts) {
    return {
      ...cohortQueryBase(variantOpts, { allowCohorts: false }),
      controls: {
        execute: false,
      },
    }
  },
}


export default {
  base,
  stateVariants,
}