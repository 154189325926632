import React from 'react'
import moment from 'moment-timezone'

import { Box, Flex, Text, Grid } from '../../../components/Base'
import { datasetColors, useSelectedCohortSetting } from '../util'
import { useSelector } from 'react-redux'
import { sortBy } from 'lodash'
import useStyles from '../../../lib/useStyles'

import { EntityDropdown } from '../../../components/DbEntities/OtherComps/EntityDropdown'

import { PresetDateRangePicker2 } from '../../../components/PresetDateRangePicker'

import { dbEntitiesStoreActions } from '../../../store/dbEntities'
import { useDispatch } from 'react-redux'

const ControlBar = React.memo(({ pageTitle, dateRange, setDateRange, labelSecondary }) => {
  // const classes = useStyles()

  // const handleSetDaysAgo = (event) => {
  //   setDaysAgo(event.target.value)
  // }

  const dateRangePickerOpts = React.useMemo(() => {
    return {
      defaultPresetIdx: 1,
      presets_startDaysAgo: [0, 7, 30,
        365, // TEST
      ],
      ...dateRange,
    }
  }, [ dateRange ])

  return(
    <Flex
      justifyContent='space-between'
      alignItems='center'
    >
      <Box>
        <Flex alignItems='baseline'>
          <Text variant='h5' pb='3px'>
            {pageTitle}
          </Text>
          {
            labelSecondary &&
            <Text variant='body2' ml='12px'>
              {labelSecondary}
            </Text>
          }
        </Flex>
        <Text variant='body2'>
          {moment(dateRange.start).tz(global.TZ).format('MMM DD, YYYY')} - {moment(dateRange.end).tz(global.TZ).format('MMM DD, YYYY')}
        </Text>
      </Box>
      <Box>
      <Flex
        mr='12px'
        alignItems='center'
      >
        <PresetDateRangePicker2
          styleVariant='outlined2'
          modelOpts={dateRangePickerOpts}
          onClose={setDateRange}
        />
      </Flex>
      {/* <Flex alignItems='center'>
        <Flex mr='12px'>
          <Select
            value={daysAgo}
            onChange={handleSetDaysAgo}
            input={<StyledInput/>}
          >
            <MenuItem value={1}>
              <Text color='inherit' variant='button'>Last 24 Hours</Text>
            </MenuItem>
            <MenuItem value={7}>
              <Text color='inherit' variant='button'>Last 7 Days</Text>
            </MenuItem>
            <MenuItem value={30}>
              <Text color='inherit' variant='button'>Last 30 Days</Text>
            </MenuItem>
            <MenuItem value={365}>
              <Text color='inherit' variant='button'>Last 365 Days TEST</Text>
            </MenuItem>
          </Select>
        </Flex>
      </Flex> */}
      </Box>
    </Flex>
  )
})

const AddDatasetButton = React.memo(({onClick}) => {
  const classes = useStyles()
  return (
    <>
      <Flex className={classes.addCohort} onClick={onClick}>
        <i className='fas fa-plus' style={{fontSize: '12px', paddingRight: '12px'}}/>
        <Text color='inherit' variant='button'>
          {'Add Dataset'}
        </Text>
      </Flex>
    </>
  )
})

export const CohortsSelector = React.memo(({ appusersCohort_0, appusersCohort_1, setAppusersCohort_0, setAppusersCohort_1, compare, setCompare }) => {
  const cohorts = useSelector((store) => {
    let ret = store.dbEntities.lists.cohorts.filter((c) => c.query.target === 'appusers' && !c.query.type)
    return sortBy(ret, (item) => item.name)
  })
  return (
    <Grid
      gridGap='16px'
      gridAutoFlow='column'
      gridAutoColumns='max-content'
    >
      <EntityDropdown
        entities={cohorts}
        selectedEntity={appusersCohort_0}
        setEntity={setAppusersCohort_0}
        noneVal={{}}
        markerColor={datasetColors[0]}
        noneText='All Workers'
      />
      {
        compare ?
        <EntityDropdown
          entities={cohorts}
          selectedEntity={appusersCohort_1}
          setEntity={setAppusersCohort_1}
          noneVal={null}
          markerColor={datasetColors[1]}
          noneText='Select Cohort'
        />
        : <AddDatasetButton onClick={setCompare}/>
      }
    </Grid>
  )
})

export const CohortSelectorSetting = React.memo(({ settingsPath, showAddButton, noneVal, noneText = 'Select Cohort', ...props  }) => {
  const cohorts = useSelector((store) => {
    let ret = store.dbEntities.lists.cohorts.filter((c) => c.query.target === 'appusers' && !c.query.type)
    return sortBy(ret, (item) => item.name)
  })

  const dispatch = useDispatch()
  const selectedCohort = useSelectedCohortSetting(settingsPath, noneVal)
  // const selectedCohort = dbEntitiesStoreActions.useDynamicPathSelector(settingsPath, noneVal, 'cohorts')
  const setSelectedCohort = React.useCallback(
    (val) => dbEntitiesStoreActions.setAtDynamicPath(dispatch, `safety.selectedCohorts[${settingsPath}]`, val?.id),
    [ dispatch, settingsPath ]
  )

  const [ showAddDatasetButton, setShowAddDatasetButton ] = React.useState(showAddButton)
  if (showAddDatasetButton) {
    return <AddDatasetButton onClick={() => setShowAddDatasetButton(false)}/>
  }

  return (
    <EntityDropdown
      entities={cohorts}
      selectedEntity={selectedCohort}
      setEntity={setSelectedCohort}
      noneVal={noneVal}
      noneText={noneText}
      {...props}
    />
  )
})

export default ControlBar