import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import * as auth from "../../../store/ducks/auth.duck"

import useApi from '../../../lib/useApi';
import useService from '../../../lib/useService';
import {parseRole} from '../../../lib/utils'
import useStyles from '../../../lib/useStyles'

import { Box, Flex, Text, Grid } from '../../../components/Base'
import EditDialog from './EditDialog'

import { Button } from "@material-ui/core"
import { Add, FileCopyOutlined, EditOutlined, ChevronRight } from '@material-ui/icons';

// Callback to add a new organization + get an initial set of apikeys installed
const useAddNewOrg = () => {
  const organization = useSelector(store => store.auth.organization)
  const api = useApi()
  const dispatch = useDispatch()
  const {notify} = useService()

  return React.useCallback((data) => {
    let logo;
    if (data.logo) {
      logo = data.logo;
      data.hasLogo = logo.type
    }
    delete data.logo;
    console.log("New org data", logo)
    api({ method: "post", url: `/api/Organizations/${organization.id}/organizations`, data, handler: (newOrg) => {
      dispatch(auth.actions.addChildOrganization(newOrg))
      api({ method: "post", url: `/api/Organizations/${newOrg.id}/addKeyPair`, handler: (keyPair) => {
        notify('success', 'Organization created!');
        if (logo) {
          let formData = new FormData()
          formData.append("file", logo)
          api({
            method: "post",
            url: `/api/Organizations/${newOrg.id}/uploadLogo`,
            opts: {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            },
            data: formData,
            // handler: () => {}
          });
        }
      }});
    }});
  }, [api, dispatch, organization.id, notify])
}

const useEditOrg = () => {
  const organization = useSelector(store => store.auth.organization)
  const {setOrganization} = auth.actions;
  const api = useApi()
  const dispatch = useDispatch()
  const {notify} = useService()

  return React.useCallback((data) => {
    let logo;
    
    if (data.logo) {
      logo = data.logo;
      data.hasLogo = logo.type
    }
    delete data.logo;

    api({ method: "patch", url: `/api/Organizations/${organization.id}`, data, handler: (o) => {
      if (logo) {
        let formData = new FormData()
        formData.append("file", logo)
        api({
          method: "post",
          url: `/api/Organizations/${organization.id}/uploadLogo`,
          opts: {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          },
          data: formData,
          handler: () => {
            dispatch(setOrganization(o))
          }
        });
      }
      else { dispatch(setOrganization(o)); }
      notify('success', 'Organization updated!');
    }});
  }, [api, organization.id, dispatch, setOrganization, notify])
}

const OrganizationProfile = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const addNewOrg = useAddNewOrg()
  const editOrg = useEditOrg()
  const {notify} = useService()

  const user = useSelector(store => store.auth.user)
  const organization = useSelector(store => store.auth.organization)

  const setOrgAndResetApp = (org) => {
    dispatch(auth.actions.setApp(null))
    dispatch(auth.actions.setOrganization(org))
  }
  
  const [ openForm, setOpenForm ] = React.useState(false)
  const [ openEdit, setOpenEdit ] = React.useState(false)
  const urole = parseRole(user.role)
  const parentOrgId = organization.organizationId
  const _isParent = organization.isParent
  const _canHaveSuborgs = organization.canHaveSuborgs

  const [parentOrg, setParentOrg] = React.useState()
  const [suborgs, setSuborgs] = React.useState()

  React.useEffect(() => {
    if (organization?.organization) {
      setParentOrg(organization.organization)
    }
    if (organization?.organizations) {
      setSuborgs(organization.organizations)
    }
  }, [organization])

  return(
    <>
      <Grid
        width='100%'
        gridTemplateColumns='1fr 1fr'
        gridGap='32px'
      >
        <Box>
          <Box>
            <Flex
              justifyContent='space-between'
              alignItems='center'
              mb='32px'
            >
              <Text
                variant='h6'
                color='text.primary'
              >
                Account Management
              </Text>
            </Flex>
            <Flex className={classes.orgItemActive}>
              <Box width='100%'>
                <Flex
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Box>
                    <Text
                      variant='subtitle2'
                      color='text.primary'
                      mb='8px'
                    >
                      Account Name
                    </Text>
                    <Flex
                      alignItems='center'
                    >
                      <Text
                        variant='body2'
                        color='text.primary'
                        mr='16px'
                      >
                        {organization.name}
                      </Text>
                    </Flex>
                  </Box>
                  {
                    urole.admin &&
                    <Button
                      color='primary'
                      startIcon={<EditOutlined />}
                      variant='outlined'
                      size='small'
                      onClick={() => setOpenEdit(true)}
                    >
                      Edit
                    </Button>
                  }
                </Flex>
                {
                  !_isParent &&
                  <Box
                    display={organization.code ? 'inline-block' : 'none'}
                    mt='16px'
                  >
                    <Text
                      variant='subtitle2'
                      color='text.primary'
                      mb='4px'
                    >
                      Brand ID
                    </Text>
                    <Text
                      variant='small3'
                      color='text.secondary'
                      mb='8px'
                    >
                      * This code is required during user-registration and must be manually provided to {organization.name} app users. 
                    </Text>
                    <CopyToClipboard
                      text={organization.code}
                      onCopy={() => notify("success", `${organization.code} copied to clipboard`)}  
                    >
                      <Flex
                        alignItems='center'
                        cursor='pointer'
                        className={classes.chip}
                        width='min-content'
                      >
                        <Text
                          variant='value3'
                          color='primary.main'
                          mr='8px'
                        >
                          {organization.code}
                        </Text>
                        <FileCopyOutlined className={classes.actionButton} color='primary' />
                      </Flex>
                    </CopyToClipboard>
                  </Box>
                }
              </Box>
            </Flex>
          </Box>
          {
            _canHaveSuborgs &&
            <Box mt='24px'>
              <Flex
                justifyContent='space-between'
                alignItems='flex-end'
                mb='8px'
              >
                <Box>
                  <Text
                    variant='subtitle2'
                    color='text.primary'
                    mb='4px'
                  >
                    Affiliate Accounts
                  </Text>
                  {
                    suborgs && suborgs.length > 0 &&
                    <Text
                      variant='small3'
                      color='text.secondary'
                    >
                      These accounts are managed by {organization.name}.
                    </Text>
                  }
                  {
                    suborgs && suborgs.length === 0 &&
                    <Text
                    variant='small3'
                    color='text.secondary'
                    >
                      {organization.name} is not currently managing other accounts.
                    </Text>
                  }
                </Box>
                
                {
                  urole.admin &&
                  <Button
                    onClick={() => setOpenForm(true)}
                    variant='outlined'
                    size='small'
                    color='primary'
                    startIcon={<Add />}
                  >
                    Add Account
                  </Button>
                }
              </Flex>
              {
                suborgs && suborgs.length > 0 &&
                <Grid className={classes.orgList}>
                  {
                    suborgs.map((org, i) => (
                      <Flex
                        key={i}
                        className={classes.orgItem}
                        onClick={() => setOrgAndResetApp(org)}
                      >
                        <Flex
                          alignItems='center'
                        >
                          <Text
                            variant='body2'
                            color='inherit'
                            mr='8px'
                          >
                            {org.name}
                          </Text>
                          {
                            org.code && !org.isParent &&
                            <Text
                              variant='value5'
                              className={classes.chip}
                              color='primary.main'
                            >
                              {org.code}
                            </Text>
                          }
                        </Flex>
                        <ChevronRight style={{opacity:'66%'}}/>
                      </Flex>
                    ))
                  }
                </Grid>
              }
            </Box>
          }
          {
            urole.admin && parentOrgId && parentOrg && parentOrg.name &&
            <Box mt='24px'>
              <Text
                variant='subtitle2'
                color='text.primary'
                mb='8px'
              >
                Admin
              </Text>
              <Grid className={classes.orgList}>
                <Flex
                  className={classes.orgItem}
                  onClick={() => setOrgAndResetApp(parentOrg)}
                >
                  <Flex alignItems='center'>
                    <Text
                      variant='body2'
                      color='inherit'
                      mr='8px'
                    >
                      {parentOrg.name}
                    </Text>
                  </Flex>
                  <ChevronRight style={{opacity:'66%'}}/>
                </Flex>
              </Grid>
            </Box>
          }
          {
            !urole.admin && urole.zt && parentOrgId && parentOrg && parentOrg.name &&
            <Box mt='24px'>
              <Text
                variant='subtitle2'
                color='text.primary'
                mb='8px'
              >
                Admin
              </Text>
              <Grid className={classes.orgList}>
                <Flex
                  className={classes.orgItem}
                  onClick={() => setOrgAndResetApp(parentOrg)}
                >
                  <Flex alignItems='center'>
                    <Text
                      variant='body2'
                      color='inherit'
                      mr='8px'
                    >
                      {parentOrg.name}
                    </Text>
                  </Flex>
                  <ChevronRight style={{opacity:'66%'}}/>
                </Flex>
              </Grid>
            </Box>
          }
        </Box>
      </Grid>
      <EditDialog
        org={{}}
        open={openForm}
        onClose={(data) => {
          setOpenForm(false)
          if (!data) return
          addNewOrg(data)
        }}
      />
      <EditDialog
        org={organization}
        open={openEdit}
        onClose={(data) => {
          setOpenEdit(false)
          if (!data) return
          editOrg(data)
        }}
      />
    </>
  );
}

export default OrganizationProfile