import React from 'react';
import { Flex, Grid, Text, Card } from '../components/Base'
import useStyles from '../lib/useStyles'

const AttributeList = ({title, subtitle, attrs, ...props}) => {

  const classes = useStyles()

  return(
    // <Flex flexDirection='column' minWidth='480px' style={{ flexBasis:'50%'}}>
      <Card p='16px'>
      {
        title &&
        <Flex flexDirection='row' mb='12px' alignItems='center'>
          <Text variant='h6'>{title}</Text>
        </Flex>
      }
        <Grid
          gridAutoFlow='row'
        >
          {
            subtitle &&
            <Flex
              alignItems='center'
              p='8px 0'
              className={classes.tableRow}
            >
              <Text
                className={classes.tableCell_Head}
                variant='subtitle2'
                color='text.secondary'
                pr='12px'
              >
                Firmware
              </Text>
              <Text
                className={classes.tableCell_Body}
                variant='body2'
                color='text.secondary'
              >
                {subtitle}
              </Text>
            </Flex>
          }
          {Object.keys(attrs).sort().map((key) => (
            <Flex
              key={key}
              alignItems='center'
              p='8px 0'
              className={classes.tableRow}
            >
              <Text
                className={classes.tableCell_Head}
                variant='subtitle2'
                color='text.secondary'
                pr='12px'
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Text>
              <Text
                className={classes.tableCell_Body}
                variant='body2'
                color='text.secondary'
              >
                {typeof attrs[key] === 'object' ? JSON.stringify(attrs[key]) : attrs[key]}
              </Text>
            </Flex>
          ))}
        </Grid>
      </Card>
    // </Flex>
  );
}

export default AttributeList;

// TODO auto detect iso, humanize + tooltip
export const ObjectPropertiesList = React.memo(({ title, subtitle, obj, ...props }) => {
  const classes = useStyles()

  return (
      <>
        {
          title &&
            <Flex flexDirection='row' mb='12px' alignItems='center'>
              <Text variant='h6'>{title}</Text>
            </Flex>
        }
        <Grid gridAutoFlow='row'>
          {
            subtitle &&
              <Flex
                alignItems='center'
                p='8px 0'
                className={classes.tableRow}
              >
                <Text
                  className={classes.tableCell_Head}
                  variant='subtitle2'
                  color='text.secondary'
                  pr='12px'
                >
                  Firmware
                </Text>
                <Text
                  className={classes.tableCell_Body}
                  variant='body2'
                  color='text.secondary'
                >
                  {subtitle}
                </Text>
              </Flex>
          }
          {
            Object.keys(obj).sort().map((key) => (
              <Flex
                key={key}
                alignItems='center'
                p='8px 0'
                className={classes.tableRow}
              >
                <Text
                  className={classes.tableCell_Head}
                  variant='subtitle2'
                  color='text.secondary'
                  pr='12px'
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </Text>
                <Text
                  className={classes.tableCell_Body}
                  variant='body2'
                  color='text.secondary'
                >
                  {obj[key]}
                </Text>
              </Flex>
            ))
          }
        </Grid>
      </>
  )
})

// export const AttributeGrid = ({title, subtitle, attrs, gridProps, ...props}) => {

//   return(
//     <Flex flexDirection='column' width='100%' {...props}></Flex>
//       <Flex justifyContent='space-between' mb='8px' alignItems='center'>
//         <Text variant='h6'>{title}</Text>
//         {subtitle && <Text variant='subtitle2' color='text.disabled'>{subtitle}</Text>}
//       </Flex>
//       <Card p='0px'>
//         <Grid {...gridProps}>
//           {Object.keys(attrs).map((key) => (
//               <TableRow key={key}>
//                 <TableCell component="th" scope="row">
//                 {key.charAt(0).toUpperCase() + key.slice(1)}
//                 </TableCell>
//                 <TableCell align="right">{attrs[key]}</TableCell>
//               </TableRow>
//             ))}
//         </Grid>
//       </Card>
//     <Flex/>
//   );
// }
