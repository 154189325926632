import { QueryGroup, QueryItem, QueryCohort } from './Comps'

const _defaultGroupOp = 'and'
const _groupOpItems = [
  {
    val: 'and',
  },
  {
    val: 'or',
  }
]

// TODO if last child is a group, there is extra bracket margin
const variant =  ({ defaultGroupOp = _defaultGroupOp, isFixedGroupOp, hideFixedGroupOp, hasBrace }) => {
  const groupOpItems = (!isFixedGroupOp && [ ..._groupOpItems ]) || undefined

  return {
    compResolver(item, hasChildren, isRoot) {
      if (isRoot && !hasChildren) return null
      if (item.groupOp) return QueryGroup
      if (item.field) {
        if (item.field === '_COHORTS') {
          return QueryCohort
        }
        else {
          return QueryItem
        }
      }
    },
    childrenResolver: (item) => item.children,
    isBranchFunc: (item) => item.groupOp,
    dataTransformers: {
      from: {
        leaf(item) {
          // TODO_MAYBE detect fieldType
          return item
        },
        branch(itemWithChildren, childrenResolver) {
          const {
            groupOp = defaultGroupOp,
            target,
          } = itemWithChildren
  
          const itemOut = { groupOp }

          if (target) {
            itemOut.target = target
          }
          // Could do something custom here instead of using childrenResolver
          const childrenOut = childrenResolver(itemWithChildren)
  
          return [ itemOut, childrenOut ]
        }
      },
      to: {
        leaf({ field, op, val, ...other }) {
          return { field, op, val, ...other }
        },
        branch(itemNoChildren, children) {
          const { groupOp, target } = itemNoChildren
          return { groupOp, target, children }
        }
      }
    },
    treeCompProps: {
      all: null,
      leaf: null,
      root: {
        py: '0px',
        mb: 0,
      },
      branch: {
        hasBrace,
        hideFixedGroupOp,
        groupOpProps: {
          methodKey: 'setByPath',
          methodOpts: {
            path: 'groupOp',
          },
          items: groupOpItems,
        },
      },
    }
  }
}

export default variant