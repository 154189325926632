import useMethods from 'use-methods'
import { objectVariantOrPassthrough } from '../../lib/state'
import modelVariants from './TreeModelStateVariants'
import { set, uniqueId } from 'lodash'
import deepMerge from '../../lib/deepMerge'
// import { original } from 'immer'

// TODO MAYBE root can have different structure than branches

//
// Util
//
const _empty = []
function _findItemById(node, id, parent, nodeIdx) {
  if (node._treeId === id) {
    return [ node, parent, nodeIdx ]
  }

  // recurse
  const children = node._treeChildren
  if (children) {
    for (let i = 0; i < children.length; i++) {
      const ret = _findItemById(children[i], id, node, i)
      if (ret.length) return ret
    }
  }

  return _empty
}

function _itemFromDataTransform(item, forceIsGroup, ...params) {
  const [ childrenResolver, isBranchFunc, leafTransformer, branchTransformer,  idResolver ] = params

  const _treeId = idResolver?.({}) || uniqueId()
  if ((isBranchFunc(item) || forceIsGroup)) {
    const [
      inittedBranch,
      children = []
    ] = (branchTransformer && branchTransformer(item, childrenResolver)) || [ item, item._treeChildren ]

    // recurse
    const _treeChildren = []
    for (let i = 0; i < children.length; i++) {
      _treeChildren.push(_itemFromDataTransform(children[i], false, ...params))
    }

    return {
      ...inittedBranch,
      _treeId,
      _treeChildren,
    }
  }
  else {
    const inittedLeaf = (leafTransformer && leafTransformer(item)) || item

    return {
      ...inittedLeaf,
      _treeId,
    }
  }
}

function _itemToDataTransform(item, ...params) {
  const [ isBranchFunc, leafTransformer, branchTransformer ] = params

  if (isBranchFunc(item)) {
    const { _treeChildren, _treeId, ...branchOther } = item

    // recurse
    const childrenOut = []
    for (let i = 0; i < _treeChildren.length; i++) {
      childrenOut.push(_itemToDataTransform(_treeChildren[i], ...params))
    }

    return branchTransformer(branchOther, childrenOut)
  }
  else {
    const { _treeId, ...leafOther } = item
    return leafTransformer(leafOther)
  }
}

//
// State Util
//

function _fromDataTransform(state, val, forceIsGroup, opts) {
  const { dataTransformers, isBranchFunc, childrenResolver, idResolver } = state
  if (!dataTransformers || !dataTransformers.from) {
    const mergeWith = { _treeId: idResolver?.({}) || uniqueId() }
    if (forceIsGroup) mergeWith._treeChildren = []
    return deepMerge(val, mergeWith) // deep copy
  }
  else {
    const { leaf, branch } = dataTransformers.from
    return _itemFromDataTransform(val, forceIsGroup, childrenResolver, isBranchFunc, leaf, branch, idResolver)
  }
}

function _toDataTransform(state, val, opts) {
  const { dataTransformers, isBranchFunc } = state
  if (!dataTransformers || !dataTransformers.to) {
    return val
  }
  else {
    const { leaf, branch } = dataTransformers.to
    return _itemToDataTransform(val, isBranchFunc, leaf, branch)
  }
}

const _add = (state, toId, toAdd) => {
  const { root } = state
  
  if (!toId) {
    root._treeChildren.push(toAdd)
  }
  else {
    const [ newParent ] = _findItemById(root, toId)
    if (newParent._treeChildren) {
      newParent._treeChildren.push(toAdd)
    }
    else {
      // TODO nest 'toNode' and 'fromNode' into a new branch node under 'toParent'
    }
  }
}

function _getAndRemove(state, id) {
  const { root, removeParentOnNoChildren } = state

  const [ toRemove, currParent, toRemoveIdx ] = _findItemById(root, id)
  currParent._treeChildren.splice(toRemoveIdx, 1)

  if (removeParentOnNoChildren && currParent._treeChildren.length === 0 && currParent._treeId !== root._treeId) {
    _getAndRemove(state, currParent._treeId)
  }

  return toRemove
}

function _replace(state, newItem, id, opts) {
  const { root } = state
  // eslint-disable-next-line no-unused-vars
  const [ node, nodeParent, nodeIdx ] = _findItemById(root, id)
  nodeParent._treeChildren[nodeIdx] = _fromDataTransform(state, newItem, false, opts)
}

function _transform(state, transformer, id, opts) {
  const { root } = state
  // eslint-disable-next-line no-unused-vars
  const [ node, nodeParent, nodeIdx ] = _findItemById(root, id)
  const data = transformer(_toDataTransform(state, node, opts))
  if (!data) {
    _getAndRemove(state, id)
    return
  }
  nodeParent._treeChildren[nodeIdx] = _fromDataTransform(state, data, false, opts)
}

// TODO option for deep comparison to original state
function _checkIfChanged(state) {
  if (!state.trackHasChanged) return
  _checkIfHasItems(state)
  state.hasChanged = true
}

//
// State
//

const stateInitializer = (stateIntializerArgs) => {
  stateIntializerArgs = objectVariantOrPassthrough(modelVariants, stateIntializerArgs)

  let {
    // internal
    unnestSingularGroups = true, //TODO
    removeParentOnNoChildren = true,
    trackHasChanged = true,
    childrenResolver,
    dataTransformers,
    // internal + comp
    root,
    isBranchFunc,
    idResolver,
    // Debug
    debug,
    debugMethods,
    // State for external monitoring
    data,
    // Non-internal
    compResolver,
    treeCompProps,
  } = stateIntializerArgs

  data = data || root
  const _checkpointData = data || {}
  root = data || {}

  const newState = {
    // internal
    unnestSingularGroups,
    removeParentOnNoChildren,
    trackHasChanged,
    childrenResolver,
    dataTransformers,
    // internal + comp
    isBranchFunc,
    idResolver,
    // Debug
    debug,
    debugMethods,
    // State for external monitoring
    data,
    hasChanged: false, // changed since data was last set
    _checkpointData,
    // Non-internal
    compResolver,
    treeCompProps,
  }

  // set initalized root
  newState.root = _fromDataTransform(newState, root, true)

  // TODO set data only if root is defined (and handle this lazy initialization case overall)
  // newState.data = _toDataTransform(newState)

  _checkIfHasItems(newState)

  if (debug)
    console.log('TREE_MODEL_STATEINITIALIZER', newState)
  
  return newState
}

function _checkIfHasItems(state) {
  state.hasItems = state.root._treeChildren.length > 0
}

function _setRoot(state, val, opts) {
  state.root = _fromDataTransform(state, val, true, opts)
  _checkIfHasItems(state)
}

//
// Methods
//

const _methods = (state) => {
  return {
    revert(val, opts) {
      _setRoot(state, state._checkpointData, opts)
      state.hasChanged = false
    },
    clear(val, opts) {
      const hadItems = state.hasItems
      _setRoot(state, {}, opts)
      state.hasChanged = hadItems
    },
    setRoot(val, opts) {
      state._checkpointData = val
      _setRoot(state, val, opts)
      state.hasChanged = false
    },
    setInitalData(val, opts) {
      state._checkpointData = val
      _setRoot(state, val, opts)
      state.hasChanged = false
      state.data = val
    },
    add(val = {}, opts = {}, id) {
      _add(state, opts.toId, _fromDataTransform(state, val, false, opts))
      _checkIfChanged(state)
    },
    addGroup(val = {}, opts = {}, id) {
      _add(state, opts.toId, _fromDataTransform(state, val, true, opts))
      _checkIfChanged(state)
    },
    move(val, opts = {}, id) {
      _add(state, opts.toId, _getAndRemove(state, id))
      _checkIfChanged(state)
    },
    remove(val, opts, id) {
      _getAndRemove(state, id)
      _checkIfChanged(state)
    },
    replace(val, opts, id) {
      _replace(state, _fromDataTransform(state, val, false, opts), id, opts)
      _checkIfChanged(state)
    },
    transform(val, opts, id) {
      _transform(state, val, id, opts)
      _checkIfChanged(state)
    },
    setByPath(val, { path, isSilent, }, id) {
      if (state.debugMethods) {
        console.log('TREE_MODEL_REPLACE', val, path, id)
      }

      if (path && path.indexOf('_treeChildren') >= 0) {
        console.error('setByPath does not support tree paths (._treeChildren.): ' + path)
        return
      }
      // Change a node's state
      else {
        const [ node ] = _findItemById(state.root, id)
        set(node, path, val)
      }
      if (!isSilent) {
        _checkIfChanged(state)
      }
    },
    executeToDataTransformer(_, opts) {
      state.data = _toDataTransform(state, state.root, opts)
      state._checkpointData = state.data
      state.hasChanged = false
    },
  }
}

export const useModel = (stateIntializerArgs) => {
  return useMethods(_methods, stateIntializerArgs, stateInitializer)
}