import { setControlVal } from '../../../lib/state'
// import { tryExpandCohort, queryData_toLoopback } from '../../Query/util'


const initEntityModel = (entityCfg) => {
  const otherData = entityCfg.otherData
  const {
    contentTypes,
    defaultContentType,
  } = otherData

  return {
    dbEntityType: entityCfg.dbEntityType,
    hasWantsToSaveToken: true,
    entityMethodsInit(state) {
      return {
        setContentType(val, opts) {
          setControlVal(state, state.controls.selectedContentType, val)
        },
        setContent(val, opts) {
          // if (!state.controls.content.defaultVal) {
          //   state.controls.content.defaultVal = {
          //     [state.controls.selectedContentType.val]: val
          //   }
          //   state.controls.content.val = state.controls.content.defaultVal
          // }
          setControlVal(state, state.controls.content, val, true, true, state.controls.selectedContentType.val)
        },
        setIsActive(val, opts) {
          setControlVal(state, state.controls.isActive, val)
        },
        setName(val, opts) {
          setControlVal(state, state.controls.name, val)
        },
        // setActionsQuery(val, opts) {
        //   setControlVal(state, state.controls.actionsQuery, val)
        // },
        setScheduling(val, opts) {
          // if (!state.controls.scheduling.defaultVal) {
          //   state.controls.scheduling.defaultVal = val
          //   state.controls.scheduling.val = state.controls.scheduling.defaultVal
          // }
          setControlVal(state, state.controls.scheduling, val)
        },
        //
        // setCohortQuery(val, opts) {
        //   val = tryExpandCohort(val)
        //   setControlVal(state, state.controls.cohortQuery, val)
        // },
        // setCohortQueryHasChanged(val, opts) {
        //   // TODO check has changed enitrely inside of tree
        //   state.controls.cohortQuery._forceHasChanged = val
        //   checkHasChanged(state)
        // },
        setAudience(val, opts) {
          setControlVal(state, state.controls.audience, val)
        },
      }
    },
    getControlsCfg(state, isNew, isClone, isSaveAs, isEdit) {
      // TODO _validateAllowNotChanged bulk
      return {
        selectedContentType: {
          methodKey: 'setContentType',
          menuCfg: {
            itemsCfg: Object.keys(contentTypes).map((key) => {
              return {
                label: key,
              }
            }),
            getKey: (item) => item.label,
          },
          _validateAllowNotChanged: true,
        },
        isActive: {
          methodKey: 'setIsActive',
          _validateAllowNotChanged: true,
        },
        name: {
          methodKey: 'setName',
          _validator: (val) => {
            return (val && val !== '') || 'Name is required'
          },
          _validateAllowNotChanged: true,
        },
        // actionsQuery: {
        //   methodKey: 'setActionsQuery',
        //   _validateAllowNotChanged: true,
        // },
        scheduling: {
          methodKey: 'setScheduling',
          _validateAllowNotChanged: true,
        },
        content: {
          methodKey: 'setContent',
          _validateAllowNotChanged: true,
        },
        // cohortQuery: {
        //   methodKey: 'setCohortQuery',
        //   _validateAllowNotChanged: true,
        // },
        audience: {
          methodKey: 'setAudience',
          _validateAllowNotChanged: true,
        },
      }
    },
    isPartialEntity(data) {
      return data.isDraft
    },
    handleSetData(state, data, hasControls) {
      // Object.entries(contentTypes).forEach(([key, val]) => {
      //   if (data.content[key]) {
      //     data.selectedContentType = key
      //   }
      // })
      // console.log(state.initialData)

      if (!data.content) {
        data.content = Object.keys(contentTypes).reduce((o, item) => {
          o[item] = {}
          return o
        }, {})
      }

      state.attributes = data.attributes

      if (hasControls) {
        state.controls.selectedContentType.defaultVal = data.selectedContentType || defaultContentType

        if (state.isClone) {
          state.controls.name.defaultVal = ''
          // state.controls.isActive.defaultVal = false
        }
        else {
          state.controls.name.defaultVal = data.name
          // state.controls.isActive.defaultVal = data.isActive || false
        }

        // nested models
        // state.controls.actionsQuery.defaultVal = data.audience.usersActivitesPerformed
        state.controls.scheduling.defaultVal = data.scheduling
        state.controls.content.defaultVal = data.content
        // state.controls.audience.defaultVal = data.audience.query || {}
        state.controls.audience.defaultVal = data.audience.cohort || {}
      }
    },
    buildOutData(state) {
      let ret = {
        // isActive: state.controls.isActive.val,
        name: state.controls.name.val,
        isDraft: false,
        audience: {
          // usersBuilder: state.controls.cohortQuery.val,
          // usersQueryStr: JSON.stringify(queryData_toLoopback(state.controls.cohortQuery.val)),
          // query: state.controls.audience.val,
          cohort: state.controls.audience.val,
        },
        scheduling: state.controls.scheduling.val,
        content: state.controls.content.val,
        attributes: {
          ...state.attributes,
          '_v': 1,
        },
      }

      return ret
    },
  }
}

export default initEntityModel