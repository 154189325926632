/* eslint-disable no-unused-vars */
/*
   Custom hooks
 */
import React from 'react';
import { isEqual, uniq } from 'lodash'
import { useSelector } from 'react-redux'
import useApi from './useApi'
import { dbEntitiesStoreActions } from '../store/dbEntities'
import { useDispatch } from 'react-redux'
import flatten from 'flat'
import deepMerge from './deepMerge'
import * as dbEntityCfgs from '../components/DbEntities/allDbEntities'

//
// Util
//

export const useDeepCompareRef = (val, debug, simpleDebug) => {
  const ref = React.useRef([])

  if (!isEqual(val, ref.current)) {
    if (debug) {
      const currFlat = flatten(ref.current)
      const currKeys = Object.keys(currFlat)
      const newFlat = flatten(val)
      const newKeys = Object.keys(newFlat)

      const allKeys = uniq([ ...currKeys, ...newKeys])
      const diff = {}

      allKeys.forEach((key) => {
        if (currKeys.indexOf(key) === -1) {
          diff[key] = 'ADDED'
        }
        else if (newKeys.indexOf(key) === -1) {
          diff[key] = 'REMOVED'
        }
        else if (currFlat[key] !== newFlat[key]) {
          diff[key] = 'CHANGED'
        }
      })

      console.log('useDeepCompareRef diff: ', diff, 'current: ', ref.current, 'next: ', val)
    }

    if (simpleDebug) {
      console.log(ref.current, val)
    }

    ref.current = val
  }

  return ref
}

export const useBooleanToken = (val) => {
  const [ _val, set ] = React.useState(val)

  React.useEffect(() => {
    if (!val) {
      set(false)
    }
    else if (_val === false) {
      set(Math.random() + 1)
    }
  }, [_val, val])

  return _val
}

export const useSelfId = () => useSelector((store) => (store.auth.user && store.auth.user.id))

//
// Permissions
//

function useIsRoleMatch(a, b) {
  const urole = useSelector((store) => store.auth.user && store.auth.user.role)

  return React.useMemo(() => {
    if (!urole) return false

    const and = [ a, b ]
    const split = urole.split(':')

    for (let i = 0; i < and.length; i++) {
      if (and[i] && (and[i] !== split[i])) {
        return false
      }
    }
    return true
  }, [
    urole,
    a, b
  ])
}

export const useSetting = (path, def, isIdOfEntityType, resolver = undefined) => {
  return useDeepCompareRef(dbEntitiesStoreActions.useDynamicPathSelector(path, def, isIdOfEntityType, resolver)).current
}

// export const useEnsureSetting = (path, def) => {
//   const dispatch = useDispatch()
//   const curr = dbEntitiesStoreActions.useDynamicPathSelector(path)

//   if (curr === undefined) {
//     dbEntitiesStoreActions.setAtDynamicPath(dispatch, path, def)
//   }
// }

export const useSettingSetter = (path) => {
  const dispatch = useDispatch()

  const setter = React.useCallback(
    (val) => dbEntitiesStoreActions.setAtDynamicPath(dispatch, path, val),
    [ dispatch, path ]
  )

  return setter
}

// Permissions Hooks

const useIs_orgIsSuper = () => useSelector((store) => store.auth.organization && (store.auth.organization.name === 'Zhortech'))
const useIs_superAdmin = () => useIsRoleMatch('zhortech', 'admin')
const useIs_superAny = () => useIsRoleMatch('zhortech', null)

export const rolePermissionHooks = {
  useIs_superAdmin,
  useIs_superAny,
  useIs_orgIsSuper,

  useIs_orgAdmin: () => useIsRoleMatch('org', 'admin'),
  useIs_orgAny: () => useIsRoleMatch('org', null),

  useIs_anyAdmin: () => useIsRoleMatch(null, 'admin'),
  useIs_anyUser: () => useIsRoleMatch(null, 'user'),

  useIs_anyRole: () => true,

  useIs_superAny_and_orgIsSuper: () => {
    const roleMatch = useIs_superAny()
    const orgMatch = useIs_orgIsSuper()
    return roleMatch && orgMatch
  },

  useIs_superAdmin_and_orgIsSuper: () => {
    const roleMatch = useIs_superAdmin()
    const orgMatch = useIs_orgIsSuper()
    return roleMatch && orgMatch
  },
}

export const entityPermissionHooks = {
  useIs_owner: (entity) => {
    const selfId = useSelfId()
    return selfId === entity.saasuserId
  }
}

// Permissions Cfg
const emptyObj = {}
export const useMergedPermissionCfg = (hooks, permissionCfg = emptyObj, ...args) => {
  let passedKeys = Object.keys(permissionCfg).filter((key) => hooks[`useIs_${key}`](...args))

  passedKeys = useDeepCompareRef(passedKeys).current
  
  return React.useMemo(() => {
    const passedKeysObj = {}
    passedKeys.forEach((key) => passedKeysObj[key] = true)

    return [
      deepMerge(...passedKeys.map((key) => permissionCfg[key])),
      passedKeysObj,
    ]
  }, [
    passedKeys,
    permissionCfg,
  ])
}

export const useMergedRolePermissionCfg = (permissionCfg) => useMergedPermissionCfg(rolePermissionHooks, permissionCfg)
export const useMergedEntityPermissionCfg = (permissionCfg, entity) => useMergedPermissionCfg(entityPermissionHooks, permissionCfg, entity)

//

export const useSelectedApps = () => useSelector((store) => store.auth.app?.apps)

//
// useApi Helpers
//

const getAppIdsFromStore = (store) => store.auth.app?.apps?.map((app) => app.id)

export const useApi_apiUrl = (relativePath) => {
  return [
    useApi(),
    `/api${relativePath}`,
  ]
}

export const useApi_OrgId_AppId = () => {
  return [
    useApi(),
    useSelector((store) => store.auth.organization && store.auth.organization.id),
    useSelector((store) => store.auth.app && store.auth.app.id),
  ]
}

export const useAppIds = () => {
  return useDeepCompareRef(useSelector(getAppIdsFromStore)).current
}

export const useApi_OrgId_AppId_AppIds = () => {
  return [
    ...useApi_OrgId_AppId(),
    useAppIds(),
  ]
}

export const useApi_AppId = () => {
  return [
    useApi(),
    useSelector((store) => store.auth.app && store.auth.app.id),
  ]
}

export const useApi_OrgId = () => {
  return [
    useApi(),
    useSelector((store) => store.auth.organization && store.auth.organization.id),
  ]
}

export const useApi_AppOrOrgUrl = (relativePath, dbEntityType, allowMultipleAppIds) => {
  return [
    useApi(),
    useDeepCompareRef(useSelector((store) => {
      if (dbEntityType) {
        const dbEntityCfg = dbEntityCfgs[dbEntityType]
        if (dbEntityCfg && !dbEntityCfg.relations.belongsTo.organization && !dbEntityCfg.relations.belongsTo.app) {
          return `/api${relativePath}`
        }
      }

      const orgId = store.auth.organization && store.auth.organization.id
      let appId = store.auth.app && store.auth.app.id

      if (dbEntityType) {
        if (!dbEntityCfgs[dbEntityType]?.relations.belongsTo.app) {
          appId = undefined
        }
      }

      if (allowMultipleAppIds) {
        let appIds = getAppIdsFromStore(store)
        if (appIds) {
          return appIds.map((appId) => {
            let _url = `/api/Apps/${appId}`
            if (relativePath) _url += relativePath
            return _url
          })
        }
      }

      let _url = '/api'

      if (appId) {
        _url += `/Apps/${appId}`
      }
      else {
        _url += `/Organizations/${orgId}`
      }
      
      if (relativePath) {
        _url += relativePath
      }

      return _url
    })).current
  ]
}

//
// KEYED HOOKS UTIL
//

const baseUrlCharts = '/saas/v1/charts/v1'

// Fields
const _fieldsTargets_Attributes = {
  url: `${baseUrlCharts}/attributeNames`,
  basePath: 'attributes',
}
const _fieldsTargets_EventData = {
  url: `${baseUrlCharts}/eventDataNames`,
  basePath: 'data',
}
const dbEntityFieldsTargets = {
  appusers: {
    ..._fieldsTargets_Attributes,
    reqData: { for: 'AppUser' },
    hasCohorts: true,
  },
  shoes: {
    ..._fieldsTargets_Attributes,
    reqData: { for: 'Shoe' },
    hasCohorts: true,
  },
  eventsAttributes: {
    ..._fieldsTargets_Attributes,
    reqData: { for: 'Event' },
  },
  events: {
    ..._fieldsTargets_EventData,
  },
  eventsData: {
    ..._fieldsTargets_EventData,
  },
}

// Field Values
const _fieldValuesTargets_Attributes = {
  url: `${baseUrlCharts}/attributeValues`,
  reqDataKeyForrelativePath: 'attributeName',
}
const dbEntityFieldValuesTargets = {
  appusers: {
    ..._fieldValuesTargets_Attributes,
    reqData: { for: 'AppUser' },
  },
  shoes: {
    ..._fieldValuesTargets_Attributes,
    reqData: { for: 'Shoe' },
  },
  eventsAttributes: {
    ..._fieldValuesTargets_Attributes,
    reqData: { for: 'Event' },
  },
  eventsNames: {
    url: `${baseUrlCharts}/eventNames`,
    relativePath: 'name',
  },
  
}

const newSpecialField = (target, relativePath, label) => {
  return {
    isSpecial: true,
    target,
    basePath: null,
    relativePath,
    label,
    path: relativePath,
    id: `${target}.${relativePath}`,
  }
}

const unitsToShortUnits = {
  //
  milliseconds: 'ms',
  seconds: 'sec',
  minutes: 'min',
  //
  millimeters: 'mm',
  centimeters: 'cm',
  meters: 'm',
  //
  newton: 'N',
  hectonewton: 'hN',
  //
  degrees: '°',
  percent: '%',
}

export const useAppPlugin = () => {
  return useSelector((store) => {
    return {
      name: store.auth.app?.pluginName,
      appId: store.auth.app?.id,
    }
  })
}

//
// KEYED HOOKS
//
// NOTE: It is safer to deconstruct args before useEffect, instead of using args itself as a dependency

export let keyedHooks = {
  useSelectedApps(_, cb) {
    const selectedApps = useSelectedApps()
    React.useEffect(() => {
      cb(selectedApps)
    }, [ cb, selectedApps ])
  },

  useDbEntityById(args = {}, cb) {
    const {
      dbEntityType,
      id,
      reqData,
    } = args

    const [ api, url ] = useApi_apiUrl(`/${dbEntityType}/${id}`)
    
    React.useEffect(() => {
      if (!id) return

      api({
        method: 'getFiltered',
        url,
        data: reqData,
        handler: (res) => {
          cb(res)
        },
      })
    }, [
      cb,
      api, url,
      dbEntityType, id, reqData
    ])
  },

  useCurrentPluginFields(args = {}, cb) {
    let {
      appId,
    } = args

    const [ api, storeAppId ] = useApi_AppId()
    const dispatch = useDispatch()

    appId = appId || storeAppId

    const dbEntitiesStoreDynamicPath = `plugins.${appId}.fields`
    const cachedPluginFields = dbEntitiesStoreActions.useDynamicPathSelector(dbEntitiesStoreDynamicPath)

    React.useEffect(() => {
      if (!appId) return

      if (cachedPluginFields) {
        cb(cachedPluginFields) // item: {field, label, unit, fcn}
      }
      else {
        api({
          method: 'get',
          url: `/saas/v1/plugin/dataPropertyMap/${appId}`,
          data: {},
          handler: (res) => {
            res = res.reduce((acc, val) => {
              val.unitShort = val.unit.replace(' ', '')
                .split('/')
                .map((str) => unitsToShortUnits[str] || str)
                .join('/')

              acc[val.field] = val
              return acc
            }, {})
            dbEntitiesStoreActions.setAtDynamicPath(dispatch, dbEntitiesStoreDynamicPath, res)
          },
        })
      }
    }, [
      cb,
      api, appId, dbEntitiesStoreDynamicPath, dispatch, cachedPluginFields
    ])
  },

  useDbEntityFields(args = {}, cb) {
    const {
      target, // TODO rename to fieldsTargetStr
      allowCohorts = true,
    } = args

    const [ api, organizationId, appId, appIds ] = useApi_OrgId_AppId_AppIds()

    React.useEffect(() => {
      if (!target) return

      const { url, basePath, reqData = {}, hasCohorts } = dbEntityFieldsTargets[target]

      let data = undefined
      let datas = undefined

      if (appIds) {
        datas = appIds.map((appId) => ({
          organizationId,
          appId,
          ...reqData,
        }))
      }
      else {
        data = {
          organizationId,
          appId,
          ...reqData,
        }
      }

      api({
        method: 'post',
        url,
        data,
        datas,
        multiResTransformer: (res) => {
          return uniq(res.flat())
        },
        handler: (res) => {
          let _res = []

          if (allowCohorts && hasCohorts) {
            _res.push(newSpecialField(target, '_COHORTS', 'cohorts'))
          }

          _res = [
            ..._res,
            ...res.map((relativePath) => {
              if (relativePath.includes('.')) {
                return null
              }
              return {
                target: target,
                basePath,
                relativePath,
                label: relativePath,
                path: `${basePath}.${relativePath}`,
                id: `${target}.${relativePath}`
              }
            }).filter(o => o)
          ]

          cb(_res)
        },
      })
    }, [
      cb,
      api, organizationId, appId, appIds,
      target, allowCohorts,
    ])
  },

  useDbEntityFieldValues(args = {}, cb) {
    let { 
      target,
      relativePath: _relativePath = null,
    } = args

    const { hasCohorts } = dbEntityFieldsTargets[target] || {}
    const _cohorts = useSelector((store) => (hasCohorts && store.dbEntities.lists.cohorts) || null)
    const cohorts = React.useMemo(() => {
      return _cohorts?.filter((c) => !c.query.type) || null
    }, [_cohorts])

    const [ api, organizationId, appId, appIds ] = useApi_OrgId_AppId_AppIds()

    React.useEffect(() => {
      if (!target) return

      if (_relativePath === '_COHORTS') {
        cb({
          res: cohorts.filter((item) => item.query.target === target)
            .map((item) => {
              return {
                label: item.name,
                val: item.id,
              }
            }
          ),
          meta: {
            restrictToOptions: true,
            target,
            relativePath: _relativePath,
            id: `${target}.${_relativePath}`,
          }
        })
        return
      }

      const fieldValuesCfg = dbEntityFieldValuesTargets[target] || {}

      let { url, reqDataKeyForrelativePath, relativePath, reqData = {} } = fieldValuesCfg

      if (!url) return

      let data = undefined
      let datas = undefined

      reqData = {
        ...reqData,
      }

      if (reqDataKeyForrelativePath) {
        relativePath = _relativePath
        reqData[reqDataKeyForrelativePath] = relativePath
      }

      if (appIds) {
        datas = appIds.map((appId) => ({
          organizationId,
          appId,
          ...reqData,
        }))
      }
      else {
        data = {
          organizationId,
          appId,
          ...reqData,
        }
      }

      api({
        method: 'post',
        url,
        data,
        datas,
        multiResTransformer: (res) => {
          return uniq(res.flat())
        },
        handler: (res) => {
          let _res = res.map((val) => {
            return {
              label: val.toString(),
              val,
            }
          })

          if (_res.length) {
            if (_res?.[0].val !== _res?.[0].label) {
              _res.sort((a, b) => a.val - b.val)
            }
            else {
              _res.sort((a, b) => a.val.localeCompare(b.val))
            }
          }

          cb({
            res: _res,
            meta: {
              target,
              relativePath,
              id: `${target}.${relativePath}`,
            },
          })
        },
      })
    }, [
      cb,
      api, organizationId, appId, appIds,
      target, _relativePath, cohorts,
    ])
  },
  //
  // TEMPLATE
  //
  _template(args = {}, cb) {

    const { TEMPLATE_ARG } = args

    const [ api, organizationId, appId ] = useApi_OrgId_AppId()

    React.useEffect(() => {

    }, [
      cb,
      api, organizationId, appId,
      TEMPLATE_ARG,
    ])
  },
}

//
// useDbApi Hooks
//

keyedHooks = {
  ...keyedHooks,
  useDbApi_create: (args = {}, cb) => {
    const { entity, dbEntityType } = args
    
    const [ api, url ] = useApi_AppOrOrgUrl(`/${dbEntityType}`)
    const dispatch = useDispatch()

    React.useEffect(() => {
      if (!entity) return

      api({
        method: 'post',
        data: entity,
        url,
        handler: (res) => {
          // TODO get relations generically somehow
          dbEntitiesStoreActions.listUpsert(dispatch, dbEntityType, res)
          if (cb) cb(res)
        },
      })
    }, [
      cb,
      api, url, dispatch,
      dbEntityType, entity
    ])
  },

  useDbApi_update: (args = {}, cb) => {
    const { entity, dbEntityType, initialEntity = {}, isPatch, urlOverride } = args
    
    const [ api, url ] = useApi_AppOrOrgUrl(`/${dbEntityType}/${initialEntity.id}`, dbEntityType)
    const dispatch = useDispatch()

    React.useEffect(() => {
      if (!entity || !initialEntity.id) return

      api({
        method: isPatch ? 'patch' : 'put',
        data: entity,
        url: urlOverride || url,
        handler: (res) => {
          // For including initially present relations
          res = {
            ...initialEntity,
            ...res,
          }
          dbEntitiesStoreActions.listUpsert(dispatch, dbEntityType, res)
          if (cb) cb(res)
        },
      })
    }, [
      cb,
      api, url, dispatch,
      dbEntityType, entity, initialEntity, isPatch, urlOverride,
    ])
  },

  // TODO generic
  useDbApi_getList: (args = {}, cb) => {
    const { dbEntityType, reqData, allow, url: _url, debug, beforeSetList } = args

    let [ api, url ] = useApi_AppOrOrgUrl(`/${dbEntityType}`, dbEntityType)
    url = _url || url

    const dispatch = useDispatch()

    React.useEffect(() => {
      if (allow === false) return

      api({
        method: 'getFiltered',
        url,
        data: reqData,
        handler: (res) => {
          if (debug) console.log(res)
          if (beforeSetList && typeof beforeSetList === 'function')
            dbEntitiesStoreActions.listSet(dispatch, dbEntityType, beforeSetList(res))
          else
            dbEntitiesStoreActions.listSet(dispatch, dbEntityType, res)
          if (cb) cb(res)
        },
      })
    }, [
      cb,
      api, url, dispatch, debug,
      dbEntityType, reqData, allow, beforeSetList
    ])
  },

  useDbApi_getById: (args = {}, cb) => {
    const { id, dbEntityType, reqData } = args

    const [ api, url ] = useApi_apiUrl(`/${dbEntityType}/${id}`)
    const dispatch = useDispatch()

    React.useEffect(() => {
      if (!id) return

      const _reqData = reqData || {}

      api({
        method: 'getFiltered',
        url,
        data: {
          ..._reqData,
        },
        handler: (res) => {
          if (res) {
            dbEntitiesStoreActions.listUpsert(dispatch, dbEntityType, res)
          }
          else {
            dbEntitiesStoreActions.listRemove(dispatch, dbEntityType, { id, })
          }
          
          if (cb) cb(res)
        },
      })
    }, [
      cb,
      api, url, dispatch,
      dbEntityType, id, reqData
    ])
  },

  useDbApi_deleteById: (args = {}, cb) => {
    const {
      id,
      dbEntityType,
    } = args

    const [ api, url ] = useApi_AppOrOrgUrl(`/${dbEntityType}/${id}`, dbEntityType)
    const dispatch = useDispatch()

    React.useEffect(() => {
      if (!id) return

      api({
        method: 'delete',
        url,
        handler: (res) => {
          dbEntitiesStoreActions.listRemove(dispatch, dbEntityType, { id })
          if (cb) cb(res)
        },
      })
    }, [
      cb,
      api, url, dispatch,
      dbEntityType, id,
    ])
  },

  useDbApi_archiveById: (args = {}, cb) => {
    const {
      id,
      dbEntityType,
    } = args
    
    const api = useApi()
    const dispatch = useDispatch()

    React.useEffect(() => {
      if (!id) return

      let data = { isArchived: true }

      if (dbEntityType === 'campaigns') {
        data.isActive = false
      }

      api({
        method: 'patch',
        data,
        url: `api/${dbEntityType}/${id}`,
        handler: (res) => {
          dbEntitiesStoreActions.listUpsert(dispatch, dbEntityType, res)
          if (cb) cb(res)
        },
      })
    }, [
      cb,
      api, dispatch,
      dbEntityType, id
    ])
  },

  useDbApi_patchById: (args = {}, cb) => {
    const {
      id,
      dbEntityType,
      data,
    } = args
    
    const api = useApi()
    const dispatch = useDispatch()

    React.useEffect(() => {
      if (!id) return

      api({
        method: 'patch',
        data,
        url: `api/${dbEntityType}/${id}`,
        handler: (res) => {
          dbEntitiesStoreActions.listUpsert(dispatch, dbEntityType, res)
          if (cb) cb(res)
        },
      })
    }, [
      cb,
      api, dispatch,
      dbEntityType, id, data
    ])
  },

  useDbApi_getOrgUsers: (args = {}, cb) => {
    let [ api, url ] = useApi_AppOrOrgUrl(``)
    url = "/saas/v1/users/v1/detailedUsersForOrganization"

    React.useEffect(() => {
      api({
        method: 'get',
        url,
        handler: (res) => {
          // console.log(res)
          if (cb) cb(res)
        },
      })
    }, [
      cb,
      api, url,
    ])
  },
}
