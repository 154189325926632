import React from 'react'

import { Grid, } from '../../../../components/Base'
import ControlBar, { CohortSelectorSetting } from '../../components/ControlBar'
import MaterialTableWrapper from '../../../../components/Tables/MaterialTableWrapper'

import { ModalLink } from '../../../../components/Modal'

import { useParams } from 'react-router-dom'
import { workloadFieldsCfg, riskCfg, useEntitiesActivitiesDataAgg, datasetColors, defaultRiskThresholds, useSelectedCohortSetting } from '../../util'
import SafetyProfile from '../../components/Profile'


const ProfileModalLink = React.memo(({ entity, entityName, dateRange }) => {
  const profileModalProps = React.useMemo(() => {    
    return {
      variant: 'view',
      variantOpts: {
        confirmLabel: 'Done',
        size: 'full',
        showHeader: false,
      },
      Content: SafetyProfile,
      contentProps: {
        entity,
        entityName,
        dateRange,
      }
    }
  }, [
    entity,
    entityName,
    dateRange,
  ])

  return (
    <ModalLink
      label={entityName}
      size='small'
      modalProps={profileModalProps}
      // image={entity.picture.data}
    />
  )
})

const Diagnostics = ({ dateRange, setDateRange }) => {
  const [ data, setData ] = React.useState()

  const selectedCohort = useSelectedCohortSetting(0, {})

  const riskThresholds = selectedCohort[0]?.attributes?.riskSettings || defaultRiskThresholds

  useEntitiesActivitiesDataAgg({
    dataHandler: setData,
    dateRange,
    averagePerDay: true,
    appusersWhere: selectedCohort,
    riskThresholds,
  })

  const { riskSetting } = useParams()

  const dateRangeRef = React.useRef(dateRange)
  dateRangeRef.current = dateRange

  const tableModelOpts = React.useMemo(() => {
    let riskItems = riskCfg

    switch (riskSetting) {
      case 'highRisk': riskItems = [riskCfg[0], riskCfg[1]]
        break
      case 'mediumRisk': riskItems = [riskCfg[1]]
        break
      case 'lowRisk': riskItems = [riskCfg[2]]
        break
      default:
        break
    }

    return {
      variant: 'entitiesActivityDataAgg',
      variantOpts: {
        dbEntityType: 'appusers',
        // generic
        showSearch: true,
        exportOpts: {
          exportButton: true,
          exportFileName: 'Worker Diagnostics'.replace(' ', ''),
        },
        fieldsCfg: [
          ...riskItems,
          ...workloadFieldsCfg.map((item) => {
            item.isTime = true
            return item
          }),
        ],
        entityColComp: ProfileModalLink,
        entityColTitle: 'Worker',
        entityColPathedProps: {
          dateRange: (_) => dateRangeRef.current,
        },
      }
    }
  }, [ riskSetting ])

  return (
    <Grid
      gridAutoColumns='1fr'
      gridGap='32px'
    >
      <ControlBar pageTitle='Worker Diagnostics' setDateRange={setDateRange} dateRange={dateRange}/>
      <Grid
        gridGap='16px'
        gridAutoFlow='column'
        gridAutoColumns='max-content'
      >
        <CohortSelectorSetting
          settingsPath={0}
          showAddButton={false}
          noneVal={{}}
          markerColor={datasetColors[0]}
          noneText='All Workers'
          hasNoneOption={!selectedCohort?.id}
        />
      </Grid>
      <MaterialTableWrapper items={data} tableModelOpts={tableModelOpts} />
    </Grid>
  )
}

export default Diagnostics