import moment from 'moment-timezone'
import { useApiCall } from '../../lib/useApi'

import LabeledIcon from '../../components/LabeledIcon'

// TODO just make this a component and put ContentComp in every item
const cardPropsBase = {
  ContentComp: LabeledIcon,
  backgroundPosition: 'right',
  backgroundRepeat: 'no-repeat',
  bgimage: 'chartGraphic.fadeLeft.white',
  boxShadow_hover: 'card_hover',
}

const activeUsersApiCallArgsBase = {
  method: 'post',
  urlBase: '/saas/v1/stats/v1/eventNamesCountsStats',
  useTimezone: true,
  includeAppIds: true,
  reqDataBase: {
    interval: null,
    uniqueByAppuser: true,
  },
  responseTransformer: (res, req) => {
    return {
      label: res.total.toLocaleString(),
    }
  },
  // debug: true,
}

const cardsCfg = [
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/activeUsers_daily',
    bgimage: 'barGraphic.fadeLeft.white',
    contentProps: {
      IconComp: 'fas fa-calendar-day',
      label: '---',
      labelSecondary: 'Daily Active Users',
      labelTertiary: `${moment().startOf('day').format('dddd, MMM DD')}`,
      useData: {
        useDataHook: useApiCall,
        args: {
          ...activeUsersApiCallArgsBase,
          reqDataFunc: () => ({
            start: moment().startOf('day').toISOString(),
            end: moment().toISOString(),
          }),
        }
      },
    },
  },
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/activeUsers_weekly',
    bgimage: 'barGraphic.fadeLeft.white',
    contentProps: {
      IconComp: 'fas fa-calendar-week',
      label: '---',
      labelSecondary: 'Weekly Active Users',
      labelTertiary: `since ${moment().startOf('isoWeek').format('dddd, MMM DD')}`,
      useData: {
        useDataHook: useApiCall,
        args: {
          ...activeUsersApiCallArgsBase,
          reqDataFunc: () => ({
            start: moment().startOf('isoWeek').toISOString(),
            end: moment().toISOString(),
          }),
        }
      },
    },
  },
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/activeUsers_monthly',
    bgimage: 'barGraphic.fadeLeft.white',
    contentProps: {
      IconComp: 'fas fa-calendar-alt',
      labelSecondary: 'Monthly Active Users',
      labelTertiary: `since ${moment().startOf('month').format('dddd, MMM DD')}`,
      label: '---',
      useData: {
        useDataHook: useApiCall,
        args: {
          ...activeUsersApiCallArgsBase,
          reqDataFunc: () => ({
            start: moment().startOf('month').toISOString(),
            end: moment().toISOString(),
          }),
        }
      },
    },
  },
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/events',
    bgimage: 'barGraphic.fadeLeft.white',
    contentProps: {
      label: 'Event Analysis',
      IconComp: 'fas fa-chart-bar',
    },
  },
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/retention',
    contentProps: {
      label: 'Retention',
      IconComp: 'fas fa-magnet',
    },
  },
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/funnel',
    contentProps: {
      label: 'Funnels',
      IconComp: 'fas fa-filter',
    },
  },
  // {
  //   ...cardPropsBase,
  //   routeTo: '/analysis/chart/explore',
  //   // bgimage: 'barGraphic.fadeLeft.white',
  //   // bgimage_hover: 'barGraphic.fadeLeft.white_hover',
  //   // gridColumnStart: 'span 3',
  //   contentProps: {
  //     label: 'Explore',
  //     IconComp: ExploreOutlined,
  //   },
  // },
]

export default cardsCfg
