import React from 'react';
import {useSelector} from 'react-redux';
import { useLocation } from "react-router-dom";

import Header from './Header';
import Menu from './Menu'

import MenuConfig from './MenuConfig';
import useStyles from '../lib/useStyles'
import { Flex } from '../components/Base'

const menuItemAttribute = (pathname, attr) => {
  // console.log(pathname)
  // const currentPage = pathname.split(/\//)[1]; // from location.pathname ... will be something like "/apps"
  const allItems = [
    ...MenuConfig.aside.items,
  ];
  const item = allItems.find(item => pathname.startsWith('/' + item.page));
  if ( ! item ) return undefined;
  return item[attr];
}

const Layout = ({children}) => {
  const classes = useStyles();

  const location = useLocation();
  const organization = useSelector(store => store.auth.organization);
  const app = useSelector(store => store.auth.app);

  const [sidebar, setSidebar] = React.useState(false);

  // When the location changes, look in the MenuConfig to see if the sidebar should
  // be visible or not.  Use a state variable to turn it off or on.
  React.useEffect(() => {
    setSidebar(
      menuItemAttribute( location.pathname, 'sidebar' ) ? true : false
    );
  }, [location] );

  return(
    <>
    <Flex minHeight='100%' width='100%'>
      {
        sidebar &&
        <div className={classes.sidebarContainer}>
          <Menu />
        </div>
      }
      <Flex className={classes.pageContainer}>
        <div className={classes.header}>
          <Header organization={organization} app={app} pageTitle={menuItemAttribute(location.pathname, 'title')}/>
        </div>
        <div className={classes.page}>
          {children}
        </div>
      </Flex>
    </Flex>
    </>
  );
}

export default Layout;
