import React from 'react'

import { selectedValAndAllowedItemsFromCfg } from '../../../lib/state'
import moment from 'moment-timezone'
import deepMerge from '../../../lib/deepMerge'

import { KeyboardReturn } from '@material-ui/icons'

const defaultConditionOpsItemsCfg = {
  '==': {
    label: '==',
  },
  '!=': {
    label: '!=',
  },
  '<': {
    label: '<',
  },
  '<=': {
    label: '<=',
  },
  '>': {
    label: '>',
  },
  '>=': {
    label: '>=',
  },
  'contains': {
    label: 'contains',
  },
  '!contains': {
    label: '!contains',
  },
}

const stateVariants = {
  default() {
    return {
      gridProps: {},
      conditionalValCompResolver: null,
      controls: {
        field: selectedValAndAllowedItemsFromCfg({
          itemsCfg: null,
          placeholderLabel: 'field',
        }),
        conditionOp: selectedValAndAllowedItemsFromCfg({
          itemsCfg: defaultConditionOpsItemsCfg,
          firstIsDefault: true,
          sortByKey: false,
        }),
        conditionVal: {
          val: null,
          placeholder: 'Select value...',
          options: [],
          _validator: (val) =>  val && val !== '',
          minwidth: '230px',
        },
        execute: {
          // variant='textual'
          // color='primary'
          // val: 'Add Condition →',
          title: 'Add Contition',
          icon: KeyboardReturn,
          variant: 'icon',
          color: 'primary',
        },
      },
      _hooks: {
        _valTypeHandler: {
          useHook: (state, methods) => {
            const {
              fieldValType,
              _tempConditionValOptions,
            } = state

            React.useEffect(() => {
              if (fieldValType === 'iso') {
                // TODO_MAYBE handle data format options conversions inside of TextField component

                const momentToNativeFormat = moment.HTML5_FMT.DATETIME_LOCAL
                
                methods.setConditionValState({
                  inputType: 'datetime-local',
                  max: moment().add(1, 'hours').format(momentToNativeFormat),
                  minwidth: '280px',
                  _validator: (val) => val != null && (val.val || moment(val, momentToNativeFormat).isValid()),
                  _other: {
                    executeOutputTransformer: (val) => val != null && moment(val.val || val, momentToNativeFormat).toISOString(),
                  },
                })
                methods.setConditionValOptions(_tempConditionValOptions.map((item) => {
                  const m = moment(item.val)
                  const nativeFmt =  m.format(momentToNativeFormat)
                  return {
                    label: m.format('lll'),
                    inputVal: nativeFmt,
                    val: nativeFmt,
                  }
                }))
              }
              else if (fieldValType === 'numeric') {
                methods.setConditionValState({
                  _validator: (val) =>  val != null && !isNaN(val.val || val)
                })
                methods.setConditionValOptions(_tempConditionValOptions)
              }
            }, [
              _tempConditionValOptions,
              methods,
              fieldValType,
            ])
          }
        }
      },
    }
  },
}

const postProcessor = (o, variantOpts) => {
  const {
    fieldsOpts,
    clearOnExecute,
    allowCohorts,
  } = variantOpts

  o = deepMerge(
    {
      allowCohorts,
      clearOnExecute,
      fieldsOpts: {
        placeholderLabel: 'Select property...',
      },
    },
    {
      fieldsOpts,
    },
    o,
  )

  return o
}

export default {
  stateVariants,
  postProcessor
}