import React from 'react';
import { Text, Flex, IconResolver } from './Base'
// import { useTheme } from '@material-ui/core/styles'

const LabeledIcon = React.memo(({ label, labelSecondary, labelTertiary, useData, IconComp, ...props }) => {
  // const theme = useTheme()
  
  if (useData) {
    const { useDataHook, args } = useData
    const data = useDataHook(args)
    if (data) {
      label = data.label || label
      labelSecondary = data.labelSecondary || labelSecondary
      labelTertiary = data.labelTertiary || labelTertiary
    }
  }

  return(
    <Flex
      flexDirection='row'
      alignItems='stretch'
      minHeight='64px'
      { ...props }
    >
      <Flex
        alignItems='center'
      >
        <Flex
          height='48px'
          width='48px'
          alignItems='center'
          justifyContent='center'
        >
          <IconResolver icon={IconComp} fontSize='20px' color='text.disabled'/>
        </Flex>
      </Flex>
      <Flex
        flexDirection='column'
        justifyContent='space-between'
        marginLeft='16px'
      >
        {
          labelSecondary
            ? (
              <>
                <Text variant='value1' color='primary.main'>{label}</Text>
                <Text variant='subtitle3'>{labelSecondary}</Text>
                <Text variant='small3' color='text.secondary'>{labelTertiary}</Text>
              </>
            ) : (
              <Flex
                alignItems='center'
                height='100%'
              >
                <Text variant='subtitle3'>{label}</Text>
              </Flex>
            )
        }
      </Flex>
    </Flex>
  );
})

export default LabeledIcon