import React from 'react'

import { useSelector } from 'react-redux'
import { keyedHooks, rolePermissionHooks } from './hooks'

// import { dbEntitiesStoreActions } from '../store/dbEntities'

// TODO use entities store
// TODO remove need for app listening
import useApi from './useApi'
import { useDispatch } from 'react-redux'
import * as auth from '../store/ducks/auth.duck'


const useUpdateOrganization = () => {
  const api = useApi()
  const dispatch = useDispatch()
  const organizationId = useSelector(store => store.auth.organization?.id)
  // const app = useSelector(store => store.auth.app)

  const isSuper = rolePermissionHooks.useIs_superAny()
  const isAdmin = rolePermissionHooks.useIs_orgAdmin()
  const userOrganizationId = useSelector((store) => store.auth.user?.organizationId)
  const isPermitted = isAdmin || isSuper

  React.useEffect(() => {
    if (!organizationId || !isPermitted) return

    const include = [
      "organizations",
      "apps",
    ]

    if (isSuper) {
      include.push("organization")
    }
    else if (isAdmin) {
      // Get parent org only if organizationId is a child org
      if (organizationId !== userOrganizationId) {
        include.push("organization")
      }
    }

    api({
      method: "getFiltered",
      url: `/api/Organizations/${organizationId}`,
      data: {
        include,
      },
      handler: (data) => {
        dispatch(auth.actions.setOrganization(data))
      },
      errorHandler: (err) => {
        if (err.request.status === 404) {
          dispatch(auth.actions.resetOrganization())
        }
      } 
    })
  }, [isPermitted, isSuper, isAdmin, api, dispatch, organizationId, userOrganizationId])
}

const usePopulateOrganizationSubApps = () => {
  const api = useApi()
  const dispatch = useDispatch()
  const organizationId = useSelector(store => store.auth.organization?.id)

  React.useEffect(() => {
    if (!organizationId) return

    api({
      method: "get",
      url: `/api/Organizations/${organizationId}/subApps`,
      handler: (data) => {
        dispatch(auth.actions.orgSetSubApps(data))
      },
    })
  }, [api, dispatch, organizationId])
}

const useGlobalStateHooks = () => {
  keyedHooks.useDbApi_getList(React.useMemo(() => {
    return {
      dbEntityType: 'cohorts',
      reqData: {
        order: 'updatedAt DESC',
        include: [
          {
            relation: 'saasuser',
            scope: {
              fields: [ 'name', 'email' ],
            }
          },
        ],
      }
    }
  }, []))

  useUpdateOrganization()
  usePopulateOrganizationSubApps()
}

export default useGlobalStateHooks