import React from 'react';
import {useSelector} from 'react-redux';
import useStyles from '../../../lib/useStyles'
import { Box, Text, Grid } from '../../../components/Base'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// All of this for a Select!!
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

// For a checkbox
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Slide from '@material-ui/core/Slide';
import {parseRole} from '../../../lib/utils';

import validate from 'validate.js';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// open: boolean
// onClose: function(data)
// user: the user object
const NewUserDialog = ({open, onClose, user}) => {
  const classes = useStyles();

  const loggedInUser = useSelector(store => store.auth.user);
  const org = useSelector(store => store.auth.organization);
  const parentOrgId = org.organizationId
  const parentOrg = org.organization

  const [email, setEmail] = React.useState('');
  const [role, setRole] = React.useState('');
  const [sendInvite, setSendInvite] = React.useState(true);
  const [name, setName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [formErrors, setFormErrors] = React.useState({});
  
  // Return the form data suitable to return the the caller of this dialog
  const getData = () => {
    return {
      email,
      role,
      sendInvite,
      name,
      password,
    };
  }

  // Form contraints and the checker
  //
  const fc1 = {
    email: { presence: true, email: true },
    role: { presence: true, length: {minimum: 1, message: "Must specify a role" }},
  };

  const fc2 = {
    name: { presence: true, length: {minimum: 1} },
    password: { presence: true, length: {minimum: 8} },
  }

  const clearError = (fieldName) => {
    if ( ! formErrors[fieldName] ) return;
    setFormErrors({
      ...formErrors,
      [fieldName]: undefined
    });
  }

  const checkForm = () => {

    const reformat = (result) => {
      let e = {};
      Object.keys(result).forEach((k) => {
        e[k] = result[k][0];
      });
      return e;
    }
    
    let valid = true;
    setFormErrors({});
    let newFormErrors = {};
    let r1 = validate({email, role}, fc1 );
    if (r1) {
      valid = false;
      let r = reformat(r1);
      newFormErrors = {
        ...newFormErrors,
        ...r
      };
    }
    if ( sendInvite === false ) {
      let r2 = validate({name, password}, fc2 );
      if ( r2 ) {
        valid = false;
        let r = reformat(r2);
        newFormErrors = {
          ...newFormErrors,
          ...r
        };
      }
    }
    setFormErrors(newFormErrors);
    return valid;
  }

  // Called when add is clicked.  Check form (and display any errors).
  const checkAndSend = () => {
    if ( ! checkForm() ) return;
    onClose(getData());
  }
  
  // Make sure we start clean
  React.useEffect(() => {
    if (open) {
      setEmail('');
      setRole('');
      setName('');
      setPassword('');
      setFormErrors({});
      setSendInvite(true);
    }
  }, [open]);

  // The set of roles available to choose from depend on the role of the logged in user.  If the
  // logged in user is a zhortech:admin, then any role is possible.  If the logged in user is a org:admin,
  // then only org:admin and org:user are possible.
  //
  // If the logged in user is *:user, then they shouldn't even be able to add a user.
  //

  // If sendInvite is set to false, then prompt for name and password.

  const urole = parseRole(loggedInUser.role);
  const roles = [
    { name: "Org User", value: "org:user" },
  ];
  // only if the org allows creation of admin users
  if ( org?.canCreateAdminUsers ) {
    roles.push({ name: "Org Admin", value: "org:admin" });
  }
  else if ( parentOrgId && parentOrg?.canCreateAdminUsers ) {
    roles.push({ name: "Org Admin", value: "org:admin" });
  }
  // only if the user is a zhortech admin AND the current organization is Zhortech
  if ( urole.zt && org.name === 'Zhortech' ) {
    roles.push({ name: "ZT Admin", value: "zhortech:admin" });
    roles.push({ name: "ZT User", value: "zhortech:user" });
  }


  return(
    <Dialog open={open} onClose={() => onClose(null)} TransitionComponent={Transition} fullWidth={true} maxWidth="sm">
      <DialogContent>
        <Grid gridGap='24px' mb='16px'>
          <Text variant='h5'>
            New Portal User
          </Text>
          <Box>
            <Text variant='small2' mb='12px'>
              Add a new user to the <strong>{org.name}</strong> organization.
            </Text>
            <Text variant='small2'>
              To create a pre-verified user, deselect "Send Invite" and enter a user name and password.
            </Text>
          </Box>

          <FormControl
            fullWidth
            className={classes.formControl}
            error={formErrors['email'] !== undefined}
          >
            <Text variant='small1' pb='6px'>
              {'Email'}
            </Text>
            <TextField
              required
              autoFocus
              fullWidth
              variant='outlined'
              type="email"
              value={email}
              onFocus={() => clearError('email')}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormHelperText>{formErrors['email']}</FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            className={classes.formControl}
            error={formErrors['role'] !== undefined}
          >
            <Text variant='small1' pb='6px'>
              {'Role'}
            </Text>
            <Select
              required
              value={role}
              variant='outlined'
              onFocus={() => clearError('role')}
              onChange={(e) => setRole(e.target.value)}
            >
              {roles.map((r) => (
                <MenuItem key={r.value} value={r.value}>{r.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{formErrors['role']}</FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            className={classes.formControl}
          >
            <FormControlLabel
              label={<Text variant='small1'>{'Send Invite Email'}</Text>}
              control={
                <Checkbox
                  checked={sendInvite}
                  onChange={(e) => setSendInvite(e.target.checked)}
                  value="send-invite"
                  size='small'
                />
              }
            />
            <FormHelperText>Deselect to create a pre-validated user</FormHelperText>
          </FormControl>
          {sendInvite===false && (
            <>
            <FormControl
              fullWidth
              className={classes.formControl}
              error={formErrors['name'] !== undefined}
            >
              <Text variant='small1' pb='6px'>
                {'Full Name'}
              </Text>
              <TextField
                required
                fullWidth
                variant='outlined'
                type="text"
                value={name}
                onFocus={() => clearError('name')}
                onChange={(e) => setName(e.target.value)}
              />
              <FormHelperText>{formErrors['name']}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              className={classes.formControl}
              error={formErrors['password'] !== undefined}
            >
              <Text variant='small1' pb='6px'>
                {'Password'}
              </Text>
              <TextField
                required
                fullWidth
                variant='outlined'
                type="text"
                value={password}
                onFocus={() => clearError('password')}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormHelperText>{formErrors['password']}</FormHelperText>
            </FormControl>
            </>
          )}
        </Grid>
        <DialogActions>
          <Button onClick={() => onClose(null)} color="primary" variant='outlined' size='small'>Cancel</Button>
          <Button onClick={checkAndSend} color="primary" variant='contained' size='small'>Add</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );  
}

export default NewUserDialog;
