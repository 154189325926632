import { KeyboardReturn } from '@material-ui/icons'

const stateVariants = {
  default(variantOpts) {
    const {
      clearOnExecute = true,
    } = variantOpts

    return {
      gridProps: {},
      clearOnExecute,
      controls: {
        newKey: {
          val: null,
          placeholder: 'Key',
          defaultVal: '',
          methodKey: 'setNewKey',
          _validator: (val) =>  val && val !== '',
          minwidth: '248px',
        },
        newValue: {
          val: null,
          placeholder: 'Value',
          defaultVal: '',
          methodKey: 'setNewValue',
          _validator: (val) =>  val && val !== '',
          minwidth: '172px',
        },
        execute: {
          title: 'Add',
          icon: KeyboardReturn,
          methodKey: 'execute',
          variant: 'icon',
          color: 'primary',
        },
      },
    }
  },
}

export default {
  stateVariants,
}