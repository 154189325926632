import React from 'react';
import {useParams} from "react-router-dom";
import useApi from '../../lib/useApi';
import moment from 'moment-timezone';

import clsx from 'clsx';

//import useStyles from '../../lib/useStyles'
import { fade, makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  InputBase,
  TextField,
  Button,
  IconButton,
  Tooltip
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ReloadIcon from '@material-ui/icons/Replay';
import extractJson from './extract-json';

const useStyles = makeStyles(theme => ({
  logs_grow: {
    flexGrow: 1,
  },
  logs_search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  logs_searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logs_inputRoot: {
    color: 'inherit',
  },
  logs_inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  logger: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    height: "85vh",
  },
  logContainer: {
    width: "100%",
    overflowX: "scroll",
    overflowY: "scroll",
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    height: "100%",
    fontFamily: "Consolas, Monaco, \"Andale Mono\", \"Ubuntu Mono\", monospace",
    whiteSpace: "pre",
    lineHeight: "1.5",
    tabSize: "4",
  },
  logLine: {
  //    whiteSpace: "nowrap",
  },
  logs_dt: {
    color: 'white',
  },
  logs_selected: {
    backgroundColor: 'green'
  },
}))

const lineStyles = {
  debug: {
    color: 'Cyan',
  },
  warn: {
    color: 'LightSalmon',
  },
  error: {
    color: 'red',
  },
  info: {
  },
  other: {
  },
};


// For adding colors to events
const debugRE = new RegExp(/\[debug\]/);
const warnRE = new RegExp(/\[warn\]/);
const errorRE = new RegExp(/\[error\]/);
const infoRE = new RegExp(/\[info\]/);

const dateRE = new RegExp(/(^\d{4}-\d{2}-\d{2}T\d{2}:\d{2})/);

const Logs = () => {
  const classes = useStyles();

  const {q} = useParams();

  // eslint-disable-next-line
  const [start, setStart] = React.useState();
  // eslint-disable-next-line
  const [end, setEnd] = React.useState();

  const [rangeStart, setRangeStart] = React.useState('');
  const [rangeEnd, setRangeEnd] = React.useState('');

  const [searchString, setSearchString] = React.useState(q);
  const [qq, setQQ] = React.useState(q);

  const [messages, setMessages] = React.useState([]);

  const [selected, setSelected] = React.useState(-1);

  const api = useApi();

  const containerRef = React.useRef();

  const fetchData = React.useCallback(() => {

    const data = {
      q: qq || '',
      //start,
      //end
      //follow: true
    };

    console.log(`start: ${start}, end: ${end}`);

    if (start && end) {
      data.start = start;
      data.end = end;
    } else {
      data.follow = true;
    }

    setSelected(-1);

    api({ method: "post", url: `/saas/v1/users/v1/logSearch`, data, handler: (response) => {
      let events = response.events;
      let msgs = events.map((e) => {
        let style = lineStyles.other;
        if ( infoRE.test(e.message) ) style = lineStyles.info;
        else if ( debugRE.test(e.message) ) style = lineStyles.debug;
        else if ( warnRE.test(e.message) ) style = lineStyles.warn;
        else if ( errorRE.test(e.message) ) style = lineStyles.error;
        return {
          id: e.id,
          message: e.message,
          style,
        };
      });
      setMessages(msgs);
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }});

  }, [api, qq, start, end] );

  React.useEffect(() => {
    fetchData();
  }, [fetchData] );

  const newRange = () => {
    setQQ(searchString);

    if (dateRE.test(rangeStart) && dateRE.test(rangeEnd)) {
      setStart(moment(rangeStart,'YYYY-MM-DDThh:mm'));
      setEnd(moment(rangeEnd,'YYYY-MM-DDThh:mm'));
    }
    else {
      setStart(null);
      setEnd(null);
    }
  }

  const handleKeyDown = (e) => {
    if(e.keyCode === 13)
      setQQ(searchString);
  }

  const lineSelect = (line) => {
    let o = extractJson(line);
    if (!o) return null;
    return JSON.stringify(o, null, 2);
  }

  return(
    <div className={classes.logger}>
    <div className={classes.logContainer} ref={containerRef}>
      {messages.map((m) => {
         return(
           <div key={m.id}>
            <div
                className={clsx((selected===m.id?classes.logs_selected:null))}
                style={m.style}
                onClick={() => {if (selected===m.id) setSelected(""); else setSelected(m.id);}}>{m.message}
            </div>
            {(selected === m.id && lineSelect(m.message)!== null) ? <p>{lineSelect(m.message)}</p> : null}
          </div>
         )
      })}
    </div>
    <div className={classes.logs_grow}>
      <AppBar position="static">
        <Toolbar>
          <div className={classes.logs_search}>
            <div className={classes.logs_searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
                placeholder="Search…"
                classes={{
                    root: classes.logs_inputRoot,
                    input: classes.logs_inputInput,
                  }}
                inputProps={{ 'aria-label': 'search' }}
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <Tooltip title="fetch more logs" placement='right-end'>
              <IconButton aria-label="reload logs" size="small" style={{color: "white"}} onClick={fetchData}>
                <ReloadIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.logs_grow} />
          <div>
            <span>filter date range:</span>&nbsp;
            <TextField
                InputProps={{className: classes.logs_dt}}
                InputLabelProps={{className: classes.logs_dt}}
                //label="starting"
                type="datetime-local"
                value={rangeStart}
                onChange={(e) => setRangeStart(e.target.value)}
            />
            <TextField
                InputProps={{className: classes.logs_dt}}
                InputLabelProps={{className: classes.logs_dt}}
                //label="and ending"
                type="datetime-local"
                value={rangeEnd}
                onChange={(e) => setRangeEnd(e.target.value)}
            />
            <Button style={{verticalAlign: "bottom", marginBottom: "1px"}} variant="contained" size="small" onClick={newRange}>go</Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>

    </div>
  );
}

export default Logs;

