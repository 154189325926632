import { useModel } from '../../../lib/state'
import dbEntityModelFactory from '../entityModelFactory'
import initEntityModel from './initEntityModel'
import ContentComps from './ContentComps'

// Actions imports
import React from 'react'
import { getLongEntityLabel } from '../util'
import { ModalButton } from '../../Modal'
import { PlayArrowOutlined } from '@material-ui/icons'
import { keyedHooks } from '../../../lib/hooks'

const managementTableCfg = {
  showRowManagementActions: true,
  dynamicPathRoot: 'attributes',
  staticCols: [
    {
      path: 'attributes._v',
      dataKey: '_v',
      colMUIT: {
        hidden: true,
      },
    },
    {
      path: 'attributes.lastTriggerOn',
      dataKey: 'lastTriggerOn',
      type: {
        customType: 'materialTableIso',
      },
      colMUIT: {
        title: 'Last Triggered',
      },
    },
    {
      path: 'attributes.timesTriggered',
      dataKey: 'timesTriggered',
      colMUIT: {
        title: 'Times Triggered',
      },
    },
    {
      path: 'attributes.messagesSent',
      dataKey: 'messagesSent',
      colMUIT: {
        title: 'Messages Sent',
      },
    },
    {
      path: [
        'saasuser.name',
        'saasuser.email',
      ],
      dataKey: 'createdBy',
      colMUIT: {
        title: 'Created By',
      },
    }
  ],
}

let entityCfg = {
  ContentComps,
  dbEntityType: 'campaigns',
  label: 'Campaign',
  labelPlural: 'Campaigns',
  icon: 'fas fa-flag-checkered',
  relations: {
    belongsTo: {
      organization: true,
      app: true,
      saasuser: true,
    },
    hasOne: {},
    hasMany: {
      campaignEvents: true,
    },
  },
  actionsPermissionCfgs: {
    role: {
      anyAdmin: { edit: true, archive: true, delete: true, activate: true, },
      anyRole:  { new: true, clone: true },
    },
    entity: {
      owner: { edit: true, },
    },
  },
  modalOpts: {
    base: {
      editor: {
        size: 'large',
      },
      archiver: {
     
      },
    },
  },
  manageIsTable: true,
  manage: {
    tabsCfg: {
      '/create': {
        label: 'Create',
        filter: (item) => item.isActive === false &&  item.isArchived === false,
        table: {
          ...managementTableCfg,
          allowedActions: [ 'new', 'edit', 'delete', 'activate', 'clone' ],
        },
      },
      '/active': {
        label: 'Active',
        canCreate: false,
        filter: (item) => item.isActive === true && item.isArchived === false,
        table: {
          ...managementTableCfg,
          allowedActions: [ 'archive', 'view', 'clone' ],
        },
      },
      '/archived': {
        label: 'Archived',
        canCreate: false,
        filter: (item) => item.isArchived === true,
        table: {
          ...managementTableCfg,
          allowedActions: [ 'view', 'clone' ],
        },
      },
    },
  }
}

entityCfg = {
  ...entityCfg,
  nameLabel: 'Name',
  nameExportKey: 'name', // Only need with EXPLORE
  nameDataPath: 'name',
  getEntityName(entity) {
    return entity.name
  },
  getNewEntityBase(newEntityOpts = {}) { // OPTIONAL, DELETE this comment or function
    return {
      name: '',
      webhook: null,
      isArchived: false,
      isActive: false,
      isDraft: false,
      selectedContentType: null,
      content: null,
      audience: {
        //// cohort
        users: null,
        //// AND OR x # of y ACTIVITIES within z days
        usersActivitesPerformed: null,
        //// tempotrans
        // dynamic: {},
      },
      // TODO selected scheduling type
      scheduling: {
        schedulingType: 'once',
        //// once
        on: '',
        //// recurring
        startDate: '',
        time: '',
        endDate: '',
        interval: '',
        //// dynamic (tempotrans)
        // start: '',
        // end: '',
      },
      attributes: {},
    }
  },
}

const defaultEmailTemplateName = 'basic'
const emailTemplates = {
  basic: {
    salutation: '',
    body: '',
    farewell: '',
    logoURL: '',
    color_1: '#007BA7',
  },
  custom: {
    html: null,
  }
}

entityCfg = {
  ...entityCfg,
  otherData: {
    defaultEmailTemplateName,
    defaultContentType: 'push',
    
    contentTypes: {
      sms: {
        // disabled: true,
        body: '',
        image: '',
      },
      email: {
        from: {
          email: '',
          name: '',
        },
        replyTo: '',
        bcc: '',
        subject: '',
        template: defaultEmailTemplateName,
        content: emailTemplates[defaultEmailTemplateName],
      },
      push: {
        // disabled: true,
        // TODO ios/android
        title: '',
        body: '',
        // data: undefined,
      },
    }
  },
}

entityCfg = {
  ...entityCfg,
  customActions: {
    activate: {
      modifiesState: true,
      Comp: React.memo(({ entity, onCompleted, disabled, otherData }) => {
          // TODO check for date validity (not relevant while we only have 'once')
          return (
            <ModalButton
              variant='icon'
              icon={PlayArrowOutlined}
              title={`Activate`}
              disabled={disabled}
              modalProps={React.useMemo(() => {
                return {
                  variant: 'performAction',
                  variantOpts: {
                    titlePrefix: 'Activate',
                    entityName: entityCfg.getEntityName(entity),
                    entityLabel: getLongEntityLabel(entityCfg, entity, null),
                    confirmPrefix: 'Activate',
                    text: 'The campaign will no longer be editable.'
                  },
                  // TODO_MAYBE generic
                  Content: ({ formMethods }) => {
                    const [ executeToken, setExecuteToken ] = React.useState()

                    React.useEffect(() => {
                      formMethods.setOnConfirm(() => setExecuteToken(Math.random()))
                    }, [ formMethods ])

                    keyedHooks.useDbApi_patchById(
                      {
                        id: executeToken && entity.id,
                        dbEntityType: entityCfg.dbEntityType,
                        data: React.useMemo(() => ({
                          isActive: true,
                        }), [])
                      },
                      React.useCallback(() => {
                        formMethods.close()
                        if (onCompleted) onCompleted()
                      }, [ formMethods ])
                    )

                    return null
                  }
                }
              }, [ entity, onCompleted ])}
            />
          )
      })
    },
  },
}

const entityModel =  dbEntityModelFactory(initEntityModel(entityCfg))
const useEntityModel = (stateInitializerArgs) => useModel(entityModel, stateInitializerArgs)
entityCfg.useEntityModel = useEntityModel

export default entityCfg