export default {
  pageSize: 10,
  pageSizeOptions: [10, 20, 30, 50],
  rowStyle: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', },
  headerStyle: {
    whiteSpace: 'nowrap',
    backgroundColor: '#F3F3F3',
    borderTop: '1px solid #E8E8E8',
    padding: '8px 16px',
  },
  // paginationType: 'stepped',
  padding: 'dense',
  // paging: false,
}