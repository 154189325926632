import React from 'react'

import { riskCfg } from '../util'

import { Box, Grid, Text } from '../../../components/Base'
import { ModalIcon } from '../../../components/Modal'

const Content = () => {

  return (
    <Grid
      gridGap='24px'
      gridAutoFlow='row'
      gridAutoRows='max-content'
    >
      <Box>
        <Text
          variant='small1'
          color={riskCfg[0].color}
        >
          {riskCfg[0].label}
        </Text>
        <Text
          variant='small3'
        >
          Worker has <strong>1 or more</strong> High Risk metrics.
        </Text>
      </Box>
      <Box>
        <Text
          variant='small1'
          color={riskCfg[1].color}
        >
          {riskCfg[1].label}
        </Text>
        <Text
          variant='small3'
        >
          Worker has <strong>1 or more</strong> Medium Risk metrics & <strong>0</strong> High Risk metrics.
        </Text>
      </Box>
      <Box>
        <Text
          variant='small1'
          color={riskCfg[2].color}
        >
          {riskCfg[2].label}
        </Text>
        <Text
          variant='small3'
        >
          Worker has <strong>0</strong> Medium Risk & <strong>0</strong> High Risk metrics.
        </Text>
      </Box>
    </Grid>
  )
}

const RiskDefinitions = React.memo(() => {
  const profileModalProps = React.useMemo(() => {
    return {
      variant: 'view',
      variantOpts: {
        title: 'Risk Threshold Definitions',
        confirmLabel: 'Close',
        size: 'fit',
      },
      Content: Content,
      contentProps: {}
    }
  }, [])

  return (
    <ModalIcon
      icon='far fa-question-circle'
      modalProps={profileModalProps}
    />
  )
})

export default RiskDefinitions