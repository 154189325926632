import { useModel, objectVariantOrPassthrough } from '../../../lib/state'
import modelVariants from './TreeBuilderModelStateVariants'

// TODO validatation (has query)
const model = {
  //
  // STATE
  //
  stateInit: (stateIntializerArgs) => {
    const newState = objectVariantOrPassthrough(modelVariants, stateIntializerArgs)
    return newState
  },
  //
  // METHODS
  //
  methodsInit: (state) => {
    return {
    }
  },
}

export default (stateInitializerArgs) => {
  return useModel(model, stateInitializerArgs)
}