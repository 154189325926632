import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useApi from '../../../lib/useApi'
import useService from "../../../lib/useService";
import useStyles from '../../../lib/useStyles'
import {parseRole} from '../../../lib/utils'
import { ExpandMoreOutlined, ImageOutlined } from '@material-ui/icons'
import {useSelector} from 'react-redux'
import { Box, Flex, Text, Card, } from '../../Base'
import AttributeList from '../../AttributeList'
import moment from 'moment-timezone'
import Confirm from '../../Confirm'

import {
  Button,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Menu, MenuItem,
  Typography,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

// const DEFAULT_IMG = "/media/users/image-outline.png"

const getQrcodeImage = async(attributes) => {
  if (!attributes.serialNumber) return null;

  const { labels, hardwareIds } = require('./labelData.json');
  const serialRegex = /^(?<prefix>ZT.-....)(?<side>[xLR])-(?<hwId>[0-9]{2})(?<tester>.)X-(?<year>[0-9]{2})(?<week>[0-9]{2})(?<serial>[0-9]{5})$/;
  const qrCodeRegex = [
      // Old \n separated qrCode for ZT2, ZT3
      /^(?<serial>ZT.*?)\s*\n(?<macleft>[0-9A-F:]+)\s*\n(?<macright>[0-9A-F:]+)\s*$/,

      // New GS1-based datamatrix (ZT4, ZT5)
      /\x1D250(?<serial>[^\x1D]+)\x1D91(?<macleft>[0-9A-F:]+)\x1D92(?<macright>[0-9A-F:]+)/ // eslint-disable-line no-control-regex
  ];

  const {hwId, tester, year, week, serial} = serialRegex.exec(attributes.serialNumber).groups;
  const match = qrCodeRegex.map(regex => regex.exec(attributes.qrCode)).find(match => match != null);

  if (!match) return null;

  const {macleft, macright} = match.groups;
  const hwName = hardwareIds[hwId];
  const label = labels[hwName];

  const zpl = label.zpl
      .replaceAll("%abbr%", `${year}${week}${tester}${serial}`)
      .replaceAll("%serialnumber%", attributes.serialNumber)
      .replaceAll("%macleft%", macleft)
      .replaceAll("%macright%", macright);

  const labelUrl = `https://api.labelary.com/v1/printers/${label.resolution}/labels/${label.size}/0/${encodeURIComponent(zpl)}`

  const labelRequest = await fetch(labelUrl, {
      referrerPolicy: 'no-referrer',
      headers: {
          "X-Rotation": label.rotation ?? 0
      }
  })

  return URL.createObjectURL(await labelRequest.blob())
}

function QRCode({attrs}) {
  const [image, setImage] = React.useState(null);

  React.useEffect(() => {
    let ignore = false;

    getQrcodeImage(attrs).then(url => {
        if (!ignore) {
            setImage(url)
        }
    })

    return () => {
        ignore = true
    }
  }, [attrs]);

  return (
    attrs && image ?
    <Text>
      <img src={image} alt="Barcode"/>
    </Text> :
    <CircularProgress />
  );
}

const ShoesProfile = () => {
  const { id } = useParams()
  const api = useApi()
  const {notify} = useService()
  const classes = useStyles();

  const [_attrs, setAttrs] = React.useState({})
  const [_code, setCode] = React.useState()
  const [_createdAt, setCreatedAt] = React.useState()
  const [_updatedAt, setUpdatedAt] = React.useState()

  // Used for the admin menu
  const loggedInUser = useSelector(store => store.auth.user);
  const urole = parseRole(loggedInUser.role);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  // Admin Menu stuff
  const handleAdminMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAdminMenuClose = () => {
    setAnchorEl(null);
  };

  const [openDeleteUserConfirm, setOpenDeleteShoeConfirm] = React.useState(false);
  const [openDeleteUserActivityConfirm, setOpenDeleteShoeActivityConfirm] = React.useState(false);

  React.useEffect(() => {
    api({
      method: "get",
      url: `/api/Shoes/${id}`,
      data: {
        id,
      },
      handler: (res) => {
        setAttrs(res.attributes)
        setCode(res.code)
        setCreatedAt(moment(res.createdAt).format('lll'))
        setUpdatedAt(moment(res.updatedAt).fromNow())
      },
    })

  }, [id, api] );

  const deleteShoe = (data) => {
    setOpenDeleteShoeConfirm(false);
    if ( ! data ) return;
    api({method: "delete", url: `/api/Shoes/${id}`, handler: () => {
      history.goBack();
    }});
  }

  const deleteShoeActivity = (data) => {
    setOpenDeleteShoeActivityConfirm(false);
    if ( ! data ) return;
    api({method: "delete", url: `/api/Shoes/${id}/chunks`, handler: () => {
      notify("success", "All activity data deleted!");
    }});
  }

  const [showQRCode, setShowQRCode] = React.useState(false);

  return(
    <>
    <Flex>
      <Flex flexDirection='column' width='100%'>
        <Card alignItems='start' mb='32px' p='16px' display='flex' justifyContent='space-between'>
          <Flex alignItems='center'>
            <Flex className={classes.avatarIconContainer}>
              <ImageOutlined className={classes.avatarIcon}/>
            </Flex>
            <Box ml='16px' >
              <Text variant='h6' mb='8px'>{_code}</Text>
              <Flex>
                <Text variant="subtitle2" color="text.secondary" className={classes.tableCell_Head} >Created </Text>
                <Text variant="body2" color="text.secondary" className={classes.tableCell_Body2} >{_createdAt}</Text>
              </Flex>
              <Flex>
                <Text variant="subtitle2" color="text.secondary" className={classes.tableCell_Head}>Updated </Text>
                <Text variant="body2" color="text.secondary" className={classes.tableCell_Body2} >{_updatedAt}</Text>
              </Flex>
              {/* <Flex>
                <Text variant="subtitle2" color="text.secondary" className={classes.tableCell_Head}>Last Used By </Text>
                <Text variant="body2" color="text.secondary" className={classes.tableCell_Body2}> </Text>
              </Flex> */}
            </Box>
          </Flex>
          <Box>
          {urole.admin && (
                <>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    size='small'
                    variant='outlined'
                    color='primary'
                    endIcon={<MenuIcon />}
                    onClick={handleAdminMenuClick}
                  >
                    Admin
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleAdminMenuClose}
                  >
                    <MenuItem onClick={(e) => {handleAdminMenuClose(e); setOpenDeleteShoeConfirm(true);}}>Delete Shoe...</MenuItem>
                    <MenuItem onClick={(e) => {handleAdminMenuClose(e); setOpenDeleteShoeActivityConfirm(true);}}>Delete Activity Data...</MenuItem>
                  </Menu>
                </>
              )}
          </Box>
        </Card>
        <Card mb='32px' p='16px'>
          <ExpansionPanel onChange={(event, expanded) => expanded && setShowQRCode(true)} >
            <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
              <Typography>Show QR code</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {showQRCode && <QRCode attrs={_attrs}/>}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Card>
        <AttributeList title='Shoe Attributes' attrs={_attrs}/>
      </Flex>
    </Flex>
    <Confirm
          size="sm"
          open={openDeleteUserConfirm}
          onClose={deleteShoe}
          title="Delete Shoe"
          passthru={id}
      >
        This development only function will <strong>delete</strong> this shoe. Are you sure you want to do this?
      </Confirm>

      <Confirm
          size="sm"
          open={openDeleteUserActivityConfirm}
          onClose={deleteShoeActivity}
          title="Delete Shoe Activity Data"
          passthru={id}
      >
        This development only function will <strong>delete</strong> this shoe's activity data.  This is irreversible!
        All past activity data will be deleted.  Use this feature only if you want to start capturing new activity
        data from scratch.
      </Confirm>
    </>
  )
}

export default ShoesProfile
