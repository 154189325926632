import { useModel } from '../../../lib/state'
import dbEntityModelFactory from '../entityModelFactory'
import initEntityModel from './initEntityModel'
import ContentComps from './ContentComps'
import { queryData_fields } from '../../Query/util'
import { mapValues } from 'lodash'

import appusersEntityCfg from '../appusers'
import shoesEntityCfg from '../shoes'

let entityCfg = {
  ContentComps,
  dbEntityType: 'cohorts',
  label: 'Cohort',
  labelPlural: 'Cohorts',
  icon: 'fas fa-chart-pie',
  relations: {
    belongsTo: {
      organization: true,
      app: 'OPTIONAL',
      saasuser: true,
    },
    hasOne: {},
    hasMany: {},
  },
  actionsPermissionCfgs: {
    role: {
      anyAdmin: { edit: true, delete: true },
      anyRole:  { new: true, clone: true },
    },
    entity: {
      owner: { edit: true, delete: true },
    },
  },
  modalOpts: {
    base: {
      editor: {
        size: 'fit',
      },
    },
  },
  manage: {
    tabsCfg: {
      // TODO_MAYBE organization level toggle
      '/appusers': {
        label: 'User',
        newEntityOpts: { target: 'appusers' },
        filter: (item) => item.query.target === 'appusers',
      },
      '/shoes': {
        label: 'Shoe',
        newEntityOpts: { target: 'shoes' },
        filter: (item) => item.query.target === 'shoes',
      },
      '/mine': {
        label: 'My Cohorts',
        onlyOwnedBySelf: true
      },
    }
  },
}

const allowedTargetEntities = {
  appusers: appusersEntityCfg,
  shoes: shoesEntityCfg,
}

entityCfg = {
  ...entityCfg,
  otherData: {
    targets: {
      ...mapValues(allowedTargetEntities, (entityCfg) => {
        return {
          label: entityCfg.label,
          labelPlural: entityCfg.labelPlural,
          icon: entityCfg.icon,
        }
      })
    }
  },
}

entityCfg = {
  ...entityCfg,
  getNewEntityBase(newEntityOpts = {}) {
    let ret = {
      name: newEntityOpts.name,
      query: {
        target: newEntityOpts.target,
      },
    }

    if (newEntityOpts.isDefault) {
      ret.attributes = {}
      ret.attributes.isDefault = true
    }

    return ret
  },
  nameLabel: 'Title',
  nameDataPath: 'name',
  getEntityName(entity) {
    return entity.name + ' - Risk Threshold Settings'
  },
  getManagerContentsAndTooltips_single(entity) {
    const fields = queryData_fields(entity.query).map((field) => {
      const split = field.split('.')
      return split[split.length - 1]
    }).join(', ')

    return [ fields, `Included fields: ${fields}` ]
  },
  getEntityLabelPrefix(entity) {
    return null
  },
  getEntityLabelSuffix(entity) {
    return null
  },
}

const entityModel =  dbEntityModelFactory(initEntityModel(entityCfg))
const useEntityModel = (stateInitializerArgs) => useModel(entityModel, stateInitializerArgs)
entityCfg.useEntityModel = useEntityModel

export default entityCfg