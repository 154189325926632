import { useModel, objectVariantOrPassthrough, validateAll, validationReset } from '../../../lib/state'
import { tryTransformToPrimitive } from '../util'
import stateVariants from './EditorModelStateVariants'
//
// Util
//

const _validateAll = (state) => {
  validateAll(state, Object.values(state.controls))
}

const _validationReset = (state) => {
  validationReset(state, Object.values(state.controls))
}

//
// model
//

const model = {
  //
  // STATE
  //
  stateInit: (args) => {
    let ret = {
      ...objectVariantOrPassthrough(stateVariants, args),
      //
    }
  
    return ret
  },
  //
  // METHODS
  //
  methodsInit: (state) => {
    return {
      //
      // control val setters
      //
      setNewKey(val, opts) {
        state.controls.newKey.val = val
        _validateAll(state)
      },
      setNewValue(val, opts) {
        state.controls.newValue.val = val
        _validateAll(state)
      },
      //
      // other
      //
      setOnExecute(val, opts) {
        state.onExecute = val
      },
      execute(val, opts) {
        state.shouldValidate = true
        _validateAll(state)
        if (state.isValid) {
          const val = state.controls.newValue.val
          state.onExecute(
            {
              __key: state.controls.newKey.val,
              __val: tryTransformToPrimitive(val),
            }
          )

          if (state.clearOnExecute) {
            state.controls.newKey.val = state.controls.newKey.defaultVal
            state.controls.newValue.val = state.controls.newValue.defaultVal
            _validationReset(state)
          }
        }
      }
    }
  },
  //
  // Hooks
  //
  hooks: {

  }
}

export default (stateInitializerArgs) => {
  return useModel(model, stateInitializerArgs)
}