import React from 'react'

import { Box, Flex, Grid, Text } from '../../../Base'

import {
  // ExpandMore,
  Notifications
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  AndroidDevice: {
    position: 'relative',
    width: '392px',
    height: '812px',
    borderRadius: '40px',
    backgroundColor: '#fff',
    margin: '-16px 0px',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.card}`,
    padding: '40px 8px 24px',
  },
  screen: {
    position: 'relative',
    height: '100%',
    borderRadius: '20px',
    backgroundColor: theme.palette.bg.secondary,
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.card}`,
  },
  notif_Android: {
    backgroundColor: '#FFF',
    margin: '0px 8px',
    flexDirection: 'column',
    boxShadow: 'rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    padding: '16px',
    cursor: 'pointer',
    position: 'relative',
    top: '40px',
  },
  notif_Header_Android: {
    padding: '0 0 8px',
    gridTemplateColumns: '17px min-content min-content',
    gridTemplateRows: '15px',
    gridGap: '4px',
    alignContent: 'center',
  },
  notif_Content_Android: {
    justifyContent: 'space-between',
    width: '100%',
  },
  notif_Text_Android: {
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  notif_Img_Android: {
    width: '38px',
    height: '38px',
  },
}))

const PreviewPushAndroid = React.memo(({ title, body, appName }) => {
  const classes = useStyles()
  const image = null

  return(
    <Box mt='16px'>
      <Flex className={classes.AndroidDevice}>
        <Flex className={classes.screen}>
          <Flex className={classes.notif_Android}>
            <Grid className={classes.notif_Header_Android}>
              <Notifications style={{height:'15px', width:'17px'}}/>
              <Text variant='pushAppName_Android' color='rgb(99,99,99)' pt='1px'>
                { appName || 'ExampleApp'}
              </Text>
              {/* <ExpandMore style={{height:'15px', width:'15px', paddingTop:'1px'}}/> */}
            </Grid>
            <Flex className={classes.notif_Content_Android}>
              <Flex className={classes.notif_Text_Android}>
                <Text variant='pushTitle_Android' color='rgb(83,83,83)' width='280px' textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>
                  { title || 'Notification Preview'}
                </Text>
                <Text variant='pushMessage_Android' color='rgb(117,117,117)' width='280px' textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>
                  { body || 'Preview your push notification'}
                </Text>
              </Flex>
              <Box className={classes.notif_Img_Android} bgcolor='#e8e8e8' hidden={image !== null ? false : true}/>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
})

export default PreviewPushAndroid