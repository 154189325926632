import { setControlVal } from '../../../lib/state'

import { defaultRiskThresholds } from '../../../plugins/safety/util'

import flat from 'flat'

const initEntityModel = (entityCfg) => {
  return {
    dbEntityType: entityCfg.dbEntityType,
    entityMethodsInit(state) {
      const ret = {}

      Object.entries(defaultRiskThresholds).forEach(([key, arr]) => {
        arr.forEach((defaultVal, i) => {
          const controlKey = `${key}.${i}`
          ret[`set_${controlKey}`] = (val, opts) => setControlVal(state, state.controls[controlKey], val)
        })
      })

      return ret
    },
    getControlsCfg(state, isNew, isClone, isSaveAs, isEdit) {
      const riskSettings = state.attributes?.riskSettings

      const ret = {}

      Object.entries(defaultRiskThresholds).forEach(([key, arr]) => {
        arr.forEach((defaultVal, i) => {
          const controlKey = `${key}.${i}`
  
          ret[controlKey] = {
            methodKey: `set_${controlKey}`,
            defaultVal: riskSettings?.[key]?.[i] || defaultVal || 0,
            _validator: (val) => {
              val = parseFloat(val)
              return (val >= 0)  || 'Number must be >= 0'
            },
            _validateAllowNotChanged: true,
          }
        })
      })

      return ret
    },
    isPartialEntity(data) {
      return !data.name
    },
    handleSetData(state, data, hasControls) {
      if (hasControls) {
        Object.entries(defaultRiskThresholds).forEach(([key, arr]) => {
          arr.forEach((defaultVal, i) => {
            const controlKey = `${key}.${i}`
            state.controls[controlKey].defaultVal = data.attributes?.riskSettings?.[key]?.[i] || defaultVal
          })
        })
      }
    },
    buildOutData(state) {
      let riskSettings = {}
      Object.entries(defaultRiskThresholds).forEach(([key, arr]) => {
        arr.forEach((defaultVal, i) => {
          const controlKey = `${key}.${i}`
          riskSettings[controlKey] = parseFloat(state.controls[controlKey].val) || 0
        })
      })
      riskSettings = flat.unflatten(riskSettings)

      let ret = {
        ...state.initialData,
        attributes: {
          ...state.initialData.attributes,
          riskSettings,
        },
      }

      return ret
    },
  }
}

export default initEntityModel