// import React from 'react'
import { fade, darken, lighten, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  sidebarContainer: {
    width: "216px",
    flexShrink: 0,
    boxSizing: 'border-box',
    backgroundColor: darken(theme.palette.primary.dark, 0.08),
  },
  sidebar: {
    width: "100%",
    position: 'sticky',
    height: '100vh',
    top: '0px',
    left: '0px',
    flexShrink: 0,
  },
  sidebarClosedSidebar: {
    display: "none",
  },
  header: {
    height: '58px',
    boxShadow: 'rgba(67, 90, 111, 0.42) 0px 0px 1px 0px',
    width: '100%',
    zIndex: 1
  },
  content: {
    height: "calc(100% - 58px)",
  },
  page: {
    padding: "32px 32px",
    width: '100%',
  },
  pageContainer: {
    width: '100%',
    flexDirection: 'column',
    overflowY: 'auto'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  formActions: {
    display: "flex",
    margin: '32px 0px 16px 0px',
    justifyContent: "flex-end",
  },
  avatarLarge: {
    width: 128,
    height: 128,
    borderRadius: '50%',
  },
  avatarSmall: {
    width: 24,
    height: 24,
    borderRadius: '50%',
  },
  avatarIcon: {
    width: 64,
    height: 64,
    color: theme.palette.text.disabled,
  },
  avatarIconContainer: {
    padding: '16px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.bg.secondary,
  },
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  appCard: {
    minWidth: 284,
    position: "relative",
    borderRadius: '8px',
  },
  cardContent: {
    // minHeight: 270,
    paddingBottom: '16px',
  },
  appStatusInactive: {
    color: theme.palette.danger.main,
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: theme.palette.bg.secondary,
    borderTop: `1px solid ${theme.palette.border.card}`,
    padding: '4px',
  },
  actionButton: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  currentOrgContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "16px",
    margin: "0px 0px 0px 24px",
    borderRadius: '8px',
    border: '1px solid transparent',
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.bg.primary,
    boxShadow: '0px 4px 8px 0px rgba(37,40,43,0.14)',
  },
  listItemCard: {
    borderRadius: '8px',
    border: '1px solid transparent',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.bg.primary,
    boxShadow: '0px 4px 8px 0px rgba(37,40,43,0.14)',
    '&:hover': {
      backgroundColor: theme.palette.bg.primary,
      boxShadow: '0px 4px 16px -2px rgba(37,40,43,0.2)',
    },
  },
  buttonSubMenu: {
    alignItems: 'center',
    // justifyContent: 'space-between',
    padding: '12px 16px 12px 8px',
    cursor: 'pointer',
    backgroundColor: fade(theme.palette.primary.main, 0.0),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
  controlBar: {
    margin: '0px auto 32px',
    padding: '0px',
    alignItems: "center",
    justifyContent: 'start',
    maxWidth: '916px',
    minHeight: '32px',
  },
  controlBar_full: {
    margin: '0px auto 32px',
    padding: '0px',
    alignItems: "center",
    justifyContent: 'space-between',
    minHeight: '32px',
  },
  controlBar_split: {
    margin: '0px auto 32px',
    padding: '0px',
    alignItems: "center",
    justifyContent: 'space-between',
    minHeight: '32px',
    maxWidth: '916px',
  },
  sideNav_Link: {
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.0125rem',
    lineHeight: '1rem',
    color: fade(theme.palette.primary.contrastText, 0.70),
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },
  scrim: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: fade(theme.palette.text.primary, 0.40),
    zIndex: 1300,
  },
  scrimDark: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: fade(theme.palette.text.primary, 0.70),
    zIndex: 1300,
  },
  tableRow: {
    // borderLeft:'1px solid #E8E8E8',
    paddingLeft:'12px',
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.bg.secondary,
    },
  },
  tableCell_Head: {
    flexBasis:'50%',
    maxWidth:'300px',
    flexGrow:'0',
    flexShrink:'0',
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
  },
  tableCell_Body: {
    maxWidth:'320px',
    flexBasis:'50%',
    flexGrow:'1',
    flexShrink:'0',
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
  },
  tableCell_Body2: {
    flexBasis:'50%',
    flexGrow:'1',
    flexShrink:'0',
    whiteSpace:'nowrap',
  },
  noWrapEllipsis: {
    flexGrow:'1',
    flexShrink:'0',
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
  },
  noWrap: {
    flexGrow:'0',
    flexShrink:'1',
    whiteSpace:'nowrap',
  },
  rowUnderlined: {
    borderBottom:`1px solid ${theme.palette.border.card}`,
    '&:last-child': {
      borderBottom:'none',
    },
  },
  inputfile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  inputfileLabel: {
    fontWeight: '500',
    fontSize: '0.75rem',
    padding: '6px 16px',
    color: theme.palette.primary.mute,
    borderRadius: '4px',
    border: `1px solid ${fade(theme.palette.border.mute, 0.5)}`,
    transition: theme.transitions.buttonHover,
    '&:hover': {
      border: `1px solid ${theme.palette.border.mute}`,
      backgroundColor: fade(theme.palette.border.mute, 0.04),
    }
  },
  inputFilePreview: {
    // height: '58px',
    justifyContent: 'center',
    // alignItems: 'center',
    border: `1px solid ${theme.palette.border.card}`,
    // margin: ' 0px 0px 0.5rem 24px',
    backgroundColor: theme.palette.bg.tertiary,
    padding: '0px 16px',
    cursor: 'pointer',
    '& + #show': {
      display: 'none',
    },
    '&:hover + #show': {
      display: 'block',
      alignSelf: 'start',
      marginLeft: '-10px',
    },
  },
  // hideButton: {
  //   display: 'none',
  // },
  formCard_inner: {
    backgroundColor: theme.palette.bg.secondary,
    border: `1px solid ${theme.palette.border.card}`,
    borderRadius: '8px',
    padding: '16px',
  },
  formSection: {
    backgroundColor: theme.palette.bg.tertiary,
    border: `1px solid ${theme.palette.border.card}`,
    borderRadius: '8px',
    // overflow: 'auto',
    margin: '0px -8px',
    padding: '16px'
  },
  divider: {
    margin: 'auto',
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.border.inactive,
  },
  // Start MUI Switch //
  switch: {
    width: '42px',
    height: '26px',
    padding: '0px',
    margin: '0px 8px 0px 0px',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: 'white',
      '& + $track': {
        backgroundColor: theme.palette.primary.light,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.light,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.border.inactive}`,
    backgroundColor: '#eee',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  // End MUI Switch //
  // Webhook Editor //
  listItem: {
    alignItems: 'center',
    backgroundColor: theme.palette.bg.primary,
    '&:nth-child(even)': {
      backgroundColor: theme.palette.bg.secondary,
    },
  },
  webhookEditorItem: {
    borderTop: '1px solid #E8E8E8',
    margin: '0px -24px',
    padding: '0px 24px',
    '&:last-child': {
      borderBottom: '1px solid #E8E8E8',
    },
  },
  webhookEditorItemTitle: {
    color: theme.palette.text.primary,
    alignItems: 'center',
    cursor: 'pointer',
    padding: '8px 0px',
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  webhookEditorItemBody: {
    overflow: 'hidden',
    maxHeight: '0px',
  },
  webhookEditorItemBodyActive: {
    padding: '0px 0px 8px',
  },
  toastWarn: {
    padding: '8px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.warning.main}`,
    backgroundColor: fade(theme.palette.warning.main, 0.04),
    // marginBottom: '16px',
  },
  countStatsCardTitle: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  statsCard: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.border.card,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: lighten(theme.palette.primary.main, 0.66),
    }
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  linkUnderButton: {
    color: theme.palette.text.secondary,
    fontSize: '0.6875rem',
    fontWeight: 500,
    marginTop: '3px',
    // textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    }
  },
  iconButton: {
    width: '32px',
    height: '32px',
    marginLeft: '4px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: fade(theme.palette.primary.main, 0),
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: fade(theme.palette.primary.main, 0.04),
    }
  },
  chip: {
    padding: '1px 4px',
    borderRadius: '4px',
    border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
    backgroundColor: fade(theme.palette.primary.main, 0.04),
  },
  orgItem: {
    padding: '8px 8px',
    backgroundColor: theme.palette.bg.primary,
    color: theme.palette.text.secondary,
    borderTop: `1px solid ${theme.palette.border.card}`,
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.border.card}`,
    },
  },
  orgItemActive: {
    color: theme.palette.text.primary,
    borderRadius: '4px',
    // alignItems: 'start',
    // justifyContent:'space-between',
    borderBottom: `1px solid ${theme.palette.border.card}`,
    paddingBottom: '32px'
  },
  orgList: {
    borderLeft: `1px solid ${theme.palette.border.card}`,
    borderRight: `1px solid ${theme.palette.border.card}`,
  },
  menuGroupExpanded: {
    // zIndex: 1300,
    visibility: 'hidden',
    opacity: 0,
    // position: 'absolute',
    // top: 0,
    // right: 0,
    // bottom: 0,
    // left: 0,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    background: 'rgba(0, 0, 0, .08)',
    // background: 'rgba(0, 0, 0, .7)',
    transition: 'all .3s',
    '&:target': {
      visibility: 'visible',
      opacity: 1
    }
  },
  modal: {
    zIndex: 1300,
    visibility: 'hidden',
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(77, 77, 77, .7)',
    // background: 'rgba(0, 0, 0, .7)',
    transition: 'all .3s',
    '&:target': {
      visibility: 'visible',
      opacity: 1
    }
  },
  modal__content: {
    borderRadius: '4px',
    position: 'relative',
    width: '500px',
    maxWidth: '90%',
    background: '#fff',
    padding: '1em 2em',
  },
  modal__footer: {
    textAlign: 'right'
  },
  modal__close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#585858',
    textDecoration: 'none',
  },
  modal__open: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    '&:hover': {
      color: theme.palette.text.primary
    }
  },
  quickButton: {
    padding: '2px 4px',
    borderRadius: '4px',
    border: `1px solid ${fade(theme.palette.danger.main, 0.24)}`,
    color: theme.palette.danger.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.danger.main,
      border: `1px solid ${theme.palette.danger.main}`,
      backgroundColor: fade(theme.palette.danger.main, 0.04),
    }
  },
  muteButton: {
    padding: '6px 16px',
    alignItems: 'center',
    height: '32px',
    borderRadius: '4px',
    border: `1px solid ${fade(theme.palette.border.mute, 0.24)}`,
    color: theme.palette.primary.mute,
    cursor: 'pointer',
    transition: theme.transitions.buttonHover,
    '&:hover': {
      color: theme.palette.primary.mute,
      border: `1px solid ${theme.palette.border.mute}`,
      backgroundColor: fade(theme.palette.border.mute, 0.04),
    }
  },
  muteButtonSmall: {
    padding: '4px 8px',
    alignItems: 'center',
    justifyContent: 'center',
    // height: '24px',
    borderRadius: '4px',
    border: `1px solid ${fade(theme.palette.border.mute, 0.24)}`,
    color: theme.palette.primary.mute,
    cursor: 'pointer',
    transition: theme.transitions.buttonHover,
    '&:hover': {
      color: theme.palette.primary.mute,
      border: `1px solid ${theme.palette.border.mute}`,
      backgroundColor: fade(theme.palette.border.mute, 0.04),
    }
  },
  addCohort: {
    padding: '6px 16px',
    alignItems: 'center',
    height: '32px',
    borderRadius: '4px',
    color: theme.palette.primary.mute,
    cursor: 'pointer',
    transition: theme.transitions.buttonHover,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: fade(theme.palette.primary.mute, 0.04),
    },
  },
  visibleOnHover: {
    visibility: 'hidden',
    '&:hover': {
      visibility: 'visible'
    }
  }
}))

export default useStyles