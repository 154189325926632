import React from 'react'
// import useStyles from '../../lib/useStyles'
import { Box, Flex, Grid, Text } from '../../../Base'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => (
  {
    iOSDevice: {
      position: 'relative',
      width: '360px',
      height: '780px',
      borderRadius: '40px',
      boxShadow: `0px 0px 0px 1px ${theme.palette.border.card}, 0px 0px 0px 15px #fff, 0px 0px 0px 16px ${theme.palette.border.card}`,
      backgroundColor: theme.palette.bg.secondary,
      margin: '0px 16px',
      flexDirection: 'column',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        transform: `translateX(-50%)`,
        bottom: '7px',
        width: '140px',
        height: '4px',
        backgroundColor: theme.palette.border.card,
        borderRadius: '10px',
      },
      '&::before': {
        content: '""',
        top: '0px',
        width: '56%',
        height: '30px',
        backgroundColor: '#fff',
        borderRadius: '0px 0px 40px 40px',
        alignSelf: 'center',
        marginTop: '-1px',
        border: `1px solid ${theme.palette.border.card}`,
        borderTop: 'none',
      },
    },
    notif_iOS: {
      backgroundColor: '#FFF',
      borderRadius: '10px',
      flexDirection: 'column',
      boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 3px 0px',
      margin: '0px 10px',
      cursor: 'pointer',
      position: 'relative',
      top: '40px',
    },
    notif_Header_iOS: {
      padding: '14px',
      gridTemplateColumns: '21px 1fr min-content',
      gridTemplateRows: '21px',
      justifyContent: 'space-between',
      width: '100%',
      gridGap: '8px',
      alignContent: 'center',
    },
    notif_Content_iOS: {
      padding: '0px 14px 14px',
      justifyContent: 'space-between',
      width: '100%',
    },
    notif_Text_iOS: {
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    notif_Img_iOS: {
      width: '35px',
      height: '35px',
      borderRadius: '3px',
    },
  }
))

const PreviewPushiOS = React.memo(({ title, body, appName }) => {
  const classes = useStyles()
  const image = null

  return(
    <Box mt='16px'>
      <Flex className={classes.iOSDevice}>
        <Flex className={classes.notif_iOS}>
          <Grid className={classes.notif_Header_iOS}>
            <Box bgcolor='#e8e8e8' borderRadius='3px'/>
            <Text variant='pushAppName_iOS' color='rgb(85,85,85)' pt='1px' textTransform='uppercase'>
              { appName || 'ExampleApp'}
            </Text>
            <Text variant='pushAppName_iOS' color='rgb(85,85,85)' textAlign='right'>
              now
            </Text>
          </Grid>
          <Flex className={classes.notif_Content_iOS}>
            <Flex className={classes.notif_Text_iOS}>
              <Text variant='pushTitle_iOS' color='rgb(0,0,0)' mb='5px' width='280px' textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>
                { title || 'Notification Preview'}
              </Text>
              <Text variant='pushMessage_iOS' color='rgb(0,0,0)' width='280px' textOverflow='ellipsis' overflow='hidden' maxHeight='76px'>
                { body || 'Preview your push notification'}
              </Text>
            </Flex>
            <Box className={classes.notif_Img_iOS} bgcolor='#e8e8e8' hidden={image !== null ? false : true}/>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
})

export default PreviewPushiOS