import React from "react";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { fade } from '@material-ui/core/styles/colorManipulator'

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    shape: {
      borderRadius: 4
    },

    overrides: {
      MuiButton: {
        root: {
          borderRadius: 4,
          padding: "10px 16px",
          textTransform: "none",
        },
        startIcon: {
          marginRight: "10px",
        },
        outlined: {
          padding: "10px 16px",
        },
        contained: {
          padding: "10px 16px",
        },
        outlinedSizeSmall: {
          padding: "0px 16px",
          fontSize: "0.75rem",
          fontWeight: 500,
          height: "32px"
        },
        containedSizeSmall: {
          padding: "0px 16px",
          fontSize: "0.75rem",
          fontWeight: 500,
          height: "32px",
        },
        // label: {
        //   alignItems: 'center',
        //   // marginTop: '1px',
        // }
      },
      MuiPaper: {
        rounded: {
          borderRadius: '8px',
        }
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: '4px',
        }
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: "#fff",
          minHeight: "32px",
        },
        input: {
          padding: "1px 12px",
          fontSize: "0.75rem",
          // minHeight: "30px"
        },
        multiline: {
          padding: "8px 12px"
        },
      },
      // MuiAutocomplete: {
      //   inputRoot: {
      //     '&&[class*="MuiOutlinedInput-root" $input': {
      //       padding: "0px"
      //     }
      //   }
      // },
      MuiInputBase: {
        root: {
          fontSize: '0.75rem',
        },
      },
      MuiTabs:
      {
        root: {
          minHeight: "40px",
          height: "40px",
          marginLeft: "32px"
        },
      },
      MuiTab:
      {
        root: {
          minHeight: "28px",
          // maxHeight: "28px",
          padding: "10px 2px",
          marginRight: "20px",
        },
        wrapper: {
          textTransform: 'none',
          margin: "auto",
          fontSize: "0.75rem"
        },
      },
      MuiTableCell: {
        head: {
          fontSize: '0.75rem',
          fontWeight: 400,
          color: '#52575C'
        },
        sizeSmall: {
          padding: '8px 16px'
        },
        footer: {
          borderBottom: 'none'
        }
      },
      MuiToolbar: {
        gutters: {
          paddingLeft: '16px !important',
          paddingRight: '16px'
        }
      },
    },

    // direction: "rtl",
    typography: {
      fontFamily: ["Montserrat"].join(",")
    },

    palette: {
      // custom props
      bg: {
        primary: '#ffffff',
        secondary: '#F9F9FA',
        tertiary: '#F3F3F3'
      },
      // Material UI props
      contrastThreshold: 2,
      primary: {
        veryLight: "#E6F2F6",
        light: "#3395B9",
        main: "#007BA7",
        dark: "#006F96",
        mute: "#455B63",
        contrastText: "#ffffff"
      },
      secondary: {
        light: '#33c1f4',
        main: '#00B2F2',
        dark: '#007ca9',
        contrastText: "#ffffff"
      },
      error: {
        main: "#cd2026"
      },
      disabled: {
        main: "#E8E8E8"
      },
      danger: {
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#ffffff"
      },
      warning: {
        main: "#F2B918",
        light: "#F3C02F",
      },
      success: {
        main: "#58A608",
        light: "#3DDC84",
      },
      brand: {
        main: "#007BA7",
      },
      info: {
        main: "#2196f3",
      },
      black: {
        main: '#000000',
      },
      text: {
        primary: "#25282B",
        secondary: "#52575C",
        disabled: "#A0A4A8",
      },
      border: {
        active: "#A0A4A8",
        inactive: "#DBDDE0",
        card: "#E8E8E8",
        mute: "#7D9EAA",
      },
    },

    gradients: {},

    images: {
      chartGraphic: {
        raw: `url(${process.env.PUBLIC_URL + '/media/zhortech/chartGraphic.png'})`,
        fadeLeft: {
          white: `
            linear-gradient(90deg, #ffffff 40%, ${fade('#ffffff', 0.36)}),
            url(${process.env.PUBLIC_URL + '/media/zhortech/chartGraphic.png'})
          `,
          white_hover: `
            linear-gradient(${fade('#006F96', 0.04)}, ${fade('#006F96', 0.04)}),
            linear-gradient(90deg, #ffffff 40%, ${fade('#ffffff', 0.36)}),
            url(${process.env.PUBLIC_URL + '/media/zhortech/chartGraphic.png'})
          `,
        }
      },
      barGraphic: {
        raw: `url(${process.env.PUBLIC_URL + '/media/zhortech/barGraphic.png'})`,
        fadeLeft: {
          white: `
            linear-gradient(90deg, #ffffff 40%, ${fade('#ffffff', 0.36)}),
            url(${process.env.PUBLIC_URL + '/media/zhortech/barGraphic.png'})
          `,
          white_hover: `
            linear-gradient(${fade('#006F96', 0.04)}, ${fade('#006F96', 0.04)}),
            linear-gradient(90deg, #ffffff 40%, ${fade('#ffffff', 0.36)}),
            url(${process.env.PUBLIC_URL + '/media/zhortech/barGraphic.png'})
          `,
        }
      }
    },

    shadows: [
      'none',
      '0px 4px 8px 0px rgba(37,40,43,0.14)', //card:raised
      '0px 4px 8px 0px rgba(37,40,43,0.16)', //button:contained
      '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
      '0px 6px 12px 0px rgba(37,40,43,0.24)', //button:hover
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
      '0px 4px 8px 0px rgba(37,40,43,0.14)', //dropdown menu
      '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
      '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
      '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
      '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
      '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
      '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
      '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
      '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
      '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
      '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
      '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
      '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
      '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
      '0 12px 40px 0 rgba(37,40,43,0.32)', //modal
    ],

    shadowsKeyed: {
      card: '0px 4px 8px 0px rgba(37,40,43,0.14)',
      card_hover: '0px 8px 16px -2px rgba(37,40,43,0.20)',
      button: '0px 4px 8px 0px rgba(37,40,43,0.16)',
      button_hover: '0px 4px 16px 0px rgba(37,40,43,0.24)',
      button_disabled: 'none',
      modal: '0 12px 40px 0 rgba(37,40,43,0.32)',
    },

    transitions: {
      buttonHover: `
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
      `
    },

    variantsCard: {
      raised: {
        boxShadow: '0px 4px 8px 0px rgba(37,40,43,0.14)',
      },
      bordered: {
        boxShadow: 'none',
        border: '1px solid #E8E8E8',
      },
      raisedBordered: {
        boxShadow: '0px 4px 8px 0px rgba(37,40,43,0.14)',
        border: '1px solid #E8E8E8',
      },
      admin: {
        boxShadow: 'none',
        border: '1px solid rgba(147,17,166,100%)',
        background: 'rgba(147,17,166,10%)'
      },
      modal: {
        boxShadow: '0 12px 40px 0 rgba(37,40,43,0.32)',
        padding: '0px',
        margin: 'auto',
        maxHeight: '98vh'
      },
      form: {
        padding: '16px'
      },
    },
    
    variantsText: {
      h1: {
        fontSize: '3.25rem',
        fontWeight: 300,
        letterSpacing: '0.0125rem',
        lineHeight: '4rem',
      },
      h2: {
        fontSize: '2.75rem',
        fontWeight: 500,
        lineHeight: '3.375rem',
      },
      h3: {
        fontSize: '2rem',
        fontWeight: 600,
        letterSpacing: '0.00625rem',
        lineHeight: '2.5rem',
      },
      h4: {
        fontSize: '1.625rem',
        fontWeight: 500,
        letterSpacing: '0.0125rem',
        lineHeight: '2rem',
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 600,
        letterSpacing: '0.0125rem',
        lineHeight: '1.625rem',
      },
      h6: {
        fontSize: '1.125rem',
        fontWeight: 500,
        letterSpacing: '0.0125rem',
        lineHeight: '1.5rem',
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 500,
        letterSpacing: '0.00625rem',
        lineHeight: '1.5rem',
      },
      subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 500,
        letterSpacing: '0.00625rem',
        lineHeight: '1.375rem',
      },
      subtitle3: {
        fontSize: '0.875rem',
        fontWeight: 600,
        letterSpacing: '0.00625rem',
        lineHeight: '1.375rem',
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        letterSpacing: '0.00625rem',
        lineHeight: '1.5rem',
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        letterSpacing: '0.00625rem',
        lineHeight: '1.375rem',
      },
      body3: {
        fontSize: '0.8125rem',
        fontWeight: 400,
        lineHeight: '1rem',
        letterSpacing: '0.05px',
        // lineHeight: '1.375rem',
      },
      small1: {
        fontSize: '0.75rem',
        fontWeight: 500,
        letterSpacing: '0.0125rem',
        lineHeight: '1rem',
      },
      small2: {
        fontSize: '0.75rem',
        fontWeight: 400,
        letterSpacing: '0.0125rem',
        lineHeight: '1rem',
      },
      small3: {
        fontSize: '0.6875rem',
        fontWeight: 400,
        letterSpacing: '0.0125rem',
        // lineHeight: '0.875rem',
        lineHeight: '1rem',
      },
      button: {
        fontSize: '0.875rem',
        fontWeight: 500,
        letterSpacing: '0.0125rem',
        // lineHeight: '1.125rem',
        lineHeight: 'initial',
        textTransform: 'none'
      },
      initials: {
        fontSize: '0.8125rem',
        fontWeight: 600,
        letterSpacing: '0.0125rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
      },
      value1: {
        fontFamily: 'Lato',
        fontSize: '2rem',
        fontWeight: 700,
        letterSpacing: '0.0125rem',
        lineHeight: '2.25rem',
      },
      value2: {
        fontFamily: 'Lato',
        fontSize: '0.875rem',
        fontWeight: 700,
        letterSpacing: '0.0125rem',
      },
      value3: {
        fontFamily: 'Lato',
        fontSize: '0.875rem',
        fontWeight: 400,
        letterSpacing: '0.0125rem',
      },
      value4: {
        fontFamily: 'Lato',
        fontSize: '1.125rem',
        fontWeight: 700,
        letterSpacing: '0.0125rem',
        lineHeight: '1.5rem',
      },
      value5: {
        fontFamily: 'Lato',
        fontSize: '0.6875rem',
        fontWeight: 400,
        letterSpacing: '0.0125rem',
        lineHeight: 'initial',
      },
      pushAppName_iOS: {
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '20px',
        fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
      },
      pushTitle_iOS: {
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: '19px',
        fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
      },
      pushMessage_iOS: {
        fontSize: '15px',
        fontWeight: 300,
        lineHeight: '19px',
        fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
      },
      pushAppName_Android: {
        fontSize: '12px',
        lineHeight: '15px',
        fontFamily: 'Roboto, Proxima-Nova, Proxima Nova, Open Sans, Arial, Helvetica, sans-serif',
      },
      pushTitle_Android: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        fontFamily: 'Roboto, Proxima-Nova, Proxima Nova, Open Sans, Arial, Helvetica, sans-serif',
        letterSpacing: '0.1px',
      },
      pushMessage_Android: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19px',
        fontFamily: 'Roboto, Proxima-Nova, Proxima Nova, Open Sans, Arial, Helvetica, sans-serif',
        letterSpacing: '0.1px',
      },

    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 24 for popovers.
      MuiPopover: {
        elevation: 24
      },
    }
  }
);

export default function ThemeProvider(props) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
