/*
   How data export happens.

   Once the user has modified the filename and monkeied with the date range, the user
   clicks the 'Start Export'.  This sends information to the backend via the
   call to "/saas/v1/data/v1/rawData", passing download=true and the filename.  The
   download=true is what tells the backend this is an actual export.

   The backend will check if the plugin associated with this app has its own data export function
   and will delegate to it if it does.  Otherwise there is a default data export for use cases that don't
   need their own.  The point is, that what the user downloads is not nessesarily a simple .csv file of
   activities ... it may be a more complex package of files.

   So the backend does what it needs to do to package up the final export image, and then it streams it to
   a temporary bucket in S3.  When that succeeds, the backend returns a pre-signed url to this S3 object
   that expires in 15 minutes.  This url is stuffed into an anchor tag, and when the user clicks on that,
   the S3 object will stream to the user's file system.

   So, this is a two-click process.  Click on "Start Export" to initiate the export, and when the anchor
   tag appears, click on the anchor to get the file.

   The primary modivation for this two step process is that the backend is deployed as a Lambda function
   in AWS and is thus limited to 1Mb request and response bodies.  When we export real data, it is expected
   that it would frequently exceed 1Mb in size.
 */
import React from 'react'

import { useSelector } from 'react-redux'
import useApi from '../../lib/useApi'
import useStyles from '../../lib/useStyles'
import { timeRangeToFilenameStr } from '../../lib/utils'

import { Box, Flex, Text } from '../../components/Base'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

import SaveAltIcon from '@material-ui/icons/SaveAlt'

const RawDataExporter = React.memo(({ dbEntityType, where, start, end, titleSuffix, disabled }) => {
  const classes = useStyles()
  const [ filename, setFilename ] = React.useState('')
  const [ hasCustomName, setHasCustomName ] = React.useState(false)

  React.useEffect(() => {
    if (!hasCustomName) {
      setFilename([
        'rawactivities',
        dbEntityType,
        timeRangeToFilenameStr(start, end),
      ].join('_'))
    }
  }, [ hasCustomName, dbEntityType, end, start ])
  
  const api = useApi()
  const appId = useSelector(store => (store.auth.app && store.auth.app.id) || null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [ url, setUrl ] = React.useState(null)

  React.useEffect(() => {
    setUrl(null)
  }, [api, appId, dbEntityType, end, filename, start, where])

  const doExport = React.useCallback(() => {
    setUrl(null)
    api({
      method: "post",
      url: "/saas/v1/data/v1/rawData",
      data: {
        appId,
        model: dbEntityType,
        where,
        start,
        end,
        download: true,
        filename,
      },
      isLoading: setIsLoading,
      handler: (data) => {
        setUrl(data.url)
      }
    })
  }, [api, appId, dbEntityType, end, filename, start, where])

  let title = 'Export Raw Activities'
  if (titleSuffix) {
    title += ' ' + titleSuffix
  }

  let downloadFilename = ''
  if (url) {
    let split = url.split('/')
    downloadFilename = split[split.length - 1].split('?')[0]
  }
  
  return (
    <Flex flexDirection='column'>
      <Text variant='h5' mb='24px'>
        {title}
      </Text>
      <FormControl className={classes.formControl} style={{ width: '50%' }}>
        <TextField
          required
          type="text"
          value={filename}
          label="Filename"
          placeholder='Enter a filename'
          onChange={(e) => {
            setHasCustomName(true)
            setFilename(e.target.value)
          }}
        />
      </FormControl>
      <Flex mt='32px' alignItems='center'>
        <Box mr='16px'>
          <Button
            disabled={Boolean(disabled || isLoading || url)}
            onClick={doExport}
            variant="contained"
            color='primary'
            mr='32px'
          >
            {'Start Export'}
          </Button>
        </Box>
        {
          isLoading &&
            <CircularProgress size={18}/>
        }
        {
          url &&
            <a href={url} download>
              <SaveAltIcon/> {`Download "${downloadFilename}"`}
            </a>
        }
      </Flex>
    </Flex>
  )
})

export default RawDataExporter
