//
// Dialog for changing a user's password
//
import React from 'react';
import useStyles from '../../../lib/useStyles'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Slide from '@material-ui/core/Slide';

import validate from 'validate.js';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePasswordDialog = ({open, onClose, user}) => {
  const classes = useStyles();

  const [name, setName] = React.useState(user.name || '');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [formErrors, setFormErrors] = React.useState({});
  
  const getData = () => {
    return {
      ...user,
      password,
      name
    };
  }

  const fc = {
    name: { presence: true, length: {minimum: 1} },
    password: { presence: true, length: {minimum: 8} },
    password2: {
      equality: "password",
    }
  };

  const clearError = (fieldName) => {
    if ( ! formErrors[fieldName] ) return;
    setFormErrors({
      ...formErrors,
      [fieldName]: undefined
    });
  }

  const checkForm = () => {
    const reformat = (result) => {
      let e = {};
      Object.keys(result).forEach((k) => {
        e[k] = result[k][0];
      });
      return e;
    }

    let result = validate({name, password, password2}, fc);
    if ( !result ) return true;
    
    setFormErrors({
      ...formErrors,
      ...reformat(result)
    });

    return false;
  }

  const checkAndSend = () => {
    if ( ! checkForm() ) return;
    onClose(getData());
  }

  // Make sure we start clean
  React.useEffect(() => {
    if (open) {
      if ( user === undefined ) setName('');
      else setName(user.name === 'Unknown' ? '' : (user.name || ''));
      setPassword('');
      setPassword2('');
      setFormErrors({});
    }
  }, [open, user]);

  return(
    <Dialog open={open} onClose={() => onClose(null)} TransitionComponent={Transition} fullWidth={true} maxWidth="sm">
      <DialogTitle>Change User Password {name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Force a user's password.  The user will not receive a notification, so you will
          have to convey the new password to the user.
        </DialogContentText>
        <FormControl
            fullWidth
            className={classes.formControl}
            error={formErrors['name'] !== undefined}
        >
          <TextField
              required
              autoFocus
              fullWidth
              label="Name"
              type="text"
              value={name}
              onFocus={() => clearError('name')}
              onChange={(e) => setName(e.target.value)}
          />
          <FormHelperText>{formErrors['name']}</FormHelperText>
        </FormControl>
        <FormControl
            fullWidth
            className={classes.formControl}
            error={formErrors['password'] !== undefined}
        >
          <TextField
              required
              fullWidth
              label="Password"
              type="password"
              value={password}
              onFocus={() => clearError('password')}
              onChange={(e) => setPassword(e.target.value)}
          />
          <FormHelperText>{formErrors['password']}</FormHelperText>
        </FormControl>
        <FormControl
            fullWidth
            className={classes.formControl}
            error={formErrors['password2'] !== undefined}
        >
          <TextField
              required
              fullWidth
              label="Confirm Password"
              type="password"
              value={password2}
              onFocus={() => clearError('password2')}
              onChange={(e) => setPassword2(e.target.value)}
          />
          <FormHelperText>{formErrors['password2']}</FormHelperText>
        </FormControl>
        <DialogActions>
          <Button onClick={() => onClose(null)}>Cancel</Button>
          <Button onClick={checkAndSend} color="primary">Change</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default ChangePasswordDialog;

