// for componentDidMount and component state management
import React, {useEffect} from 'react';

import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import { CodeBlock } from "../../partials/content/CodeExample";

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";

import { useIntl } from "react-intl";

//import {useService, useApi} from '../../lib/hooks';

import Segment from '../../components/Segment';

import useService from '../../lib/useService';
import useApi from '../../lib/useApi';

const Test = () => {
  const intl = useIntl();

  const user = useSelector(store => store.auth.user);

  const service = useService();
  const api     = useApi();

  // Establishing a state variable and a setter function
  const [orgs, setOrgs] = React.useState({});

  //const [{data: orgs, isLoading}, orgsRequest] = useService({},[]);
  //const api = useApi({});

  const getOrganizations = () => {
    api({method: "get", url: "/api/organizations", opts:{simulatedDelay:2000}, handler: (data) => {
      setOrgs(data);
    }});
  }

  useEffect(() => {
    api({method: "get", url: "/api/organizations", opts:{simulatedDelay:2000}, handler: (data) => {
      setOrgs(data);
    }});
  }, [api]);

  const testExpired = () => {
    api({ method: "post", url: "/api/tokenExpired", data: {some: "data"}, handler: (data) => {
      // do something
    }});
  }

  const testUnhandledError = () => {
    api({ method: "post", url: "/api/unhandledError", data: {some: "data"}, handler: (data) => {
      // do something
    }});
  }
  
  return(
    <>
    <div className="row">
      <div className="col-md-12">
        {user?user.organization.name:'no org'}
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <Button variant="contained" style={{margin: "8px"}}
                onClick={() => service.notify("success", "You did it!")}
        >{intl.formatMessage({id: "ZT.TEST.NOTIFY"})}
        </Button>

        <Button variant="contained" color="primary" style={{margin: "8px"}}
                onClick={() => service.alert("Server Error", "Invalid params")}
        >Global Alert
        </Button>
        <Button variant="contained" style={{margin: "8px"}}
                onClick={getOrganizations}
        >Get Orgs
        </Button>

        <Button onClick={testExpired}>Token Expired Test</Button>
        <Button onClick={testUnhandledError}>Unhandled Error Test</Button>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <Segment busy={false} minWidth="100%" minHeight="100px">
          <Portlet>
            <PortletHeader title="Organizations" />
            <PortletBody>
              <CodeBlock disableCopy="true" language="json" code={JSON.stringify(orgs,null,2)} />
            </PortletBody>
          </Portlet>
        </Segment>
      </div>
    </div>
    </>
  );
}

export default Test;
