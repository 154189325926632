import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { Link, } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import { Authenticator, SignUp, Greetings, RequireNewPassword, VerifyContact} from 'aws-amplify-react';
import * as auth from '../../store/ducks/auth.duck';
import useService from '../../lib/useService';
// import useStyles from '../../lib/useStyles'
import { Text, Flex, Box } from '../../components/Base'
import CircularProgress from '@material-ui/core/CircularProgress';

import {MyRequireNewPassword} from './MyRequireNewPassword';
import {MyVerifyContact} from './MyVerifyContact';

const BASEURL = "https://zhortech.com";

const BaseUrl = (loc) => {
  if ( loc) return `${BASEURL}/${loc}`;
  else return BASEURL;
}

function AuthPage(props) {
  const [showGreetings, setShowGreetings] = useState(false);
  // const [showClearLocal, setShowClearLocal] = useState(false)

  // const classes = useStyles()
  const dispatch = useDispatch();
  const {setUser} = auth.actions;

  const service = useService();
  
  const MyGreetings = () => {
    if (showGreetings)
      return <CircularProgress color="secondary" />;
    else
      return null;
  }
  
  const clearLocalStorage = () => {
    localStorage.clear()
    window.location.reload()
  }

  document.onkeydown = (e) => {
    e = e || window.event
    if (e.key === 'F4')
      // setShowClearLocal(prevCheck => !prevCheck)
      clearLocalStorage()
  }

  return (
    <>
      {/* https://github.com/nfl/react-helmet */}
      <Helmet>
        {/* <link
            type="text/css"
            rel="stylesheet"
            href={toAbsoluteUrl(
                "/assets/css/demo1/style.bundle.css"
            )}
        />
        <link
          type="text/css"
          rel="stylesheet"
          href={toAbsoluteUrl(
            "/assets/css/demo1/pages/login/login-1.css"
          )}
        /> */}
      </Helmet>

      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/zhortech/footwear-microprocessor.png")})`,
                backgroundSize: "cover"
              }}
            >
              <div className="kt-grid__item">
                <Box>
                  <Link to="/" className="kt-login__logo">
                    <img
                      alt="Footprints-logo"
                      src={toAbsoluteUrl("/media/zhortech/footprints-white-medium.png")}
                      style={{maxWidth: "100%"}}
                    />
                  </Link>
                  <Flex
                    alignItems='baseline'
                    maxWidth='50%'
                    display={{xs:'none', sm:'none', md:'flex' }}
                  >
                    <Text color='white' mr='8px' variant='body2'>by</Text>
                    <img
                      alt="Zhortech-logo"
                      src={toAbsoluteUrl("/media/zhortech/logo-zhortech-white.png")}
                      style={{maxWidth: "50%"}}
                    />
                  </Flex>
                </Box>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">Welcome to the Footprints Dashboard</h3>
                  <h4 className="kt-login__subtitle">
                    Smart Shoes Technology Platform
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; {new Date().getFullYear()} Zhortech
                  </div>
                  <div className="kt-login__menu">
                    <a
                      href={BaseUrl("privacy-policy")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy
                    </a>
                    <a
                      href={BaseUrl("general-conditions-of-use")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Legal
                    </a>
                    <a
                      href={BaseUrl("#contact")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Authenticator
                hide={[ SignUp, Greetings, RequireNewPassword, VerifyContact ]}
                onStateChange={(authState) => {
                  if ( ! (authState === "signedIn") ) return;
                  // We are signed in now.  Go get the SaaS user and set the redux user, which will cause us to
                  // get into the application itself.
                  setShowGreetings(true);
                  // Get the full "profile" of the user from Cognito.  This will include "name", the full name of
                  // the user, captured during account initialization
                  service.getAuthenticatedUser().then((u) => {
                    // Now get the backend user (which will include their entity and apps).  Pass the Cognito
                    // profile so values from Cognito can be added to the database user (in particular "name").
                    service.getSaasUser(u).then((user) => {
                      dispatch(setUser(user));
                      service.loggedIn();
                      service.versionCheck(true);
                    }).catch((err) => {
                      // There was a cognito user, but we could not find a corresponding record in the backend.
                      service.signout().then(() => {
                        setShowGreetings(false);
                        service.alert( 'Server Error', 'We could not find you in our database!' );
                      });
                    });
                  }).catch((err) => {
                    service.signout().then(() => {
                      setShowGreetings(false);
                      service.alert( 'Server Error', 'We could not obtain your profile from the cloud!' );
                    });
                  });
                }}
              >
                <MyGreetings />
                <MyVerifyContact />
                <MyRequireNewPassword />
              </Authenticator>
              <Flex
                alignItems='center'
                justifyContent='center'
              >
                <Text
                  variant='small3'
                  color='text.disabled'
                >
                  Trouble signing in? Press F4 to clear your local storage.
                </Text>
              </Flex>
              {/* {
                showClearLocal &&
                <Flex alignItems='center' justifyContent='center'>
                  <Flex
                    className={classes.muteButton}
                  >
                    <Text
                      onClick={clearLocalStorage}
                      variant='small1'
                      color='inherit'
                    >
                      Clear Local Storage
                    </Text>
                  </Flex>
                </Flex>
              } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthPage;
