import React from 'react'
import { useHistory } from "react-router-dom"

import { useTheme } from '@material-ui/core/styles'

import useStyles from '../../../lib/useStyles'

import { useModel_ChartJs } from '../../../components/Charting/ChartJsModel'
import ChartContainer from '../../../components/Charting/ChartJsContainer'
import { Grid, Card, Text, Flex, Box } from '../../../components/Base'

import { riskCfg } from '../util'
import RiskDefinitions from './RiskDefinitions'

const ActionHandler = React.memo(({ routeTo, onClick, children, ...props }) => {
  if (routeTo) {
    const history = useHistory()
    onClick = () => history.push(routeTo)
  }

  const child = React.Children.only(children)

  return React.cloneElement(child, { onClick, ...props })
})

const LegendItem = React.memo(({ label, color, value, routeTo }) => {
  const classes = useStyles()
  return(
    <Box>
      <Flex alignItems='center'>
        <Box
          height='9px'
          width='9px'
          mr='6px'
          bgcolor={color}
        />
        <Text variant='small1'>
          {label}
        </Text>
      </Flex>
      <ActionHandler routeTo={routeTo}>
        <Text variant='value2' className={classes.link}>
          {`${(value?.count || 0)} Workers - ${(value?.ratio * 100 || 0).toFixed(0)}%`}
        </Text>
      </ActionHandler>
    </Box>
  )
})

const WorkloadRiskCard = React.memo(({ dateRange, entitiesData, ...props }) => {
  const [ riskData, setRiskData ] = React.useState()

  const [ chartState, chartMethods ] = useModel_ChartJs({
    variant: 'doughnut',
    variantOpts: {
      theme: useTheme(),
      tooltips: {
        callbacks: {
          title(tooltipItem, data) {
            return null
          },
          label(tooltipItem, data) {
            // console.log(data)
            // const points = data.datasets[tooltipItem.datasetIndex].data
            // const point = points[tooltipItem.index]
            return data.labels[tooltipItem.index]
          },
        },
      }
    },
  })

  React.useEffect(() => {
    if (!entitiesData) return

    const _riskData = [
      { count: entitiesData.filter((entity) => entity.activitiesDataAgg.highRisk > 0).length },
      { count: entitiesData.filter((entity) => entity.activitiesDataAgg.highRisk === 0 && entity.activitiesDataAgg.mediumRisk > 0).length },
      { count: entitiesData.filter((entity) => entity.activitiesDataAgg.highRisk === 0 && entity.activitiesDataAgg.mediumRisk === 0).length },
    ]

    _riskData.forEach((o) => {
      o.ratio = o.count / entitiesData.length
    })

    setRiskData(_riskData)
  }, [chartMethods, entitiesData])

  React.useEffect(() => {
    if (!riskData) return

    chartMethods.setDataSetsRaw(
      [
        {
          backgroundColor: riskCfg.map((o) => o.color),
          data: riskData.map((o) => o.count),
        },
      ],
      {
        labels: riskData.map((o) => `${(o?.ratio * 100 || 0).toFixed(0)}%`),
      }
    )
  }, [chartMethods, riskData])

  return (
      <Card
        p='0px'
        variant='raisedBordered'
        {...props}
      >
        <Grid
          p='16px'
          gridGap='24px'
        >
          <Flex justifyContent='space-between' alignItems='center'>
            <Text color='inherit' variant='subtitle3'>
              {'Workload Risk'}
            </Text>
            {/* <i className='fas fa-expand-alt' */}
            <RiskDefinitions />
          </Flex>
          <Flex justifyContent='space-between' alignItems='start' pl='8px' pb='8px'>
            <Grid
              gridGap='16px'
              gridAutoColumns='max-content'
            >
              {
                riskCfg.map((item, i) => (
                  <LegendItem
                    key={i}
                    label={item.label}
                    color={item.color}
                    value={riskData?.[i]}
                    routeTo={`/safety/diagnostics/${item.dataField}`}
                  />
                ))
              }
            </Grid>
            <ChartContainer height='154px' state={chartState} methods={chartMethods}/>
          </Flex>
        </Grid>
      </Card>
  )
})

export default WorkloadRiskCard