import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


// open
// title, size
// passthru (whatever is passed in is passed back out
// children
// onClose( true/false (ok/cancel), passthru )

// sizes: xs, sm, md, lg, xl

const NewVersionNotification = ({open, onClose, size}) => {
  
  const handleOk = () => {
    onClose();
    global.window.location.reload();
  }
  
  return(
    <Dialog
        open={open}
        aria-labelledby="version-dialog-title"
        aria-describedby="version-dialog-description"
        maxWidth={size}
        fullWidth={true}
        TransitionComponent={Transition}
        disableBackdropClick
        disableEscapeKeyDown
    >
      <DialogTitle id="version-dialog-title">New Version Available</DialogTitle>
      <DialogContent>
        <DialogContentText id="version-dialog-description">
          A new version of this portal is available.  Your current version may not function
          correctly with our servers.  Clicking on the "Ok" button below will force a page reload
          and install the latest version.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewVersionNotification;
