import * as i18n from "./ducks/i18n";

/**
 * Reexports
 */
export * from "./utils/utils";
export { default as I18nProvider } from "./i18n/I18nProvider";
export { default as ThemeProvider } from "./materialUIThemeProvider/ThemeProvider";

/**
 * Ducks
 */

export const metronic = { i18n };
