import { useModel } from '../../../lib/state'
import dbEntityModelFactory from '../entityModelFactory'
import initEntityModel from './initEntityModel'
import ContentComps from './ContentComps'

let entityCfg = {
  ContentComps,
  dbEntityType: 'apikeys',
  label: 'API Key',
  labelPlural: 'API Keys',
  icon: '',
  relations: {
    belongsTo: {
      organization: true,
    },
    hasOne: {},
    hasMany: {},
  },
  actionsPermissionCfgs: {
    role: {
      anyAdmin: { new: true, edit: true, delete: true },
    },
    entity: {},
  },
  modalOpts: { // OPTIONAL, DELETE this comment or object
    base: {
      creator: {
        title: 'New API key?',
        confirmLabel: 'Confirm',
      },
    },
  },
  manageIsTable: true,
  manage: {
    table: {
      showRowManagementActions: true,
      staticCols: [
        {
          path: 'secret',
          dataKey: 'secret',
          colMUIT: {
            title: 'Secret',
          },
        },
        {
          path: 'status',
          dataKey: 'status',
          colMUIT: {
            title: 'Status',
          },
        },
      ],
    },
  },
}

entityCfg = {
  ...entityCfg,
  otherData: {},
}

entityCfg = {
  ...entityCfg,
  nameLabel: 'Key',
  nameExportKey: 'apikey',
  nameDataPath: 'apikey',
  getEntityName(entity) {
    // console.log(entity.apikey)
    return entity.apikey
  },
  getNewEntityBase(newEntityOpts = {}) {
    return { status: 'ACTIVE' }
  },
}

const entityModel =  dbEntityModelFactory(initEntityModel(entityCfg))
const useEntityModel = (stateInitializerArgs) => useModel(entityModel, stateInitializerArgs)
entityCfg.useEntityModel = useEntityModel

export default entityCfg