import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Redirect} from "react-router-dom";
import * as auth from "../../../store/ducks/auth.duck";

import useService from '../../../lib/useService';
import useApi from '../../../lib/useApi';

import {parseRole} from '../../../lib/utils';
import AppCard from './AppCard';
import EditDialog from './EditDialog';
import Confirm from '../../../components/Confirm';
import useStyles from '../../../lib/useStyles'

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { Flex, Grid, Text } from '../../../components/Base';

const Apps = () => {
  const classes = useStyles()
  const dispatch = useDispatch();

  const user = useSelector(store => store.auth.user);
  const organization = useSelector(store => store.auth.organization);
  const currentApp = useSelector(store => store.auth.app);
  const parentOrgId = organization.organizationId

  const urole = parseRole(user.role);

  // For dispatching
  const {notify} = useService();
  const {setApp, orgAddApp, orgRemoveApp, orgReplaceApp, orgAppSetStatus} = auth.actions;
  // editor dialog control
  const [openEditor, setOpenEditor] = React.useState(false);
  // what to pass to the editor
  const [theApp, setTheApp] = React.useState({});
  // remove confirm dialog
  const [removeConfirm, setRemoveConfirm] = React.useState(false);
  // toggle activation dialog
  const [toggleActivationConfirm, setToggleActivationConfirm] = React.useState(false);

  const [gotoDash, setGotoDash] = React.useState(false);

  const api = useApi();

  // open the editor to add a new app
  const onAddApp = (data) => {
    setTheApp({ name: '', description: '', latest: '0.1.0', status: 'ACTIVE', appId: '', attributes: {} });
    setOpenEditor(true);
  }

  // open the editor to edit an existing app
  const onEditApp = (data) => {
    setTheApp(data);
    setOpenEditor(true);
  }

  // open the remove app confirm dialog
  const onRemoveApp = (data) => {
    setTheApp(data);
    setRemoveConfirm(true);
  }

  // open the toggle activation confirm dialog
  const onToggleActivationApp = (data) => {
    setTheApp(data);
    setToggleActivationConfirm(true);
  }

  // handle removing an app
  const handleAppRemove = (okied, app) => {
    setRemoveConfirm(false);
    if ( ! okied ) return; // was cancelled
    api({ method: "delete", url: `/api/Apps/${app.id}`, handler: () => {
      dispatch(orgRemoveApp(app));
      notify("success", "App removed successfully.")
    }});
  }

  const handleToggleActivation = (okied, app) => {
    setToggleActivationConfirm(false);
    if ( ! okied ) return;
    if ( app.status === 'ACTIVE' ) {
      // we are going to inactivate
      api({ method: "patch", url: `/api/Apps/${app.id}`, data:  {status: 'INACTIVE'}, handler: () => {
        dispatch(orgAppSetStatus(app, 'INACTIVE'));
        notify("success", "App status changed to inactive.")
      }});
    }
    else {
      // we are going to re-activate
      api({ method: "patch", url: `/api/Apps/${app.id}`, data:  {status: 'ACTIVE'}, handler: () => {
        dispatch(orgAppSetStatus(app, 'ACTIVE'));
        notify("success", "App status changed to active.")

      }});
    }
  }

  // handle an edit done (new or modify)
  const handleAppEdit = (data) => {
    setOpenEditor(false);
    if ( ! data ) return; // was cancelled

    if ( data.id ) {
      // Editing an existing app
      api({ method: "patch", url: `/api/Apps/${data.id}`, data, handler: (app) => {
        dispatch(orgReplaceApp(app));
        if (currentApp && currentApp.id === app.id) dispatch(setApp(app)); // if editing the currently selected app!
        notify("success", "Changes saved.")
      }});
    }
    else {
      // Adding a new app
      api({ method: "post", url: `/api/Organizations/${organization.id}/apps`, data, handler: (app) => {
        dispatch(orgAddApp(app));
        notify("success", "App created successfully.")

      }});
    }
  }

  const onSelectApp = (app) => {
    dispatch(setApp(app));
    setGotoDash(true);
  }

  if ( gotoDash ) return <Redirect to="/dashboard" />;

  return(
    <>
      <Flex
        className={classes.controlBar_full}
      >
        <Flex alignItems='center'>
          <Text
            variant='h6'
            mr='16px'
          >
            Applications
          </Text>
          <Grid
            gridGap='8px'
            gridAutoFlow='column'
            alignItems='center'
          >
          {
            urole.admin && parentOrgId && !organization?.canCreateApps &&
            <Button
              color="primary"
              variant="outlined"
              size='small'
              startIcon={<AddIcon />}
              onClick={onAddApp}
            >
              Create New
            </Button>
          }
          {
            urole.admin && organization?.canCreateApps &&
            <Button
              color="primary"
              variant="outlined"
              size='small'
              startIcon={<AddIcon />}
              onClick={onAddApp}
            >
              Create New
            </Button>
          }
          </Grid>
        </Flex>
      </Flex>
    <Grid
      gridTemplateColumns='1fr 1fr 1fr'
      gridRowGap='32px'
      gridColumnGap='32px'
      maxWidth='916px'
      // m='auto'
    >
      <>
        {organization.apps && organization.apps.map((app) => (
          <AppCard
            key={app.id||app.name}
            urole={urole}
            app={app}
            onAdd={onAddApp}
            onEdit={onEditApp}
            onRemove={onRemoveApp}
            onToggleActivation={onToggleActivationApp}
            onSelect={onSelectApp}
            notify={(sev, msg) => notify(sev, msg)}
          />
        ))}
      </>
    </Grid>
    <EditDialog open={openEditor} app={theApp} onClose={handleAppEdit} />
    <Confirm
        size="sm"
        title="Remove App"
        open={removeConfirm}
        onClose={handleAppRemove}
        passthru={theApp}
    >
      This function is not enabled in production.  This is a test only feature.  If you
      confirm then <strong>{theApp.name}</strong>&nbsp;will be deleted on the server, but
      no associated data will be deleted.  In the production system, apps can only be
      INACTIVATED.
    </Confirm>
    <Confirm
        size="sm"
        title="Toggle Activation"
        open={toggleActivationConfirm}
        onClose={handleToggleActivation}
        passthru={theApp}
    >
      {(theApp.status === 'ACTIVE') && (
         <>
         Do you wish to <strong>INACTIVATE</strong> this app?  Mobile apps in the field using this appId
         will begin to fail to authenticate with the servers.
         </>
       )}
      {!(theApp.status === 'ACTIVE') && (
         <>
           Do you wish to <strong>RE-ACTIVATE</strong> this app?  Mobile
           apps in the field this appId
           will be able to authenticate with the servers.
         </>
       )}
    </Confirm>
    </>
  );
}

export default Apps;
