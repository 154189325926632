import React from 'react'
import { Box, Grid, Text, } from '../components/Base'
import useStyles from '../lib/useStyles'
import { Link } from "react-router-dom"

const MenuFooterGrid = () => {

  const classes = useStyles()

  return(
    <Grid
      m='16px 0px 16px 16px'
      gridGap='16px 0px'
      gridTemplateColumns='1fr'
    >
      <Link
        to='/contact'
        className={classes.sideNav_Link}
      >
        Contact
      </Link>
      {/* <Link
        to='/documentation'
        className={classes.sideNav_Link}
      >
        Documentation
      </Link> */}
      <Box>
        <Text
          variant='small3'
          color='rgba(255, 255, 255, 38%)'
        >
          © {new Date().getFullYear()} Zhortech
        </Text>
        <Text
          variant='small3'
          color='rgba(255, 255, 255, 38%)'
        >
          {process.env.REACT_APP_VERSION}
        </Text>
      </Box>
    </Grid>
  )
}
export default MenuFooterGrid