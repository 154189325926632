// import React from 'react'

// import { Box, Grid, Text } from '../../Base'
// import TextField from '../../ControlComps/TextField'

export default {
  EditorContents: null,
  ManagerContents: null,
  DeleterContents: null,
  Profile: null,
}