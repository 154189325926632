import merge from 'deepmerge'
import isPlainObject from 'is-plain-object'

// From https://www.npmjs.com/package/deepmerge
const combineMerge = (target, source, options) => {
  const destination = target.slice()

  source.forEach((item, index) => {
      if (typeof destination[index] === 'undefined') {
          destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
      } else if (options.isMergeableObject(item)) {
          destination[index] = merge(target[index], item, options)
      } else if (target.indexOf(item) === -1) {
          destination.push(item)
      }
  })
  return destination
}

const deepMergeCfg = {
  isMergeableObject: (item) => Array.isArray(item) || isPlainObject(item),
}

const deepMergeCfg_combineArrays = {
  ...deepMergeCfg,
  arrayMerge: combineMerge,
}

export default function (source, target, ...other) {
  target = target || (Array.isArray(source) ? [] : {})

  if (other.length) {
    other = other.filter((item) => item)
    return merge.all([source, target, ...other], deepMergeCfg)
  }
  return merge(source, target, deepMergeCfg)
}

export function deepMergeCombineArrays (source, target, ...other) {
  target = target || (Array.isArray(source) ? [] : {})

  if (other.length) {
    other = other.filter((item) => item)
    return merge.all([source, target, ...other], deepMergeCfg_combineArrays)
  }
  return merge(source, target, deepMergeCfg_combineArrays)
}