import { useModel, objectVariantOrPassthrough, initControls, setControlVal } from '../../../../lib/state'
import stateVariants from './variants'

const validHexRegex = new RegExp(/^#([0-9A-F]{3}){1,2}$/i)

const _buildContent = (state) => {
  state._content = {
    color_1: state.controls.color_1.val,
    salutation: state.controls.salutation.val,
    body: state.controls.body.val,
    farewell: state.controls.farewell.val,
    logoURL: state.controls.logoURL.val,
  }
}

///////////
// MODEL //
///////////

const model = {
  ///////////
  // STATE //
  ///////////
  stateInit(args) {
    let ret = {
      ...objectVariantOrPassthrough(stateVariants, args),
      //
    }

    ret.controls = {
      fromEmail: {
        defaultVal: ret.from.email,
        methodKey: 'setFromEmail',
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      fromName: {
        defaultVal: ret.from.name,
        methodKey: 'setFromName',
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      replyTo: {
        defaultVal: ret.replyTo,
        methodKey: 'setReplyTo',
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      bcc: {
        defaultVal: ret.bcc,
        methodKey: 'setBcc',
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      subject: {
        defaultVal: ret.subject,
        methodKey: 'setSubject',
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      template: {
        defaultVal: ret.template || 'basic',
        methodKey: 'setTemplate',
        items: {
          basic: {
            label: 'Basic'
          },
          // custom: {
          //   label: 'Custom HTML'
          // },
        },
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      salutation: {
        defaultVal: ret.content.salutation,
        methodKey: 'setSalutation',
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      body: {
        defaultVal: ret.content.body,
        methodKey: 'setBody',
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      farewell: {
        defaultVal: ret.content.farewell,
        methodKey: 'setFarewell',
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      logoURL: {
        defaultVal: ret.content.logoURL,
        methodKey: 'setLogoURL',
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      color_1: {
        defaultVal: ret.content.color_1,
        methodKey: 'setcolor_1',
        _validator: (val) => {
          return (val && val !== '') || (!validHexRegex.test(val) && 'Not a valid hex color (#RRGGBB)')
        },
        _validateAllowNotChanged: true,
      },
      html: {
        defaultVal: ret.content.html,
        methodKey: 'setHTML',
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
    }

    initControls(ret)

    _buildContent(ret)
  
    return ret
  },
  /////////////
  // METHODS //
  /////////////
  methodsInit(state) {
    return {
      // generic email props
      setFromName(val, opts) {
        setControlVal(state, state.controls.fromName, val)
      },
      setFromEmail(val, opts) {
        setControlVal(state, state.controls.fromEmail, val)
      },
      setReplyTo(val, opts) {
        setControlVal(state, state.controls.replyTo, val)
      },
      setBcc(val, opts) {
        setControlVal(state, state.controls.bcc, val)
      },
      setSubject(val, opts) {
        setControlVal(state, state.controls.subject, val)
      },
      // template selector
      setTemplate(val, opts) {
        setControlVal(state, state.controls.template, val)
      },
      // template - basic
      setSalutation(val, opts) {
        setControlVal(state, state.controls.salutation, val)
        _buildContent(state)
      },
      setBody(val, opts) {
        setControlVal(state, state.controls.body, val)
        _buildContent(state)
      },
      setFarewell(val, opts) {
        setControlVal(state, state.controls.farewell, val)
        _buildContent(state)
      },
      setLogoURL(val, opts) {
        setControlVal(state, state.controls.logoURL, val)
        _buildContent(state)
      },
      setcolor_1(val, opts) {
        setControlVal(state, state.controls.color_1, val)
        _buildContent(state)
      },
      // template - custom
      setHTML(val, opts) {
        setControlVal(state, state.controls.html, val)
      },
      // other
      _setShouldValidate(val, opts) {
        state.shouldValidate = val
        setControlVal(state)
      },
      _buildVal(val, opts) {
        const _val = {
          from: {
            name: state.controls.fromName.val,
            email: state.controls.fromEmail.val,
          },
          replyTo: state.controls.replyTo.val,
          bcc: state.controls.bcc.val,
          subject: state.controls.subject.val,
          template: state.controls.template.val,
          content: {
            ...state._content,
            html: state.controls.html.val,
          },
        }

        state._builtVal = _val
      },
    }
  },
  ///////////
  // HOOKS //
  ///////////
  // HOOK_EXAMPLES: {
  //   EXAMPLE_callback: { // A key to access ./lib/hooks keyedHooks
  //     getHookArgs: (state) => { return {} },
  //     callback: (res, methods) => {}
  //   },
  //   EXAMPLE_localHook: {
  //     useHook: (stateOrArgs, methods) => {},
  //   },
  // }
  hooks: {
    
  },
}

// returns [ state, methods ]
export default (...args) => {
  return useModel(model, ...args)
}