import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";
import { reducer as dbEntitiesReducer } from './dbEntities'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  dbEntities: dbEntitiesReducer,
});

