import React from 'react'

import useModel from './model'

import { Grid, Text } from '../../../../Base'
import TextField from '../../../../ControlComps/TextField'
import SelectorButton from '../../../../ControlComps/SelectorButton'

const Comp = React.memo(({ stateCfg, shouldValidate, onBuiltVal, ...props }) => {
  const [ state, methods ] = useModel(stateCfg, shouldValidate, onBuiltVal)

  return (
    <Grid
      gridGap='8px'
    >
      <Grid
        gridAutoFlow='column'
        gridTemplateColumns='max-content max-content'
        justifyContent='left'
        alignItems='center'
        borderRadius='4px'
        gridGap='8px'
      >
        <Text
          variant='small1'
          textAlign='right'
          width='116px'
        >
          {'...performed'}
        </Text>
        <SelectorButton
          state={state.controls.fieldVal}
          methods={methods}
          variant='outlined2'
          color='primary'
          height='100%'
          fontSize='0.875rem'
        />
      </Grid>
      <Grid
        gridAutoFlow='column'
        gridTemplateColumns='max-content max-content max-content max-content'
        justifyContent='left'
        alignItems='center'
        borderRadius='4px'
        gridGap='8px'
      >
        <Text
          variant='small3'
          color='text.secondary'
          textAlign='right'
          width='116px'
        >
          {'with count'}
        </Text>
        {/* <Text variant='small3' color='text.secondary'>
          {'count'}
        </Text> */}
        <SelectorButton
          state={state.controls.conditionOp}
          methods={methods}
          variant='outlined2'
          color='primary'
          height='100%'
          fontSize='0.875rem'
        />
        <TextField
          state={state.controls.conditionVal} 
          methods={methods}
          // variant='outlined'
        />
        <Text
          variant='small3'
          color='text.secondary'
        >
          {'times'}
        </Text>
      </Grid>
      <Grid
        gridAutoFlow='column'
        gridTemplateColumns='max-content max-content max-content max-content max-content'
        justifyContent='left'
        alignItems='center'
        borderRadius='4px'
        gridGap='8px'
      >
        <Text
          variant='small3'
          color='text.secondary'
          textAlign='right'
          width='116px'
        >
          {'anytime during'}
        </Text>
        {/* <Text variant='small3' color='text.secondary'>
          {'during'}
        </Text> */}
        <SelectorButton
          state={state.controls.lastDays}
          methods={methods}
          variant='outlined2'
          color='primary'
          height='100%'
          fontSize='0.875rem'
        />
        <Text variant='small3' color='text.secondary'>
          {'offset by'}
        </Text>
        <SelectorButton
          state={state.controls.offsetDays}
          methods={methods}
          variant='outlined2'
          color='primary'
          height='100%'
          fontSize='0.875rem'
        />
      </Grid>
    </Grid>
  )
})

export default Comp