import React from 'react';
import { Redirect, Route, Switch, useLocation, useHistory, useParams } from "react-router-dom";
import { styled } from '@material-ui/core/styles';
import { Flex } from './Base';
import { Tabs, Tab } from "@material-ui/core";
import { useDeepCompareRef } from '../lib/hooks';

// ROUTED TABS
// TODO extract to components folder
// TODO_PRACTICE hooks version for comparison w/ HOC solution
// TODO_MAYBE generic TabsObjectSelector, or maybe an array of objects selector hook
// TODO do we need to set currIdx to 0? (we might have cases where we do not want to preselect the first tab)

const StyledTab = styled(Tab)({
  fontSize: (props) => props.fontSize,
  textTransform: 'none',
  borderBottom: '1px solid transparent',
  margin: 'auto 20px auto 0px',
  // minWidth: "128px",
  minWidth: "min-content",
  '&:hover': {
    color: '#007BA7',
    // borderBottom: '1px solid #E6F2F6',
  }
})

// TODO redirect from here
export const TabbedRoutesTabs = React.memo(({ items, injectParamsIntoUrl, ...props }) => {
  const location = useLocation();
  const history = useHistory();

  if (injectParamsIntoUrl) {
    const params = useDeepCompareRef(useParams()).current

    items = React.useMemo(() => {
      return items.map((item) => {
        const {
          tabProps,
          label,
          path,
          page
        } = item

        let url = path || page

        Object.entries(params).forEach(([key, val]) => {
          url = url.replace(`:${key}`, val)
        })

        return {
          tabProps,
          label,
          url,
        }
      })
    }, [
      items,
      params
    ])
  }

  const [tabIdx, setTabIdx] = React.useState(0);

  React.useEffect(() => {
    let currIdx = items.findIndex((i) => (i.url || i.page) === location.pathname)
    currIdx = currIdx === -1 ? 0 : currIdx
    setTabIdx(currIdx)
  }, [items, location.pathname])

  const handleChange = (e, idx) => {
    setTabIdx(idx)
    const item = items[idx]
    history.push(item.url || item.page)
  }

  return (
    <Flex
      m='-32px -32px 32px'
      borderBottom='1px solid #E8E8E8'
      // bgcolor='bg.primary'
    >
      <Flex
        // m='auto'
        // borderBottom='1px solid #DBDDE0'
      >
        <Tabs
          value={tabIdx}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}>
          {items.map((item, i) => (
            <StyledTab fontSize='0.75rem' key={i} label={item.label} {...item.tabProps}/>
          ))}
        </Tabs>
      </Flex>
    </Flex>
  )
})

export const NestedTabsWithRoot = ({ root, nested, nestedBasePath }) => {
  return (
    <>
      <Route exact path={root.path}>
        <root.Comp {...root.props}/>
      </Route>
      <Route path={nestedBasePath}>
        <TabbedRoutesTabs
          items={nested}
          injectParamsIntoUrl={true}
        />
        <Switch>
          {
            nested.map((item, i) => (
              <Route key={i} path={item.path}>
                <item.Comp {...item.props}/>
              </Route>
            ))
          }
          <Redirect
            from={nestedBasePath}
            to={nested[0].path}
          />
        </Switch>
      </Route>
    </>
  )
}

export const TabbedRoutesObjectCfged = React.memo(({ basePath, tabsCfg, DefaultComp, defaultCompPropsBase }) => {
  const tabsKeys = Object.keys(tabsCfg)
  const tabsItems = tabsKeys.map((key) => {
    return {
      label: tabsCfg[key].label,
      path: basePath + key,
    }
  })

  return (
    <>
      <TabbedRoutesTabs items={tabsItems} injectParamsIntoUrl={true}/>
      <Switch>
        {
          tabsKeys.map((key, i) => {
            const item = tabsCfg[key]
            return (
              <Route key={i} path={basePath + key}>
                {
                  item.element
                    || (item.Comp && <item.Comp {...item.props}/>)
                    || <DefaultComp {...defaultCompPropsBase} {...item}/>
                }
              </Route>
            )
          })
        }
        <Redirect from={basePath} to={basePath + tabsKeys[0]}/>
      </Switch>
    </>
  )
})

// TODO rename to include switch
const TabbedRoutes = React.memo(({ items, redirectFrom, redirectTo }) => {
  return (
    <>
      <TabbedRoutesTabs items={items} />
      <Switch>
        { redirectFrom && <Redirect exact from={redirectFrom} to={redirectTo || items[0].url || items[0].page} /> }
        {items.map(({ page, component }, i) => (
          <Route key={i} path={page} component={component} />
        ))}
      </Switch>
    </>
  );
})

export default TabbedRoutes