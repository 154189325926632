import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl"
import sidebarStyles from '../lib/sidebarStyles'
import { Box } from '../components/Base'

const MenuItemButton = ({item, isActive, isSubmenuItem}) => {
  const classes = sidebarStyles()

  return (
    <Link 
      to={`/${item.page}`}
      className={isActive ? classes.menuItemActive : classes.menuItem}
    >
      {item.icon &&
        <i
          className={`${item.icon}`}
          style={{
            width: '24px',
            color: 'inherit',
            fontSize: '16px',
            marginRight: '12px'
          }}
        />
      }
      <span
        className={classes.menuItemText}
      > 
        {!item.translate ? (
          item.title
        ) : (
          <FormattedMessage id={item.translate} defaultMessage={item.title} />
        )}
      </span>

      {/* {item.badge && (
        <span className="kt-menu__link-badge">
          <span className={`kt-badge ${item.badge.type}`}>
            {item.badge.value}
          </span>
        </span>
      )} */}
    </Link>
  )
}

const MenuItem = ({item, parentItem, currentUrl}) => {
  const isMenuItemIsActive = (item) => {
    if (!item.page) {
      return false
    }
    return currentUrl.indexOf(`/${item.page}`) === 0
  }
  const isActive = isMenuItemIsActive(item)

  return (
    <>
      <MenuItemButton key='root' item={item} isActive={isActive}/>
      {
        item.submenu && isActive &&
        <Box
          bgcolor='rgb(0,0,0,0.1)'
          py='1px'
          mt='-8px'
          boxShadow='inset 0px 7px 9px -7px rgba(0,0,0,0.1)'
        >
          {
            item.submenu && isActive &&
              item.submenu.map((innerItem, i) => {
                const innerIsActive = isMenuItemIsActive(innerItem)
                return (
                  <MenuItemButton key={i} item={innerItem} isActive={innerIsActive} isSubmenuItem={true}/>
                )
              })
          }
        </Box>
      }
    </>
  )
}

export default MenuItem