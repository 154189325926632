import moment from 'moment-timezone'
import { isNumber, get } from 'lodash'
import { randomBytes } from 'crypto'

export function parseRole(role) {
  let parsed = {
    zt: false,
    org: false,
    user: false,
    admin: false
  };
  const [o, r] = role.split(':');
  if ( o === 'zhortech' ) parsed.zt = true;
  if ( o === 'org' ) parsed.org = true;
  if ( r === 'admin' ) parsed.admin = true;
  if ( r === 'user' ) parsed.user = true;
  return parsed;
}

export function timezone() {
  return moment.tz.guess();
}

export const EventNames = {
  INSTALLED: "App Installed",         // app installed
  SCAN: "SCAN",                       // scan for devices
  CONNECT: "CONNECT",                 // connect to a device
  CALIBRATION: "Calibration",         // shoe/device calibration
  ACTIVATED: "Activated",             // shoe record created 1st time
  FW_UPDATED: "Firmware Updated",     // firmware successfully updated
  START_ACTIVITY: "Start Activity",   // activity start
  STOP_ACTIVITY: "Stop Activity",     // activity stop
  ADD_SEGMENT: "Activity Data",       // add segment
};

// Pass in the data blob from an activity or a segment, will turn those ginormous fractions
// into more reasonable numbers for display.
export const humanizeData = (data) => {
  let r = {};
  Object.keys(data).forEach((key) => {
    if ( isNumber(data[key]) )
      r[key] = Number(data[key].toFixed(2));
    else
      r[key] = data[key];
  });
  return r;
}

export const timeRangeToReadableStr = (start, end, tz, sep = ' to ') => {
  start = moment(start)
  if (tz) {
    start = start.tz(tz)
  }

  if (!end) {
    return start.format('lll')
  }

  end = moment(end)
  if (tz) {
    end = end.tz(tz)
  }

  return `${start.format('lll')}${sep}${end.format('lll')}`
}

export const timeRangeToFilenameStr = (start, end, outerSep = '_', innerSep = '-', upToTime = 'minutes') => {
  let chars = 10
  if (upToTime) {
    switch(upToTime) {
      case 'hours':
        chars += 3
        break

      case 'minutes':
        chars += 6
        break

      case 'seconds':
        chars += 9
        break

      default:
        chars = 24 - 1
        break
    }
  }

  const startStr = moment(start).toISOString().replace(/[.:]+/, innerSep).slice(0, chars).replace('T', innerSep + 'T' + innerSep)
  if (!end) {
    return startStr
  }

  const endStr = moment(end).toISOString().replace(/[.:]+/, innerSep).slice(0, chars).replace('T', innerSep + 'T' + innerSep)

  return [ startStr, endStr ].join(outerSep)
}

export const sortedObjectByKeys = (o) => {
  return Object.keys(o)
    .sort()
    .reduce((acc, key) => { 
        acc[key] = o[key]
        return acc
      },
      {}
    )
}

// path
// - can be a single string
// - or a mixed array of strings and { path: 'PATH }
// returns the first value retrieved from a path
export const valFromPathOrPaths = (target, path, returnPath = false) => {
  let val = null
  let selectedPath = null

  if (Array.isArray(path)) {
    for (let i = 0; i < path.length; i++) {
      const _path = path[i].path || path[i]
      if (_path === '') {
        val = target
      }
      else {
        val = get(target, _path, null)
      }
     
      if (val) {
        selectedPath = _path
        break
      }
    }
  }
  else {
    selectedPath = path
    if (path === '') {
      val = target
    }
    else {
      val = get(target, path, null)
    }
  }

  if (returnPath) {
    return [ val, selectedPath ]
  }

  return val
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname

export const countryCodeToFlagUrl = (code) => `${process.env.PUBLIC_URL}/media/flags/${code.toLowerCase()}.svg`

export const uuidHexStr = () => randomBytes(16).toString("hex")

export const normPointsAndTimeUnit = (p, yUnit, preferredYUnit) => {
  if (!preferredYUnit) {
    return [ p, yUnit ]
  }

  let div

  if (yUnit === 'seconds') {
    if (preferredYUnit === 'minutes') div = 60
    else if (preferredYUnit === 'hours') div = 60 * 60

    yUnit = preferredYUnit
  }
  else if (yUnit === 'milliseconds') {
    if (preferredYUnit === 'seconds') div = 1000
    if (preferredYUnit === 'minutes') div = 1000 * 60
    else if (preferredYUnit === 'hours') div = 1000 * 60 * 60

    yUnit = preferredYUnit
  }

  if (div) {
    p = p.map((val) => val / div)
  }

  return [ p, yUnit ]
}

// pathsInfo
// - can be a single string
// - or a mixed array of strings and { path: 'PATH }
// returns a flattened array of retrieved values, or of { path: 'PATH', val: 'VAL' } objects
// export const collectValsFromPathOrPaths = (target, pathsInfo, includePath=false) => {

//   if (!Array.isArray(pathsInfo)) {
//     pathsInfo = [ pathsInfo ]
//   }

//   let ret = pathsInfo.map((pathInfo) => {
//       let path = pathInfo.path || pathInfo
//       let val = get(target, path, null)
//       if (includePath && val != null) {
//         val = {
//           path,
//           val,
//         }
//       }
//       return val
//     })
//     .filter((val) => val != null)
//     .map((val) => {
//       if (!Array.isArray(val)) {
//         val = [ val ]
//       }
//       return val
//     })

//   ret = flatten(ret)

//   return ret
// }


// const useImmerReduxAction = (func, ...args) => {
//   const dispatch = useDispatch()
//   args = useDeepCompareRef(args)

//   return React.useCallback((...res) => {
//     func(dispatch, ...args.current, ...res)
//   }, [
//     func,
//     dispatch,
//     args,
//   ])
// }