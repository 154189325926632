import queryTreeStateVariant from '../Query/Tree/stateVariant'
import objectTreeStateVariant from '../Query/ObjectEditor/Tree/stateVariant'

const stateVariants = {
  query: queryTreeStateVariant,
  object: objectTreeStateVariant,
}

const postProcessor = (o, variantOpts) => {
  const {
    root,
    data,
    debug,
  } = variantOpts

  o.root = root
  o.data = data
  o.debug = debug
  
  return o
}

export default {
  stateVariants,
  postProcessor
}