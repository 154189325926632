import moment from 'moment-timezone'
import { useApiCall } from '../../lib/useApi'

import LabeledIcon from '../../components/LabeledIcon'
import { 
  ScheduleOutlined,
  LockOutlined,
  MobileFriendlyOutlined,
  DetailsOutlined,
  //DirectionsRunOutlined,
  // ExploreOutlined,
  DateRangeOutlined,
  DateRange,
  EmojiObjectsOutlined,
} from '@material-ui/icons'

// TODO just make this a component and put ContentComp in every item
const cardPropsBase = {
  ContentComp: LabeledIcon,
  backgroundPosition: 'right',
  backgroundRepeat: 'no-repeat',
  bgimage: 'chartGraphic.fadeLeft.white',
  bgimage_hover: 'chartGraphic.fadeLeft.white_hover',
  boxShadow_hover: 'card_hover',
}

const activeUsersApiCallArgsBase = {
  method: 'post',
  urlBase: '/saas/v1/stats/v1/eventNamesCountsStats',
  useTimezone: true,
  includeAppIds: true,
  reqDataBase: {
    interval: null,
    uniqueByAppuser: true,
  },
  responseTransformer: (res, req) => {
    return {
      label: res.total.toLocaleString(),
    }
  },
  // debug: true,
}

// TODO do we want moment().startOf('week') ect?
const cardsCfg = [
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/activeUsers_daily',
    bgimage: 'barGraphic.fadeLeft.white',
    bgimage_hover: 'barGraphic.fadeLeft.white_hover',
    // gridColumnStart: 'span 2',
    contentProps: {
      IconComp: ScheduleOutlined,
      label: '---',
      labelSecondary: 'Daily Active Users',
      labelTertiary: `since yesterday (${moment().subtract(1, 'days').format('dddd, MMM DD')})`,
      useData: {
        useDataHook: useApiCall,
        args: {
          ...activeUsersApiCallArgsBase,
          reqDataFunc: () => ({
            start: moment().subtract(1, 'days').toISOString(),
            end: moment().toISOString(),
          }),
        }
      },
    },
  },
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/activeUsers_weekly',
    bgimage: 'barGraphic.fadeLeft.white',
    bgimage_hover: 'barGraphic.fadeLeft.white_hover',
    // gridColumnStart: 'span 2',
    contentProps: {
      IconComp: DateRangeOutlined,
      label: '---',
      labelSecondary: 'Weekly Active Users',
      labelTertiary: `since 7 days ago (${moment().subtract(1, 'weeks').format('dddd, MMM DD')})`,
      useData: {
        useDataHook: useApiCall,
        args: {
          ...activeUsersApiCallArgsBase,
          reqDataFunc: () => ({
            start: moment().subtract(1, 'weeks').toISOString(),
            end: moment().toISOString(),
          }),
        }
      },
    },
  },
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/activeUsers_monthly',
    bgimage: 'barGraphic.fadeLeft.white',
    bgimage_hover: 'barGraphic.fadeLeft.white_hover',
    // gridColumnStart: 'span 2',
    contentProps: {
      IconComp: DateRange,
      labelSecondary: 'Monthly Active Users',
      labelTertiary: `since 30 days ago (${moment().subtract(1, 'months').format('dddd, MMM DD')})`,
      label: '---',
      useData: {
        useDataHook: useApiCall,
        args: {
          ...activeUsersApiCallArgsBase,
          reqDataFunc: () => ({
            start: moment().subtract(1, 'months').toISOString(),
            end: moment().toISOString(),
          }),
        }
      },
    },
  },
  // TODO grab correct data
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/activation',
    bgimage: 'barGraphic.fadeLeft.white',
    bgimage_hover: 'barGraphic.fadeLeft.white_hover',
    // gridColumnStart: 'span 3',
    contentProps: {
      IconComp: MobileFriendlyOutlined,
      labelSecondary: 'Device Paired',
      labelTertiary: `since 7 days ago (${moment().subtract(1, 'weeks').format('dddd, MMM DD')})`,
      label: '---',
      useData: {
        useDataHook: useApiCall,
        args: {
          ...activeUsersApiCallArgsBase,
          reqDataFunc: () => ({
            start: moment().subtract(1, 'weeks').toISOString(),
            end: moment().toISOString(),
            eventName: 'Activated',
          }),
        }
      },
    },
  },
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/events',
    bgimage: 'barGraphic.fadeLeft.white',
    bgimage_hover: 'barGraphic.fadeLeft.white_hover',
    // gridColumnStart: 'span 3',
    contentProps: {
      label: 'Event Analysis',
      IconComp: EmojiObjectsOutlined,
    },
  },
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/retention',
    // bgimage: 'barGraphic.fadeLeft.white',
    // bgimage_hover: 'barGraphic.fadeLeft.white_hover',
    // gridColumnStart: 'span 3',
    contentProps: {
      label: 'Retention',
      IconComp: LockOutlined,
    },
  },
  {
    ...cardPropsBase,
    routeTo: '/analysis/chart/funnel',
    // bgimage: 'barGraphic.fadeLeft.white',
    // bgimage_hover: 'barGraphic.fadeLeft.white_hover',
    // gridColumnStart: 'span 3',
    contentProps: {
      label: 'Funnels',
      IconComp: DetailsOutlined,
    },
  },
  // {
  //   ...cardPropsBase,
  //   routeTo: '/analysis/chart/explore',
  //   // bgimage: 'barGraphic.fadeLeft.white',
  //   // bgimage_hover: 'barGraphic.fadeLeft.white_hover',
  //   // gridColumnStart: 'span 3',
  //   contentProps: {
  //     label: 'Explore',
  //     IconComp: ExploreOutlined,
  //   },
  // },
]

export default cardsCfg
