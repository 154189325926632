import React from 'react'

import useModel from './model'

import { Box, Flex, Grid, Text } from '../../Base'

import TextField from '../../ControlComps/TextField'

import useStyles from '../../../lib/useStyles'

const Comp = React.memo(({ stateCfg, onTriggerSetter, onCompleted, onHasChanged, ...props }) => {
  const [ state, methods ] = useModel(stateCfg)
  const classes = useStyles()

  React.useEffect(() => {
    onTriggerSetter(() => {
      methods.doUpdateCreds()
    })
  }, [
    methods,
    onTriggerSetter,
  ])

  React.useEffect(() => {
    methods.setOnCompletedCallback(onCompleted)
  }, [
    methods,
    onCompleted,
  ])

  const hasChanged = state.hasChanged
  React.useEffect(() => {
    onHasChanged(hasChanged)
  }, [
    hasChanged,
    onHasChanged,
  ])

  return (
    <>
      <Grid
        gridGap='24px'
        gridTemplateRows='max-content'
        mr='16px'
      >
        {
          state.validationhint &&
            <Text
              variant='small3'
              color={'error.main'}
            >
              {state.validationhint}
            </Text>
        }
        <Box>
          <Text variant='small1' pb='12px'>
            {'Certificate (.p12 file)'}
          </Text>
          <Flex minHeight='40px' pb='6px'>
            <input
              type='file'
              name='.p12 Certificate'
              id='file'
              className={classes.inputfile}
              onChange={(e) => methods[state.controls.p12.methodKey](e.target.files[0], methods)}
            />
            <label
              htmlFor='file'
              className={classes.inputfileLabel}
            >
              Choose .p12 Certificate
            </label>
          </Flex>
          <Text
            variant='small2'
            wordBreak='break-all'
          >
            {state.controls.p12.val?.name}
          </Text>
        </Box>
        <Box>
          <Text variant='small1' pb='6px'>
            {'Certificate Password'}
          </Text>
          <TextField
            fullWidth={true}
            variant='outlined'
            placeholder="Password used to generate .p12 certificate"
            state={state.controls.password}
            methods={methods}
          />
        </Box>
      </Grid>
    </>
  )
})

export default Comp