import React from "react"

import { Flex, Text } from '../../components/Base'

const Contact = () => {
  
  return (
    <>
      <Flex flexDirection='column' m='auto' alignItems='center'>
        <Text variant='h1' m='32px 0px 64px 0px'>Get In Touch</Text>
        <Flex
          flexDirection='column'
          m='auto'
          alignItems='center'
          maxWidth='916px'
        >
          <Text
            variant='subtitle1'
            color='primary.main'
            mt='16px'
          >
            contact@zhortech.com
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default Contact
