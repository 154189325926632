import React from 'react'

import { deepMergeCombineArrays } from '../../lib/deepMerge'
import { isEqual } from 'lodash'

import { Chart } from 'chart.js'
import { Box, Flex, RenderConfig } from '../Base'

const _reactiveOuterContainer = React.memo(({...props}) => <Box minWidth='0px' position='relative' {...props}/>)

const _getSpecialCaseCfg = (state) => {
  if (!state.dataWasSet && state.dataNotSetOpts) {
    return state.dataNotSetOpts
  }

  if (!state.hasDataPoints && state.noDataPointsOpts) {
    return state.noDataPointsOpts
  }

  if (state.hasAllZeroData && state.allZeroDataPointsOpts) {
    return state.allZeroDataPointsOpts
  }
}

const ChartJsContainer = React.memo(({state, methods, OuterContainerComp, onRef, ...props}) => {
  const chartContainer = React.useRef()
  const [chartInstance, setChartInstance] = React.useState(null)

  const specialCaseCfg = _getSpecialCaseCfg(state)

  React.useEffect(() => {
    if (specialCaseCfg && specialCaseCfg.contentsRenderCfg) {
      if (chartInstance) {
        chartInstance.destroy()
      }
      setChartInstance(null)
    }

    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current

      if (state.chartBgColor) {
        ctx.style.backgroundColor = state.chartBgColor
      }

      // NOTE: Deep cloning is necessary, ChartJS mutates the input config!!!
      const mergedLibCfg = deepMergeCombineArrays(
        state.libCfg,
        state.chartTypeLibCfg[state.libCfg.type],
        specialCaseCfg && specialCaseCfg.libCfg,
      )

      if (onRef) {
        onRef(chartContainer)
      }

      const canUpdate = chartInstance !== null
        && mergedLibCfg.type === chartInstance.config.type
        && isEqual(mergedLibCfg.plugins, chartInstance.config.plugins)

      if (canUpdate) {
        chartInstance.data = mergedLibCfg.data
        chartInstance.options = mergedLibCfg.options
        chartInstance.update()
      }
      else {
        if (chartInstance) {
          chartInstance.destroy()
        }
        setChartInstance(new Chart(ctx, mergedLibCfg))
      }
    }
  }, [
    chartInstance,
    state,
    specialCaseCfg,
    onRef
  ])

  return React.useMemo(() => {
    // Render alternative comp if present (specialCaseCfg.contentsRenderCfg)
    if (specialCaseCfg && specialCaseCfg.contentsRenderCfg) {
      const { containerProps, contentsRenderCfg } = specialCaseCfg

      return (
        <Flex {...props} {...containerProps}>
          <RenderConfig renderCfg={contentsRenderCfg}/>
        </Flex>
      )
    }

    // Render chart canvas
    const OuterContainer = OuterContainerComp || _reactiveOuterContainer
    return (
      <OuterContainer {...props}>
        <canvas ref={chartContainer}/>
      </OuterContainer>
    )
  }, [
    OuterContainerComp,
    props,
    specialCaseCfg,
  ])
})

export default ChartJsContainer
