/*
   A customization of RequireNewPassword from the stock Amplify UX components.  We wish to add
   a password confirmation field, to check that the confirmation matches the password, and to make the
   "name" prompt more explicit.
 */
import React from 'react';
import {
  RequireNewPassword,
} from "aws-amplify-react";

import * as Amp from 'aws-amplify-react';

import { I18n } from '@aws-amplify/core';

const SectionFooterPrimaryContent = props => {
  const theme = props.theme;
  const style = theme.sectionFooterPrimaryContent;
  return(
    <span className={"sectionFooterPrimaryContent"} style={style}>
      {props.children}
    </span>
  );
};

export const SectionFooterSecondaryContent = props => {
  const theme = props.theme;
  const style = theme.sectionFooterSecondaryContent;
  return(
    <span className={"sectionFooterSecondaryContent"} style={style}>
      {props.children}
    </span>
  );
};

export class MyRequireNewPassword extends RequireNewPassword {
  state = {
    password: '',
    confirmpassword: '',
    name: '',
  };

  valueChange(evt) {
    this.inputs = this.inputs || {};
    const { name, value, type, checked } = evt.target;
    const check_type = ['radio', 'checkbox'].includes(type);
    this.inputs[name] = check_type ? checked : value;
    this.inputs['checkedValue'] = check_type ? value : null;

    this.setState({[name]: this.inputs[name]});
  }
    
  showComponent(theme) {
    const user = this.props.authData;
    const { requiredAttributes } = user.challengeParam;

    const {  
      FormSection,
      SectionHeader,
      SectionBody,
      Button,
      Link,
    } = Amp;

    const passwordValid = () => {
      if ( requiredAttributes.length ) {
        return this.state.name.length &&
               this.state.password.length >= 8 &&
               this.state.confirmpassword.length >= 8 &&
               this.state.password === this.state.confirmpassword;
      }
      else {
        return this.state.password.length >= 8 &&
               this.state.confirmpassword.length >= 8 &&
               this.state.password === this.state.confirmpassword;
      }
    }
    
    return (
      <FormSection theme={theme}>
        <SectionHeader
            theme={theme}
        >
          {I18n.get('Change Password')}
        </SectionHeader>
        <SectionBody
            theme={theme}
        >
          <div className="amplify-input-label">New Password (length must be >= 8) *</div>
          <input
              autoFocus
              placeholder={I18n.get('New Password')}
              theme={theme}
              key="password"
              name="password"
              type="password"
              value={this.state.password}
              onChange={(e) => this.valueChange(e)}
          />
          <div className="amplify-input-label">Confirm Password *</div>
          <input
              placeholder={I18n.get('Confirm Password')}
              theme={theme}
              key="confirmpassword"
              name="confirmpassword"
              type="password"
              value={this.state.confirmpassword}
              onChange={(e) => this.valueChange(e)}
          />
          <div className="amplify-input-label">&nbsp;</div>
          {requiredAttributes.map(attribute => (
             <React.Fragment key={attribute}>
             <div className="amplify-input-label">{I18n.get(convertToPlaceholder(attribute))} *</div>
             <input
                 placeholder={I18n.get(convertToPlaceholder(attribute))}
                 theme={theme}
                 name={attribute}
                 type="text"
                 value={this.state.name}
                 onChange={(e) => this.valueChange(e)}
             />
             </React.Fragment>
           ))}
        </SectionBody>
        <div className="amplify-section-footer">
          <SectionFooterPrimaryContent theme={theme}>
            <Button theme={theme} disabled={!passwordValid()} onClick={this.change}>
              {I18n.get('Change')}
            </Button>
          </SectionFooterPrimaryContent>
          <SectionFooterSecondaryContent theme={theme}>
            <Link
                theme={theme}
                onClick={() => this.changeState('signIn')}
            >
              {I18n.get('Back to Sign In')}
            </Link>
          </SectionFooterSecondaryContent>
        </div>
      </FormSection>
    );
  }
}

function convertToPlaceholder(str) {
  if (str === 'name' ) return 'Your Full Name';
  else return str
                .split('_')
                .map(part => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
                .join(' ');
}

