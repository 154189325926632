import React from 'react'
import StyledButton from './StyledButton'

const ToggleButton = React.memo((props) => {
  const {
    methodKey,
    methodOpts,
    methods,
    id,
    state,
    //
    defaultVal,
    hasChanged,
    _validateAllowNotChanged,
    _other,
    _validator,
    _notChangedValidationHint,
    ...nonModelProps
  } = {
    ...props.state,
    ...props,
  }

  let {
    val,
    items,
    onClick,
    trueText,
    falseText,
    text,
    disabled,
    isValid,
    flipStyle,
    variant = 'primary',
    ...nonStateProps
  } = nonModelProps

  let handleClick = onClick
  if (!onClick) {
    handleClick = (event) => {
      methods[methodKey](!val, methodOpts, id)
    }
  }

  if (disabled === undefined) disabled = isValid === false

  let styleBool

  if (!flipStyle) styleBool = val
  else styleBool = !val

  if (variant === 'primary') {
    return (
      <StyledButton
        onClick={handleClick}
        disabled={disabled}
        variant={styleBool ? 'solidSmall' : 'outlinedSmall'}
        {...nonStateProps}
      >
        {(val ? trueText : falseText) || text || val}
      </StyledButton>
    )
  }
  else if (variant === 'onOffStatus') {
    return (
      <StyledButton
        onClick={handleClick}
        disabled={disabled}
        variant='solidSmall'
        color={styleBool ? 'success' : 'danger'}
        {...nonStateProps}
      >
        {(val ? trueText : falseText) || text || val}
      </StyledButton>
    )
  }
})

export default ToggleButton
