import { useModel } from '../../../lib/state'
import dbEntityModelFactory from '../entityModelFactory'
import initEntityModel from './initEntityModel'
import ContentComps from './ContentComps'

let entityCfg = {
  ContentComps,
  dbEntityType: 'shoes',
  label: 'Shoe',
  labelPlural: 'Shoes',
  icon: 'fas fa-shoe-prints',
  relations: {
    belongsTo: {
      organization: true,
      app: true,
    },
    hasOne: {},
    hasMany: {
      appusers: true,
      apps: true,
      events: true,
      activities: true,
      chunks: true,
    },
  },
  actionsPermissionCfgs: {
    role: {
      superAdmin: { edit: true, delete: true, devLogs: true },
    },
  },
  managementTabs: null,
  explore: {
    root: {
      lastEventsTable: {
        connectedEntityType: 'appusers',
      },
    },
    profile: true,
    activities: {
      chart: true,
      table: true,
    },
    chunks: {
      table: true,
    },
    relations: {
      appusers: true,
    },
  },
}

entityCfg = {
  ...entityCfg,
  nameLabel: 'Serial/Code',
  nameExportKey: 'code',
  nameDataPath: 'code',
  getEntityName(entity) {
    return entity.code
  },
  getAttributesViewObj(entity) {
    return entity.attributes
  },
  getAttributesConnectedEntities(entity) {
    return {
      appusers: {
        id: entity.attributes.lastUserDocId,
        userId: entity.attributes.lastUserId,
      }
    }
  },
}

const entityModel =  dbEntityModelFactory(initEntityModel(entityCfg))
const useEntityModel = (stateInitializerArgs) => useModel(entityModel, stateInitializerArgs)
entityCfg.useEntityModel = useEntityModel

export default entityCfg