import React from 'react';

import { Route, Switch } from "react-router-dom"
import { Box, Grid } from '../../components/Base'
import IconActionCard from '../../components/ActionCard'
import ControlledChartRouted from '../../components/Charting/ControlledChartRouted'

import cardsCfg from './cardsCfg.js'

import { useSelector } from 'react-redux'

// TODO support multi app id retention/funnels
const cardsCfgMultiApp = cardsCfg.slice(0, 5)

const AnalysisCards = React.memo(() => {
  const multiApps = useSelector((store) => store.auth.app?.apps)
  const _cardsCfg = multiApps ? cardsCfgMultiApp : cardsCfg


  return (
    <Box bgcolor='none'>
      <Grid gridGap='32px' gridTemplateColumns='repeat(2, 1fr)' gridAutoRows='126px'>
        {_cardsCfg.map((props, i) => 
          <IconActionCard key={i} {...props}/>
        )}
      </Grid>
    </Box>
  )
})

const Analysis = React.memo(() => {
  return (
    <Switch>
      <Route exact path='/analysis'>
        <Box>
          <AnalysisCards/>
        </Box>
      </Route>
      <Route path='/analysis/chart/:chartVariant'>
        <ControlledChartRouted/>
      </Route>
    </Switch>
  )
})

export default Analysis
