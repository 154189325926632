import React from 'react'

import { get } from 'lodash'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { StringifyProps } from '../Base'



const StringifyStore = (props) => {
  const {
    path
  } = {
    ...props,
    ...useParams(),
  }

  const store = useSelector(store => store)

  let _props = {
    __textProps: {
      fontSize: '12px',
    },
  }

  if (path) {
    _props[path] = get(store, path, {})
  }
  else {
    _props = {
      ...store,
      ..._props,
    }
  }

  return (
    <StringifyProps
      {..._props}
    />
  )
}

export default StringifyStore