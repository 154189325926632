import React from 'react'
import StyledButton from './StyledButton'

const ControlButton = React.memo((props) => {

  const {
    methodKey,
    methodOpts,
    methods,
    id,
    state,
    hasChanged,
    _validateAllowNotChanged,
    ...nonModelProps
  } = {
    ...props.state,
    ...props,
  }

  let {
    val,
    items,
    onClick,
    text,
    disabled,
    isValid,
    label,
    ...nonStateProps
  } = nonModelProps

  let handleClick = onClick
  if (!handleClick) {
    handleClick = (event) => {
      methods[methodKey](val, methodOpts, id)
    }
  }

  if (disabled === undefined) disabled = isValid === false

  return (
    <StyledButton
      onClick={handleClick}
      disabled={disabled}
      {...nonStateProps}
    >
      {text || label || val || undefined}
    </StyledButton>
  )
})

export default ControlButton;
