// import deepMerge from '../../lib/deepMerge'

//////////
// Util //
//////////

//////////
// Base //
//////////

const base = (variantOpts) => {
  const {
    // callbacks
    onCancel,
    onConfirm,
    // container
    isWarning,
    closeOnOutside = true,
    size = 'medium',
    // title
    title,
    TitleIcon,
    titlePrefix,
    // confirm control
    confirmLabel,
    confirmPrefix,
    // cancel control
    hasCancel = true,
    cancelLabel = 'Cancel',
    confirmAlwaysEnabled,
    // content
    children,
    text,
    showHeader = true,
  } = variantOpts

  return {
    onCancel,
    onConfirm,
    isWarning,
    closeOnOutside,
    size,
    title,
    TitleIcon,
    titlePrefix,
    confirmLabel,
    confirmPrefix,
    hasCancel,
    cancelLabel,
    confirmAlwaysEnabled,
    children,
    text,
    showHeader,
  }
}

//////////////
// Variants //
//////////////

const stateVariants = {}

stateVariants.view = (variantOpts) => {
  return {
    hasCancel: false,
    confirmAlwaysEnabled: true,
    confirmLabel: variantOpts.confirmLabel || 'Continue',
  }
}

stateVariants.interact = (variantOpts) => {
  const {
    title,
    entityName,
    entityLabel,
  } = variantOpts

  return {
    hasCancel: false,
    confirmAlwaysEnabled: true,
    title: title || `${entityLabel} - ${entityName}`,
    confirmLabel: 'Done',
  }
}

stateVariants.performAction = (variantOpts) => {
  const {
    title,
    titlePrefix,
    entityName,
    entityLabel,
    confirmLabel,
    confirmPrefix,
  } = variantOpts

  return {
    hasCancel: true,
    confirmAlwaysEnabled: true,
    title: title || (titlePrefix && `${titlePrefix} ${entityLabel} - ${entityName}?`) || `${entityLabel} - ${entityName}?`,
    confirmLabel: confirmLabel || `${confirmPrefix} ${entityLabel}`,
  }
}

stateVariants.editNew = (variantOpts) => {
  const {
    title,
    entityLabel,
    confirmLabel,
  } = variantOpts

  return {
    title: title || entityLabel,
    confirmLabel: confirmLabel || 'Save',
    closeOnOutside: false,
  }
}

stateVariants.edit = (variantOpts) => {
  const {
    title,
    entityName,
    entityLabel,
    confirmLabel,
  } = variantOpts

  return {
    title: title || `${entityLabel} - ${entityName}`,
    confirmLabel: confirmLabel || 'Apply Changes',
  }
}

stateVariants.clone = (variantOpts) => {
  const {
    title,
    entityLabel,
    entityName,
  } = variantOpts

  return {
    title: title || `${entityLabel} (from ${entityName})`,
    confirmLabel: 'Save',
    closeOnOutside: false,
  }
}

// TODO stateVariants.performWarningAction
stateVariants.delete = (variantOpts) => {
  const {
    title,
    entityLabel,
    entityName,
  } = variantOpts

  return {
    title: title || `Delete ${entityLabel} - ${entityName}?`,
    isWarning: true,
    confirmLabel: `Delete ${entityLabel}`,
    confirmAlwaysEnabled: true,
  }
}

// TODO stateVariants.performWarningAction
stateVariants.archive = (variantOpts) => {
  const {
    title,
    entityLabel,
    entityName,
  } = variantOpts

  return {
    title: title || `Archive ${entityLabel} - ${entityName}?`,
    isWarning: true,
    confirmLabel: `Archive ${entityLabel}`,
    confirmAlwaysEnabled: true,
  }
}

////////////
// Export //
////////////

export default {
  base,
  stateVariants,
}