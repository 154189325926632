// import React from 'react'
import { fade, lighten, darken, makeStyles } from '@material-ui/core/styles'

const sidebarStyles = makeStyles(theme => ({
  sidebarContainer: {
    width: "216px",
    flexShrink: 0,
    boxSizing: 'border-box',
    backgroundColor: darken(theme.palette.primary.dark, 0.08),
  },
  sidebar: {
    width: "100%",
    position: 'sticky',
    height: '100vh',
    top: '0px',
    left: '0px',
    flexShrink: 0,
  },
  sidebarClosedSidebar: {
    display: "none",
  },
  menuItem: {
    display: 'flex',
    color: `${lighten(theme.palette.primary.main, .4)}`,
    width: '100%',
    padding: '0px 16px',
    margin: '8px 0px',
    height: '40px',
    alignItems: 'center',
    backgroundColor: 'transparent',
    '&:hover': {
      color: `${lighten(theme.palette.primary.main, .6)}`,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  menuItemActive: {
    display: 'flex',
    width: '100%',
    padding: '0px 16px 0px 12px',
    margin: '8px 0px',
    height: '40px',
    alignItems: 'center',
    color: '#fff',
    backgroundColor: theme.palette.primary.dark,
    borderLeft: `4px solid ${theme.palette.primary.light}`,
    '&:hover': {
      color: '#fff',
    },
  },
  menuItemText: {
    color: 'inherit',
    fontSize: '0.8125rem',
    letterSpacing: '0.05px',
    fontWeight: 400,
    // paddingLeft: '8px'
  },
  appPicker: {
    padding: '12px 16px 12px 9px',
    maxWidth: '220px',
    width: '100%',
    alignItems: 'center',
    borderRadius: '0px',
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  appMenuItem: {
    color: theme.palette.primary.mute
  },
  sideNav_Link: {
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.0125rem',
    lineHeight: '1rem',
    color: fade(theme.palette.primary.contrastText, 0.70),
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },
  menuGroupExpanded: {
    // zIndex: 1300,
    visibility: 'hidden',
    opacity: 0,
    // position: 'absolute',
    // top: 0,
    // right: 0,
    // bottom: 0,
    // left: 0,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    background: 'rgba(0, 0, 0, .08)',
    // background: 'rgba(0, 0, 0, .7)',
    transition: 'all .3s',
    '&:target': {
      visibility: 'visible',
      opacity: 1
    }
  },
  modal: {
    zIndex: 1300,
    visibility: 'hidden',
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(77, 77, 77, .7)',
    // background: 'rgba(0, 0, 0, .7)',
    transition: 'all .3s',
    '&:target': {
      visibility: 'visible',
      opacity: 1
    }
  },
  modal__content: {
    borderRadius: '4px',
    position: 'relative',
    width: '500px',
    maxWidth: '90%',
    background: '#fff',
    padding: '1em 2em',
  },
  modal__footer: {
    textAlign: 'right'
  },
  modal__close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#585858',
    textDecoration: 'none',
  },
  modal__open: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    '&:hover': {
      color: theme.palette.text.primary
    }
  },
}))

export default sidebarStyles