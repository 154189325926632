import React from 'react'

import {
  useDeepCompareRef, useSelfId, keyedHooks,
  // useBooleanToken,
} from '../../lib/hooks'

// import useApi from '../../lib/useApi'

import { useSelector } from 'react-redux'

import { useHistory, useParams } from 'react-router-dom'

import { Card, Grid, } from '../Base'
import Link from '@material-ui/core/Link'
import Permitted from '../Permitted'

import { ObjectPropertiesList } from '../AttributeList'

import * as dbEntityCfgs from './allDbEntities'

export const getLongEntityLabel = (dbEntityCfg, entity, newEntityOpts, onlyOwnedBySelf = false, isPlural = false) => {
  if (!entity && newEntityOpts) {
    entity = dbEntityCfg.getNewEntityBase(newEntityOpts)
  }

  return [
    onlyOwnedBySelf && 'My',
    entity && dbEntityCfg.getEntityLabelPrefix && dbEntityCfg.getEntityLabelPrefix(entity),
    dbEntityCfg[isPlural ? 'labelPlural' : 'label'],
    entity && dbEntityCfg.getEntityLabelSuffix && dbEntityCfg.getEntityLabelSuffix(entity),
  ].filter(str => str).join(' ')
}

export const useFilteredEntityItems = (dbEntityType, filter, onlyOwnedBySelf, bulkFilter, items = null) => {
  const _items = useSelector((store) => items || store.dbEntities.lists[dbEntityType])
  const selfId = useSelfId()

  // const dbEntityCfg = dbEntityCfgs[dbEntityType]
  // const allowApiToken = useBooleanToken(allowApi && _items && _items.length === 0)
  // const appId = useSelector((store) => store.auth.app && store.auth.app.id)
  // keyedHooks.useDbApi_getList(React.useMemo(() => {
  //   const where = {}
  //   if (appId && dbEntityCfg.relations.ownedBy.app) {
  //     where.appId = appId
  //   }

  //   const include = []
  //   if (dbEntityCfg.relations.ownedBy.saasuser) {
  //     include.push({
  //       relation: 'saasuser',
  //       scope: {
  //         fields: [ 'name', 'email' ],
  //       }
  //     })
  //   }

  //   return {
  //     allow: allowApiToken,
  //     dbEntityType,
  //     reqData: {
  //       where,
  //       include,
  //       order: 'updatedAt DESC',
  //     }
  //   }
  // }, [appId, dbEntityCfg, dbEntityType, allowApiToken]))
  
  return React.useMemo(() => {
    let ret = _items
    if (bulkFilter) {
      ret = bulkFilter(ret)
    }
    if (onlyOwnedBySelf) {
      ret = ret.filter((item) => item.saasuserId === selfId)
    }
    if (filter) {
      ret = ret.filter(filter)
    }
    return ret
  }, [_items, filter, onlyOwnedBySelf, bulkFilter, selfId])
}

export const useEntityModalProps = (modalVariant, dbEntityType, { entity, newEntityOpts, cloneFrom, saveFrom, isDeleter, isArchiver }, onCompleted) => {
  const dbEntityCfg = dbEntityCfgs[dbEntityType]

  const modalProps = React.useMemo(() => {
    const modalOptsBaseCfg = dbEntityCfg.modalOpts && dbEntityCfg.modalOpts.base
    

    // TODO cleanup or rename modalOptsBase / modalOptsBaseCfg (maybe modalOpts.modalVariantOpts)

    let modalOptsBase = undefined
    let modalOptsForm = undefined
    if (isDeleter) {
      modalOptsBase = modalOptsBaseCfg?.deleter
      modalOptsForm = dbEntityCfg.modalOpts?.form?.deleter
    }
    else if (isArchiver) {
      modalOptsBase = modalOptsBaseCfg?.archiver
    }
    else {
      if (modalVariant === 'editNew' && newEntityOpts) {
        modalOptsBase = modalOptsBaseCfg?.creator
        modalOptsForm = dbEntityCfg.modalOpts?.form?.creator
      }

      if (!modalOptsBase) {
        modalOptsBase = modalOptsBaseCfg?.editor
        modalOptsForm = dbEntityCfg.modalOpts?.form?.editor
      }
    }

    modalOptsBase = modalOptsBase || {}
    
    return {
      variant: modalOptsBase.variant || modalVariant, // TODO dont use modalOptsBase.variant (create generic interactor vairant logic)
      variantOpts: {
        ...modalOptsBase,
        ...((modalOptsBaseCfg && modalOptsBaseCfg[modalVariant]) || {}),
        entityName: (entity || saveFrom || cloneFrom) && dbEntityCfg.getEntityName(entity || saveFrom || cloneFrom),
        entityLabel: getLongEntityLabel(dbEntityCfg, entity || saveFrom || cloneFrom, newEntityOpts),
      },
      Content: isDeleter || isArchiver
        ? DbEntityDeleterOrArchiverFormContents
        : DbEntityEditorFormContents,
      contentProps: {
        dbEntityType,
        entity,
        newEntityOpts,
        cloneFrom,
        saveFrom,
        onCompleted,
        isDeleter,
        isArchiver,
        ...modalOptsForm,
      }
    }
  }, [
    dbEntityType,
    dbEntityCfg,
    modalVariant,
    entity, newEntityOpts, cloneFrom, saveFrom,
    isDeleter,
    isArchiver,
    onCompleted,
  ])

  return modalProps
}

//
// Comps
//

export const DbEntityActionButton = React.memo(({ dbEntityType, permsPath, cfg, entity, otherData, onCompleted, disabled, computedRolePerms = null, computedEntityPerms = null }) => {
  const {
    Comp,
    modifiesState,
    permsPath: _permsPath,
  } = cfg

  let _onCompleted = onCompleted

  if (onCompleted) {
    _onCompleted = React.useCallback(() => onCompleted(modifiesState), [modifiesState, onCompleted])
  }

  return (
    <Permitted
      path={_permsPath || permsPath}
      permsCfg={dbEntityCfgs[dbEntityType].actionsPermissionCfgs}
      computedRolePerms={computedRolePerms}
      computedEntityPerms={computedEntityPerms}
    >
      <Comp
        dbEntityType={dbEntityType}
        entity={entity}
        onCompleted={_onCompleted}
        disabled={disabled}
        otherData={otherData}
      />
    </Permitted>
  )
})

export const ExploreEntityLink = React.memo(({ dbEntityType, entity, id, label, subLoc, baseLoc, shouldLink = true, suffix, defaultLabel = '-' }) => {
  const dbEntityCfg = dbEntityCfgs[dbEntityType]

  if (!label) {
    if (entity) {
      label = dbEntityCfg.getEntityName(entity)
    }
  
    if (label) {
      if (suffix) {
        label = `${label} ${suffix}`
      }
    }
    else {
      label = defaultLabel
    }
  }

  if (shouldLink && ((entity && entity.id) || id)) {
    const history = useHistory()

    return (
      <Link
        component='button'
        onClick={() => history.push(`/${baseLoc?.substring(1) || dbEntityType}/${id || entity.id}${(subLoc && ('/' + subLoc)) || ''}`)}
      >    
        {label}
      </Link>
    )
  }
  
  return (
    <span>{label}</span>
  )
})

const useEntity = (dbEntityType, entity, id, relations) => {
  // TODO try grab from store
  // TODO_MAYBE remove router params
  const routerParams = useParams()
  id = id || routerParams.id

  const [_entity, setEntity] = React.useState(entity)

  relations = useDeepCompareRef(relations).current
  const reqData = React.useMemo(() => {
    return {
      include: relations
    }
  }, [ relations ])

  keyedHooks.useDbApi_getById({
    dbEntityType,
    id: _entity ? undefined : id,
    reqData,
  }, setEntity)

  return _entity
}

export const EntityAttributesViewSimple = React.memo(({ dbEntityType, entity, id }) => {
  entity = useEntity(dbEntityType, entity, id)

  if (!entity) {
    return null
  }

  const dbEntityCfg = dbEntityCfgs[dbEntityType]
  return (
    <ObjectPropertiesList
      mb='32px'
      title={<ExploreEntityLink dbEntityType={dbEntityType} entity={entity}/>}
      obj={dbEntityCfg.getAttributesViewObj(entity)}
    />
  )
})

export const EntityRelationViewSimple = React.memo(({ dbEntityType, entity, id, relation }) => {
  entity = useEntity(dbEntityType, entity, id, [ relation ])

  const dbEntityCfg = dbEntityCfgs[dbEntityType]
  if (dbEntityCfg.relations.belongsTo[relation] || dbEntityCfg.relations.hasOne[relation]) {
    return (
      <Card>
        <EntityAttributesViewSimple
          dbEntityType='relation'
          entity={entity && entity[relation]}
          id={entity && entity[`${relation}Id`]}
        />
      </Card>
    )
  }
  else if (dbEntityCfg.relations.hasMany[relation]) {
    const entities = (entity && entity[relation]) || []
    return (
      <Grid
        gridAutoFlow='row'
        gridGap='32px'
        gridTemplateColumns='repeat(2, 1fr)'
        alignSelf='center'
      >
        {
          entities.map((innerEntity) => {
            return (
              <Card key={innerEntity.id} p='16px'>
                <EntityAttributesViewSimple
                  dbEntityType={relation}
                  entity={innerEntity}
                />
              </Card>
            )
          })
        }
      </Grid>
    )
  }
})

//
// Form Contents
//

const useNewEntityWithBelongsToData = (dbEntityType, newEntityOpts, cloneFrom) => {
  const dbEntityCfg = dbEntityCfgs[dbEntityType]
  const belongsTo = dbEntityCfg.relations.belongsTo || {}

  const saasuserId = useSelector((store) => belongsTo.saasuser && store.auth.user && store.auth.user.id)
  const appId = useSelector((store) => belongsTo.app && store.auth.app && store.auth.app.id)
  const organizationId = useSelector((store) => belongsTo.organization && store.auth.organization && store.auth.organization.id)

  if (belongsTo.app && belongsTo.app !== 'OPTIONAL' && !appId) {
    throw new Error(`dbEntityType:${dbEntityType} must be owned by an app, no app was selected`)
  }

  newEntityOpts = useDeepCompareRef(newEntityOpts).current
  return React.useMemo(() => {
    const ret = (cloneFrom && { ...cloneFrom }) || dbEntityCfg.getNewEntityBase(newEntityOpts)

    if (belongsTo.saasuser) ret.saasuserId = saasuserId
    if (belongsTo.app) ret.appId = appId
    if (belongsTo.organization) ret.organizationId = organizationId

    return ret
  }, [
    dbEntityCfg, belongsTo, newEntityOpts, cloneFrom,
    saasuserId, appId, organizationId,
  ])
}

const DbEntityEditorFormContents = React.memo((props) => {
  let {
    formMethods,
    dbEntityType,
    onCompleted,
    id,
    entity,
    newEntityOpts,
    cloneFrom,
    saveFrom,
    saveOnClose = true,
  } = props

  const dbEntityCfg = dbEntityCfgs[dbEntityType]

  // TODO_MAYBE allow and implement [ cloneFrom + newEntityOpts ] at the same time
  const num = Boolean(entity) + Boolean(newEntityOpts) + Boolean(cloneFrom) + Boolean(id) + Boolean(saveFrom)
  if (num > 1) {
    throw new Error(`only one of [ entity, newEntityOpts, cloneFrom, id, saveFrom ] is allowed!`)
  }

  if (num === 0) newEntityOpts = {}

  const isNew = Boolean(newEntityOpts || cloneFrom || saveFrom)

  const [ entityState, entityMethods ] = dbEntityCfg.useEntityModel({
    variant: null,
    variantOpts: {
      id,
      data: entity || saveFrom || (!id && useNewEntityWithBelongsToData(dbEntityType, newEntityOpts, cloneFrom)) || undefined,
      isNew,
      isClone: Boolean(cloneFrom),
      isSaveAs: Boolean(saveFrom),
      isEdit: Boolean(entity),
    }
  })

  React.useEffect(() => {
    if(saveOnClose) {
      entityMethods.setOnSaved(() => {
        formMethods.close()
        if (onCompleted) onCompleted()
      })
      formMethods.setOnConfirm(entityMethods.trySaveOnNextValidate)
    }
    else {
      formMethods.setOnConfirm(() => {
        formMethods.close()
        if (onCompleted) onCompleted()
      })
    }
  }, [
    onCompleted,
    entityMethods,
    formMethods,
    saveOnClose,
  ])

  React.useEffect(() => {
    formMethods.setConfirmEnabled(entityState.hasChanged)
  }, [
    entityState.hasItems, // TODO this might not be used
    entityState.hasChanged,
    formMethods,
  ])

  if (isNew && dbEntityCfg.ContentComps.CreatorContents) {
    return <dbEntityCfg.ContentComps.CreatorContents state={entityState} methods={entityMethods}/>
  }

  return <dbEntityCfg.ContentComps.EditorContents state={entityState} methods={entityMethods}/>
})

const DbEntityDeleterOrArchiverFormContents = React.memo((props) => {
  const {
    formMethods,
    dbEntityType,
    onCompleted,
    id,
    entity,
    isDeleter,
    isArchiver,
  } = props

  const dbEntityCfg = dbEntityCfgs[dbEntityType]

  const [ entityState, entityMethods ] = dbEntityCfg.useEntityModel({
    variant: null,
    variantOpts: {
      id,
      data: entity,
      hasControls: false,
    }
  })

  React.useEffect(() => {
    let onActionDoneSetter = null
    let onConfirm = null

    if (isDeleter) {
      onActionDoneSetter = entityMethods.setOnDeleted
      onConfirm = entityMethods.delete
    }
    else if (isArchiver) {
      onActionDoneSetter = entityMethods.setOnArchived
      onConfirm = entityMethods.archive
    }

    onActionDoneSetter(() => {
      formMethods.close()
      if (onCompleted) onCompleted()
    })
    formMethods.setOnConfirm(onConfirm)
  }, [
    isDeleter,
    isArchiver,
    onCompleted,
    entityMethods,
    formMethods,
  ])

  if (dbEntityCfg.ContentComps.DeleterContents) {
    return <dbEntityCfg.ContentComps.DeleterContents state={entityState} methods={entityMethods}/>
  }

  return null
})


