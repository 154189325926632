import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import * as auth from "../store/ducks/auth.duck"

import { Box, Text, Flex, Grid, IconResolver } from '../components/Base'
import sidebarStyles from '../lib/sidebarStyles'

import { Menu, MenuItem } from '@material-ui/core'
import { withStyles, lighten } from '@material-ui/core/styles'

const getSubAppsList = (org) => {
  if ( ! org ) return []

  let subApps
  
  if ( ! org.subApps ) {
    subApps = []
  }
  else if ( org.subApps && org.subApps.length <= 1 ) {
    subApps = [
      ...org.subApps.map((app) => {
        return {
          ...app,
          _selectorName: `${app.organization?.name} - ${app.name}`,
          _selectedName: `${app.organization?.name} - ${app.name}`,
        }
      })
    ]
  }
  else {
    subApps = [
      {
        // TODO this can become outdated, so perhaps do somethign like 'allSubApps: true'
        name: 'All Affiliate Apps',
        _selectedName: "All Affiliate Apps",
        apps: org.subApps,
      },
      ...org.subApps.map((app) => {
        return {
          ...app,
          _selectorName: `${app.organization?.name} - ${app.name}`,
          _selectedName: `${app.organization?.name} - ${app.name}`,
        }
      })
    ]
  }
  return subApps
}
const getAppsList = (org) => {
  if ( ! org ) return []

  let orgApps

  if ( ! org.apps ) {
    orgApps = []
  }
  else if ( org.apps && org.apps.length <= 1 ) {
    orgApps = [ ...org.apps ]
  }
  else {
    orgApps = [
      {
        name: `All ${org?.name} Apps`,
        _selectedName: `All ${org?.name} Apps`,
      },
      ...org.apps,
    ]
  }
  return orgApps
}
const makeAppsList = (org) => {
  if ( ! org ) return []
  let orgApps = getAppsList(org)
  let subApps = getSubAppsList(org)
  let appsList = []
  if ( orgApps?.length && subApps?.length ) {
    appsList = [
      ...orgApps,
      undefined,
      ...subApps,
    ]
  }
  else {
    appsList = [
      ...orgApps,
      ...subApps,
    ]
  }
  return appsList
}

const StyledMenu = withStyles({
  paper: {
    borderRadius: "4px",
    border: "1px solid #E8E8E8",
    width: '240px',
    top: '0px !important'
  },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    style={{margin: '6px'}}
    {...props}
  />
));

const AppPicker = () => {
  const history = useHistory()
  const classes = sidebarStyles()

  const organization = useSelector(store => store.auth.organization)
  const currentApp = useSelector(store => store.auth.app)
  
  // const appType = currentApp?.pluginName

  const dispatch = useDispatch()
  const {setApp} = auth.actions

  const [ menuAnchor, setMenuAnchor ] = React.useState(null)
  
  const [ apps, setApps ] = React.useState(makeAppsList(organization))

  const handleSelect = (app) => {
    setMenuAnchor(null);
    dispatch(setApp(app))
    history.push('/')
  }

  React.useEffect(() => {
    setApps(makeAppsList(organization))
  }, [organization])

  let subtext, onClick, endIcon, subtextColor, fontStyle

  // if ( !currentApp && apps?.length ) {
  //   dispatch(setApp(apps[0]))
  //   // handleSelect(apps[0])
  // }
  if (currentApp) {
    subtext = currentApp._selectedName || currentApp.name
    onClick = (e) => setMenuAnchor(e.currentTarget)

    endIcon =
      <IconResolver
        icon={'fas fa-chevron-down'}
        fontSize='9px'
        color={lighten('#007BA7', .4)}
      />
    subtextColor = '#fff'
    fontStyle = 'normal'
  }
  else if ( apps?.length && !currentApp ) {
    subtext = "Select App..."
    onClick = (e) => setMenuAnchor(e.currentTarget)
    endIcon =
      <IconResolver
        icon={'fas fa-chevron-down'}
        fontSize='9px'
        color={lighten('#007BA7', .4)}
      />
    subtextColor = 'success.light'
    fontStyle = 'italic'
    // dispatch(setApp(apps[0]))
  }
  else {
    subtext = "Create App..."
    onClick = () => history.push('/orgs/apps')
    endIcon =
      <IconResolver
        icon={'fas fa-chevron-right'}
        fontSize='9px'
        color={lighten('#007BA7', .4)}
      />
    subtextColor = 'warning.main'
    fontStyle = 'italic'
  }

  return(
    <>
      <Grid
        className={classes.appPicker}
        aria-label="app-picker"
        onClick={onClick}
        gridTemplateColumns='min-content 1fr min-content'
        height='58px'
      >
        <Box> 
          <Flex
            minWidth='28px'
            height='28px'
            mr='10px'
            borderRadius='3px'
            alignItems='center'
            justifyContent='center'
          >
            <img
              src={ organization?.logo || ("/media/zhortech/footprints-icon.png")}
              alt='Footprints'
            />
          </Flex>
        </Box>
        <Box>
          <Text
            variant='small2'
            textAlign='left'
            color='bg.primary'
            style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: "147px" }}
          >
            {organization?.name}
          </Text>
          <Text
            variant='body3'
            color={subtextColor}
            style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: "147px" }}
            fontStyle={fontStyle}
          >
            {subtext}
          </Text>
        </Box>
        <Box>
          {endIcon}
        </Box>
      </Grid>
      <StyledMenu
        id="actions"
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        keepMounted
      >
        {
          apps.map((app, i) => {
            if (!app) {
              return (
                <Box
                  key={i}
                  height='1px'
                  bgcolor='border.card'
                  my='8px'
                />
              )
            }
            return (
              <MenuItem
                key={app.id || app.name}
                onClick={() => handleSelect(app)}
                style={{
                  fontSize: "0.75rem",
                  fontWeight: 500,
                  padding: '12px 16px',
                }}
                className={classes.appMenuItem}
              >
                {app._selectorName || app.name}
              </MenuItem>
            )
          })
        }
      </StyledMenu>
    </>
  );
}

export default AppPicker

