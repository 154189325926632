import React from 'react'

import ManageDbEntity from '../../components/DbEntities/Manage'
import { keyedHooks } from '../../lib/hooks'

const getListArgs = {
  dbEntityType: 'webhooks',
  reqData: {
    order: 'id DESC',
  }
}

const WebhooksPage = () => {
  keyedHooks.useDbApi_getList(getListArgs)

  return (
    <ManageDbEntity
      dbEntityType='webhooks'
      basePath='/webhooks'
    />
  )
}

export default WebhooksPage
