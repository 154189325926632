import React from 'react';

import deepMerge from '../../lib/deepMerge';
import { styled, withStyles, useTheme } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { MenuItem, Select } from "@material-ui/core"

import { Flex } from '../Base';

// TODO_MAYBE use logic from MenuButton for callbacks

// TODO_MAYBE import { ExpandMore } from '@material-ui/icons'

const _stylesCfgBase = {
  root: {},
  selectMenu: {},
  icon: {
    // right: '4px',
  },
  select: {
    '&&': {
      'padding-right': '24px',
    },
    padding: '0px',
    paddingLeft: '4px',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    boxSizing: 'border-box',
    height: (props) => props.height || '32px',
    borderRadius: (props) => props.bradius,
    fontSize: '0.75rem',
    // fontSize: (props) => props.fontSize || '0.75rem',
    // lineHeight: (props) => props.lineheight || '1.125rem',
    fontWeight: (props) => props.fontweight || '500',
    fontStyle: (props) => props.fontStyle || 'normal',
    boxShadow: (props) => props.bshadow,
    "&:focus": {
      borderRadius: (props) => props.bradius,
    },
    '&:hover': {
      transition: (props) => props.theme.transitions.buttonHover,
    },
  }
}

const _solid = {
  root: {
    color: (props) => props.bgcolor.contrastText,
    backgroundColor: (props) => props.bgcolor.main,
    '&:hover': {
      backgroundColor: (props) => props.bgcolor.dark,
    }
  },
  // selectMenu: {
  //   color: (props) => props.bgcolor.contrastText,
  // },
  icon: {
    fill: (props) => props.bgcolor.contrastText,
  },
  select: {
    boxShadow: (props) => props.bshadow,
    "&:focus": {
      background: (props) => props.bgcolor.main,
    },
  },
}

const _outlined = {
  root: {
    color: (props) => props.textcolor || props.bgcolor.main,
    border: (props) => `1px solid ${fade(props.bgcolor.main, 0.5)}`,
    '&:hover': {
      border: (props) => `1px solid ${props.bgcolor.main}`,
      // backgroundColor: (props) => fade(props.bgcolor.main,  props.hoveropacity || props.theme.palette.action.hoverOpacity),
    }
  },
  // selectMenu: {
  //   color: (props) =>  props.bgcolor.main,
  // },
  icon: {
    fill: (props) =>  props.bgcolor.main,
  },
  select: {
    "&:focus": {
      background: 'none',
    },
  },
}

const _outlined2 = {
  root: {
    color: (props) => props.textcolor || props.theme.palette.text.secondary,
    border: (props) => `1px solid ${props.theme.palette.border.inactive}`,
    '&:hover': {
      border: (props) => `1px solid ${props.theme.palette.border.active}`,
      // color: (props) => props.textcolor || props.bgcolor.main,
      backgroundColor: (props) => fade(props.theme.palette.border.active, props.hoveropacity || props.theme.palette.action.hoverOpacity),
    },
  },
  // selectMenu: {
  //   color: (props) =>  props.bgcolor.main,
  // },
  icon: {
    fill: (props) =>  props.theme.palette.text.disabled,
  },
  select: {
    "&:focus": {
      background: 'none',
    },
  },
}

const _textual = {
  root: {
    color: (props) => props.textcolor || props.theme.palette.text.secondary,
    '&:hover': {
      color: (props) => props.bgcolor.main,
      backgroundColor: (props) => fade(props.bgcolor.main, props.hoveropacity || props.theme.palette.action.hoverOpacity),
      transition: (props) => props.theme.transitions.buttonHover,
    },
  },
  icon: {
    fill: (props) =>  props.theme.palette.text.disabled,
  },
  select: {
    "&:focus": {
      background: 'none',
    },
  },
}

const StyledSelectSolid = withStyles(
  deepMerge(
    _stylesCfgBase,
    _solid,
  )
)(Select)
// Alternative using withStyles only
// const StyledSelectBase = withStyles(_stylesCfgBase)(Select)
// const StyledSelectSolid = withStyles(_solid)(StyledSelectBase)

const StyledSelectOutlined = withStyles(
  deepMerge(
    _stylesCfgBase,
    _outlined,
  )
)(Select)

const StyledSelectOutlined2 = withStyles(
  deepMerge(
    _stylesCfgBase,
    _outlined2,
  )
)(Select)

const StyledSelectTextual = withStyles(
  deepMerge(
    _stylesCfgBase,
    _textual,
  )
)(Select)

const StyledMenuItem = styled(MenuItem)({
  fontSize: (props) => props.fontSize,
  fontStyle: (props) => props.fontStyle,
  color: (props) => props.color,
})

const variants = {
  solid: StyledSelectSolid,
  outlined: StyledSelectOutlined,
  textual: StyledSelectTextual,
  outlined2: StyledSelectOutlined2,
}

// TODO fully normalize function call with MenuButton (currentTarget vs target?)
const SelectorButton = React.memo((props) => {
  const {
    methodKey,
    methodOpts,
    methods,
    id,
    state,
    // unused but might be present
    defaultVal,
    hasChanged,
    _validateAllowNotChanged,
    _other,
    _validator,
    _notChangedValidationHint,
    //
    ...nonModelProps
  } = {
    ...props.state,
    ...props,
  }

  const {
    val,
    items,
    isValid,
    ...nonStateProps
  } = nonModelProps

  const {
    textcolor,
    fontSize,
    color,
    errColor = 'error',
    variant = 'solid',
    disabled,
    IconComponent,
    ...nonStyleProps
  } = nonStateProps

  const theme = useTheme()
  const SelectComp = variants[variant]

  const handleClickItem = (event) => {
    methods[methodKey](event.target.value, methodOpts, id)
  }

  const selectedValIsInvalid = items[val].isInvalid
  
  return (
    <Flex {...nonStyleProps}>
      <SelectComp
        theme={theme}
        disabled={disabled}
        value={val}
        onChange={handleClickItem}
        bgcolor={isValid ? theme.palette[color] : theme.palette[errColor]}
        fontSize={fontSize}
        fontStyle={selectedValIsInvalid ? 'italic' : 'normal'}
        textcolor={isValid ? textcolor : theme.palette[errColor].main}
        bradius={theme.shape.borderRadius}
        disableUnderline={true}
        style={{ width: nonStyleProps.width }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          PaperProps: {
            elevation: 6,
          }
        }}
        IconComponent={IconComponent}
      >
        {
          Object.keys(items).map((key) => {
            const item = items[key]
            return (
              <StyledMenuItem
                disabled={item.isInvalid}
                fontStyle={(item.isInvalid || item.isSpecial) ? 'italic' : 'normal'}
                fontSize={fontSize}
                color={item.isSpecial ? theme.palette.primary.main : theme.palette.text.primary}
                key={key}
                value={key}
              >
                {item.label}
              </StyledMenuItem>
            )
          })
        }
      </SelectComp>
    </Flex>
  )
})

export default SelectorButton
