import FileDownload from 'js-file-download'
import Papa from 'papaparse'

const mimeTypeToExt = {
  'text/csv': '.csv',
  'application/json': '.json',
  'image/png': '.png',
  'image/jpeg': '.jpg',
}

const checkFileName = ({ fileName, mimeType, ext }) => {
  if (!ext && mimeType) {
    ext = mimeTypeToExt[mimeType]
    if (fileName.endsWith(ext)) ext = null
  } 
  return (ext && fileName + ext) || fileName
}
 
export const exportCanvas = (canvas, { fileName, mimeType, ext, qualityArgument, preProcessOpts={} }) => {
  fileName = checkFileName({ fileName, mimeType, ext })

  let { bgColor, padding } = preProcessOpts
  let toSave = canvas
  let modify = Boolean(bgColor || padding)

  if (modify) {
    let px, py = 0
    let { width, height } = canvas

    if (typeof padding === 'number') {
      px = padding
      py = padding
    } else if (padding) {
      px = padding.x
      py = padding.y
    }

    if (px > 0 && px <= 1) px = Math.round(width * px)
    if (py > 0 && py <= 1) py = Math.round(height * py)

    let px_half = px / 2
    let py_half = py / 2

    const _canvas = document.createElement('canvas')
    _canvas.width = width + px
    _canvas.height = height + py
    const modCtx = _canvas.getContext('2d');

    // Before redraw
    if (bgColor) {
      modCtx.fillStyle = bgColor
      modCtx.fillRect(0, 0, _canvas.width, _canvas.height)
    }

    // Redraw
    modCtx.drawImage(canvas, px_half, py_half)

    // After redraw
    //

    toSave = _canvas
  }
  
  toSave.toBlob((blob) => FileDownload(blob, fileName), mimeType, qualityArgument)
}

export const exportData = (data, {fileName, mimeType, ext, preProcessOpts={}}) => {
  fileName = checkFileName({ fileName, mimeType, ext })
  let toExport = null

  switch (mimeType) {
    case 'text/csv':
      let { papaCfg } = preProcessOpts
      toExport = Papa.unparse(data, papaCfg)
      break

    case 'application/json':
      toExport = data
      break

    case null:
    case undefined:
    case 'application/octet-stream':
      toExport = data
      break

    default:
      console.warn(`Data Export type not implemented: ${mimeType}`)
      return
  }

  FileDownload(toExport, fileName, mimeType)
}

export const doExport = ({data, canvas, ...other}) => {
  if (canvas) {
    exportCanvas(canvas, other)
  }
  if (data) {
    exportData(data, other)
  }
}