import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  // Tabs,
  // Tab
} from '@material-ui/core';
// import { styled } from '@material-ui/core/styles'
import {
  // Flex,
  Box
} from '../../../components/Base'

import EditForm from './EditForm';
// import EditWebhooks from './EditWebhooks';

import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const StyledTab = styled(Tab)({
//   fontSize: (props) => props.fontSize,
//   textTransform: 'none',
//   borderBottom: '1px solid transparent',
//   minWidth: "128px",
//   '&:hover': {
//     color: '#007BA7',
//   }
// })

const EditDialog = ({open, onClose, app}) => {

  const title = `${app.id ? 'Edit' : 'New'} App`;

  // const [tabIdx, setTabIdx] = React.useState(0)
  // const handleChange = (e, idx) => {
  //   setTabIdx(idx)
  // }

  return(
    <Dialog open={open} onClose={() => onClose(null)} TransitionComponent={Transition} fullWidth={true} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      {/* <Flex
        m='auto'
        borderBottom='1px solid #DBDDE0'
      >
        <Tabs
          value={tabIdx}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <StyledTab fontSize='0.875rem' label='Settings' />
          <StyledTab fontSize='0.875rem' label='Webhooks'/>
        </Tabs>
      </Flex> */}
      <DialogContent>
        <Box>
          <EditForm app={app} onSubmit={(data) => onClose(data)} onCancel={() => onClose(null)} />
          {/* {tabIdx === 0 ? <EditForm app={app} onSubmit={(data) => onClose(data)} onCancel={() => onClose(null)} /> : <EditWebhooks app={app} onCancel={() => onClose(null)}/>} */}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EditDialog;
