// import React from 'react'

// import { setControlVal } from '../../../lib/state'
// import useApi from '../../../lib/useApi'

const initEntityModel = (entityCfg) => {
  return {
    dbEntityType: entityCfg.dbEntityType,
    entityMethodsInit(state) { return {} },
    getControlsCfg(state, isNew, isClone, isSaveAs) { return {}},
    isPartialEntity(data) { return !data.data},
    handleSetData(state, data, hasControls) { },
    hookOverrides: {
    },
  }
}

export default initEntityModel