import React from "react";
import {useSelector} from 'react-redux';

import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";

import { useAppPlugin } from '../lib/hooks'

const MenuList = ({currentUrl, menuConfig}) => {
  const user = useSelector(store => store.auth.user);
  const app = useSelector(store => store.auth.app);
  const appPlugin = useAppPlugin()
  
  const checkRole = (child) => {
    if ( ! child.roles ) return true; // if no roles array, then all can see
    if ( child.roles.findIndex(r => r === user.role) !== -1 ) return true;  // user's role is in the list!
    return false; // user cannot see
  }

  const meetsRequirements = (child) => {
    // Some sidebar entries require that an app has been selected
    if ( child.requiresApp && !(app && app.id) ) return false;

    if (child.pluginName && (child.pluginName !== appPlugin.name)) return false
    
    return true;
  }

  return menuConfig.aside.items.map((child, index) => {
    return (
      <React.Fragment key={`menuList${index}`}>
        {child.section && checkRole(child) && <MenuSection item={child} />}
        {child.separator && checkRole(child) && <MenuItemSeparator item={child} />}
        {child.title && checkRole(child) && meetsRequirements(child) && !child.hidden && (
           <MenuItem
               item={child}
               currentUrl={currentUrl}
           />
         )}
      </React.Fragment>
    );
  });
}

export default MenuList;
