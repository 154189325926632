import React from 'react'

import { Box, Grid, Text, Flex } from '../../Base'
import TextField from '../../ControlComps/TextField'

import useStyles from '../../../lib/useStyles'

import { workloadFieldsCfg, riskCfg } from '../../../plugins/safety/util'

const RiskThresholdSettingItem = ({ label, dataField, state, methods }) => {
  return (
    <Grid
      gridGap='8px'
      gridAutoFlow='column'
      gridAutoColumns='max-content'
      alignItems='center'
    >
      <Box>
        <Text
          variant='small1'
          color='text.primary'
        >
          {label}
        </Text>
        <Text
          variant='small3'
          color='text.secondary'
        >
          {'(minutes per day)'}
        </Text>
      </Box>
      <Box>
        <TextField
          state={state.controls[`${dataField}.1`]}
          methods={methods}
          variant='outlined'
        />
      </Box>
      <Box>
        <TextField
          state={state.controls[`${dataField}.2`]}
          methods={methods}
          variant='outlined'
        />
      </Box>
    </Grid>
  )
}

const SectionHeader = ({ label }) => {
  return (
    <Grid
      gridGap='8px'
      gridAutoFlow='column'
      gridTemplateColumns='100px 1fr 1fr'
      alignItems='baseline'
    >
      <Box>
        <Text variant='h6'>{label}</Text>
      </Box>
      <Box>
        <Text
          variant='small3'
          color={riskCfg[1].color}
        >
          {riskCfg[1].label}
        </Text>
      </Box>
      <Box>
        <Text
          variant='small3'
          color={riskCfg[0].color}
        >
          {riskCfg[0].label}
        </Text>
      </Box>
    </Grid>
  )
}

const EditorContents = React.memo(({ state, methods }) => {
  const classes = useStyles()

  return (
    <Grid
      gridGap='24px'
      gridTemplateColumns='max-content'
      gridAutoFlow='row'
      pb='24px'
      // pr='8px'
    >
      <Flex className={classes.toastWarn} width='max-content' alignItems='center'>
        <Flex color='warning.main' pr='8px'>
          <i
            className='fas fa-exclamation-circle'
            style={{fontSize:'0.875rem', color:'inherit'}}
          />
        </Flex>
        <Text variant='small3'>
          <span>Default values determined using biomechanical data & research.</span>
        </Text>
      </Flex>
      <SectionHeader label='Motion'/>
      {
        workloadFieldsCfg.slice(0, 3).map((item, i) => (
          <RiskThresholdSettingItem
            key={i}
            label={item.label}
            dataField={item.dataField}
            state={state}
            methods={methods}
          />
        ))
      }
      <Box height='1px' bgcolor='border.card' ml='-24px'/>
      <SectionHeader label='Posture'/>
      {
        workloadFieldsCfg.slice(3, 7).map((item, i) => (
          <RiskThresholdSettingItem
            key={i}
            label={item.label}
            dataField={item.dataField}
            state={state}
            methods={methods}
          />
        ))
      }
    </Grid>
  )
})

export default {
  EditorContents,
  ManagerContents: null,
  DeleterContents: null,
  Profile: null,
}