import React from 'react'

import { get } from 'lodash'

import { Box, Card } from '../../components/Base'

import RawDataExporter from './RawDataExporter'
import MaterialTableWrapper from '../../components/Tables/MaterialTableWrapper'
import TabbedRoutes from '../../components/TabbedRoutes'

import * as dbEntityCfgs from '../../components/DbEntities/allDbEntities'

const urlBase = '/tools/activitydata'

const routesCfg = Object.entries(dbEntityCfgs).map(([key, val]) => {
  const dbEntityType = val.dbEntityType

  if (val.relations.hasMany.activities) {
    return {
      label: dbEntityCfgs[dbEntityType].labelPlural,
      page: `${urlBase}/${dbEntityType}`,
      component: () => <DbEntitiesActivitiesExporter dbEntityType={dbEntityType}/>,
    }
  }

  return null
}).filter((item) => Boolean(item))

const ActivitiesExporters = () => {
  return(
    <TabbedRoutes
      items={routesCfg}
      redirectFrom={urlBase}
    />
  )
}

export default ActivitiesExporters

const DbEntitiesActivitiesExporter = React.memo(({ dbEntityType }) => {
  const dbEntityCfg = dbEntityCfgs[dbEntityType]

  const tableModelOpts = React.useMemo(() => ({
    variant: 'entitesThatHaveEvents',
    variantOpts: {
      size: 'small',
      primaryEntityType: dbEntityType,
      connectedEntityType: dbEntityCfg.explore.root.lastEventsTable.connectedEntityType,
      includeAppName: false,
      // general
      showSearch: true,
      showQueryBuilder: true,
      showDateRangePicker: true,
      showCount: false,
      titlePrefix: 'Segmented',
      onlyActivities: true,
    },
  }), [ dbEntityCfg, dbEntityType ])

  const [ tableReqData, setTableReqData ] = React.useState({})
  const [ tableHasData, setTableHasData ] = React.useState(false)

  const [ start, end ] = get(tableReqData || {}, "where.['attributes.lastActivityOn'].between", [])

  return (
    <>
      <Box mb='24px'>
        <MaterialTableWrapper
          onReqDataChanged={setTableReqData}
          onHasDataChanged={setTableHasData}
          tableModelOpts={tableModelOpts}
        />
      </Box>
      <Card>
        <RawDataExporter
          dbEntityType={dbEntityType}
          titleSuffix={'for Segmented ' + dbEntityCfg.labelPlural}
          where={tableReqData && tableReqData.where}
          start={start}
          end={end}
          disabled={!tableHasData}
        />
      </Card>
    </>
  )
})
