import { EventNames } from '../../lib/utils'
import { selectedValAndAllowedItemsFromCfg } from '../../lib/state'
import deepMerge from '../../lib/deepMerge'

// TODO dbEntityCfgs

//
// util
//

const _get_queryCfg_builder = ({ title, fieldsTarget }) => {
  return {
    type: 'Builder',
    title,
    state: {
      builderOpts: {
        variant: 'cohortQuery',
        variantOpts: {
          target: fieldsTarget,
        }
      },
    }
  }
}

const _emptyQuerySelector = (placeholderLabel) => {
  return {
    type: 'Selector',
    state: selectedValAndAllowedItemsFromCfg({
      itemsCfg: null,
      placeholderLabel,
    })
  }
}

//
// Mixins
//

const queryDropdown_dbEntityFieldValues = ({ queryOptsKey, fieldsTargetStr, defaultVal, placeholderLabel='query', firstIsDefault=true }) => {
  return {
    queryOpts: {
      [queryOptsKey]: _emptyQuerySelector(placeholderLabel),
    },
    _hooks: {
      useDbEntityFieldValues: {
        getHookArgs: (state) => {
          return {
            target: fieldsTargetStr,
          }
        },
        callback: ({ res, meta }, methods) => {
          const dropdownState = selectedValAndAllowedItemsFromCfg({
            itemsCfg: res.map(({ label, val }) => {
              return {
                label,
                query: {
                  field: meta.relativePath,
                  op: '==',
                  val: val.toString(),
                },
              }
            }),
            getKey: (item) => item.label,
            placeholderLabel,
            firstIsDefault,
            defaultVal,
          })
  
          methods.setQueryDropdownState(dropdownState, null, queryOptsKey)
        }
      }
    }
  }
}

const queryDropdown_pluginFields = () => {
  const queryOptsKey = 'AppPlugin'
  const pluginFieldPlaceholderLabel = 'Select activity'

  return {
    queryOpts: {
      [queryOptsKey]: _emptyQuerySelector(pluginFieldPlaceholderLabel),
    },
    _hooks: {
      useCurrentPluginFields: {
        getHookArgs: (state) => {
          return {
            appId: state.supplementalData.appId,
          }
        },
        callback: (res, methods) => {
          res = Object.values(res).filter(({ field, label, unit, fcn }) => unit !== 'none')
          
          const dropdownState = selectedValAndAllowedItemsFromCfg({
            itemsCfg: res.map(({ field, label, unit, fcn }) => {
              return {
                label,
                unit,
                query: {
                  field: 'field',
                  op: '==',
                  val: field,
                },
              }
            }),
            getKey: (item) => item.label,
            placeholderLabel: pluginFieldPlaceholderLabel,
          })
  
          methods.setQueryDropdownState(dropdownState, null, queryOptsKey)
        },
      },
    },
  }
}

const shoesGetReqData = {
  include: {
    relation: 'apps',
    scope: {
      fields: [ 'id' ]
    },
  }
}

function byEntityId({ id, dbEntityType }) {
  let queryKey = null

  // TODO cfg 'User', normalize or cfg 'AppUser'
  let reqData
  if (dbEntityType === 'appusers') {
    queryKey = 'AppUser'
  }
  else if (dbEntityType === 'shoes') {
    queryKey = 'Shoe'
    reqData = shoesGetReqData
  }

  return {
    query: {
      [queryKey]: {
        field: 'id',
        op: '=',
        val: id
      }
    },
    _hooks: {
      useDbEntityById: {
        getHookArgs: (state) => {
          return { dbEntityType, id, reqData }
        },
        callback: (res, methods) => {
          if (res.attributes.tz) {
            methods.setDisplayTimezone(res.attributes.tz)
          }
          
          const entityName = res.code || res.userId
          if (entityName) {
            methods.setTitle(`${entityName}`)
          }
          
          methods.setSupplementalData(res.appId || res.apps?.[0].id, { key: 'appId'})
        }
      },
    },
  }
}

//
// Variant Util TODO replace with mixins
//

const getVariant_EventData = ({ title, eventName }) => {
  return {
    title,
    chartApiType: 'eventStats',
    controlsOpts: {},
    queryOpts: {
      AppUser: _get_queryCfg_builder({
        title: 'Users',
        fieldsTarget: 'appusers'
      }),
    },
    query: {
      Event: {
        field: 'name',
        op: '=',
        val: eventName
      }
    }
  }
}

const getVariant_ActiveUsers = ({ title, aggInterval }) => {
  return {
    title,
    chartApiType: 'eventStats',
    controlsOpts: { aggInterval: null, },
    aggInterval,
    queryOpts: {
      AppUser: _get_queryCfg_builder({
        title: 'Users',
        fieldsTarget: 'appusers'
      }),
    },
    query: {
      Event: {
        field: 'name',
        op: '=',
        val: undefined
      }
    }
  }
}

//
// Variants
//

const stateVariants = {
  explore() {
    return {
      title: 'Explore',
      chartApiType: 'eventStats',
      controlsOpts: {},
      queryOpts: {
        AppUser: _get_queryCfg_builder({
          title: 'Users Query',
          fieldsTarget: 'appusers'
        }),
        Shoe: _get_queryCfg_builder({
          title: 'Shoes Query',
          fieldsTarget: 'shoes',
        }),
        Event: _get_queryCfg_builder({
          title: 'Events Query',
          fieldsTarget: 'events'
        }),
      },
    }
  },
  //
  // Active users (no Event query)
  //
  activeUsers_daily() {
    return getVariant_ActiveUsers({
      title: 'Daily Active Users',
      aggInterval: 'day',
    })
  },
  activeUsers_weekly() {
    return {
      ...getVariant_ActiveUsers({
        title: 'Weekly Active Users',
        aggInterval: 'week',
      }),
      datePickerOpts: {
        defaultPresetIdx: 0,
        presets_startDaysAgo: [ 12, 26, 52 ].map((weeks) => weeks * 7)
      }
    }
  },
  activeUsers_monthly() {
    return {
      ...getVariant_ActiveUsers({
        title: 'Monthly Active Users',
        aggInterval: 'month',
      }),
      datePickerOpts: {
        presets_startDaysAgo: [ 3, 6, 12, 24 ].map((months) => months * 30)
      }
    }
  },
  //
  // Event Dropdown
  //
  events() {
    return deepMerge(
      queryDropdown_dbEntityFieldValues({
        queryOptsKey: 'Event',
        fieldsTargetStr: 'eventsNames',
        defaultVal: EventNames.ACTIVATED,
      }),
      {
        title: 'Event',
        chartApiType: 'eventStats',
        controlsOpts: {},
        queryOpts: {
          AppUser: _get_queryCfg_builder({
            title: 'Users',
            fieldsTarget: 'appusers'
          }),
        },
      },
    )
  },
  //
  // Static Event
  //
  userActivated() {
    return getVariant_EventData({
      title: 'User Activated',
      eventName: EventNames.INSTALLED
    })
  },
  activation() {
    return getVariant_EventData({
      title: 'Device Paired',
      eventName: EventNames.ACTIVATED,
    })
  },
  activity() {
    return getVariant_EventData({
      title: 'Activity Started',
      eventName: EventNames.START_ACTIVITY
    })
  },
  //
  // Retention API
  //
  retention() {
    return deepMerge(
      queryDropdown_dbEntityFieldValues({
        queryOptsKey: 'Event',
        fieldsTargetStr: 'eventsNames',
        defaultVal: EventNames.ACTIVATED,
      }),
      {
        title: 'Retention',
        chartApiType: 'retention',
        chartLibOpts: {
          merge: { //TODO_MAYBE remove merge usage
            libCfg: {
              type: 'line',
            }
          }
        },
        controlsOpts: {},
        queryOpts: {
          AppUser: _get_queryCfg_builder({
            title: 'Users',
            fieldsTarget: 'appusers'
          }),
        },
      },
    )
  },
  //
  // Funnel API
  //
  funnel() {
    return {
      title: 'Funnel',
      chartApiType: 'funnel',
      chartLibOpts: {
        merge: {
          libCfg: {
            type: 'line',
          }
        }
      },
      controlsOpts: {
        aggInterval: null,
      },
      queryOpts: {
        AppUser: _get_queryCfg_builder({
          title: 'Users',
          fieldsTarget: 'appusers'
        }),
      },
    }
  },
  //
  // Activities
  //
  entityActivities({ dbEntityType, id }) {
    let titlePrefix = null

    if (dbEntityType === 'appusers') {
      titlePrefix = 'User'
    }
    else if (dbEntityType === 'shoes') {
      titlePrefix = 'Shoe'
    }

    return deepMerge(
      byEntityId({
        id,
        dbEntityType
      }),
      queryDropdown_pluginFields(),
      {
        title: `${titlePrefix} Analysis`,
        chartApiType: 'activityAnalysis',
        controlsOpts: {},
        chartLibOpts: {
          variant: 'entityActivities',
        },
      }
    )
  },
  cohortActivity({ dbEntityType, cohort, genericUsersName = 'Users', activityLabel, activityName, appId, datePickerOpts, chartLibOpts, entity, entityName }) {
    let title = undefined
    let AppUser = undefined

    if (entity) {
      title = `${activityLabel} - ${entityName}`
      AppUser = {
        field: 'id',
        op: '==',
        val: entity.id,
      }
    }
    else {
      let cohortName = null

      if (cohort?.name) {
        cohortName = cohort.name
      }
      else {
        if (dbEntityType === 'appusers') {
          cohortName = 'All ' + genericUsersName
        }
        else if (dbEntityType === 'shoes') {
          cohortName = 'All Shoes'
        }
      }

      title = `${activityLabel} - ${cohortName}`
      AppUser = cohort?.query || undefined
    }

    return {
      title,
      chartApiType: 'activityAnalysis',
      chartLibOpts,
      controlsOpts: {},
      query: {
        // TODO_MAYBE support shoes
        AppUser,
        AppPlugin: {
          field: 'field',
          op: '==',
          val: activityName,
        },
      },
      supplementalData: {
        appId,
      },
      datePickerOpts,
    }
  },
}

const postProcessor = (o, variantOpts) => {
  if (!o.chartLibOpts) {
    o.chartLibOpts = {}
  }

  o.chartLibOpts.variant = variantOpts.chartLibVariant || o.chartLibOpts.variant || 'default'

  return o
}


export default {
  stateVariants,
  postProcessor,
}