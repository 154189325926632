import React from 'react'

// import { Box, Grid, Text } from '../../Base'
import ToggleButton from '../../ControlComps/ToggleButton'

const EditorContents = React.memo(({ state, methods }) => {
  if (!state.hasControls) return null

  return (
    <>
      <ToggleButton
        state={state.controls.status}
        methods={methods}
        color='primary'
        height='40px'
        // width='50%'
      />
    </>
  )
})

export default {
  EditorContents,
  ManagerContents: null,
  DeleterContents: null,
  Profile: null,
}