// import React from 'react'

import { useModel, objectVariantOrPassthrough, initControls, setControlVal, selectedValAndAllowedItemsFromCfg } from '../../../../../lib/state'
import stateVariants from './variants'

// {
//   name: 'Stop Activity',
//   agg: 'count',
//   op: '>=',
//   val: 5,
//   time: {
//     start: null,
//     end: null,
//     lastDays: 600,
//     offsetDays: 0,
//   },
// },

//
// Util
//

///////////
// MODEL //
///////////

const model = {
  ///////////
  // STATE //
  ///////////
  stateInit(args) {
    let ret = {
      ...objectVariantOrPassthrough(stateVariants, args),
      //
    }

    let defaultConditionVal = ret.val
    if (defaultConditionVal !== 0 && !defaultConditionVal) {
      defaultConditionVal = 1
    }

    ret.defaultFieldVal = ret.name || null

    ret.controls = {
      fieldVal: {
        ...selectedValAndAllowedItemsFromCfg({
          itemsCfg: {
            'Any Event': {
              label: 'Any Event',
              val: null,
            },
          },
          firstIsDefault: true,
          defaultVal: ret.name || null,
        }),
        methodKey: 'setFieldVal',
        _validateAllowNotChanged: true,
      },
      //
      conditionOp: {
        ...selectedValAndAllowedItemsFromCfg({
          itemsCfg: {
            '==': {
              label: '==',
            },
            '!=': {
              label: '!=',
            },
            '<': {
              label: '<',
            },
            '<=': {
              label: '<=',
            },
            '>': {
              label: '>',
            },
            '>=': {
              label: '>=',
            },
          },
          sortByKey: false,
          defaultVal: ret.op || '>=',
        }),
        methodKey: 'setConditionOp',
        _validateAllowNotChanged: true,
      },
      conditionVal: {
        type: 'number',
        defaultVal: defaultConditionVal,
        val: defaultConditionVal,
        _validator: (val) =>  (val != null && !isNaN(val.val || val)) || 'Invalid Number',
        _validateAllowNotChanged: true,
        methodKey: 'setConditionVal',
      },
      lastDays: {
        ...selectedValAndAllowedItemsFromCfg({
          itemsCfg: [7, 14, 30, 60, 90].map((daysAgo) => {
            return {
              label: `Last ${daysAgo.toString()} days`,
              val: daysAgo,
            }
          }),
          getKey: (item) => item.val,
          defaultVal: ret.time?.lastDays || 30,
        }),
        methodKey: 'setLastDays',
        _validateAllowNotChanged: true,
      },
      offsetDays: {
        ...selectedValAndAllowedItemsFromCfg({
          itemsCfg: [0, 7, 14, 30, 60, 90].map((daysAgo) => {
            return {
              label: `${daysAgo.toString()} days`,
              val: daysAgo,
            }
          }),
          getKey: (item) => item.val,
          defaultVal: ret.time?.offsetDays || 0,
        }),
        methodKey: 'setOffsetDays',
        _validateAllowNotChanged: true,
      },
    }

    // ret.defaultIsValid = true // what is this for?

    initControls(ret)
  
    return ret
  },
  /////////////
  // METHODS //
  /////////////
  methodsInit(state) {
    return {
      //
      // control val setters
      //
      setFieldVal(val, opts) {
        setControlVal(state, state.controls.fieldVal, val)
      },
      setConditionOp(val, opts) {
        setControlVal(state, state.controls.conditionOp, val)
      },
      setConditionVal(val, opts) {
        setControlVal(state, state.controls.conditionVal, val)
      },
      setLastDays(val, opts) {
        setControlVal(state, state.controls.lastDays, val)
      },
      setOffsetDays(val, opts) {
        setControlVal(state, state.controls.offsetDays, val)
      },
      //
      // other
      //
      setFieldValOptions({res, meta}) {
        const fieldsState = selectedValAndAllowedItemsFromCfg({
          itemsCfg: [
            {
              key: ' Any Event', // sort first
              label: 'Any Event',
              val: null,
            },
            ...res.map(({ label, val }) => {
              return {
                key: label,
                label,
                val,
              }
            })
          ],
          getKey: (item) => item.key,
          firstIsDefault: true,
          defaultVal: state.defaultFieldVal,
        })

        state.controls.fieldVal = {
          ...state.controls.fieldVal,
          ...fieldsState,
        }
      },
      _setShouldValidate(val, opts) {
        state.shouldValidate = val
        setControlVal(state)
      },
      _buildVal(val, opts) {
        const _val = {
          name: state.controls.fieldVal.items[state.controls.fieldVal.val].val,
          agg: 'count',
          op: state.controls.conditionOp.val,
          val: Number(state.controls.conditionVal.val),
          time: {
            start: null,
            end: null,
            lastDays: Number(state.controls.lastDays.val),
            offsetDays: Number(state.controls.offsetDays.val),
          },
        }

        state._builtVal = _val
      },
    }
  },
  ///////////
  // HOOKS //
  ///////////
  hooks: {
    useDbEntityFieldValues: {
      getHookArgs: (state) => {
        return {
          target: 'eventsNames',
        }
      },
      methodKey: 'setFieldValOptions',
    },
  },
}

// returns [ state, methods ]
export default (...args) => {
  return useModel(model, ...args)
}