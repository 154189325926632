import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import { FileCopyOutlined, ErrorOutline, DeleteOutline, SendOutlined } from '@material-ui/icons'
import { IconButton, Tooltip } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'

import { Box, Grid, Text, Flex, } from '../../Base'
import useStyles from '../../../lib/useStyles'

import TextField from '../../ControlComps/TextField'
import SelectorButton from '../../ControlComps/SelectorButton'
import ToggleButton from '../../ControlComps/ToggleButton'
import ControlButton from '../../ControlComps/ControlButton'

import TreeBuilder from '../../Query/Builder/TreeBuilder'

import moment from 'moment-timezone'

import { fill } from 'lodash'

const ErrorItem = React.memo(({ state, methods, disableControls }) => {
  const classes = useStyles()

  return (
    <Flex
      className={classes.listItem}
      maxWidth='927px'
      justifyContent='space-between'
      padding='4px 12px'
    >
      <Flex alignItems='center'>
        <Box mr='12px'>
          <ErrorOutline color='error' className={classes.actionButton} mb='2px'/>
        </Box>
        <Text
          variant='body2'
          color='text.secondary'
          whiteSpace='nowrap'
        >
          { moment(state.createdAt).tz(global.TZ).format('lll') }
        </Text>
        <Tooltip
          title={state.message}
          arrow
        >
          <Text
            variant='body2'
            color='text.secondary'
            ml='32px'
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
            maxWidth='620px'
          >
            { state.message }
          </Text>
        </Tooltip>
      </Flex>
      <Flex>
        <ControlButton
          variant='icon'
          color='default'
          methods={methods}
          methodKey={'errors_resendOne'}
          title='Resend'
          val={state}
          icon={SendOutlined}
          disabled={disableControls}
        />
        <ControlButton
          variant='icon'
          color='default'
          methods={methods}
          methodKey={'errors_deleteOne'}
          title='Delete'
          val={state}
          icon={DeleteOutline}
          disabled={disableControls}
        />
      </Flex>
      {/* <Checkbox color='default' size='small'/> */}
    </Flex>
  )
})

const ErrorsContents = React.memo(({ state, methods }) => {
  // if (!state.errors) return

  return (
    <Box width='100%' marginTop='-16px'>
      <Text variant='subtitle1' pb='8px'>
        {`Errors (Most Recent ${state.errorsLimit.toLocaleString()})`}
      </Text>
      <Flex alignItems='center' justifyContent='space-between' pb='8px'>
        <Flex alignItems='center'>
          <Box mr='8px'>
            <ControlButton
              variant='solidSmall'
              color='secondary'
              methods={methods}
              methodKey={'errors_pollStart'}
              text='Start Polling'
              disabled={state.errorPollingUntill != null}
            />
          </Box>
          <Box mr='8px'>
            <ControlButton
              variant='solidSmall'
              color='secondary'
              methods={methods}
              methodKey={'errors_pollStop'}
              text={
                state.errorPollingUntill != null
                  ? `Stop Polling (${Math.round(state.errorPollingUntill.diff(moment()) / 1000)}s)`
                  : 'Stop Polling'
              }
              // text='Stop Polling'
              disabled={state.errorPollingUntill == null}
            />
          </Box>
          {/* {
            state.errorPollingUntill != null &&
              <Text variant='small2' color='text.secondary' mr='16px'>
                {`${Math.round(state.errorPollingUntill.diff(moment()) / 1000)}s`}
              </Text>
          } */}
        </Flex>
        <Flex alignItems='center'>
          <Flex>
            <Box mr='8px'>
              <ControlButton
                variant='outlinedSmall'
                color='secondary'
                methods={methods}
                methodKey={'errors_resendAll'}
                text='Send All'
                disabled={state.errorPollingUntill != null || !(state.errors.length > 0)}
              />
            </Box>
            <ControlButton
              variant='outlinedSmall'
              color='danger'
              methods={methods}
              methodKey={'errors_deleteAll'}
              text='Delete All'
              disabled={state.errorPollingUntill != null || !(state.errors.length > 0)}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex maxHeight='160px' flexDirection='column' overflow='auto' borderLeft={`1px solid ${fade('#000000', 0.1)}`}>
        {
          state.errors.map((itemState) => <ErrorItem state={itemState} key={itemState.id} methods={methods} disableControls={state.errorPollingUntill != null}/>)
        }
      </Flex>
    </Box>
  )
})

// const TesterContents = React.memo(({ state, methods }) => {
//   const bodyRespRows = 12

//   return (
//     <>
//       <Box width='100%' height='1px' bgcolor='disabled.main' mb='24px'/>
//       <Flex>
//         <Box width='100%' pr='12px'>
//           <Text variant='small2' color='text.secondary' pb='8px'>
//             URL
//           </Text>
//           <TextField
//             variant='outlined'
//             placeholder="https://"
//             fullWidth
//             state={state.controls.testUrl}
//             methods={methods}
//           />
//         </Box>
//         <Box pr='24px'>
//           <Text variant='small2' color='text.secondary' pb='8px'>
//             Method
//           </Text>
//           <SelectorButton
//             variant='outlined2'
//             color='primary'
//             state={state.controls.testSelectedMethod}
//             methods={methods}
//             height='42px'
//           />
//         </Box>
//         <Box>
//           <Text variant='small2' color='text.secondary' pb='8px'>
//             Content Type
//           </Text>
//           <SelectorButton
//             variant='outlined2'
//             color='primary'
//             state={state.controls.testContentType}
//             methods={methods}
//             height='42px'
//           />
//         </Box>
//       </Flex>
//       <Flex>
//       <Box width='100%' pr='12px'>
//         <Text variant='small2' color='text.secondary' pb='8px'>
//           Body
//         </Text>
//         <TextField
//           variant='outlined'
//           placeholder="Body"
//           fullWidth
//           state={state.controls.testBody}
//           methods={methods}
//           multiline
//           rows={bodyRespRows}
//         />
//       <Flex justifyContent='end' pt='8px' pr='12px'>
//         <Button variant='outlined' color='primary' size='small'>Send</Button>
//       </Flex>
//       </Box>
//       <Box width='100%'>
//         <Text variant='small2' color='text.secondary' pb='8px'>
//           Response
//         </Text>
//         <TextField
//           variant='outlined'
//           placeholder="Response"
//           fullWidth
//           state={state.controls.url}
//           methods={methods}
//           multiline
//           rows={bodyRespRows}
//           disabled
//         />
//         <Text variant='small2' color='text.secondary' pt='8px' pr='12px' textAlign='right' >
//           HTTP 200
//         </Text>
//       </Box>
//       </Flex>
//     </>
//   )
// })

const ClipboardDisabledInput = React.memo(({ title, label, val, ...props }) => {
  const classes = useStyles()

  return (
    <Box {...props}>
      {
        title &&
          <Text variant='small2' color='text.secondary' pb='8px'>
            {title}
          </Text>
      }
      <Flex
        alignItems='center'
        border='1px solid #DBDDE0'
        p='0px 0px 0px 16px'
        justifyContent='space-between'
        borderRadius='8px'
        bgcolor='#F9F9FA'
      >
        <Text variant='body1' color='text.secondary' pr='16px'>
          {label || val}
        </Text>
        <CopyToClipboard text={val || label}>
          <IconButton> <FileCopyOutlined color='primary' className={classes.actionButton}/> </IconButton>
        </CopyToClipboard>
      </Flex>
    </Box>
  )
})

const EditorItem = React.memo(({ title, children, setActiveItem, activeItem, index, helperText = null }) => {
  const classes = useStyles()
  const expanded = activeItem === index
  return (
    <>
      <Box className={classes.webhookEditorItem}>
        <Flex
          className={classes.webhookEditorItemTitle}
          onClick={() => setActiveItem( expanded ? null : index )}
        >
          <Text
            color={'inherit'}
            mr='12px'
          >
            <i className={ expanded ? 'fa fa-caret-up' : 'fa fa-caret-down' } aria-hidden="true" />
          </Text>
          <Text
            variant='subtitle1'
            color={'inherit'}
            mr='12px'
          >
            {title}
          </Text>
        </Flex>
        <Flex className={ expanded ? classes.webhookEditorItemBodyActive : classes.webhookEditorItemBody }>
          <Box width='100%'>
            { helperText &&
              <Flex className={classes.toastWarn} mb='16px'>
                {helperText}
              </Flex>
            }
            {children}
          </Box>
        </Flex>
      </Box>
    </>
  )
})

// const ResponseItem = React.memo(({ state, key }) => {
//   const response = state.response || {}

//   return (
//     <Flex alignItems='center' p='3px'>
//       <Text variant='small2' color='text.primary' minWidth='72px'>
//         Users:
//       </Text>
//       <Text variant='small1' textAlign='center' minWidth='60px'>
//         {response.users || '-'}
//       </Text>
//     </Flex>
//   )
// })

const EditorContents = React.memo(({ state, methods }) => {
  const [activeItem, setActiveItem] = React.useState(null)
  return (
    <>
      <Flex justifyContent='space-between'>
        <Box>
          <Text variant='small1' pb='6px'>
            URL
          </Text>
          <Text variant='subtitle1' color='text.secondary'>
            {state.controls.url.val}
          </Text>
        </Box>
        <Box>
          <Text variant='small1' pb='6px'>
            Authentication Type
          </Text>
          <Text variant='subtitle1' color='text.secondary'>
            {state.controls.selectedAuth.val}
          </Text>
        </Box>
        <Box>
          <Text variant='small1' pb='6px'>
            Payload Compression
          </Text>
          <Text variant='subtitle1' color='text.secondary'>
            {state.controls.compress.val ? 'Enabled' : 'Disabled'}
          </Text>
        </Box>
        <Box>
          <Text variant='small1' pb='6px'>
            Status
          </Text>
          <ToggleButton
            state={state.controls.disabled}
            methods={methods}
          />
        </Box>
      </Flex>
      {
        state.controls.selectedAuth.val === 'basic' && 
          <Flex>
            <ClipboardDisabledInput
              title='Username'
              val={state.controls.auth_basicUsername.val}
              width='100%'
              pr='16px'
            />
            <ClipboardDisabledInput
              title='Password'
              label={fill(Array(state.controls.auth_basicPassword.val.length), '*').join('')}
              val={state.controls.auth_basicPassword.val}
              width='100%'
            />
          </Flex>
      }
      {
        state.controls.selectedAuth.val === 'signature' &&
          <Box>
            <Text variant='small2' color='text.secondary' pb='8px'>
              {'Public Key'}
            </Text>
            <Flex>
              {
                state.publicKey &&
                  <ClipboardDisabledInput
                    val={state.publicKey}
                    width='100%'
                    pr='16px'
                  />
              }
              {
                !state.publicKey &&
                  <ControlButton
                    color='secondary'
                    text='Generate Public Key'
                    methods={methods}
                    methodKey={'generatePublicKey'}
                    height='42px'
                  />
              }
            </Flex>
          </Box>
      }
      <Box>
        <EditorItem
          index={1}
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          title='Attributes'
          helperText={
            <Text variant='small3'>
              <span style={{fontWeight:'bold'}}> ignoreSegments: true </span> will prevent any segment data from being webhooked. The `Activity` event will not
              have a "segments" property. Use this option if you do not care about detailed segment data. This will
              save a lot of processing and network bandwidth. You will not get `Segment` or `Segments` events.
              <p/>
              <span style={{fontWeight:'bold'}}> sendSegmentsWithActivity: true </span> will cause all segments to be included in a
              property called "segments" in the `Activity` event. You will not get `Segment` or `Segments` events.
              <p/>
              <span style={{fontWeight:'bold'}}> sendSegmentsAsBatch: true </span> will send the segment array in the eventType `Segments`. The `Activity`
              event will not include segments will not have a "segments" property. You will not get `Segment` events.
              <p/>
              With no flags set (<span style={{fontWeight:'bold'}}>default</span>), you will get `Segment` events whenever a segment arrives at Zcloud, one
              `Segment` event per segment. The `Activity` event will not have a "segments" property.
            </Text>
          }
        >
          <Flex justifyContent='space-between' width='100%'>
            <TreeBuilder
              initialData={state.initialData.attributes}
              updateDataTrigger={state._triggerAttributesUpdateToken}
              builderOpts={React.useMemo(() => {
                return {
                  variant: 'object',
                }
              }, [])}
              onDataUpdate={methods.setAttributes}
              setHasChanged={methods.setAttributesBuilderHasChanged}
            />
            <ControlButton
              color='secondary'
              text={`Save Attributes`}
              width='min-content'
              height='min-content'
              methods={methods}
              methodKey={'saveAttributes'}
              disabled={!state.attributesBuilderHasChanged}
            />
          </Flex>
        </EditorItem>
        <EditorItem
          index={2}
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          title='Send Historical'
          helperText={
            <Text variant='small3'>
              You may send previously made activity data from Zcloud to your webhook endpoint.
              Use this feature if you are missing some historical data from Zcloud.
              Choose a `start date` and an `end date` for the activities you want to send.
              <span style={{fontWeight:'bold'}}> This can be a very expensive operation </span>
              depending on how many activities fall within the specified window. Use `dry run`
              to see how much data will be sent without actually sending it. Use the results to adjust your
              start and end. You may want to issue multiple sends with sequential start/end ranges to get all of your missing data.
            </Text>
          }
        >
          <Flex width='100%'>
            <Box>
              <Flex mb='16px'>
                <Box>
                  <Text variant='small1' pb='6px'>
                    Start Date
                  </Text>
                  <TextField
                    state={state.controls.startDate}
                    methods={methods}                
                    variant='outlined'
                    inputType='datetime-local'
                    placeholder="yyyy-mm-dd"
                    fullWidth
                  />
                </Box>
                <Box ml='8px'>
                  <Text variant='small1' pb='6px'>
                    End Date
                  </Text>
                  <TextField
                    state={state.controls.endDate}
                    methods={methods}                
                    variant='outlined'
                    inputType='datetime-local'
                    placeholder="yyyy-mm-dd"
                    fullWidth
                  />
                </Box>
              </Flex>
              <Box mb='16px'>
                  <Text variant='small1' pb='6px'>
                    Dry Run
                  </Text>
                  <ToggleButton
                    state={state.controls.dryRun}
                    methods={methods}
                    color='primary'
                    width='min-content'
                  />
                </Box>
              <ControlButton
                color='secondary'
                text={`Send Historical Data`}
                width='min-content'
                height='min-content'
                methods={methods}
                methodKey={'sendHistoricalData'}
              />
            </Box>
            <Box
              width='1px'
              height='100%'
              bgcolor='disabled.main'
              mx='24px'
            />
            <Box ml='8px'>
              <Text variant='small1' pb='6px'>
                Response
              </Text>
              <Flex
                border='1px solid #e8e8e8'
                borderRadius='8px'
                p='4px 8px'
                flexDirection='column'
              >
                <Flex alignItems='center' p='3px'>
                  <Text variant='small2' color='text.primary' minWidth='72px'>
                    Users:
                  </Text>
                  <Text variant='small1' textAlign='center' minWidth='60px'>
                    {state.response.users || '-'}
                  </Text>
                </Flex>
                <Flex alignItems='center' p='3px'>
                  <Text variant='small2' color='text.primary' minWidth='72px'>
                    Shoes:
                  </Text>
                  <Text variant='small1' textAlign='center' minWidth='60px'>
                    {state.response.shoes || '-'}
                  </Text>
                </Flex>
                <Flex alignItems='center' p='3px'>
                  <Text variant='small2' color='text.primary' minWidth='72px'>
                    Activities:
                  </Text>
                  <Text variant='small1' textAlign='center' minWidth='60px'>
                    {state.response.activities || '-'}
                  </Text>
                </Flex>
                <Flex alignItems='center' p='3px'>
                  <Text variant='small2' color='text.primary' minWidth='72px'>
                    Segments:
                  </Text>
                  <Text variant='small1' textAlign='center' minWidth='60px'>
                    {state.response.segments || '-'}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </EditorItem>
        <EditorItem
          index={3}
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          title='Test'
        >
          <Flex width='100%'>
            <TextField
              variant='outlined'
              placeholder="JSON Body (Optional)"
              fullWidth
              state={state.controls.testBody}
              methods={methods}
              multiline
              rows={6}
            />
            <Box ml='8px'>
              <ControlButton
                color='secondary'
                text={`Send 'Test' Event`}
                width='min-content'
                height='min-content'
                methods={methods}
                methodKey={'sendTestEvent'}
              />
              {
                state.controls.testBody.validationhint &&
                  <Text
                    mt='8px'
                    variant='small2'
                    color={'error.main'}
                  >
                    {state.controls.testBody.validationhint}
                  </Text>
              }
            </Box>
          </Flex>
        </EditorItem>
      </Box>
      <ErrorsContents state={state} methods={methods}/>
      {/* {
        (!state.controls.testMode.val) && <ErrorsContents state={state} methods={methods}/>
      }
      {
        state.controls.testMode.val && <TesterContents state={state} methods={methods}/>
      } */}
    </>
  )
})

const CreatorContents = React.memo(({ state, methods }) => {
  return (
    <>
      <Grid
        gridGap='24px'
        gridTemplateRows='max-content'
        mr='16px'
      >
        {
          state.validationhint &&
            <Text
              variant='small2'
              color={'error.main'}
            >
              {state.validationhint}
            </Text>
        }
        <Box>
          <Text variant='small1' pb='6px'>
            URL
          </Text>
          <TextField
            variant='outlined'
            placeholder="https://"
            fullWidth
            state={state.controls.url}
            methods={methods}
          />
        </Box>
        <Box>
          <Flex pb='6px' alignItems='baseline'>
            <Text variant='small1'>
              Payload Compression 
            </Text>
            <Text variant='small3' color='text.disabled' fontStyle='italic' pl='8px'>(Gzip)</Text>
          </Flex>
          <Flex>
            <ToggleButton
              color='primary'
              state={state.controls.compress}
              methods={methods}
            />
          </Flex>
        </Box>
        <Box>
          <Flex pb='6px' alignItems='baseline'>
            <Text variant='small1'>
              Authentication Type
            </Text>
            { state.controls.selectedAuth.val === 'signature' && 
              <Text variant='small3' color='text.disabled' fontStyle='italic' pl='8px'>
                Public key generated after save
              </Text>
            }
          </Flex>
          <Flex alignItems='center'>
            <SelectorButton
              variant='outlined2'
              color='primary'
              state={state.controls.selectedAuth}
              methods={methods}
              height='40px'
            />
          </Flex>
        </Box>
        { state.controls.selectedAuth.val === 'basic' && 
          <Flex>
            <Box pr='16px'>
              <Text variant='small1' pb='6px'>
                Username
              </Text>
              <TextField
                variant='outlined'
                placeholder="Username"
                state={state.controls.auth_basicUsername}
                methods={methods}
              />
            </Box>
            <Box>
              <Text variant='small1' pb='6px'>
                Password
              </Text>
              <TextField
                variant='outlined'
                placeholder="Password"
                state={state.controls.auth_basicPassword}
                methods={methods}
              />
            </Box>
          </Flex>
        }
      </Grid>
    </>
  )
})

export default {
  CreatorContents,
  EditorContents,
  ManagerContents: null,
  DeleterContents: null,
  Profile: null,
}