import React from 'react'

import useModel from './model'

import { Box, Grid, Text } from '../../../../Base'
import TextField from '../../../../ControlComps/TextField'
import SelectorButton from '../../../../ControlComps/SelectorButton'

const Comp = React.memo(({ stateCfg, shouldValidate, onBuiltVal, ...props }) => {
  const [ state, methods ] = useModel(stateCfg, shouldValidate, onBuiltVal)

  return (
    <Box
      // alignItems='center'
    >
      <Grid
        gridAutoFlow='column'
        gridTemplateColumns='max-content max-content min-content min-content'
        justifyContent='left'
        alignItems='center'
        borderRadius='4px'
        gridGap='8px'
      >
        <Text
          variant='small1'
          width='116px'
          textAlign='right'
        >
          {'...had property'}
        </Text>
        <SelectorButton
          state={state.controls.field}
          methods={methods}
          variant='outlined2'
          color='primary'
          height='100%'
          fontSize='0.875rem'
        />
        <SelectorButton
          state={state.controls.conditionOp}
          methods={methods}
          variant='outlined2'
          color='primary'
          height='100%'
          fontSize='0.875rem'
        />
        <TextField
          state={state.controls.conditionVal} 
          methods={methods}
          // variant='outlined'
        />
      </Grid>
    </Box>
  )
})

export default Comp