import { TextHandler } from '../Base'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { getZeroCompensation } from './chartJsUtil'
import Chart from 'chart.js'
import { min } from 'lodash'
import * as ChartAnnotation from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const _tooltipBase = ({ theme }) => {
  return {
    intersect: false,
    mode: "nearest",
    axis: 'x',
    xPadding: 16,
    yPadding: 12,
    caretPadding: 8,
    cornerRadius: 8,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderColor: theme.palette.border.inactive,
    displayColors: false,
    titleAlign: 'center',
    titleFontStyle: 'normal',
    titleFontColor: theme.palette.text.disabled,
    bodyAlign: 'center',
    bodyFontStyle: 'bold',
    bodyFontSize: 16,
    bodyFontColor: theme.palette.text.secondary,
    callbacks: {
      title(tooltipItem, data) {
        var label = data.labels[tooltipItem[0].index];
        if (label._isAMomentObject) {
          label = label.format('MMM D, YYYY')
        }
        return label
      },
    },
  }
}

const humanizedHours = (val) => {
  const hours = Math.floor(val)
  const mins = Math.round((val - hours) * 60)
  return `${hours}h ${mins}m`
}

const safetyTooltipCallbacks = ({ decimals = 0, decimalPrecision = 2, humanizeHours = false, showDatasetLabel = true } = {}) => {
  return {
    labelTextColor: (tooltipItem, chart) => chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor,
    title(tooltipItem, data) {
      var label = data.labels[tooltipItem[0].index];
      if (label._isAMomentObject) {
        label = label.format('MMM D, YYYY')
      }
      return label
    },
    label: function(tooltipItem, data) {
      var label = (showDatasetLabel && data.datasets[tooltipItem.datasetIndex].label) || ''
  
      if (label) {
          label += ': '
      }

      const val = tooltipItem.yLabel
      if (humanizeHours) {
        label += humanizedHours(val)
      }
      else {
        if (val < 1) label += val.toPrecision(decimalPrecision)
        else label += val.toFixed(decimals)
      }

      const metadata = data.datasets[tooltipItem.datasetIndex].metadata?.[tooltipItem.index]
      if (metadata) {
        return [label, ...metadata.map((md) => md.label)]
      }

      return label
    }
  }
}

const _defaultAllZeroDataPointsOpts = ({ theme }) => {
  return {
    libCfg: {
      plugins: [
        getZeroCompensation({
          strokeStyle: theme.palette.primary.main,
        }),
      ],
    },
  }
}

const _fullBase = ({ theme }) => {
  const mainColor = theme.palette.primary.main
  return {
    noDataPointsOpts: {
      contentsRenderCfg: {
        Comp: TextHandler,
        props: {
          label: 'No data found',
          variant: 'h6',
          color: theme.palette.text.disabled,
          textAlign: 'center',
        },
      },
      containerProps: {
        alignItems: 'center',
        justifyContent: 'center',
      }
    },
    allZeroDataPointsOpts: _defaultAllZeroDataPointsOpts({ theme }),
    chartBgColor: '#ffffff',
    chartTypeLibCfg: {
      bar: {
        options: {
          scales: {
            xAxes: [{
              offset: true,
            }]
          }
        }
      },
      line: {
        data: {
          datasets: [{
            fill: true,
            lineTension: 0.0,
            pointRadius: 2,
            pointBackgroundColor: mainColor,
            pointBorderWidth: 0,
            cubicInterpolationMode: 'monotone',
          }],
        },
        options: {
          hover: false,
        },
      }
    },
    libCfg: {
      data: {
        datasets: [{
          backgroundColor: fade(mainColor, 0.1),
          borderColor: mainColor,
          borderWidth: 1,
        }],
      },
      options: {
        title: { display: false },
        tooltips: {
          ..._tooltipBase({
            theme,
          }),
        },
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
              fontColor: theme.palette.text.disabled,
              padding: 2,
            },
            gridLines: {
              tickMarkLength: 16,
              drawBorder: false,
              // zeroLineWidth: 0,
              zeroLineColor: 'rgba(0,0,0,0.04)',
              color: 'rgba(0,0,0,0.04)',
            },
            scaleLabel: {
              display: false,
            },
          }],
          xAxes: [{
            type: 'time',
            distribution: 'series',
            gridLines: false,
            ticks: {
              fontColor: theme.palette.text.disabled,
              padding: 12,
            },
            scaleLabel: {
              display: false,
            },
          }]
        }
      }
    }
  }
}

Chart.Tooltip.positioners.chartBottom = function(elements, eventPosition) {
  let x = elements?.[0]._view?.x || elements?.[0]._model?.x
  if (x === undefined ) x = eventPosition.x

  return {
    x,
    y: elements?.[0]._chart.chartArea.bottom || 0,
  }
}

Chart.Tooltip.positioners.topElement = function(elements, eventPosition) {
  let x = elements?.[0]._view?.x || elements?.[0]._model?.x
  if (x === undefined ) x = eventPosition.x

  let y = min(elements.map((el) => el._view?.y || el._model?.y))
  if (y === undefined) y = eventPosition.y

  return {
    x,
    y,
  }
}

Chart.Tooltip.positioners.topLeft = function(elements, eventPosition) {
  return {
    x: (elements?.[0]._chart.chartArea.left || 0) + (elements?.[0]._chart.legend?.left || 0) + 8,
    y: 0,
  }
}

const stateVariants = {
  default: _fullBase,
  full: _fullBase,
  dashStackedLine({ datasets, theme, zeroCompensationColor }) {
    return {
      ..._fullBase({ theme }),
      allZeroDataPointsOpts: {},
      chartTypeLibCfg: {},
      libCfg: {
        type: 'line',
        plugins: [
          getZeroCompensation({
            strokeStyle: zeroCompensationColor,
          }),
        ],
        data: {
          datasets,
        },
        options: {
          title: { display: false },
          hover: {
            intersect: false,
            mode: 'index',
            animationDuration: 1, // hover animations seem bugged when moving quickly between points so set to 1 millisecond
          },
          tooltips: {
            ..._tooltipBase({
              theme,
            }),
            xAlign: 'left',
            caretSize: 0,
            xPadding: 8,
            yPadding: 6,
            itemSort: (a, b) => b.datasetIndex - a.datasetIndex, // disable to have tooltip items sort in descending value
            callbacks: {
              labelTextColor: (tooltipItem, chart) => chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor,
            },
            titleAlign: 'left',
            bodyAlign: 'left',
            bodyFontSize: 13,
            position: 'topLeft',
            mode: 'index',
            backgroundColor: 'rgba(255,255,255,0.8)',
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: false },
          layout: { padding: { left: 12, right: 24, top: 24, bottom: 24 } },
          scales: {
            yAxes: [{
              display: true,
              gridLines: {
                drawBorder: false,
                drawTicks: false,
                zeroLineColor: 'rgba(0, 0, 0, 0.1)',
                color: 'rgba(0, 0, 0, 0.04)',
              },
              ticks: {
                fontColor: theme.palette.text.disabled,
                beginAtZero: true,
                padding: 12,
                userCallback: (label, index, labels) => {
                  if (Math.floor(label) === label) {
                    return label
                  }
                },
              },
              scaleLabel: {
                display: false,
              },
            }],
            xAxes: [{
              display: false,
              gridLines: false,
              scaleLabel: {
                display: false,
              },
              stacked: true,
            }]
          }
        }
      }
    }
  },
  safetyLine({ mainColor = '#007BA7', datasetLabel, theme, zeroCompensationColor, data, animation, decimals = 0 }) {
    const ret = {
      ..._fullBase({ theme }),
      noDataPointsOpts: {
        contentsRenderCfg: {
          Comp: TextHandler,
          props: {
            label: 'No data found',
            variant: 'body2',
            color: theme.palette.text.disabled,
            textAlign: 'center',
          },
        },
        containerProps: {
          alignItems: 'center',
          justifyContent: 'center',
        }
      },
      allZeroDataPointsOpts: {},
      chartTypeLibCfg: {},
      libCfg: {
        type: 'line',
        plugins: [
          getZeroCompensation({
            strokeStyle: zeroCompensationColor,
          }),
        ],
        data: {
          data,
          datasets: [
            {
              backgroundColor: mainColor,
              borderColor: mainColor,
              label: datasetLabel,
              fill: false,
              borderWidth: 1,
              pointRadius: 1.5,
              pointHoverRadius: 3,
            },
          ],
        },
        options: {
          title: { display: false },
          hover: {
            intersect: false,
            mode: 'index',
            animationDuration: 1, // hover animations seem bugged when moving quickly between points so set to 1 millisecond
          },
          tooltips: {
            ..._tooltipBase({
              theme,
            }),
            xAlign: 'left',
            caretSize: 0,
            xPadding: 8,
            yPadding: 6,
            itemSort: (a, b) => b.datasetIndex - a.datasetIndex, // disable to have tooltip items sort in descending value
            callbacks: safetyTooltipCallbacks(),
            titleAlign: 'left',
            bodyAlign: 'left',
            bodyFontSize: 13,
            position: 'topLeft',
            mode: 'index',
            backgroundColor: 'rgba(255,255,255,0.8)',
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: false },
          scales: {
            yAxes: [{
              display: true,
              gridLines: {
                drawBorder: false,
                drawTicks: false,
                zeroLineColor: 'rgba(0, 0, 0, 0.1)',
                color: 'rgba(0, 0, 0, 0.04)',
              },
              ticks: {
                fontColor: theme.palette.text.disabled,
                beginAtZero: true,
                padding: 12,
                // suggestedMax: 8,
                // userCallback: (label, index, labels) => {
                //   if (Math.floor(label) === label) {
                //     return label
                //   }
                // },
              },
              scaleLabel: {
                display: false,
              },
            }],
            // xAxes: [{
            //   display: false,
            // }],
            xAxes: [{
              display: false,
              gridLines: false,
              ticks: { fontColor: theme.palette.text.disabled, padding: 16 },
              scaleLabel: {
                display: false,
              },
              type: 'time',
              distribution: 'series',
              time: {
                unit: 'day',
                displayFormats: { day: 'MMM DD' },
              },
              stacked: true,
            }],
          }
        }
      }
    }

    if (animation) ret.libCfg.options.animation = animation

    return ret
  },
  entityActivities({ theme }) {
    return {
      ..._fullBase({ theme }),
      dataNotSetOpts: {
        contentsRenderCfg: {
          Comp: TextHandler,
          props: {
            label: 'No activity selected',
            variant: 'body2',
            color: theme.palette.text.primary,
            textAlign: 'center',
            fontStyle: 'italic',
          },
        },
        containerProps: {
          alignItems: 'center',
          justifyContent: 'center',
        }
      },
    }
  },
  appCard({ theme }) {
    const mainColor = theme.palette.primary.main
    return {
      noDataPointsOpts: {
        contentsRenderCfg: {
          Comp: TextHandler,
          props: {
            label: 'No data found',
            variant: 'small2',
            color: theme.palette.text.disabled,
            textAlign: 'center',
          },
        },
        containerProps: {
          alignItems: 'center',
          justifyContent: 'center',
        }
      },
      allZeroDataPointsOpts: _defaultAllZeroDataPointsOpts({ theme }),
      chartTypeLibCfg: {},
      libCfg: {
        data: {
          datasets: [{
            backgroundColor: fade(mainColor, 0.1),
            borderColor: mainColor,
            borderWidth: 1,
          }],
        },
        options: {
          title: { display: false },
          tooltips: {
            ..._tooltipBase({
              theme,
            }),
            xPadding: 8,
            yPadding: 6,
          },
          legend: { display: false },
          layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
          scales: {
            yAxes: [{
              display: false,
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: false,
              },
            }],
            xAxes: [{
              gridLines: false,
              offset: true,
              type: 'time',
              distribution: 'series',
              ticks: { fontColor: theme.palette.text.disabled, padding: 4 },
              time: {
                unit: 'week',
                displayFormats: { week: 'dd' },
              },
              scaleLabel: {
                display: false,
              },
            }]
          }
        }
      }
    }
  },
  workload({ theme, data = {}, annotation }) {
    return {
      ..._fullBase({ theme }),
      noDataPointsOpts: {
        contentsRenderCfg: {
          Comp: TextHandler,
          props: {
            label: 'No data found',
            variant: 'body2',
            color: theme.palette.text.disabled,
            textAlign: 'center',
          },
        },
        containerProps: {
          alignItems: 'center',
          justifyContent: 'center',
        }
      },
      allZeroDataPointsOpts: _defaultAllZeroDataPointsOpts({ theme }),
      chartTypeLibCfg: {},
      libCfg: {
        type: 'bar',
        plugins: [
          ChartAnnotation,
          ChartDataLabels,
        ],
        data: {
          data,
        },
        options: {
          annotation,
          plugins: {
            datalabels: {
              // color: '#36A2EB',
              anchor: 'end',
              align: 'end',
              formatter: (val, context) => humanizedHours(val),
            }
          },
          tooltips: {
            ..._tooltipBase({
              theme,
            }),
            xPadding: 8,
            yPadding: 6,
            callbacks: safetyTooltipCallbacks({ humanizeHours: true, showDatasetLabel: false, }),
            mode: 'nearest',
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                max: 8,
                beginAtZero: true,
                fontColor: theme.palette.text.disabled,
                padding: 12,
              },
              scaleLabel: {
                display: true,
                labelString: 'hours',
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: 'rgb(0,0,0,0.04)',
                color: 'rgba(0,0,0,0.04)',
              },
            }],
            xAxes: [{
              gridLines: false,
              offset: true,
              ticks: {
                display: false,
              },
              scaleLabel: {
                display: false,
              },
            }],
          },
          maintainAspectRatio: false
        }
      }
    }
  },
  doughnut({ theme, tooltips = {} }) {
    return {
      noDataPointsOpts: {
        contentsRenderCfg: {
          Comp: TextHandler,
          props: {
            label: 'No data found',
            variant: 'small2',
            color: theme.palette.text.disabled,
            textAlign: 'center',
          },
        },
        containerProps: {
          alignItems: 'center',
          justifyContent: 'center',
        }
      },
      allZeroDataPointsOpts: _defaultAllZeroDataPointsOpts({ theme }),
      chartTypeLibCfg: {},
      libCfg: {
        type: 'doughnut',
        data: {},
        options: {
          title: { display: false },
          tooltips: {
            ..._tooltipBase({
              theme,
            }),
            xPadding: 8,
            yPadding: 6,
            mode: 'index',
            intersect: true,
            ...tooltips,
          },
          legend: {
            display: false,
          },
          layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
          maintainAspectRatio: false
        }
      }
    }
  },
}

const postProcessor = (o, variantOpts) => {
  o.libCfg.type = o.libCfg.type || variantOpts.chartType || 'bar'
  o.tz = variantOpts.tz || global.TZ
  // o.data.datasets.data = o.data.datasets.data || variantOpts.data || []
  return o
}

export default {
  stateVariants,
  postProcessor
}