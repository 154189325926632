import React from 'react'

import { useModel, objectVariantOrPassthrough, initControls, setControlVal, selectedValAndAllowedItemsFromCfg } from '../../../../../lib/state'
import stateVariants from './variants'

import moment from 'moment-timezone'
import deepMerge from '../../../../../lib/deepMerge'

//
// Util
//

const isMomentIso = (str) => str.indexOf
  && str.indexOf('-') >= 0
  && moment(str, "YYYY-MM-DDTHH:mm:ss", false).isValid()

function _setConditionValState(state, val, opts) {
  state.currentDefaultConditionValState = val
  state.controls.conditionVal = {
    ...state.defaultConditionValState,
    ...val,
  }

  if (state.initialVal === 0 || state.initialVal) {
    let _val = state.initialVal
    if (isMomentIso(_val)) {
      _val = moment(_val).format(moment.HTML5_FMT.DATETIME_LOCAL)
    }
    _val = _val.toString() // ensure numbers are strings

    state.controls.conditionVal.defaultVal = _val
    state.controls.conditionVal.val = _val
  }

  setControlVal(state)
}

const _setValueOptions = (state) => {
  const fieldControl = state.controls.field
  const fieldItem = fieldControl.items[fieldControl.val]
  let cached = state._optionsCache[fieldControl.val]

  const lastFieldValType = state.fieldValType
  let newFieldValType = null
  let stripOptions = false

  let _items = []

  if (fieldItem.isInvalid) {}
  else if (cached) {
    _items = cached.items.filter((item) => item.val !== '')

    if (_items.length > 0) {
      [ newFieldValType, stripOptions ] = _detectFieldType(_items[0].val.toString(), _items.length)

      if (stripOptions) {
        _items = []
      }
    }
  }
  else {
    state.needOptionsForFieldItem = fieldItem
  }

  if (lastFieldValType !== newFieldValType) {
    state.fieldValType = newFieldValType
    if (newFieldValType == null) {
      _setConditionValState(state, state.defaultConditionValState)
    }
  }

  state.controls.conditionVal.options = _items
  state._tempConditionValOptions = _items

  if (cached) {
    const restrictToOptions = Boolean(cached.restrictToOptions)
    state.controls.conditionVal.restrictToOptions = restrictToOptions
    state.controls.conditionOp.disabled = restrictToOptions
  }

  _setConditionValState(state, state.controls.conditionVal)
}

const _detectFieldType = (sampleStr, sampleCount) => {
  const isPlaceholderIso = sampleStr === 'never' && sampleCount === 1

  if (isMomentIso(sampleStr) || isPlaceholderIso) {
    return [ 'iso', isPlaceholderIso ]
  }

  if (!isNaN(sampleStr)) {
    return [ 'numeric', false ]
  }

  return [ null, false ]
}

///////////
// MODEL //
///////////

const model = {
  ///////////
  // STATE //
  ///////////
  stateInit(args) {
    let ret = {
      ...objectVariantOrPassthrough(stateVariants, args),
      //
    }

    ret.fieldsOpts = {}
    ret.fieldsOpts.sourceStr = ret.target || 'appusers'
    ret.fieldsOpts.placeholderLabel = 'Select property...'
    ret.fieldsOpts.firstIsDefault = true

    
    ret.initialVal = ret.val || null
    ret.initialField = ret.field || null

    ret.controls = {
      field: selectedValAndAllowedItemsFromCfg({
        itemsCfg: null,
        defaultVal: null,
      }),
      conditionOp: selectedValAndAllowedItemsFromCfg({
        itemsCfg: {
          '==': {
            label: '==',
          },
          '!=': {
            label: '!=',
          },
          '<': {
            label: '<',
          },
          '<=': {
            label: '<=',
          },
          '>': {
            label: '>',
          },
          '>=': {
            label: '>=',
          },
          'contains': {
            label: 'contains',
          },
          '!contains': {
            label: '!contains',
          },
        },
        firstIsDefault: true,
        sortByKey: false,
        defaultVal: ret.op || null,
      }),
      conditionVal: {
        val: null,
        defaultval: null,
        placeholder: 'Select value...',
        options: [],
        _validator: (val) =>  val && val !== '',
        minwidth: '230px',
        _validateAllowNotChanged: true,
      },
    }

    const controls = ret.controls

    controls.field.methodKey = 'setField'
    controls.field._validateAllowNotChanged = true
    controls.conditionOp.methodKey = 'setConditionOp'
    controls.conditionOp._validateAllowNotChanged = true
    controls.conditionVal.methodKey = 'setConditionVal'

    ret._tempConditionValOptions = []
    ret._optionsCache = {}
    ret.fieldType = null
    
    ret.defaultIsValid = true // what is this for?

    ret.defaultConditionValState = deepMerge(controls.conditionVal) // deepCopy 

    _setValueOptions(ret)

    initControls(ret)
  
    return ret
  },
  /////////////
  // METHODS //
  /////////////
  methodsInit(state) {
    return {
      //
      // control val setters
      //
      setField(val, opts) {
        state.shouldValidate = false
        state.controls.field.val = val

        state.controls.conditionVal.val = null

        _setValueOptions(state)
        setControlVal(state)
      },
      setConditionOp(val, opts) {
        state.controls.conditionOp.val = val
        setControlVal(state)
      },
      setConditionVal(val, opts) {
        state.controls.conditionVal.val = val
        setControlVal(state)
      },
      //
      // other
      //
      clearInitialVal(val, opts) {
        state.initialVal = undefined
      },
      setConditionValState(val, opts) {
        _setConditionValState(state, val, opts)
      },
      setConditionValOptions(val, opts) {
        state.controls.conditionVal.options = val
      },
      setFields(val, opts) {
        const { firstIsDefault, placeholderLabel } = state.fieldsOpts
        
        state.controls.field = {
          ...state.controls.field,
          ...selectedValAndAllowedItemsFromCfg({
            itemsCfg: val,
            getKey: (item) => item.id,
            placeholderLabel,
            firstIsDefault,
            tryGetDefault: (items) => Object.values(items).find((item) => item.path === state.initialField)?.id
          })
        }
        state.initialField = null
        _setValueOptions(state)
        setControlVal(state)
      },
      cacheFieldOptions({res: val, meta: opts}) {
        state._optionsCache[opts.id] = {
          ...opts,
          items: val,
        }
        _setValueOptions(state)
        setControlVal(state)
      },
      _setShouldValidate(val, opts) {
        state.shouldValidate = val
        setControlVal(state)
      },
      _buildVal(val, opts) {
        const fieldControl = state.controls.field
        const fieldItem = fieldControl.items[fieldControl.val]

        const valControl = state.controls.conditionVal
        let conditionaValOrItem = valControl.val
        if (valControl._other && valControl._other.executeOutputTransformer) {
          conditionaValOrItem = valControl._other.executeOutputTransformer(conditionaValOrItem)
        }

        const _val = {
          field: fieldItem.path,
          op: state.controls.conditionOp.val,
          val: conditionaValOrItem?.val || conditionaValOrItem,
        }

        state._builtVal = _val
      },
    }
  },
  ///////////
  // HOOKS //
  ///////////
  // HOOK_EXAMPLES: {
  //   EXAMPLE_callback: { // A key to access ./lib/hooks keyedHooks
  //     getHookArgs: (state) => { return {} },
  //     callback: (res, methods) => {}
  //   },
  //   EXAMPLE_localHook: {
  //     useHook: (stateOrArgs, methods) => {},
  //   },
  // }
  hooks: {
    useDbEntityFields: {
      getHookArgs: (state) => {
        return {
          target: state.fieldsOpts.sourceStr,
          allowCohorts: false,
        }
      },
      methodKey: 'setFields',
    },
    useDbEntityFieldValues: {
      getHookArgs: (state) => {
        return state.needOptionsForFieldItem
      },
      methodKey: 'cacheFieldOptions',
    },
    _valTypeHandler: {
      useHook: (state, methods) => {
        const {
          fieldValType,
          _tempConditionValOptions,
        } = state

        React.useEffect(() => {
          if (fieldValType === 'iso') {
            // TODO_MAYBE handle data format options conversions inside of TextField component

            const momentToNativeFormat = moment.HTML5_FMT.DATETIME_LOCAL
            
            methods.setConditionValState({
              inputType: 'datetime-local',
              max: moment().add(1, 'hours').format(momentToNativeFormat),
              minwidth: '280px',
              _validator: (val) => val != null && (val.val || moment(val, momentToNativeFormat).isValid() || 'Invalid date'),
              _other: {
                executeOutputTransformer: (val) => val != null && moment(val.val || val, momentToNativeFormat).toISOString(),
              },
            })
            methods.setConditionValOptions(_tempConditionValOptions.map((item) => {
              const m = moment(item.val)
              const nativeFmt =  m.format(momentToNativeFormat)
              return {
                label: m.format('lll'),
                inputVal: nativeFmt,
                val: nativeFmt,
              }
            }))
            methods.clearInitialVal()
          }
          else if (fieldValType === 'numeric') {
            methods.setConditionValState({
              _validator: (val) =>  (val != null && !isNaN(val.val || val)) || 'Invalid Number',
              _other: {
                executeOutputTransformer: (val) => val != null && Number(val),
              },
            })
            methods.setConditionValOptions(_tempConditionValOptions)
            methods.clearInitialVal()
          }
          else {
            // methods.clearInitialVal()
          }
        }, [
          _tempConditionValOptions,
          methods,
          fieldValType,
        ])
      }
    }
  },
}

// returns [ state, methods ]
export default (...args) => {
  return useModel(model, ...args)
}