/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import AuthPage from "../pages/auth/AuthPage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import {LayoutContextProvider} from "../layout/LayoutContext";
import * as routerHelpers from "../router/RouterHelpers";

import ServiceProvider from '../lib/ServiceProvider';

import ZTLayout from '../layout';

import menuConfig from '../layout/MenuConfig';

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, userLastLocation } = useSelector(
    ({ auth, urls }) => ({
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  return (
    /* Create `LayoutContext` from current `history`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <ServiceProvider>
        <Switch>
          {!isAuthorized ? (
             /* Render auth page when user at `/auth` and not authorized. */
             <Route path="/auth" component={AuthPage} />
           ) : (
             /* Otherwise redirect to root page (`/`) */
             <Redirect from="/auth" to={userLastLocation} />
           )}

          <Route path="/error" component={ErrorsPage} />

          {!isAuthorized ? (
             /* Redirect to `/auth` when user is not authorized */
             <Redirect to="/auth/login" />
           ) : (
             <ZTLayout>
               {/* TODO_REFACTOR home page doesnt use userLastLocation? */}
               <HomePage userLastLocation={userLastLocation} /> 
             </ZTLayout>
           )}
        </Switch>
      </ServiceProvider>
    </LayoutContextProvider>
  );
});
