/*
   This seems pretty hack'ish, but ...

   We want to skip this step.  We don't need to send a verification email to this new user with a verification
   code and all that ... we have effectively already verified their email if they've gotten this far!

   But there seems no way to actually disable this check in Amplify, except by supplying this component.
 */
import React from 'react';

import {
  VerifyContact,
} from "aws-amplify-react";

import * as Amp from 'aws-amplify-react';

import { I18n } from '@aws-amplify/core';

const SectionFooterPrimaryContent = props => {
  const theme = props.theme;
  const style = theme.sectionFooterPrimaryContent;
  return(
    <span className={"sectionFooterPrimaryContent"} style={style}>
      {props.children}
    </span>
  );
};

export const SectionFooterSecondaryContent = props => {
  const theme = props.theme;
  const style = theme.sectionFooterSecondaryContent;
  return(
    <span className={"sectionFooterSecondaryContent"} style={style}>
      {props.children}
    </span>
  );
};

export class MyVerifyContact extends VerifyContact {

  verifyView() {
    // no need for a checkbox ... email is the only choice
    return(
      <div>
        Click on the <strong>VERIFY</strong> button.  You will receive an email with a verification code which you
        must enter on the next screen.  
      </div>
    );
  }
  
  showComponent(theme) {

    const {authData} = this.props;

    const {  
      FormSection,
      SectionHeader,
      SectionBody,
      Button,
      Link,
    } = Amp;

    // This is the only possibility in our system, so we can harcode it here
    this.inputs = this.inputs || {};
    this.inputs.contact = "email";
    this.inputs.checkedValue = "email";

    return(
      <FormSection theme={theme}>
        <SectionHeader
            theme={theme}
        >
          {I18n.get('Account recovery requires verified contact information')}
        </SectionHeader>
        <SectionBody theme={theme}>
          {this.state.verifyAttr ? this.submitView() : this.verifyView()}
        </SectionBody>
        <div className="amplify-section-footer">
          <SectionFooterPrimaryContent theme={theme}>
            {this.state.verifyAttr ? (
               <Button
                   theme={theme}
                   onClick={this.submit}
               >
                 {I18n.get('Submit')}
               </Button>
             ) : (
               <Button
                   theme={theme}
                   onClick={this.verify}
               >
                 {I18n.get('Verify')}
               </Button>
             )}
          </SectionFooterPrimaryContent>
          <SectionFooterSecondaryContent theme={theme}>
            <Link
                theme={theme}
                onClick={() => this.changeState('signedIn', authData)}
            >
              {I18n.get('Skip until next login')}
            </Link>
          </SectionFooterSecondaryContent>
        </div>
      </FormSection>
    );
  }
}

