import React from 'react'

import useStyles from '../../../lib/useStyles'

import { Box, Grid, Text, Flex } from '../../Base'
import TextField from '../../ControlComps/TextField'

import MessageCompositionEmail from './MessageCompositionEmail'
import MessageCompositionPush from './MessageCompositionPush'
import Scheduling from './DeliverySettings'

import { CohortDropdown } from '../OtherComps/EntityDropdown'

// import Query from './Audience'

import { GroupOutlined } from '@material-ui/icons'

import {
  // GroupOutlined,
  SettingsOutlined,
} from '@material-ui/icons'

const EditorContents = React.memo(({ state, methods }) => {
  const classes = useStyles()

  let ContentComp = null
  let contentMb = undefined
  if (state.controls.selectedContentType.val === 'email') {
    ContentComp = MessageCompositionEmail
  }
  else if (state.controls.selectedContentType.val === 'push') {
    ContentComp = MessageCompositionPush
    contentMb = '-16px'
  }

  // const queryBuilderOpts = React.useMemo(() => {
  //   return {
  //     variant: 'cohortQuery',
  //     variantOpts: {
  //       target: 'appusers',
  //       controls: {
  //         execute: false,
  //       },
  //     }
  //   }
  // }, [])

  return (
    <>
      <Box className={classes.formSection}>
        <Box mb={contentMb}>
          <ContentComp
            stateCfg={state.controls.content.val[state.controls.selectedContentType.val]}
            shouldValidate={state.shouldValidate}
            onBuiltVal={methods[state.controls.content.methodKey]}
          />
        </Box>
      </Box>
      <Box className={classes.formSection}>
        <Scheduling
          stateCfg={state.controls.scheduling.val}
          shouldValidate={state.shouldValidate}
          onBuiltVal={methods[state.controls.scheduling.methodKey]}
        />
      </Box>
      {/* <Box className={classes.formSection}>
        <Flex alignItems='center' mb='16px' justifyContent='start'>
          <GroupOutlined color='primary'/>
          <Text ml='8px' variant='subtitle1' color='primary.main'>
            Audience
          </Text>
        </Flex>
        <Flex flexDirection='column'>
          <Grid
            gridGap='24px'
          >
            <Box>
              <Text
                variant='small1'
                pb='6px'
              >
                Segment by user cohort
              </Text>
              <QueryBuilder
                initialData={state.controls.cohortQuery.defaultVal}
                updateDataTrigger={state.wantsToSaveToken}
                builderOpts={queryBuilderOpts}
                onDataUpdate={methods[state.controls.cohortQuery.methodKey]}
                setHasChanged={methods.setCohortQueryHasChanged}
              />
            </Box>
          </Grid>
        </Flex>
      </Box> */}
      {/* <Box className={classes.formSection}>
        <Query
          stateCfg={state.controls.audience.val}
          shouldValidate={state.shouldValidate}
          onBuiltVal={methods[state.controls.audience.methodKey]}
          Title={
            <Flex alignItems='center' justifyContent='start'>
                <GroupOutlined color='primary'/>
                <Text ml='8px' variant='subtitle1' color='primary.main'>
                  Audience
                </Text>
            </Flex>
          }
        />
      </Box> */}
      <Box className={classes.formSection}>
        <Flex alignItems='center' mb='16px' justifyContent='start'>
          <GroupOutlined color='primary'/>
          <Text ml='8px' variant='subtitle1' color='primary.main'>
            Audience
          </Text>
        </Flex>
        <Flex flexDirection='column'>
          <Grid
            gridGap='24px'
          >
            <Box>
              <Text
                variant='small1'
                pb='6px'
              >
                Segment by user cohort
              </Text>
              <CohortDropdown
                setEntity={methods[state.controls.audience.methodKey]}
                selectedEntity={state.controls.audience.val}
              />
            </Box>
          </Grid>
        </Flex>
      </Box>
      <Box className={classes.formSection}>
        <Flex alignItems='center' mb='16px' justifyContent='start'>
          <SettingsOutlined color='primary'/>
          <Text ml='8px' variant='subtitle1' color='primary.main'>
            Settings
          </Text>
        </Flex>
        <Flex flexDirection='column'>
          {
            state.validationhint &&
              <Text
                variant='small3'
                color={'error.main'}
                mb='16px'
              >
                {state.validationhint}
              </Text>
          }
          <Grid
            gridGap='24px'
            gridTemplateColumns='1fr 1fr'
          >
            <Box>
              <Text
                variant='small1'
                pb='6px'
              >
                Campaign Name
              </Text>
              <TextField
                state={state.controls.name}
                methods={methods} 
                placeholder='New Campaign'
                variant='outlined'
                fullWidth
              />
            </Box>
            {/* <Box>
              <Text
                variant='small2'
                pb='6px'
                color='text.secondary'
              >
                Status
              </Text>
              <ToggleButton
                state={state.controls.isActive}
                methods={methods}
                trueText='Active'
                falseText='Inactive'
                color='primary'
                height='40px'
                maxwidth='120px'
              />
            </Box> */}
          </Grid>
        </Flex>
      </Box>
    </>
  )
})

export default {
  EditorContents,
  ManagerContents: null,
  Profile: null,
}