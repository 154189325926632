import { Group, Item } from './Comps'
import { isObject } from 'lodash'

// TODO currently will only work for shallow objects due to the idResolver

const variant =  ({ shallow = true, hasBrace = true }) => {

  return {
    compResolver(item, hasChildren, isRoot) {
      if (isRoot && !hasChildren) return null
      if (item.__isObject || item.__isArray) return Group
      else return Item
    },
    childrenResolver: (item) => {
      if (isObject(item)) {
        return Object.entries(item).map(([key, val]) => {
          return {
            __key: key,
            __val: val,
            __isObject: isObject(val),
            __isArray: Array.isArray(val)
          }
        })
      }
      else if (Array.isArray(item)) {
        return item.map((val, i) => {
          return {
            __key: i,
            __val: val,
            __isObject: isObject(val),
            __isArray: Array.isArray(val)
          }
        })
      }
    },
    idResolver: (item) => item.__key, // TODO_MAYBE idResolver that handles full item path
    isBranchFunc: (item) => item.__isObject || item.__isArray,
    dataTransformers: {
      from: {
        leaf(item) {
          return item
        },
        branch(itemWithChildren, childrenResolver) {
          const __val = itemWithChildren.__val || itemWithChildren

          const itemOut = { 
            __isObject: itemWithChildren.__isObject || isObject(__val),
            __isArray: itemWithChildren.__isArray || Array.isArray(__val),
            __key: itemWithChildren.__key,
          }

          const childrenOut = childrenResolver(__val)
  
          return [ itemOut, childrenOut ]
        }
      },
      to: {
        leaf(item) {
          return item
        },
        branch(itemNoChildren, children) {
          const { __isObject, __isArray } = itemNoChildren

          let ret = null

          if (__isObject) {
            ret = {}
            children.forEach((item) => {
              ret[item.__key] = item.__val
            })
          }
          else if (__isArray) {
            ret = []
            children.forEach((item) => {
              ret.push(item.__val)
            })
          }

          return ret
        }
      }
    },
    treeCompProps: {
      all: null,
      leaf: null,
      root: {
        py: '0px',
        mb: 0,
      },
      branch: {
        hasBrace,
      },
    }
  }
}

export default variant