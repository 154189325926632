import React from 'react'
import { useSelector } from 'react-redux'

import useModel from './model'

import PreviewPushAndroid from './PreviewPushAndroid'
import PreviewPushiOS from './PreviewPushiOS'

import TextField from '../../../ControlComps/TextField'

import { Box, Flex, Grid, Text } from '../../../Base'

import { NotificationsActiveOutlined } from '@material-ui/icons'

const Comp = React.memo(({ stateCfg, shouldValidate, onBuiltVal, ...props }) => {
  const [ state, methods ] = useModel(stateCfg, shouldValidate, onBuiltVal)

  const appName = useSelector(store => store.auth.app?.name)

  // React.useEffect(() => {
  //   methods._setShouldValidate(shouldValidate)
  // }, [
  //   methods,
  //   shouldValidate,
  // ])

  return (
    <>
      <Flex alignItems='center' mb='16px' justifyContent='start'>
        <NotificationsActiveOutlined color='primary'/>
        <Text ml='8px' variant='subtitle1' color='primary.main'>
          Push Composition
        </Text>
      </Flex>
      <Grid
        gridGap='24px'
        gridTemplateRows='max-content'
        mr='16px'
      >
        {
          state.validationhint &&
            <Text
              variant='small3'
              color={'error.main'}
            >
              {state.validationhint}
            </Text>
        }
        <Box>
          <Text variant='small1' pb='6px'>
            {'Title'}
          </Text>
          <TextField
            fullWidth={true}
            variant='outlined'
            placeholder="Title"
            state={state.controls.title}
            methods={methods}
          />
        </Box>
        <Box>
          <Text variant='small1' pb='6px'>
            {'Body'}
          </Text>
          <TextField
            fullWidth={true}
            variant='outlined'
            placeholder="Body"
            state={state.controls.body}
            methods={methods}
          />
        </Box>
        <Flex height='240px' overflow='hidden' justifyContent='space-around'>
          <PreviewPushAndroid title={state.controls.title.val} body={state.controls.body.val} appName={appName}/>
          <PreviewPushiOS title={state.controls.title.val} body={state.controls.body.val} appName={appName}/>
        </Flex>
      </Grid>
    </>
  )
})

export default Comp