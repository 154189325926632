import React from 'react'

// import useMethods from 'use-methods'
import modelVariants from './TableStateVariants'
import { useModel, objectVariantOrPassthrough } from '../../lib/state'
import { valFromPathOrPaths } from '../../lib/utils'
import { doExport } from '../../lib/export'
import moment from 'moment-timezone'
import { queryData_toLoopback } from '../Query/util'
import { get, sortBy, uniqBy, orderBy, isFunction } from 'lodash'

const defaultLabel = '-'

// import useApi from '../../lib/useApi'
// import { original, isDraft } from 'immer'

//
// CUSTOM TYPES
//

// const _valToPayload = (val, text = '-', sortVal = 0) => {
//   return {
//     _payload: val,
//     text,
//     sortVal,
//   }
// }

// const _payloadPathToMaterialColumn = (typeInfoOpts = {}, path) => {
//   return {
//     field: path + '.text',
//     customSort: (a, b) => {
//       return get(a, path).sortVal - get(a, path).sortVal
//     },
//   }
// }

const _customTypes = {
  // MATERIAL UI TABLE defaultSort doesnt work if customSort is defined, WTF!
  // iso: {
  //   sampleToTypeOpts(sample) {
  //     if (sample.indexOf && sample.indexOf('-') >= 0) {
  //       if (moment(sample, "YYYY-MM-DDTHH:mm:ss", false).isValid()) {
  //         return {
  //           format: 'lll',
  //         }
  //       }
  //     }
  //   },
  //   dataTransformer(typeInfoOpts = {}, val) {
  //     const format = (typeInfoOpts.format) || undefined
  //     let text, sortVal

  //     if (val) {
  //       const parsed = moment(val)
  //       sortVal = parsed.valueOf()

  //       if (format === 'FROM_NOW') {
  //         text = parsed.fromNow()
  //       }
  //       else{
  //         text = parsed.format(format)
  //       }
  //     }

  //     return  _valToPayload(val, text, sortVal)
  //   },
  //   pathToColumnInfo: _payloadPathToMaterialColumn,
  // },
  materialTableIso: {
    sampleToTypeOpts(sample) {
      if (sample.indexOf && sample.indexOf('-') >= 0) {
        if (moment(sample, "YYYY-MM-DDTHH:mm:ss", false).isValid()) {
          return {
            format: 'lll',
          }
        }
      }
    },
    pathToColumnInfo(typeInfoOpts = {}, path) {
      const {
        format = 'lll',
        tz,
      } = typeInfoOpts

      return {
        renderCfg: {
          valToStr: (val) => {
            let parsed = moment(val)

            if (tz) {
              parsed = parsed.tz(tz)
            }

            if (format === 'FROM_NOW') {
              return parsed.fromNow()
            }
            return parsed.format(format)
          }
        },
      }
    },
  },
  numericFixed: {
    pathToColumnInfo(typeInfoOpts = {}, path) {
      const {
        precision = 2,
      } = typeInfoOpts

      return {
        renderCfg: {
          valToStr: (val) => {
            return parseFloat(val.toFixed(precision))
          }
        },
      }
    },
  },
}

//
// OUR COL INFO TO MATERIAL TABLE
//

const _colInfoToMaterialTableCol = (colInfo, items) => {
  let type = colInfo.type

  const customType = type && type.customType
  if (customType) {
    const pathToColumnInfo = _customTypes[customType].pathToColumnInfo
    if (pathToColumnInfo) {
      colInfo = {
        ...colInfo,
        ...pathToColumnInfo((type.opts) || undefined, colInfo.path || colInfo.dataKey),
      }
    }
    type = null
  }

  let { path, renderCfg, colMUIT } = colInfo

  let _col = {
    field: colInfo.field || path,
    emptyValue: defaultLabel,
  }

  if (type) {
    _col.type = type
  }

  // if (customType) {
  //   const dataTransformer = _customTypes[customType].dataTransformer
  //   if (dataTransformer) {
  //     items.forEach((item) => {
  //       set(path, item, dataTransformer(_getValFromPathOrPaths(item, path)))
  //     })
  //   }
  // }

  if (renderCfg || Array.isArray(path)) {
    if (Array.isArray(path)) {
      const fName = `_${colInfo.colMUIT.title}`
      _col.field = fName
      items.forEach((item) => {
        item[fName] = valFromPathOrPaths(item, path)
      })
    }
    delete _col.emptyValue
    const {
      // Comp
      Comp,
      props,
      pathedProps,
      // Span
      valToStr,
    } = renderCfg || {}

    if (!Comp) {
      _col.render = (item) => {
        let val = valFromPathOrPaths(item, path)

        if (val != null) {
          if (valToStr) {
            val = valToStr(val)
          }
        }
        else {
          val = defaultLabel
        }

        return <span>{val}</span>
      }
    }
    else {
      _col.render = (item) => {
        let rProps = {...props}
        Object.entries(pathedProps).forEach(([propsKey, innerPath]) => {     
          const _path = innerPath || path
          if (isFunction(_path)) {
            rProps[propsKey] = _path(item)
          }
          else {
            rProps[propsKey] = valFromPathOrPaths(item, _path)
          }
        })
  
        return (
          <Comp {...rProps}/>
        )
      }
    }
  }

  _col = {
    ..._col,
    ...colMUIT,
  }

  return _col
}

//
// STATE UTIL
//

const _setColumns = (state) => {
  const {
    data,
    dataOpts,
    _colsInfo,
    debug,
  } = state

  const {
    showDynamicKeys,
  } = dataOpts


  let colInfos = _colsInfo
  if (!showDynamicKeys) {
    colInfos = colInfos.filter((colInfo) => !colInfo.isDynamic)
  }

  const colType = colInfos.type?.customType || colInfos.type || 'string'

  colInfos = colInfos.map((colInfo) => colInfo._MUIT)

  colInfos = uniqBy(colInfos, (o) => o.field)

  const sorted = _defaultSortMUIT(data, colInfos, colType)

  state.data = sorted
  state.columns = colInfos

  if (debug) {
    console.log('TABLE: COLS:', colInfos)
  }
}

const _defaultSortMUIT = (items, columnsMUIT, colType) => {
  const toSortBy = columnsMUIT.filter((col) => col.defaultSort)

  const sortFuncs = toSortBy.map((col) => {
    return (item) => {
      let ret = get(item, col.field)
      if (ret == null) {
        if (colType === 'string' || colType === 'materialTableIso') {
          ret = ''
        }
        else if (colType === 'numberic' || colType === 'numericFixed') {
          ret = 0
        }
      }
      return ret
    }
  })
  const sortOrders = toSortBy.map((col) => col.defaultSort)

  let ret = orderBy(items, sortFuncs, sortOrders)
  return ret
}

const _updateReqData = (state) => {
  const newReqData = {}
  let stored = state._storedReqData

  const and = []

  if (state.showCohortDropdown) {
    if (stored.cohort) {
      // and.push(queryData_toLoopback(stored.cohort.query))
      newReqData.cohort = stored.cohort
    }
  }

  if ((state.showQueryBuilder && state.dataOpts.showDynamicKeys) || state.dataOpts.showDynamicKeys) {
    if (stored.where) {
      and.push(stored.where)
    }
  }

  if (state.showDateRangePicker) {
    if (stored.start) {
      newReqData.start = stored.start
    }
    if (stored.end) {
      newReqData.end = stored.end
    }
  }

  if (and.length) {
    newReqData.where = { and }
  }

  state.reqData = newReqData
}

//
// DATA PROCESSOR
//

const _getDynamicColsInfo = (state, items, opts) => {
  const {
    dynamicPathsInfoFunc,
    dataOpts,
    debug,
  } = state

  let dynamicPathsInfo = {}

  function setDynamicSampleCallback({ path, dataKey, sample, colMUIT }) {
    let info = dynamicPathsInfo[path]

    if (!info) {
      info = {
        path,
        dataKey,
        colMUIT,
      }
      dynamicPathsInfo[path] = info
    }

    if (info.sample == null) {
      info.sample = sample
    }
  }

  // Dynamic Samples
  if (dynamicPathsInfoFunc) {
    items.forEach((item) => dynamicPathsInfoFunc(item, dataOpts, state.supplementalData, setDynamicSampleCallback))
  }

  if (debug) {
    console.log('TABLE: DYNAMIC PATH INFO:', JSON.stringify(dynamicPathsInfo))
  }

  let entries = Object.entries(dynamicPathsInfo)
  entries = sortBy(entries, [(kvp) => kvp[0]])

  // Dynamic ColsInfos
  const ret = entries.map(([key, val]) => {
    const { sample, path, dataKey, colMUIT } = val

    let type = undefined

    if (sample != null) {
      if (val.customType) {
        type = val
      }
      else {
        let _arr = Object.entries(_customTypes)
        for (let i = 0; i < _arr.length; i++) {
          const [ customTypeStr, customType ] = _arr[i]
          if (customType.sampleToTypeOpts) {
            const opts = customType.sampleToTypeOpts(sample)
            if (opts) {
              type = {
                customType: customTypeStr,
                opts,
              }
              break
            }
          }
        }
      }

      if (!type && !isNaN(sample)) {
        type = dataOpts.numberType
      }
    }

    return {
      path,
      dataKey,
      type,
      colMUIT: {
        title: dataKey,
        ...colMUIT,
      },
      isDynamic: true,
    }
  })

  if (debug) {
    console.log('TABLE: DYNAMIC COLS INFO:', JSON.stringify(ret))
  }

  return ret
}

const _setData = (state, items, opts, skipTransform) => {
  const {
    dataOpts,
    resTransformer,
    staticColumnsInfoFunc,
    staticColsInfoAfterDynamic,
    debug,
    debugTransform,
  } = state

  if (debug) {
    if (Array.isArray(items)) {
      console.log('TABLE: DATA:', JSON.stringify(items[0]))
    } else {
      console.log('TABLE: DATA:', JSON.stringify(items))
    }
  }

  if (resTransformer && !skipTransform) {
    items = resTransformer(items, state)
    if (debugTransform) {
      console.log('TABLE: DATA: TRANSFORMED:', JSON.stringify(items[0], null, 2))
    }
  }

  state.hasData = items.length > 0

  let staticColsInfo = (staticColumnsInfoFunc && staticColumnsInfoFunc(dataOpts, state)) || []
  let dynamicColsInfo = _getDynamicColsInfo(state, items, opts)
  let staticColsInfoAfterDynamicInfo = (staticColsInfoAfterDynamic && staticColsInfoAfterDynamic(dataOpts, state)) || []

  const allColsInfo = [
    ...staticColsInfo.map((item) => (isFunction(item) && item(state.supplementalData)) || item),
    ...dynamicColsInfo,
    ...staticColsInfoAfterDynamicInfo,
  ]

  // Generate material col info
  allColsInfo.forEach((colInfo) => {
    colInfo._MUIT = _colInfoToMaterialTableCol(colInfo, items)
  })

  if (debug) {
    console.log('TABLE: ALL COLS INFO:', JSON.stringify(allColsInfo))
  }

  // if (debug) {
  //   console.log('TABLE: DATA: After Custom Types:', JSON.stringify(items[0]))
  // }

  state._colsInfo = allColsInfo
  state.data = items
  state._dataWasSet = true

  _setColumns(state)
}

//
// MODEL
//

const model = {
  //
  // STATE
  //
  stateInit: (opts) => {
    const reqData = opts.reqData || {}
  
    const _storedReqData = {
      ...reqData,
    }
  
    let newState = {
      ...objectVariantOrPassthrough(modelVariants, opts),
      reqData: _storedReqData,
      _storedReqData,
      data: [],
      columns: [],
      isLoading: true,
    }
    
    _updateReqData(newState)
  
    return newState
  },
  //
  // METHODS
  //
  methodsInit: (state) => {
    return {
      setData(val, opts) {
        _setData(state, val, opts)
      },
      setIsLoading(val, opts) {
        state.isLoading = val
      },
      export(val, opts) {
        const {
          data,
          _colsInfo,
          dataOpts,
          debug,
        } = state

        if (!data.length) return

        const {
          showDynamicKeys,
        } = dataOpts
  
        // Get export info
        let exportInfos = []
        _colsInfo.forEach((colInfo) => {
          if (colInfo.path && Array.isArray(colInfo.path)) {
            colInfo.path.forEach((pathInfo) => {
              if (pathInfo.dataKey) {
                exportInfos.push(pathInfo)
              }
            })
          }
          else if (colInfo.dataKey) {
            exportInfos.push({
              path: colInfo.path,
              exportRelPath: colInfo.exportRelPath,
              dataKey: colInfo.dataKey,
              isDynamic: colInfo.isDynamic,
            })
          }
        })
  
        if (debug) {
          console.log('TABLE: ALL EXPORT INFO:', JSON.stringify(exportInfos))
        }
  
        if (!showDynamicKeys) {
          exportInfos = exportInfos.filter((exportInfo) => !exportInfo.isDynamic)
        }
  
        if (debug) {
          console.log('TABLE: FILTERED EXPORT INFO:', JSON.stringify(exportInfos))
        }
  
        // Transform items to export data
        let outData = data.map((item) => {
          let ret = {}
  
          exportInfos.forEach(({ path, dataKey, exportRelPath }) => {
            ret[dataKey] = valFromPathOrPaths(item, path)
            if (exportRelPath) {
              ret[dataKey] = valFromPathOrPaths(ret[dataKey], exportRelPath)
            }
          })
  
          return ret
        })
  
        doExport({
          mimeType: 'text/csv',
          data: outData,
          fileName: (state.exportOpts && state.exportOpts.exportFileName) || 'table',
        })
      },
      setShowDynamicKeys(val, opts) {
        state.dataOpts.showDynamicKeys = val
        _updateReqData(state)
        _setColumns(state)
      },
      setCohort(val, opts) {
        state._storedReqData.cohort = val
        _updateReqData(state)
      },
      setReqDataWhere(val, opts) {
        // console.log('hello')
        // console.log(val)
        state._storedReqData.where = queryData_toLoopback(val)
        _updateReqData(state)
      },
      setReqDataDateRange(val, opts) {
        const { start, end } = val
        state._storedReqData.start = start
        state._storedReqData.end = end
        _updateReqData(state)
      },
      setTitle(val) {
        state.title = val
      },
      setSupplementalData(val, opts) {
        if (opts.key) {
          state.supplementalData[opts.key] = val
        }
        else {
          state.supplementalData = val
        }
        if (state._dataWasSet) {
          if (state.debug) {
            console.log('TABLE: SUPPLEMENTAL DATA TRIGGERING DATA RESET')
          }
          _setData(state, state.data, opts, true)
        }
      },
    }
  },
}

export default (stateInitializerArgs) => {
  return useModel(model, stateInitializerArgs)
}
