import {
  objectVariantOrPassthrough,
  initControls,
  setControlVal,
} from '../../lib/state'

//////////////
// Variants //
//////////////

const variantsCfg = {
  base: (variantOpts) => {
    let {
      forceGrab,
      id,
      hasControls = true,
      isNew,
      isClone,
      data = {},
      isSaveAs,
      isEdit,
    } = variantOpts
  
    let initialData = { ...data }
  
    if (id) {
      initialData.id = id
    }
  
    return {
      forceGrab,
      initialData,
      hasControls,
      isNew,
      isClone,
      isSaveAs,
      isEdit,
    }
  },
  stateVariants: {}  
}

///////////
// MODEL //
///////////

const _setData = (state, data_in) => {
  state.initialData = data_in
  state.trySaveOnNextValidate = false
  state._toGet = (state.forceGrab || state._isPartialEntity(data_in)) && data_in.id
  state._handleSetData(state, data_in, state.hasControls)
}

const _trySaveOnNextValidate = (state) => {
  state.shouldValidate = true
  state.trySaveOnNextValidate = true
  if (state._hasWantsToSaveToken) {
    state.wantsToSaveToken = Math.random() 
  }
  else {
    setControlVal(state)
  }
}

const genericEntityKeys = ['appId', 'saasuserId', 'organizationId']

const getModel = (enitityModelCfg) => {
  const {
    dbEntityType,
    entityMethodsInit,
    buildOutData,
    getControlsCfg,
    handleSetData,
    isPartialEntity,
    hookOverrides = {},
    hooks = {},
    hasWantsToSaveToken,
  } = enitityModelCfg

  return {
    ///////////
    // STATE //
    ///////////
    stateInit(args) {
      let newState = {
        ...objectVariantOrPassthrough(variantsCfg, args),
        dbEntityType,
        _isPartialEntity: isPartialEntity,
        _handleSetData: handleSetData,
        _hasWantsToSaveToken: hasWantsToSaveToken,
        _afterValidateAll: (state) => {
          if (state.trySaveOnNextValidate) {
            state.trySaveOnNextValidate = false
            if (state.isValid) {
              state._toSave = buildOutData(state)

              genericEntityKeys.forEach((key) => {
                const val = state.initialData[key]
                if (val)
                  state._toSave[key] = val
              })
            }
          }
        },
      }
  
      newState.controls = (newState.hasControls && getControlsCfg(newState, newState.isNew, newState.isClone, newState.isSaveAs, newState.isEdit))
      newState.hasControls = Boolean(newState.controls)
  
      _setData(newState, newState.initialData)
  
      initControls(newState)

      // console.log(JSON.stringify(newState, null, 2))
    
      return newState
    },
    /////////////
    // METHODS //
    /////////////
    methodsInit(state) {
      return {
        ...entityMethodsInit(state, { _trySaveOnNextValidate }),
        setData(val, opts) {
          state._toGet = undefined
          _setData(state, val)
        },
        forceGrab(val, opts) {
          state._toGet = state.initialData.id
        },
        // Saving
        _wasSaved(val, opts) {
          state._toSave = undefined
          _setData(state, val)
          const _onSaved = state._onSaved
          if (_onSaved) {
            setTimeout(() => _onSaved(val))
          }
        },
        setOnSaved(val, opts) {
          state._onSaved = val
        },
        trySaveOnNextValidate() {
          _trySaveOnNextValidate(state)
        },
        // Deleting
        setOnDeleted(val, opts) {
          state._onDeleted = val
        },
        _wasDeleted(val, opts) {
          // _setData(state, {})
          // TODO test no callback
          state._toDelete = undefined
          const _onDeleted = state._onDeleted
          if (_onDeleted) {
            const id = state.initialData.id
            setTimeout(() => _onDeleted(id))
          }
        },
        delete(val, opts) {
          state._toDelete = state.initialData.id
        },
        // Archiving
        setOnArchived(val, opts) {
          state._onArchived = val
        },
        _wasArchived(val, opts) {
          // _setData(state, {})
          // TODO test no callback
          const _onArchived = state._onArchived
          if (_onArchived) {
            const id = state.initialData.id
            setTimeout(() => _onArchived(id))
          }
        },
        archive(val, opts) {
          state._toArchive = state.initialData.id
        },
      }
    },
    ///////////
    // HOOKS //
    ///////////
    hooks: {
      ...hooks,
      useDbApi_create: {
        getHookArgs: (state) => ({
          dbEntityType: state.dbEntityType,
          entity: (state.isNew && state._toSave) || undefined,
          ...hookOverrides?.create?.getHookArgs?.(state),
        }),
        callback: (res, methods) => {
          methods._wasSaved(res)
          const customCb = hookOverrides?.create?.callback
          customCb && customCb(res, methods)
        },
        useHook: hookOverrides?.create?.useHook,
      },
      useDbApi_update: {
        getHookArgs: (state) => ({
          dbEntityType: state.dbEntityType,
          entity: (!state.isNew && state._toSave) || undefined,
          initialEntity: state.initialData,
          ...hookOverrides?.update?.getHookArgs?.(state),
        }),
        callback: (res, methods) => {
          methods._wasSaved(res)
          const customCb = hookOverrides?.update?.callback
          customCb && customCb(res, methods)
        },
        useHook: hookOverrides?.update?.useHook,
      },
      useDbApi_getById: {
        getHookArgs: (state) => ({
          dbEntityType: state.dbEntityType,
          id: state._toGet,
          ...hookOverrides?.getById?.getHookArgs?.(state),
        }),
        callback: (res, methods) => {
          methods.setData(res)
          const customCb = hookOverrides?.getById?.callback
          customCb && customCb(res, methods)
        },
        useHook: hookOverrides?.getById?.useHook,
      },
      useDbApi_deleteById: {
        getHookArgs: (state) => ({
          dbEntityType: state.dbEntityType,
          id: state._toDelete,
          ...hookOverrides?.deleteById?.getHookArgs?.(state),
        }),
        callback: (res, methods) => {
          methods._wasDeleted(res)
          const customCb = hookOverrides?.deleteById?.callback
          customCb && customCb(res, methods)
        },
        useHook: hookOverrides?.deleteById?.useHook,
      },
      useDbApi_archiveById: {
        getHookArgs: (state) => ({
          dbEntityType: state.dbEntityType,
          id: state._toArchive,
          ...hookOverrides?.archiveById?.getHookArgs?.(state),
        }),
        callback: (_, methods) => methods._wasArchived(),
        useHook: hookOverrides?.archiveById?.useHook,
      },
    }
  }
}

export default (dbEntityModelCfg) => getModel(dbEntityModelCfg)