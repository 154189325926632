import React from 'react'

import { Grid, Flex } from '../../Base'

import SelectorButton from '../../ControlComps/SelectorButton'
import ControlButton from '../../ControlComps/ControlButton'
import TextField from '../../ControlComps/TextField'

const QueryEditor = React.memo(({ state, methods, ...props }) => {
  const conditionValProps = {
    // height: '100%',
  }

  const DefaultConditionValComp = (
    <TextField
      state={state.controls.conditionVal} 
      methods={methods}
      variant='outlined'
      // style={{paddingRight: '4px'}}
      {...conditionValProps}
    />
  )

  return (
    <Flex
      // height='max-content'
      alignItems='center'
    >
      <Grid
        gridAutoFlow='column'
        gridTemplateColumns='max-content min-content min-content min-content'
        justifyContent='left'
        alignItems='center'
        // border='1px solid #DBDDE0' // TODO theme
        // bgcolor='#ffffff'
        borderRadius='4px'
        gridGap='8px'
        {...state.gridProps}
      >
        <SelectorButton
          state={state.controls.field}
          methods={methods}
          variant='outlined'
          color='primary'
          height='100%'
          fontSize='0.875rem'
        />
        <SelectorButton
          state={state.controls.conditionOp}
          methods={methods}
          variant='outlined'
          color='primary'
          height='100%'
          fontSize='0.875rem'
          // marginRight='8px'
        />
        {
          (state.conditionalValCompResolver && state.conditionalValCompResolver(state, methods, conditionValProps))
            || DefaultConditionValComp
        }
      </Grid>
      <Flex mx='8px' height='32px' alignItems='center'>
        <ControlButton
          state={state.controls.execute}
          methods={methods}
        />
      </Flex>
    </Flex>
  )
})

export default QueryEditor
