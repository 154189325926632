import React from "react"
import AnimateLoading from "../partials/layout/AnimateLoading"
import { Link } from "react-router-dom"
import { toAbsoluteUrl } from "../../_metronic/utils/utils"
import UserProfile from "../partials/layout/UserProfile"

import { Box, Flex, } from "../components/Base"
import useApi from "../lib/useApi"

const Header = ({organization}) => {
  const api = useApi()
  const defaultLogo = toAbsoluteUrl("/media/zhortech/footprints-black-large.png")
  const [logo, setLogo] = React.useState(defaultLogo)
  const [orientation, setOrientation] = React.useState()

  React.useEffect(() => {
    let handleSetLogo = (data) => {
      getOrientation(data)
      setLogo(data)
    }
    if (organization?.hasLogo) {
      api({
        method: "get",
        url: `/api/Organizations/${organization.id}/logo`,
        handler: (data) => {
          handleSetLogo(data.uri)
        },
      })
    }
    // else if (!organization.isParent && !organization?.hasLogo && organization.organization?.hasLogo) {
    //   api({
    //     method: "get",
    //     url: `/api/Organizations/${organization.organization.id}/logo`,
    //     handler: (data) => {
    //       handleSetLogo(data.uri)
    //     },
    //   })
    // }
    else {
      handleSetLogo(defaultLogo)
    }
  }, [api, defaultLogo, organization])

  const getOrientation = (src) => {
    let img = new Image()
    img.src = src
    img.onload = () => {
      if (img.width > img.height) {
        setOrientation("landscape")
      }
      else {
        setOrientation("portrait")
      }
    }
  }

  return(
    <Flex
      height='58px'
      bgcolor='bg.tertiary'
    >
      <AnimateLoading />
      <Flex
        width='100%'
        alignItems='center'
        justifyContent='space-between'
        px='32px'
      >
        <Box>
          <Link to="/orgs/account">
            <Flex
              mr='24px'
              maxWidth={ orientation === 'portrait' ? '40px' : '128px'}
              maxHeight={'40px'}
            >
              <img
                id='logo'
                alt={organization?.name}
                src={ logo }
                width='100%'
                style={{fontSize: '12px', objectFit: 'contain'}}
              />
            </Flex>
          </Link>
        </Box>
        <Flex alignItems='center'>
          <UserProfile showBadge={true} />
        </Flex>
      </Flex>
      {/* <Text
        variant='small3'
        pr='16px'
        color='text.secondary'
      >
        { organization ? organization.name : '' }{ app?.organization ? `  /  ${app.organization.name}` : '' }{ app ? `  /  ${app.name}` : '' }
      </Text>
      <Text
        variant='subtitle2'
        pr='16px'
        color='text.primary'
      >
        { pageTitle }
      </Text> */}
    </Flex>
  )
}

export default Header


