/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react"
import Dropdown from "react-bootstrap/Dropdown"
import { useSelector } from "react-redux"
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle"
import { useHistory } from "react-router-dom"
import useService from '../../lib/useService'
import { Box, Flex, Text } from '../../components/Base'

//class UserProfile extends React.Component {

const UserProfile = ({showHi, showAvatar, showBadge}) => {
  const history = useHistory()
  const service = useService()
  const user = useSelector(store => store.auth.user)
  
  const logout = () => {
    service.logout().then(() => {
      history.push('/auth')
    })
  }

  // const goToAccount = () => {
  //   history.push('/account');
  // }

  const userName = user && user.name
  const userNameChar = (userName && user.name[0]) || '???'

    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">
            {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile">
                Hi,
              </span>
            )}

            {showHi && (
              <span className="kt-header__topbar-username kt-hidden-mobile">
                {userName}
              </span>
            )}

            {showAvatar && <img alt="Pic" src={user && user.pic} />}
            
            {/* TODO: Should get from currentUser */}
            {showBadge && (
              <Flex
                justifyContent='center'
                alignItems='center'
                minWidth='32px'
                height='32px'
                borderRadius='50%'
                bgcolor='#006F9610'
                cursor='pointer'
              >
                <Text
                  variant='initials'
                  color='primary.main'
                  // borderRadius='50%'
                  // bgcolor='#006F9610'
                  // width='40px'
                  // p='11px 0px'
                  // textAlign='center'
                  // cursor='pointer'
                >
                  {userNameChar}
                </Text>
              </Flex>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <Flex
            flexDirection='row'
            alignItems='center'
            padding='16px'
            bgcolor='primary.main'
            borderRadius='8px 8px 0px 0px'
          >
            <Box width='40px' mr='16px'>
              <Text
                variant='initials'
                color='white'
                borderRadius='50%'
                bgcolor='primary.light'
                p='11px 0px'
                textAlign='center'
              >
                {/* TODO: Should get from currentUser */}
                {userNameChar}
              </Text>
            </Box>
            <Text variant='subtitle2' color='white'>{userName || 'Unknown'}</Text>
          </Flex>
          <div className="kt-notification">
            <a className="kt-notification__item" onClick={logout}>
              <div className="kt-notification__item-details">
                <Text variant='subtitle2' color='text.secondary'>
                  Sign Out
                </Text>
              </div>
            </a>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
}

export default UserProfile
