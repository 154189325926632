import { setControlVal } from '../../../lib/state'

const initEntityModel = (entityCfg) => {
  return {
    dbEntityType: entityCfg.dbEntityType,
    entityMethodsInit(state) {
      return {
        setPLACEHOLDER(val, opts) {
          setControlVal(state, state.controls.PLACEHOLDER, val)
        },
      }
    },
    getControlsCfg(state, isNew, isClone, isSaveAs) {
      return {
        PLACEHOLDER: {
          methodKey: 'setPLACEHOLDER',
          _validator: (val) => {
            return (val && val !== '')
          },
          _validateAllowNotChanged: false,
        },
      }
    },
    isPartialEntity(data) {
      return !data.PLACEHOLDER
    },
    handleSetData(state, data, hasControls) {
      if (hasControls) {
        state.controls.PLACEHOLDER.defaultVal = data.PLACEHOLDER || ''
      }
    },
    buildOutData(state) {
      let ret = {
        PLACEHOLDER: state.controls.PLACEHOLDER.val,
      }
      
      return ret
    },
  }
}

export default initEntityModel