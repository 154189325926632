import React from 'react'

import useModel from './model'

import { Box, Flex, Grid, Text } from '../../../Base'
import ControlButton from '../../../ControlComps/ControlButton'

import QueryItem from './QueryItem'

import { SendOutlined } from '@material-ui/icons'

import { range } from 'lodash'

const Comp = React.memo(({ stateCfg, shouldValidate, onBuiltVal, Title, showValidationHint = true, ...props }) => {
  const [ state, methods ] = useModel(stateCfg, shouldValidate, onBuiltVal)

  return (
    <>
      <Flex alignItems='center' mb='16px' justifyContent='space-between'>
        { Title }
        <Flex alignItems='center'>
          {
            state._testRes && (
              <Text variant='small1' pr='8px'>
                {`${state._testRes.length} users found`}
              </Text>
            )
          }
          <ControlButton
            variant='outlinedSmall'
            color='secondary'
            methods={methods}
            methodKey={'testAudience'}
            text='Test'
            val={state.otherControls.test}
            icon={SendOutlined}
          />
        </Flex>
      </Flex>
      {
        state.validationhint && showValidationHint &&
          <Text
            variant='small3'
            color={'error.main'}
            mb='16px'
          >
            {state.validationhint}
          </Text>
      }
      <Grid
        gridRowGap='24px'
      >
        {
          range(6).map((i) => {
            
            return (
              <Box
                key={i}
                hidden={(!state.controls[i].val.searchType) && i > state.firstQueryItemWithoutSearchType}
              >
                <QueryItem
                  stateCfg={state.controls[i].val}
                  shouldValidate={state.shouldValidate}
                  onBuiltVal={methods.setQueryItem}
                  onBuiltValOpts={i}
                  isFirst={i === 0}
                />
              </Box>
            )
          })
        }
      </Grid>
    </>
  )
})

export default Comp