import React from 'react'

import { fade } from '@material-ui/core/styles/colorManipulator'
import { useTheme } from '@material-ui/core/styles'

import { Box, Text } from '../Base'

// Is a 'hybrid' comp
//TODO can we use bgcolor here and grab from the theme automatically 'in-between', if not maybe with StyledComponents, or Styled System?
export const SwitchToggleTextVertical = React.memo((props) => {
  const {
    methodKey,
    methodOpts,
    methods,
    id,
    ...nonModelProps
  } = props

  let {
    val,
    items,
    onClick,
    ...nonStateProps
  } = nonModelProps

  let {
    colorMain,
    colorSecondary = '#ffffff',
    colorHover,
    color,
    ...nonStyleProps
  } = nonStateProps

  // Theme
  const theme = useTheme()
  const themePaletteColor = theme.palette[color]
  if (themePaletteColor) {
    colorMain = themePaletteColor.main
    colorSecondary = themePaletteColor.contrastText
    colorHover = themePaletteColor.dark
  }

  colorHover = colorHover || colorMain
  //

  if (methods) {
    onClick = (newVal) => methods[methodKey](newVal, methodOpts, id)
  }

  if (!val) {
    const firstItem = items[0]
    val = firstItem.val || firstItem.label 
  }

  const isFirstSelected = items.findIndex((item) => (item.val || item.label) === val) === 0

  const _onClick = () => {
    const newItem = items.find((item) => (item.val || item.label) !== val)
    const newVal = newItem.val || newItem.label
    onClick(newVal, methodOpts, id)
  }

  //// TODO nix the bgimage usage and just do two boxes
  const dir = isFirstSelected ? '180deg' : '0deg'
  const bgimage = `linear-gradient(${dir}, transparent 50%, ${colorSecondary} 50%)`
  const bgimage_hover = `linear-gradient(${dir}, transparent 50%, ${fade(colorSecondary, 1- 0.12)} 50%)`
  const textComps = [
    (props) => <Text variant='small2' textAlign='center' pt='5px' pb='3px' color={colorSecondary}  {...props}/>,
    (props) => <Text variant='small2' textAlign='center' pt='3px' pb='5px' color={colorMain} color_hover={colorHover} {...props}/>,
  ]
  const TextCompA = textComps[isFirstSelected ? 0 : 1]
  const TextCompB = textComps[isFirstSelected ? 1 : 0]
  ////

  return (
    <Box
      mr='8px'
      border='1px solid'
      borderColor={colorMain}
      borderColor_hover={colorHover}
      borderRadius='8px'
      p='12px'
      py='0px'
      bgimage={bgimage}
      bgimage_hover={bgimage_hover}
      cursor='pointer'
      onClick={_onClick}
      bgcolor={colorMain}
      bgcolor_hover={colorHover}
      {...nonStyleProps}
    >
      <TextCompA>
        {items[0].label || items[0].val}
      </TextCompA>
      {/* <Box
        minHeight='12px'
      /> */}
      <TextCompB>
        {items[1].label || items[1].val}
      </TextCompB>
    </Box>
  )
})