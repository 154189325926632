import React from 'react';
import ReactInterval from 'react-interval';

import Amplify, { Auth } from 'aws-amplify';
import axios from "axios";
import * as auth from '../store/ducks/auth.duck';
import {useSelector, useDispatch} from 'react-redux';

import ServiceContext from './ServiceContext';

import Alert from '../components/Alert';
import Notification from '../components/Notification';
import NewVersionNotification from '../components/NewVersionNotification';
import PageLoading from '../components/PageLoading';

const ME_URL = "api/SaaSUsers/me";

const getEnv = (e) => {
  // This works only because create-react-app does some magic with variables in the build environment
  // that begin with REACT_APP_.  These are storing in the bundle, so don't include any secrets.
  return process.env[`REACT_APP_${e}`];
}

const ServiceProvider = ({children}) => {
  const serviceContext = React.useRef(null);
  const dispatch = useDispatch();

  const loggedInUser = useSelector(store => store.auth.user);

  // For a global Alert dialog
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertSize, setAlertSize] = React.useState("sm");
  const [alertTitle, setAlertTitle] = React.useState("Alert");
  const [alertMessage, setAlertMessage] = React.useState("");

  // For a global notification snackbar
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const [notifySeverity, setNotifySeverity] = React.useState("success");
  const [notifyMessage, setNotifyMessage] = React.useState("");

  // For a pageloader spinner over the entire app
  const [isPageLoading, setIsPageLoading] = React.useState(false);

  // For the "new portal version available" dialog
  const [versionOpen, setVersionOpen] = React.useState(false);
  const [enableVersionCheck, setEnableVersionCheck] = React.useState(false);

  // helper to call _call()
  const api = {
    get: (ep, args, opts) => { return _call('get', ep, { filter: JSON.stringify(args) }, null, opts); },
    getUnfiltered: (ep, args, opts) => { return _call('get', ep, args, null, opts); },
    post: (ep, args, opts) => {return _call( 'post', ep, null, args, opts); },
    remove: (ep, args, opts) => {return _call( 'delete', ep, args, null, opts); },
    patch: (ep, args, opts) => {return _call( 'patch', ep, null, args, opts); },
    put: (ep, args, opts) => {return _call( 'put', ep, null, args, opts); },
    head: (ep, args, opts) => {return _call( 'head', ep, null, null, opts); },
  };

  // Version check logic
  //
  // Only run the periodic version checker when the app is active.  If the
  // window is not in focus (sleep, wake) or the user is on another tab (visible)
  // then suspend the version check, so we don't have millions of checks in the
  // logs!
  //

  const visible = () => {
    //console.log(`visible: setting enable to ${document.hidden ? false : true}`);
    setEnableVersionCheck(document.hidden ? false : true);
  }

  const sleep = () => {
    //console.log('sleep: setting enable to false');
    setEnableVersionCheck(false);
  }

  React.useEffect(() => {
    // Initialize Amplify and Axios
    let opts = {
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      identityPoolId: getEnv("COGNITO_IDENTITY_POOL_ID"),
      region: getEnv("COGNITO_REGION"),
      userPoolId: getEnv("COGNITO_USER_POOL_ID"),
      userPoolWebClientId: getEnv("COGNITO_USER_POOL_WEB_CLIENT_ID"),
    };
    Amplify.configure({ Auth: opts });

    // Set up a request interceptor that will refresh the user token if
    // required and add the JWT access token to the request headers.

    function retry(fn, retriesLeft = 5, interval = 1000) {
      return new Promise((resolve, reject) => {
        fn().then(resolve).catch((err) => {
          if ( err === 'No current user' ) return reject(new Error(err));
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(err);
            return;
          }
          setTimeout(() => {
            // Passing on "reject" is the important part
            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
      });
    }

    axios.interceptors.request.use(
      (config) => {
        return retry(Auth.currentSession.bind(Auth)).then((session) => {
          let token = session.idToken.getJwtToken();
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        });
      },
      (err) => {
        console.log( 'Axios request interceptor:', err.message );
        if (err.message === 'No current user') {
          dispatch(auth.actions.logout());
          console.log( 'user signed out' );
        }
        return Promise.reject(err);
      }
    );
    axios.defaults.baseURL = getEnv("ZCLOUD_URL");

    // Had to move walke() here to avoid useEffect() dependency hell
    const wake = () => {
      if ( ! loggedInUser ) {
        return;
      }
      api.post("/api/PortalVersions/check", {env: process.env.REACT_APP_BRANCH, version: process.env.REACT_APP_VERSION}).then((data) => {
        if ( data.uptodate ) return;
        setVersionOpen(true);
      }).catch((err) => {
        // not much we can do about this...
        console.log('portal version check error:', err.message);
      }).finally(() => {
        //console.log('wake: setting enable to true');
        setEnableVersionCheck(true);
      });
    }

    // Install event handlers to support portal version checks
    document.addEventListener('visibilitychange', visible);
    document.addEventListener('blur', sleep);
    window.addEventListener('blur', sleep);
    window.addEventListener('focus', wake);
    document.addEventListener('focus', wake);

    // Remove the event handlers when unmounting
    return () => {
      window.removeEventListener('blur', sleep);
      document.removeEventListener('blur', sleep);
      window.removeEventListener('focus', wake);
      document.removeEventListener('focus', wake);
      document.removeEventListener('visibilitychange', visible);
    }
  }, [api, loggedInUser, dispatch]);

  // EO version check support

  const alertShow = React.useCallback((title, message, size) => {
    setAlertTitle(title);
    if (message) setAlertMessage(message);
    if (size) setAlertSize(size);
    setAlertOpen(true);
  }, [] );

  const alertHide = React.useCallback(() => {
    setAlertOpen(false);
  }, [] );

  const notifyShow = React.useCallback((severity, message) => {
    setNotifySeverity(severity);
    if (message) setNotifyMessage(message);
    setNotifyOpen(true);
  }, [] );

  const notifyHide = React.useCallback((severity, message) => {
    setNotifyOpen(false);
  }, [] );

  const pageLoading = React.useCallback((isLoading) => {
    setIsPageLoading(isLoading);
  }, [] );

  const versionShow = React.useCallback((show) => {
    setVersionOpen(show);
  }, [] );

  const versionHide = React.useCallback((show) => {
    setVersionOpen(false);
  }, [] );

  /* BACKEND API SUPPORT ***********************************************************************/

  const displayError = (name, message) => {
    if ( ! message ) message = 'unknown error';
    else if ( typeof message !== 'string' ) message = message.toString();
    alertShow(name, message);
  }

  const displayVersionUpdateDialog = (data) => {
    versionShow(true);
  }

  const login = (username, password) => {
    return Auth.signIn({ username, password }).then((user) => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') return user; // Will trigger the login page to bouce to the challenge page
      // _user = user; // save the Cognito user in memory
      return api.get( ME_URL, null, {handleErrorMessage: false} ).then((dbuser) => {
        return dbuser;
      });
    });
  }

  const loggedIn = () => {
    api.post("/api/SaaSUsers/loggedIn", {}).then((user) => {
      console.log( 'logged in', user );
    }).catch((err) => {
      console.log( 'error notifying the backend of a login:', err );
    });
  }

  const logout = () => {
    return Auth.signOut().then(() => {
      dispatch(auth.actions.logout());
      console.log( 'user signed out' );
    });
  }

  const versionCheck = (now) => {
    // now can be used to force the version check, even if the redux store does not
    // yet have the user stored.  This case happens during login.
    if ( ! loggedInUser && ! now ) {
      //console.log( 'version check cancelled, no logged in user');
      return;
    }
    api.post("/api/PortalVersions/check", {env: process.env.REACT_APP_BRANCH, version: process.env.REACT_APP_VERSION}).then((data) => {
      if ( data.uptodate ) return;
      displayVersionUpdateDialog(data);
    }).catch((err) => {
      // not much we can do about this...
      console.log('portal version check error:', err.message);
    });
  }

  const signout = () => {
    return Auth.signOut();
  }

  const verifySession = () => {
    return Auth.currentAuthenticatedUser().catch((err) => {
      return logout();
    });
  }

  const getAuthenticatedUser = () => {
    return Auth.currentAuthenticatedUser().then((user) => {
      return user.attributes;
    });
  }

  // This will return the SaaS user from the backend based on the JWT token of the
  // currently authenticated user.
  const getSaasUser = (profile) => {
    return api.get( ME_URL, {include: [{"organization": "apps"}]}, {handleErrorMessage: false, handle403: true, handle401: true} ).then((dbuser) => {
      return {
        ...profile,
        ...dbuser
      };
    });
  }

  const initiateForgotPassword = (email) => {
    return Auth.forgotPassword(email);
  }

  const completeForgotPassword = (email, code, password) => {
    return Auth.forgotPasswordSubmit(email, code, password);
  }

  function _handleError(err, opts, url) {
    if ( err === "No current user" ) {
      //
      // This used to be a problem on logout ... any page mounted before logout would have their useEffect()
      // re-executed.  Logout was fairly convoluted with two re-directs and some useEffect of its own to sign out
      // of Amplify and nuke the user in redux.  Now it happens directly in src/app/partials/layout/UserProfile without
      // using a /logout route and a LogoutPage.  So that problem has dissapeared, but I am keeping this check in
      // anyway, just in case there are other situations.
      //
      console.log( `Warning!  Backend call after Amplify logout, to ${url}` );
      return;
    }
    if ( err.response && err.response.status === 403 ) {
      // The session has probably expired
      if ( opts.handle403 ) throw(err);
      else logout();
    }
    else if ( err.response && err.response.status === 401 ) {
      // The session has probably expired
      if ( opts.handle401 ) throw(err);
      else logout();
    }
    else {
      let message;
      let name;
      if ( ! err.response ) {
        message = err.message;
        name = 'Comm Error';
        if ( message === 'Refresh Token has expired' ) return logout();
      }
      else if ( err.response.data instanceof Object && err.response.data.error instanceof Object ) {
        message = err.response.data.error.message; // Loopback error message
        name = err.response.data.error.name || 'General Error';
      }
      else {
        message = err.response.data;
        name = 'Server Error';
      }
      if (opts.handleErrorMessage) {
        if ( name === 'Server Error' && process.env.REACT_APP_BRANCH === 'prod' )
          displayError("We're Sorry", "Something unexpected happened on our servers.  We are looking into it now!");
        else
          displayError(name, message);
      }

      if (opts.rethrow === false) {
        return
      }

      throw(err);
    }
  }

  function _call(method, url, params, data, opts) {
    opts = opts || {};
    let axiosOpts = {
      baseURL: getEnv("ZCLOUD_URL"),
      method,
      url,
      params,
      data,
      paramsSerializer: (params) => {
        // Axios encodes spaces as '+' by default, but somehow this '+' is
        // then being encoded again to %2B, and so on the server the %2B becomes a '+'
        // and not the space we were expecting.  This function overrides this to encode
        // space into %20
        let result = '';
        Object.keys(params).forEach(key => {
          if (params[key] !== null && params[key] !== undefined)
            result += `${key}=${encodeURIComponent(params[key])}&`;
        });
        return result.substring(0, result.length - 1);
      },
      ...opts,
    };
    console.log( axiosOpts.baseURL, axiosOpts.url );
    return axios(axiosOpts).then((res) => {
      return res.data;
    }).catch((err) => {
      _handleError(err, opts, axiosOpts.url)
    });
  }

  /* A hook interface for calling the backend api using functions defined above */
  /*
     A different version, for components that do not use the return data in its raw form.  The
     caller is expected to pass in a handler for the data in a successful response.  The caller
     can also optionally pass handlers for isLoading(true/false) and errorHandler(err) if it needs
     to handle those operations itself (otherwise the page loader will be invoked, and/or the service
     library will pop up an error dialog).

     const fetchData = useServiceHandler({});

     fetchData({
       method: "post",
       url,
       handler: (data) => {
         setMyComponentState(...);
       },
       isLoading: (loading) => {
         setLoading(loading);
       },
       errorHandler: (err) => {
         setDialogMessage(err.message);
         setOpenErrorDialog(true);
       }
     });
   */
  // TODO rename isLoading to setIsLoading
  const callServiceHandler = (request) => {
    let cancelled = false;
    if ( ! (request && request.url) ) return;

    let requestParams;
    let requestData;

    let requestOptions = {
      ...request.opts
    };

    let serviceOptions = {
      handleErrorMessage: (request.errorHandler === undefined ? true : false),
      handle403: request.opts ? request.opts.handle403 : undefined,
      handle401: request.opts ? request.opts.handle401 : undefined,
    };

    // For binary file downloads
    if ( request.opts && request.opts.responseType ) serviceOptions.responseType = request.opts.responseType;

    switch(request.method) {
      case "get":
      case "delete":
        requestParams = request.data;
        break;
      case "getFiltered":
        requestParams = { filter: JSON.stringify(request.data) };
        request.method = "get";
        break;
      default:
        requestData = request.data;
    }

    // Can simulate a delay to test page loaders
    const delay = requestOptions.simulatedDelay || 0;

    if ( request.isLoading ) request.isLoading(true);
    else pageLoading(true);

    setTimeout(() => {
      let prom

      if (Array.isArray(request.url)) {
        prom = Promise.all(request.url.map((url, i) => {
            return _call(request.method, url, requestParams, request.datas?.[i] || requestData, serviceOptions)
          }))
          .then((resArr) => {
            return request.multiResTransformer?.(resArr) || resArr
          })
      }
      else if (request.datas) {
        prom = Promise.all(request.datas.map((data) => {
            return _call(request.method, request.url, requestParams, data, serviceOptions)
          }))
          .then((resArr) => {
            return request.multiResTransformer?.(resArr) || resArr
          })
      }
      else {
        prom = _call(request.method, request.url, requestParams, requestData, serviceOptions)
      }

      prom.then((result) => {
        if ( ! cancelled ) {
          request.handler(result);
        }
      })
      .catch((err) => {
        if ( cancelled ) return;
        if ( request.errorHandler ) {
          try {
            request.errorHandler(err);
          }
          catch (_err) {
            console.error('Error occured inside of errorHandler: ', _err)
            _handleError(err, {
              handleErrorMessage: true,
              rethrow: false,
            })
          }
        }
      })
      .finally(() => {
        if ( ! request.isLoading ) pageLoading(false);
        if ( cancelled ) return;
        if ( request.isLoading ) request.isLoading(false);
      });
    }, delay );

    return () => {
      cancelled = true;
    }
  }

  const useServiceHandler = (initialRequest) => {
    const [request, setRequest] = React.useState(initialRequest);

    React.useEffect(() => {
      return callServiceHandler(request)
    }, [request] );

    return setRequest;
  }

  /* END-OF BACKEND API SUPPORT ***********************************************************************/

  serviceContext.current = {
    useServiceHandler,
    callServiceHandler,

    alert: alertShow,
    notify: notifyShow,
    pageLoading,
    versionShow,

    login,
    loggedIn,
    versionCheck,
    signout,
    logout,
    verifySession,
    getAuthenticatedUser,
    getSaasUser,
    initiateForgotPassword,
    completeForgotPassword,
  };

  return(
    <ServiceContext.Provider value={serviceContext}>
      {children}
      <Alert open={alertOpen} onClose={alertHide} size={alertSize} title={alertTitle} message={alertMessage} />
      <Notification open={notifyOpen} onClose={notifyHide} severity={notifySeverity} message={notifyMessage} />
      <PageLoading busy={isPageLoading} />
      <NewVersionNotification open={versionOpen} onClose={versionHide} />
      <ReactInterval
          timeout={5*60*1000}
          enabled={enableVersionCheck}
          callback={() => versionCheck()}
      />
    </ServiceContext.Provider>
  );
}

export default ServiceProvider;
