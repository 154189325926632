import React from 'react'

import { NestedTabsWithRoot } from '../TabbedRoutes'

import { EntityAttributesViewSimple, EntityRelationViewSimple } from './util'

import MaterialTableWrapper, { MaterialTableWrapperWithRoutedId } from '../Tables/MaterialTableWrapper'
import ControlledChartRouted from '../Charting/ControlledChartRouted'

import * as dbEntityCfgs from './allDbEntities'

const ExploreDbEntity = ({ dbEntityType, entity, basePath = '' }) => {
  const cfg = React.useMemo(() => {
    const dbEntityCfg = dbEntityCfgs[dbEntityType]
    const exploreCfg = dbEntityCfg.explore

    let root = {
      path: `${basePath}/${dbEntityType}`,
    }

    // Root
    if (exploreCfg.root.lastEventsTable) {
      root = {
        ...root,
        Comp: MaterialTableWrapper,
        props: {
          tableModelOpts: {
            variant: 'entitesThatHaveEvents',
            variantOpts: {
              primaryEntityType: dbEntityType,
              connectedEntityType: exploreCfg.root.lastEventsTable.connectedEntityType,
              includeAppName: false,
              // general
              showSearch: true,
              showCohortDropdown: exploreCfg.root.lastEventsTable.showCohortDropdown,
              showDynamicKeysToggle: true,
              showQueryBuilder: true,
              showCount: true,
              exportOpts: {
                exportButton: true,
                exportFileName: dbEntityType,
              },
              dataOpts: {
                showDynamicKeys: false,
              },
            }
          },
        }
      }
    }
    else {
      root = {
        ...root,
        Comp: (exploreCfg.root.compKey && dbEntityCfg.ContentComps[exploreCfg.root.compKey]) || exploreCfg.root.Comp,
        props: {
          ...exploreCfg.root.props,
          dbEntityType,
        }
      }
    }

    // Nested
    const nestedBasePath = `${basePath}/${dbEntityType}/:id`
    let tabs = []

    if (exploreCfg.profile) {
      tabs.push({
        label: 'Profile',
        path: `${nestedBasePath}/profile`,
        Comp: dbEntityCfg.ContentComps.Profile || EntityAttributesViewSimple,
        props: {
          dbEntityType,
          entity,
          // TODO id is injected
        },
      })
    }

    if (exploreCfg.activities) {
      if (exploreCfg.activities.chart) {
        tabs.push({
          label: 'Activity Analysis',
          path: `${nestedBasePath}/activities/chart`,
          Comp: ControlledChartRouted,
          props: {
            chartVariant: 'entityActivities',
            dbEntityType,
            entity,
            // TODO id is injected
          },
        })
      }
      if (exploreCfg.activities.table) {
        tabs.push({
          label: 'Activity Data',
          path: `${nestedBasePath}/activities/table`,
          Comp: MaterialTableWrapperWithRoutedId,
          props: {
            tableModelOpts: {
              variant: 'entityActivities',
              variantOpts: {
                // TODO id is injected
                entityType: dbEntityType,
                connectedEntityType: exploreCfg.root.lastEventsTable.connectedEntityType, // TODO seperate table cfg
                showRowManagementActions: true,
                // general
                showSearch: true,
                showDateRangePicker: true,
                exportOpts: {
                  exportButton: true,
                },
              }
            },
            entity,
          }
        })
      }
    }

    if (exploreCfg.chunks) {
      if (exploreCfg.chunks.table) {
        tabs.push({
          label: 'Chunk Data',
          path: `${nestedBasePath}/chunks/table`,
          Comp: MaterialTableWrapperWithRoutedId,
          props: {
            tableModelOpts: {
              variant: 'entityChunks',
              variantOpts: {
                // TODO id is injected
                entityType: dbEntityType,
                connectedEntityType: exploreCfg.root.lastEventsTable.connectedEntityType, // TODO seperate table cfg
                showRowManagementActions: true,
                // general
                showSearch: true,
                showDateRangePicker: true,
                exportOpts: {
                  exportButton: true,
                },
              }
            },
            entity,
          }
        })
      }
    }

    

    if (exploreCfg.otherTabs) {
      exploreCfg.otherTabs.forEach((item) => {
        tabs.push({
          label: item.label,
          path: `${nestedBasePath}/${item.urlSuffix}`,
          Comp: (item.compKey && dbEntityCfg.ContentComps[item.compKey]) || item.Comp,
          props: {
            ...item.props,
            dbEntityType,
            entity,
            // TODO id is injected
          },
        })
      })
    }

    if (exploreCfg.relations) {
      Object.entries(exploreCfg.relations).forEach(([key, val]) => {
        const relationDbEntityCfg = dbEntityCfgs[key]
        let label = null

        if (dbEntityCfg.relations.belongsTo[key] || dbEntityCfg.relations.hasOne[key]) {
          label = relationDbEntityCfg.label
        }
        else if (dbEntityCfg.relations.hasMany[key]) {
          label = relationDbEntityCfg.labelPlural
        }
 
        tabs.push({
          label,
          path: `${nestedBasePath}/${key}`,
          Comp: (val.compKey && relationDbEntityCfg.ContentComps[val.compKey]) || EntityRelationViewSimple,
          props: {
            dbEntityType,
            entity,
            relation: key,
            // TODO id is injected
          },
        })
      })
    }

    // Ret
    return {
      root,
      nested: tabs,
      nestedBasePath,
    }
  }, [basePath, dbEntityType, entity])

  return(
    <NestedTabsWithRoot {...cfg}/>
  )
}

export default ExploreDbEntity