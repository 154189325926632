import React from 'react'

import ActivitiesExporters from '../data'
import Webhooks from '../webhooks'
import { Redirect, Route, Switch } from 'react-router-dom'

const Tools = () => {

  return (
    <Switch>
      <Route path='/tools/activitydata' component={ActivitiesExporters} />
      <Route path='/tools/webhooks' component={Webhooks}/>
      <Redirect to='/tools/activitydata'/>
    </Switch>
  )
}

export default Tools