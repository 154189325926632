import React from "react";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from '../lib/useStyles'

const PageLoading = ({busy}) => {
  const classes = useStyles();
  
  return(
    <Backdrop style={{zIndex:9100}} open={busy}>
      <CircularProgress
        className={classes.circularProgress}
        color='primary'
      />
    </Backdrop>
  );
}

export default PageLoading;
