import React from 'react'

import deepMerge from '../../lib/deepMerge'

import { useDeepCompareRef, useApi_OrgId_AppId_AppIds } from '../../lib/hooks'
import { useParams } from 'react-router-dom'

import MaterialTable from 'material-table'
import { Box, Flex, Card, Text } from '../Base'
import ToggleButton from '../ControlComps/ToggleButton'

import tableModel from './TableModel'

import QueryBuilder from '../../components/Query/Builder/TreeBuilder'

import { PresetDateRangePicker2 } from '../../components/PresetDateRangePicker'
import { CohortDropdown } from '../DbEntities/OtherComps/EntityDropdown'

const emptyObj = {}
const MaterialTableMemo = React.memo(MaterialTable)
const MaterialTableWrapper = React.memo(({ reqData: externalReqData = emptyObj, tableModelOpts, onReqDataChanged, onHasDataChanged, items, ...props }) => {
  const [ onActionCompletedToken, setOnActionCompletedtoken ] = React.useState(null)

  tableModelOpts = {
    ...tableModelOpts,
    variant: tableModelOpts.variant,
    variantOpts: {
      ...tableModelOpts.variantOpts,
      onActionCompleted: React.useCallback((modifiesState) => {
        if (modifiesState === false) return
        setOnActionCompletedtoken(Math.random())
      }, []),
      // TODO_MAYBE onDeleted and remove item by id instead of refreshing
    }
  }

  const [ state, methods ] = tableModel(tableModelOpts)
  let {
    // Our state
    useApiAndUrl,
    baseReqData,
    isLoading,
    title,
    titlePrefix,
    titleSuffix,
    showTitle,
    reqData = {},
    hasOtherComps,
    showCohortDropdown,
    showDateRangePicker,
    showDynamicKeysToggle,
    showQueryBuilder,
    showCount,
    dateRangePickerOpts,
    builderOpts,
    dataOpts,
    detailPanel,
    DetailPanelComp,
    getDetailPanelProps,
    //
    reqDataTransformer,
    //
    // MUIT only
    icons,
    data,
    columns,
    optsMUIT,
    components,
    actions,
    hasData,
  } = state

  optsMUIT.exportCsv = (columns, data) => {
    methods.export({ columns, data })
  }
  optsMUIT.columnsButton = true

  const [ api, url ] = (useApiAndUrl && useApiAndUrl()) || [ null, null ]
  // eslint-disable-next-line no-unused-vars
  const [ _, __, appId, appIds] = useApi_OrgId_AppId_AppIds()

  const finalReqData = useDeepCompareRef([baseReqData, reqData, externalReqData]).current

  React.useEffect(() => {
    if (!url) return

    let reqData = deepMerge(...finalReqData)

    // TODO handle in a variant?
    if (reqData?.cohort) {
      // TODO handle reqDataTransformer (start and end)
      if (onReqDataChanged) {
        onReqDataChanged(reqData)
      }
      api({
        method: 'post',
        url: `/saas/v1/Cohorts/v1/queryWithEvents`,
        data: {
          appId,
          appIds,
          query: reqData.cohort.query,
          idsOnly: false,
        },
        isLoading: methods.setIsLoading,
        multiResTransformer: (res) => res.flat(),
        handler: (res) => {
          res.forEach((o) => {
            o.id = o.id || o._id
          })
          methods.setData(Object.freeze(res)) 
        }
      })
      return
    }

    if (reqDataTransformer) {
      reqData = reqDataTransformer(reqData)
    }

    if (onReqDataChanged) {
      onReqDataChanged(reqData)
    }

    if (!reqData) return

    api({
      method: 'getFiltered',
      url,
      data: reqData,
      isLoading: methods.setIsLoading,
      multiResTransformer: (res) => res.flat(),
      handler: (res) => methods.setData(Object.freeze(res)) 
    })
  }, [
    appId,
    appIds,
    api,
    url,
    finalReqData,
    methods,
    reqDataTransformer,
    onActionCompletedToken,
    onReqDataChanged,
  ])

  React.useEffect(() => {
    if (items) {
      methods.setIsLoading(false)
      methods.setData(deepMerge(items))
    }
  }, [ methods, items ])

  React.useEffect(() => {
    if (onHasDataChanged) {
      onHasDataChanged(hasData)
    }
  }, [ onHasDataChanged, hasData ])

  detailPanel = React.useMemo(() => {
    if (detailPanel) return detailPanel

    if (DetailPanelComp || getDetailPanelProps) {
      const Comp = DetailPanelComp || MaterialTableWrapper
      return (item) => <Comp {...getDetailPanelProps(item)}/>
    }

    return undefined
  }, [
    detailPanel,
    DetailPanelComp,
    getDetailPanelProps,
  ])

  const _title = [
    titlePrefix,
    title,
    titleSuffix,
  ].filter((str) => str).join(' ')

  if (!hasOtherComps) {
    return (
      <MaterialTableMemo
        isLoading={isLoading}
        columns={columns}
        data={data}
        options={optsMUIT}
        icons={icons}
        title={_title}
        style={{ overflowX: 'auto', border: '1px solid #e8e8e8', ...props.style }}
        detailPanel={detailPanel}
        components={components}
        actions={actions}
        {...props}
      />
    )
  } else {
    return (
      <Card
        // To look like other tables
        variant='raisedBordered'
        p='0px' pt='16px'
        {...props}
      >
        <Box
          // To look like other tables
          pl='16px' pr='8px'
        >
          <Flex
            justifyContent='space-between'
            mb='8px'
            alignItems='center'
            height='40px'
          >
            <Flex
              alignItems='center'
            >
              <Flex alignItems='baseline'>
                {
                  showTitle && (
                    <Text
                      variant='h6'
                      // To look like other tables
                      fontWeight='500'
                    >
                      {_title}
                    </Text>
                  )
                }
                {
                  showCount && (
                    <Text
                      variant='small2'
                      color='text.primary'
                      ml='12px'
                    >
                      {`${data.length} total`}
                    </Text>
                  )
                }
              </Flex>
              {
                showCohortDropdown && (
                  <Box
                    // mr='16px'
                    ml='16px'
                  >
                    <CohortDropdown
                      setEntity={methods.setCohort}
                      selectedEntity={state._storedReqData.cohort}
                    />
                  </Box>
                )
              }
              {
                showDynamicKeysToggle && (
                  <Box
                    mr='16px'
                    ml='16px'
                  >
                    <ToggleButton
                      color='primary'
                      text={showCohortDropdown ? 'Show Attributes' : 'Attributes'}
                      methodKey='setShowDynamicKeys'
                      methods={methods}
                      val={dataOpts.showDynamicKeys}
                    />
                  </Box>
                )
              }
              {
                showDateRangePicker && (
                  <Box
                    mr='16px'
                    ml='16px'
                  >
                    <PresetDateRangePicker2
                      styleVariant='outlined2'
                      modelOpts={dateRangePickerOpts}
                      onClose={methods.setReqDataDateRange}
                    />
                  </Box>
                )
              }
            </Flex>
            <Flex>
            </Flex>
          </Flex>
          <Box
            display={!showCohortDropdown && showQueryBuilder && dataOpts.showDynamicKeys ? undefined : 'none'}
            mr='16px'
          >
            <QueryBuilder
              builderOpts={builderOpts}
              onDataUpdate={methods.setReqDataWhere}
            />
          </Box>
        </Box>
        <MaterialTableMemo
          isLoading={isLoading}
          columns={columns}
          data={data}
          options={optsMUIT}
          icons={icons}
          style={{
            overflowX: 'auto',
            boxShadow: '0px 0px 0px rgba(0,0,0,0.0)',
            backgroundColor:'transparent',
          }}
          title={title}
          detailPanel={detailPanel}
          components={components}
          actions={actions}
        />
      </Card>
    )
  }
})

export default MaterialTableWrapper


export const MaterialTableWrapperWithRoutedId = React.memo(({ reqData, tableModelOpts }) => {
  const params = useParams()

  tableModelOpts = React.useMemo(() => {
    const ret = { ...tableModelOpts }
    ret.variantOpts.id = params.id
    return ret

  }, [ tableModelOpts, params ])

  return (
    <MaterialTableWrapper reqData={reqData} tableModelOpts={tableModelOpts}/>
  )
})
