import React from 'react';
// import { withStyles, withStylesPropTypes, css } from 'react-with-styles';
import 'react-dates/initialize'
import { DateRangePicker, isSameDay } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import './reactDatesOverrides.css'
import ControlButton from './ControlComps/ControlButton'
import { Grid, Box } from './Base'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { useModel_PresetDateRangePicker } from './PresetDateRangePickerModel'

const DateRangePresets = React.memo(({ onDatesChange, onFocusChange, onClose, startDate, endDate, presets, ...props }) => {
  return (
    <Grid
      gridGap='8px'
      gridAutoFlow='column'
      padding='0px 24px 24px 24px'
      justifyContent='left'
    >
      {presets.map(({ label, start, end }) => {
        const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
        return (
          <ControlButton
            key={label}
            variant={isSelected ? 'solidSmall' : 'outlinedSmall'}
            color='primary'
            bshadow='none'
            text={label}
            onClick={(e) => {
              onDatesChange({ startDate: start, endDate: end })
              onFocusChange(null)
              onClose()
              e.stopPropagation()
            }}
          >
            {label}
          </ControlButton>
        );
      })}
    </Grid>
  )
})

const PresetDateRangePicker = ({ state, methods, variant='outlined', readOnly=true, ...props }) => {
  let {
    methodKey, methodOpts, onCloseKey,
    start, end, min, max, presets,
    focusedInput,
    minimumNights,
    defaultPresetIdx, onCloseState,
    ...other
  } = {
    ...state,
    ...props,
  }

  let setFocusedInput = methods.setFocusedInput
  
  const isOutsideRange = (date) => !((date > min) && (date < max))
  const onDatesChange = ({ startDate, endDate }) => methods[methodKey]({ startDate, endDate }, methodOpts)
  const onClose = () => {
    methods.onClose()
  }

  return (
    <Box
      className={`DRP_${variant}`}
      onClick={() => {
        setFocusedInput('startDate')
      }}
      {...other}
    >
      <DateRangePicker
        startDate={start}
        endDate={end}
        minDate={min}
        maxDate={max}
        startDateId='START_DATE'
        endDateId='END_DATE'
        focusedInput={focusedInput}
        onDatesChange={onDatesChange}
        onFocusChange={setFocusedInput}
        onClose={onClose}
        isOutsideRange={isOutsideRange}
        readOnly={readOnly}
        minimumNights={minimumNights}
        renderCalendarInfo={
          presets && (() => <DateRangePresets
                presets={presets}
                startDate={start}
                endDate={end}
                onDatesChange={onDatesChange}
                onFocusChange={setFocusedInput}
                onClose={onClose}
              />)
        }
        // showDefaultInputIcon
        customInputIcon={<CalendarTodayIcon style={{fontSize: '1rem'}}/>}
        customArrowIcon={<ChevronRightIcon style={{fontSize: '1rem'}}/>}
      />
    </Box>
  )
}

export const PresetDateRangePicker2 = React.memo(({ modelOpts, styleVariant = 'outlined', onChange, onClose, ...props }) => {
  const [ state, methods ] = useModel_PresetDateRangePicker(modelOpts)
  
  const isOutsideRange = (date) => {
    return !((date > state.min) && (date < state.max))
  }

  if (onClose) {
    React.useEffect(() => {
      onClose({
        start: state.onCloseState.start.toISOString(),
        end: state.onCloseState.end.toISOString(),
      })
    }, [
      onClose,
      state.onCloseState,
    ])
  }

  if (onChange) {
    React.useEffect(() => {
      onChange({
        start: state.start.toISOString(),
        end: state.end.toISOString(),
      })
    }, [
      onChange,
      state.start, state.end,
    ])
  }

  return (
    <Box
      className={`DRP_${styleVariant}`}
      onClick={() => {
        methods.setFocusedInput('startDate')
      }}
      {...props}
    >
      <DateRangePicker
        startDate={state.start}
        endDate={state.end}
        minDate={state.min}
        maxDate={state.max}
        startDateId='START_DATE'
        endDateId='END_DATE'
        focusedInput={state.focusedInput}
        onDatesChange={methods.onDatesChange}
        onFocusChange={methods.setFocusedInput}
        onClose={methods.onClose}
        isOutsideRange={isOutsideRange}
        readOnly={state.readOnly}
        minimumNights={state.minimumNights}
        renderCalendarInfo={
          state.presets &&
            (() => <DateRangePresets
              presets={state.presets}
              startDate={state.start}
              endDate={state.end}
              onDatesChange={methods.onDatesChange}
              onFocusChange={methods.setFocusedInput}
              onClose={methods.onClose}
            />)
        }
        customInputIcon={<CalendarTodayIcon style={{fontSize: '1rem'}}/>}
        customArrowIcon={<ChevronRightIcon style={{fontSize: '1rem'}}/>}
      />
    </Box>
  )
})

export default PresetDateRangePicker


//
// EXAMPLE
//

// const [ dateRangePickerState, dateRangePickerMethods ] = useModel_PresetDateRangePicker()
// React.useEffect(
//   () => {

//   },
//   [
//     dateRangePickerState.onCloseState.start,
//     dateRangePickerState.onCloseState.end,
//     // or if wanting changes while the picker is open
//     dateRangePickerState.start,
//     dateRangePickerState.end,
//   ]
// )
// <PresetDateRangePicker state={dateRangePickerState} methods={dateRangePickerMethods}/>


