import React from 'react'
import { Grid, } from '../../../../components/Base'

import ControlBar, { CohortSelectorSetting } from '../../components/ControlBar'
import WorkloadRiskCard from '../../components/WorkloadRiskCard'
import { ActivityDataPropertyChartGrid } from '../../components/StatsCard'

import { useEntitiesActivitiesDataAgg, datasetColors, defaultRiskThresholds, useSelectedCohortSetting } from '../../util'

// import MaterialTableWrapper from '../../../../components/Tables/MaterialTableWrapper'

const hazardsCfg = [
  //
  {
    title: 'Hazard Exposure',
  },
  //
  {
    label: 'Potential Slip Events',
    dataKey: 'slip',
  },
  // {
  //   label: 'Vibration Time',
  //   dataKey: 'vibration',
  // },
  {
    label: 'Trips',
    // dataKey: 'trip',
  },
  {
    label: 'Falls',
    // dataKey: 'fall',
  },
]

// const incidentsTableOpts = {
//   variant: 'activitiesList',
//   variantOpts: {
//     entityType: 'appusers',
//     showSearch: true,
//   }
// }

const Incidents = ({ dateRange, setDateRange }) => {
  const [ entitiesData, setEntitiesData ] = React.useState()

  const selectedCohort = useSelectedCohortSetting(0, {})

  const riskThresholds = selectedCohort[0]?.attributes?.riskSettings || defaultRiskThresholds

  useEntitiesActivitiesDataAgg({
    dataHandler: setEntitiesData,
    dateRange,
    averagePerDay: true,
    appusersWhere: selectedCohort,
    riskThresholds,
  })

  return (
    <Grid
      gridAutoColumns='1fr'
      gridGap='32px'
    >
      <ControlBar pageTitle='Incidents' setDateRange={setDateRange} dateRange={dateRange}/>
      {/* <Text variant='h6'>Injury Propensity</Text> */}
      <Grid
        gridGap='16px'
        gridAutoFlow='column'
        gridAutoColumns='max-content'
      >
        <CohortSelectorSetting
          settingsPath={0}
          showAddButton={false}
          noneVal={{}}
          markerColor={datasetColors[0]}
          noneText='All Workers'
          hasNoneOption={!selectedCohort?.id}
        />
      </Grid>
      <Grid
        gridGap='8px'
        gridTemplateColumns='1fr 1fr'
        maxWidth='1160px'
      >
        <WorkloadRiskCard
          dateRange={dateRange}
          entitiesData={entitiesData}
        />
      </Grid>
      <ActivityDataPropertyChartGrid
        cfg={hazardsCfg}
        dateRange={dateRange}
        mainColor='#F34D4D'
        appusersWhere={selectedCohort}
      />
      {/* <MaterialTableWrapper tableModelOpts={incidentsTableOpts}/> */}
    </Grid>
  )
}

export default Incidents