import React from 'react'

import useApi from '../lib/useApi'

import { Flex } from './Base'
import ControlButton from './ControlComps/ControlButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import SaveAltIcon from '@material-ui/icons/SaveAlt'

export const AsyncExporterIconButton = React.memo(({ title, url, reqData, filename, disabled, onCompleted }) => {  
  const api = useApi()

  const [ downloadUrl, setDownloadUrl ] = React.useState(null)
  const [ isLoading, setIsLoading ] = React.useState(false)
  const [ isDownloaded, setIsDownloaded ] = React.useState(false)
  const downloadRef = React.useRef()

  const doExport = React.useCallback(() => {
    setDownloadUrl(null)
    setIsDownloaded(false)
    api({
      method: "post",
      url,
      data: reqData,
      isLoading: setIsLoading,
      handler: (data) => {
        setDownloadUrl(data.url)
      }
    })
  }, [api, reqData, url])

  React.useEffect(() => {
    if (downloadUrl) {
      if (onCompleted) {
        onCompleted()
      }
      downloadRef.current.click()
      setIsDownloaded(true)
    }
  }, [onCompleted, downloadRef, downloadUrl])

  const isInProgress = (isLoading || downloadUrl) && !isDownloaded
  
  return (
    <>
      {
        !isInProgress &&
          <ControlButton
            variant='icon'
            color='primary'
            disabled={disabled}
            title={title}
            startIcon={SaveAltIcon}
            onClick={doExport}
          />
      }
      {
        isInProgress &&
          <Flex width='32px 'alignItems='center' justifyContent='center'>
            <CircularProgress size={16}/>
          </Flex>
      }
      {
        downloadUrl &&
          <a
            href={downloadUrl}
            ref={downloadRef}
            download
            style={{fontSize: 0}}
          >
            {`Download "${filename}"`}
          </a>
      }
    </>
  )
})
