import React from 'react'

import { useMergedRolePermissionCfg, useMergedEntityPermissionCfg } from '../../lib/hooks'
import moment from 'moment-timezone'
import { isString } from 'lodash'

import { getLongEntityLabel, useFilteredEntityItems, useEntityModalProps, DbEntityActionButton } from './util'

import { Flex, Grid, Text, Card, } from '../Base'
import { ModalButton } from '../Modal'
import Permitted from '../Permitted'
import { TabbedRoutesObjectCfged } from '../TabbedRoutes'

import useStyles from '../../lib/useStyles'
import { ControlPointDuplicateOutlined, Add, DeleteOutlined, EditOutlined, SaveOutlined, ArchiveOutlined } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'

import * as dbEntityCfgs from './allDbEntities'

import MaterialTableWrapper from '../Tables/MaterialTableWrapper'

//
// Page or Tabbed Pages
//

const ManageDbEntity = React.memo(({ basePath, dbEntityType, newEntityOpts, items = null, onNew, children }) => {
  const dbEntityCfg = dbEntityCfgs[dbEntityType]
  const tabsCfg = dbEntityCfg.manage?.tabsCfg
  
  if (!tabsCfg) {
    // TODO_MAYBE wrap with a route?
    return <DbEntityManagementPage dbEntityType={dbEntityType} newEntityOpts={newEntityOpts} items={items} {...dbEntityCfg.manage}/>
  }
  else {
    return (
      <TabbedRoutesObjectCfged
        basePath={basePath}
        tabsCfg={tabsCfg}
        DefaultComp={DbEntityManagementPage}
        defaultCompPropsBase={React.useMemo(() => {
          return {
            dbEntityType,
            newEntityOpts,
            items,
            onNew,
            children
          }
        }, [children, dbEntityType, items, newEntityOpts, onNew])}
      />
    )
  }
})

export default ManageDbEntity

//
// Page
//

const DbEntityManagementPage = React.memo(({ dbEntityType, newEntityOpts, filter, onlyOwnedBySelf, bulkfilter, items = null, onNew, table, canCreate = true, children }) => {
  const classes = useStyles()
  const dbEntityCfg = dbEntityCfgs[dbEntityType]

  items = useFilteredEntityItems(dbEntityType, filter, onlyOwnedBySelf, bulkfilter, items)
  const title = getLongEntityLabel(dbEntityCfg, null, newEntityOpts, onlyOwnedBySelf, true)
  const [ computedRolePerms, ] = useMergedRolePermissionCfg(dbEntityCfg.actionsPermissionCfgs.role)

  let contents

  if (dbEntityCfg.manageIsTable) {
    const tableProps = React.useMemo(() => {
      return {
        items,
        tableModelOpts: {
          variant: 'manage',
          variantOpts: {
            dbEntityType,
            title,
            ...table,
            // generic
            showSearch: true,
            exportOpts: {
              exportButton: true,
              exportFileName: title.replace(' ', ''),
            },
          }
        }
      }
    }, [items, dbEntityType, title, table])
    contents = (
      <MaterialTableWrapper {...tableProps}/>
    )
  }
  else {
    contents = (
      <Grid
        gridGap='8px'
        gridAutoFlow='row'
        gridTemplateColumns='1fr'
        maxWidth='916px'
        mx='auto'
      >
        {
          items.map((entity) => {
            return (
              <DbEntityManager key={entity.id} computedRolePerms={computedRolePerms} dbEntityType={dbEntityType} entity={entity}/>
            )
          })
        }
      </Grid>
    )
  }

  return (
    <>
      <Flex className={dbEntityCfg.manageIsTable ? classes.controlBar_full : classes.controlBar}>
        <Flex alignItems='center'>
          <Text variant='h5' mr='16px'>{title}</Text>
          <Grid
            gridAutoFlow='column'
            gridGap='8px'
          >
            {
              !onlyOwnedBySelf && canCreate &&
                <DbEntityCreateButton dbEntityType={dbEntityType} newEntityOpts={newEntityOpts} computedRolePerms={computedRolePerms} onCompleted={onNew}/>
            }
          </Grid>
        </Flex>
        <Grid
          gridAutoFlow='column'
          gridGap='8px'
        >
          {
            children && 
              [ children ]
          }
        </Grid>
      </Flex>
      {contents}
    </>
  )
})

//
// Management Item Contents
//

const DbEntityManager_lineSingleContents = React.memo(({ dbEntityType, entity, actions, isOwner, nameContentsAndTooltip, updatedAtContentsAndTooltip, maxActionsWidth, tooltipProps }) => {
  const dbEntityCfg = dbEntityCfgs[dbEntityType]

  const specificContentsAndTooltips = dbEntityCfg.getManagerContentsAndTooltips_single?.(entity)

  let gridTemplateColumnsStrItems = [ '2fr' ]
  if (specificContentsAndTooltips) {
    gridTemplateColumnsStrItems.push('3fr')
  }
  if (updatedAtContentsAndTooltip) {
    gridTemplateColumnsStrItems.push('1.5fr')
  }
  gridTemplateColumnsStrItems.push(`${maxActionsWidth}px`)

  const classes = useStyles()
  return (
    <>
      <Grid
        gridTemplateColumns={gridTemplateColumnsStrItems.join(' ')}
        gridGap='12px'
        alignItems='center'
      >
        <Tooltip {...tooltipProps} title={nameContentsAndTooltip[1]}>
          <Text
            className={classes.noWrapEllipsis}
            variant='subtitle2'
          >
            {nameContentsAndTooltip[0]}
          </Text>
        </Tooltip>
        {
          specificContentsAndTooltips && 
            <Tooltip {...tooltipProps} title={specificContentsAndTooltips[1]}>
              {
                isString(specificContentsAndTooltips[0]) 
                ? (
                  <Text
                    className={classes.noWrapEllipsis}
                    color='text.secondary'
                    variant='small3'
                  >
                    {specificContentsAndTooltips[0]}
                  </Text>
                )
                : specificContentsAndTooltips[0]
              }
            </Tooltip>
        }
        {
          updatedAtContentsAndTooltip &&
            <Tooltip {...tooltipProps} title={updatedAtContentsAndTooltip[1]}>
              <Flex className={classes.noWrapEllipsis}>
                <Text
                  className={classes.noWrapEllipsis}
                  whiteSpace='pre-wrap'
                  variant='small3'
                  color='text.disabled'
                >
                  {updatedAtContentsAndTooltip[0]}
                </Text>
              </Flex>
            </Tooltip>
        }
        <Grid
          gridAutoFlow='column'
          justifyContent='flex-end'
        >
          {actions}
        </Grid>
      </Grid>
    </>
  )
})

const dbEntityManagerContentsVariants = {
  lineSingleContents: DbEntityManager_lineSingleContents,
}

const dbEntityManagerContainerVariants = {
  cardCompact: (props) => <Card p='12px' flexDirection='column' variant='bordered' {...props}/>
}

const tooltipProps = {
  enterDelay: 500,
  PopperProps:{
    style: { whiteSpace: 'pre-wrap' },
  },
  arrow: true,
}

//
// Management Item
//

const isAllowed = (allowedActions, key) => {
  if (allowedActions?.indexOf(key) < 0) {
    return false
  }
  return true
}

export const DbEntityManagerActions = React.memo(({ dbEntityType, entity, otherData, allowedActions, computedRolePerms = null, computedEntityPerms = null, onEdited, onCloned, onDeleted, onArchived, onActionCompleted }) => {
  const dbEntityCfg = dbEntityCfgs[dbEntityType]

  let [ _computedRolePerms, ] = (!computedRolePerms && useMergedRolePermissionCfg(dbEntityCfg.actionsPermissionCfgs.role)) || [ computedRolePerms, null ]
  let [ _computedEntityPerms, ] = (!computedEntityPerms && useMergedEntityPermissionCfg(dbEntityCfg.actionsPermissionCfgs.entity, entity)) || [ computedEntityPerms, null ]
  
  const actionButtonProps = {
    dbEntityType,
    entity,
    computedRolePerms: _computedRolePerms,
    computedEntityPerms: _computedEntityPerms,
  }

  return (
    <>
      {
        isAllowed(allowedActions, 'edit') &&
          <DbEntityEditButton onCompleted={onEdited} {...actionButtonProps}/>
      }
      {
        isAllowed(allowedActions, 'clone') &&
          <DbEntityCloneButton onCompleted={onCloned} {...actionButtonProps}/>
      }
      {
        isAllowed(allowedActions, 'archive') &&
          <DbEntityArchiveButton onCompleted={onArchived} {...actionButtonProps}/>
      }
      {
        isAllowed(allowedActions, 'delete') &&
          <DbEntityDeleteButton onCompleted={onDeleted} {...actionButtonProps}/>
      }
      {
        dbEntityCfg.customActions && Object.entries(dbEntityCfg.customActions).map(([key, val]) => {
          if (!isAllowed(allowedActions, key)) return null
          return (
            <DbEntityActionButton key={key} onCompleted={onActionCompleted} {...actionButtonProps} permsPath={key} cfg={val} otherData={otherData}/>
          )
        })
      }
    </>
  )
})

export const DbEntityManager = React.memo(({ dbEntityType, entity, computedRolePerms, containerVariant = 'cardCompact', contentsVariant = 'lineSingleContents', onEdited, onCloned, onDeleted, onArchived }) => {
  const dbEntityCfg = dbEntityCfgs[dbEntityType]
  
  const Container = dbEntityManagerContainerVariants[containerVariant]
  const Contents = dbEntityManagerContentsVariants[contentsVariant] || dbEntityCfg.ContentComps.ManagerContents

  const [
    computedEntityPerms,
    computedEntityPerms_keysObj,
  ] = useMergedEntityPermissionCfg(dbEntityCfg.actionsPermissionCfgs.entity, entity)

  const ContentsProps = React.useMemo(() => {
    const {
      saasuser = {},
      updatedAt,
    } = entity
  
    const isOwner = computedEntityPerms_keysObj.owner
    const name = dbEntityCfg.getEntityName(entity)
    const createdBy = (!isOwner && (saasuser.name || saasuser.email)) || (isOwner && 'You') || undefined
    const _updatedAt = (updatedAt && moment(updatedAt)) || undefined
    const maxActionsWidth = 32 * 4
    let nameTooltip = name
    if (createdBy) {
      nameTooltip = `${name}\nCreated By: ${createdBy}`
    }
  
    const nameContentsAndTooltip = [ name, nameTooltip ]
    const updatedAtContentsAndTooltip = _updatedAt && [ `modified ${_updatedAt.fromNow()}`, _updatedAt.format('lll') ]

    return {
      dbEntityType,
      entity,
      nameContentsAndTooltip,
      updatedAtContentsAndTooltip,
      isOwner,
      maxActionsWidth,
      tooltipProps,
    }
  }, [dbEntityCfg, dbEntityType, entity, computedEntityPerms_keysObj.owner])

  return (
    <Container>
      <Contents
        {...ContentsProps}
        actions={
          <DbEntityManagerActions
            dbEntityType={dbEntityType}
            entity={entity}
            computedRolePerms={computedRolePerms}
            computedEntityPerms={computedEntityPerms}
            onEdited={onEdited}
            onCloned={onCloned}
            onDeleted={onDeleted}
            onArchived={onArchived}
          />
        }
      />
    </Container>
  )
})

//
// Management Modal Controls
//

// TODO_MAYBE callback or ref for outdata
export const DbEntitySaveAsButton = React.memo(({ dbEntityType, entity, disabled, onCompleted, computedRolePerms = null, computedEntityPerms = null }) => {
  const dbEntityCfg = dbEntityCfgs[dbEntityType]
  return (
    <Permitted
      path='new'
      permsCfg={dbEntityCfg.actionsPermissionCfgs}
      computedRolePerms={computedRolePerms}
      computedEntityPerms={computedEntityPerms}
    >
      <ModalButton
        variant='icon'
        icon={SaveOutlined}
        title={`Save as New ${getLongEntityLabel(dbEntityCfg, entity, null)}`}
        disabled={disabled}
        modalProps={
          useEntityModalProps('editNew', dbEntityType, { saveFrom: entity }, onCompleted)
        }
      />
    </Permitted>
  )
})

const emptyObj = {}
const DbEntityCreateButton = React.memo(({ dbEntityType, newEntityOpts = emptyObj, onCompleted, computedRolePerms = null, computedEntityPerms = null }) => (
  <Permitted
    path='new'
    permsCfg={dbEntityCfgs[dbEntityType].actionsPermissionCfgs}
    computedRolePerms={computedRolePerms}
    computedEntityPerms={computedEntityPerms}
  >
    <ModalButton
      variant='outlinedSmall'
      icon={Add}
      label='Create New'
      size='small'
      modalProps={
        useEntityModalProps('editNew', dbEntityType, { newEntityOpts }, onCompleted)
      }
    />
  </Permitted>
))
const DbEntityEditButton = React.memo(({ dbEntityType, entity, onCompleted, computedRolePerms = null, computedEntityPerms = null }) => (
  <Permitted
    path='edit'
    permsCfg={dbEntityCfgs[dbEntityType].actionsPermissionCfgs}
    computedRolePerms={computedRolePerms}
    computedEntityPerms={computedEntityPerms}
  >
    <ModalButton
      variant='icon'
      title='Edit'
      icon={EditOutlined}
      modalProps={
        useEntityModalProps('edit', dbEntityType, { entity }, onCompleted)
      }
    />
  </Permitted>
))
const DbEntityCloneButton = React.memo(({ dbEntityType, entity, onCompleted, computedRolePerms = null, computedEntityPerms = null }) => (
  <Permitted
    path='clone'
    permsCfg={dbEntityCfgs[dbEntityType].actionsPermissionCfgs}
    computedRolePerms={computedRolePerms}
    computedEntityPerms={computedEntityPerms}
  >
    <ModalButton
      variant='icon'
      title='Use as template'
      icon={ControlPointDuplicateOutlined}
      modalProps={
        useEntityModalProps('clone', dbEntityType, { cloneFrom: entity }, onCompleted)
      }
    />
  </Permitted>
))
export const DbEntityDeleteButton = React.memo(({ dbEntityType, entity, onCompleted, computedRolePerms = null, computedEntityPerms = null }) => (
  <Permitted
    path='delete'
    permsCfg={dbEntityCfgs[dbEntityType].actionsPermissionCfgs}
    computedRolePerms={computedRolePerms}
    computedEntityPerms={computedEntityPerms}
  >
    <ModalButton
      variant='icon'
      title='Delete'
      icon={DeleteOutlined}
      modalProps={
        useEntityModalProps('delete', dbEntityType, { entity, isDeleter: true }, onCompleted)
      }
    />
  </Permitted>
))
export const DbEntityArchiveButton = React.memo(({ dbEntityType, entity, onCompleted, computedRolePerms = null, computedEntityPerms = null }) => (
  <Permitted
    path='archive'
    permsCfg={dbEntityCfgs[dbEntityType].actionsPermissionCfgs}
    computedRolePerms={computedRolePerms}
    computedEntityPerms={computedEntityPerms}
  >
    <ModalButton
      variant='icon'
      title='Archive'
      icon={ArchiveOutlined}
      modalProps={
        useEntityModalProps('archive', dbEntityType, { entity, isArchiver: true }, onCompleted)
      }
    />
  </Permitted>
))



