import React from 'react'

import Workload from './workload'
import Incidents from './incidents'
import Diagnostics from './diagnostics'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAppPlugin } from '../../../lib/hooks'

// import { defaultRiskThresholds } from '../util'

import moment from 'moment-timezone'

const pluginName = 'safety'

// TODO fix double calls, is date range picker causing it?

const SafetyPlugin = () => {
  const appPlugin = useAppPlugin()

  const [ dateRange, setDateRange ] = React.useState({
    start: moment().startOf('day').subtract(7, 'days').toISOString(),
    end: moment().endOf('day').toISOString(),
  })

  return (
    <Switch>
      {
        appPlugin.name !== pluginName
          ? <Redirect to='/'/>
          : null
      }
      <Route path='/safety/workload'>
        <Workload
          dateRange={dateRange}
          setDateRange={setDateRange}
          appId={appPlugin.appId}
        />
      </Route>
      <Route path='/safety/incidents'>
        <Incidents
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Route>
      <Route path='/safety/diagnostics/:riskSetting?'>
        <Diagnostics
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Route>
      <Redirect to='/safety/workload'/>
    </Switch>
  )
}

export default SafetyPlugin