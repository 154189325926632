import moment from 'moment-timezone'
import useMethods from 'use-methods'
import modelVariants from './ChartJsStateVariants'
import { objectVariantOrPassthrough } from '../../lib/state'
import { doExport } from '../../lib/export'
import deepMerge from '../../lib/deepMerge'
import { some, fill, isNumber } from 'lodash'

const _setDataSingleDataset = (state, data = [], datasetIndex = 0, opts = {}) => {
  const chartJsData = state.libCfg.data
  const chartJsScales = state.libCfg.options.scales
  const timezone = state.tz

  if (data.length === 0) {
    chartJsData.datasets[datasetIndex].data = []
    return
  }

  let labelType = null
  let first = data[0]

  // Labels only if first dataset
  if (datasetIndex === 0) {
    if (opts.labels) {
      chartJsData.labels = opts.labels
    }
    else {
      if (first.timestamp) {
        labelType = 'ts'
        chartJsData.labels = data.map(p => moment.tz(p.timestamp || p.ts, timezone))
      }
      else if (first.label) {
        labelType = 'str'
        chartJsData.labels = data.map(p => p.label)
      }
    }

      // X axis type
    switch (labelType) {
      case 'ts':
        chartJsScales.xAxes[0].type = 'time'
        break

      case 'str':
        chartJsScales.xAxes[0].type = 'category'
        break

      default:
        break
    }
  }

  if (opts.dataSetsLabels) {
    chartJsData.datasets[datasetIndex].label = opts.dataSetsLabels?.[datasetIndex] || '?'
  }

  // TODO_MAYBE auto color fallback
  if (opts.palette) {
    chartJsData.datasets[datasetIndex].color = opts.palette?.[datasetIndex] || '#ffffff'
  }
  
  // Vals
  let valType = 'num'

  if (isNumber(first) || Number.isNaN(first)) {
    chartJsData.datasets[datasetIndex].data = data.map(p => p || 0)
  }
  else if (first.count || first.count === 0) {
    chartJsData.datasets[datasetIndex].data = data.map(p => p.count)
  }
  else if (first.value || first.value === 0) {
    chartJsData.datasets[datasetIndex].data = data.map(p => p.value)
  }
  else if (first.percent || first.percent === 0) {
    chartJsData.datasets[datasetIndex].data = data.map(p => p.percent)
    valType = 'perc'
  }
  else {
    valType = 'num'
    chartJsData.datasets[datasetIndex].data = data.map(p => (Object.values(p)?.[1]) || 0)
  }

  // Y axis type only if first dataset
  if (datasetIndex === 0) {
    if (chartJsScales.yAxes[0].display !== false) {
      if (!chartJsScales.yAxes[0].ticks) {
        chartJsScales.yAxes[0].ticks = {}
      }

      switch (valType) {
        case 'num':
          chartJsScales.yAxes[0].ticks.callback = (val) => val
          break
  
        case 'perc':
          chartJsScales.yAxes[0].ticks.callback = (val) => val + '%'
          break
  
        default:
          break
      }
    }
  }
}

const _setAxisUnit = (axis, val) => {
  axis.scaleLabel = {
    ...(axis.scaleLabel || {}),
    display: Boolean(val),
    labelString: val,
  }
}

const _setData = (state, data, opts) => {
  state.originalData = data

  const chartJsData = state.libCfg.data
  if (!chartJsData.datasets) {
    chartJsData.datasets = [{ data: [] }]
  }

  if (data.points) {
    if (data.pointLabels) {
      opts = {
        ...opts,
        // TODO handle type somehow
        labels: data.pointLabels.map(pl => moment.tz(pl, state.tz))
      }
    }

    if (data.yUnit) _setAxisUnit(state.libCfg.options.scales.yAxes[0], data.yUnit)
    if (data.xUnit) _setAxisUnit(state.libCfg.options.scales.xAxes[0], data.xUnit)
    if (data.ticks) {
      const scale = state.libCfg.options.scales.yAxes[0]
      scale.ticks = {
        ...scale.ticks,
        ...data.ticks,
      }
    }

    data = data.points
  }

  state.dataWasSet = true

  state.hasDataPoints = data.length > 0
  state.hasAllZeroData = data.length > 0 && !some(data)

  _setDataSingleDataset(state, data, 0, opts)
}

const _setDataSets = (state, dataSets, opts) => {
  const {
    fillMissingDatasets,
    missingDatasetLength,
  } = opts

  // TODO add datasets to chartJs if needed

  if (opts.yUnit) _setAxisUnit(state.libCfg.options.scales.yAxes[0], opts.yUnit)
  if (opts.xUnit) _setAxisUnit(state.libCfg.options.scales.xAxes[0], opts.xUnit)

  dataSets.forEach((dataSet, i) => {
    if (!dataSet) {
      if (fillMissingDatasets) {
        dataSet = fill(Array(missingDatasetLength), 0)
      }
    }

    _setDataSingleDataset(state, dataSet, i, opts)
  })

  state.originalData = dataSets
  state.dataWasSet = true

  const allData = dataSets.flat()
  state.hasDataPoints = allData.length > 0
  state.hasAllZeroData = allData.length > 0 && !some(allData)

  const chartJsData = state.libCfg.data
  chartJsData.datasets.forEach((o) => o.data = [])
}

const _setDataSetsRaw = (state, dataSets, opts) => {
  const {
    fillMissingDatasets,
    missingDatasetLength,
  } = opts

  // TODO add datasets to chartJs if needed

  dataSets.forEach((dataSet, i) => {
    if (!dataSet.data) {
      if (fillMissingDatasets) {
        dataSet.data = fill(Array(missingDatasetLength), 0)
      }
    }
  })

  state.originalData = dataSets
  state.dataWasSet = true

  const allData = dataSets.map(ds => ds.data || []).flat()
  state.hasDataPoints = allData.length > 0
  state.hasAllZeroData = allData.length > 0 && !some(allData)

  state.libCfg.data.datasets = dataSets

  if (opts.labels) {
    state.libCfg.data.labels = opts.labels
  }

  if (opts.yUnit) _setAxisUnit(state.libCfg.options.scales.yAxes[0], opts.yUnit)
  if (opts.xUnit) _setAxisUnit(state.libCfg.options.scales.xAxes[0], opts.xUnit)
}

const stateInitializer = ({ data, ...otherOpts } = {}) => {
  const ret = {
    ...objectVariantOrPassthrough(modelVariants, otherOpts),
    chartCanvasRef: null,
    originalData: [],
    hasAllZeroData: false,
    hasDataPoints: false,
    dataWasSet: false,
  }
  if (data) {
    _setData(data)
  }
  return ret
}

const _methods = state => {
  return {
    setChartType(val, opts) {
      state.libCfg.type = val
    },
    setTimezone(val, opts) {
      state.tz = val
      _setData(state, state.originalData)
    },
    setChartCanvasRef(ref) {
      state.chartCanvasRef = ref
    },
    setData(data, opts) {
      _setData(state, data, opts)
    },
    setDataSets(dataSets, opts) {
      _setDataSets(state, dataSets, opts)
    },
    setDataSetsRaw(dataSets, opts) {
      _setDataSetsRaw(state, dataSets, opts)
    },
    setYUnit(val, opts) {
      _setAxisUnit(state.libCfg.options.scales.yAxes[0], val)
    },
    setXUnit(val, opts) {
      _setAxisUnit(state.libCfg.options.scales.xAxes[0], val)
    },
    setOption(val, key) {
      state.libCfg.options[key] = val
    },
    exportAs(val, opts) {
      const { fileName, exportType, exportOpts } = opts
      switch(exportType) {
        case 'data':
          doExport(
            deepMerge(
              {
                mimeType: 'text/csv',
              },
              exportOpts,
              {
                data: state.originalData,
                fileName,
              }
            )
          )
          break

        case 'image':
          doExport(
            deepMerge(
              {
                mimeType: 'image/png',
                preProcessOpts: {
                  padding: 60,
                },
              },
              exportOpts,
              {
                canvas: state.chartCanvasRef.current,
                fileName,
                preProcessOpts: {
                  bgColor: state.chartBgColor,
                },
              },
            )
          )
          break

        default:
          console.warn(`${val}: ChartJs export type not implemented: ${opts}`)
      }
    },
  }
}


const useModel = (stateInitializerArgs) => {
  const [ state, methods ] = useMethods(_methods, stateInitializerArgs, stateInitializer)
  return [ state, methods ]
}

export {
  useModel as useModel_ChartJs
}
