import React from 'react'
import { useHistory } from "react-router-dom"

import * as dbEntityCfgs from '../allDbEntities'

import { useApi_AppOrOrgUrl } from '../../../lib/hooks'

import { Card, Text, Flex, Box, IconResolver } from '../../Base'
import { 
  ChevronRight,
} from '@material-ui/icons'

const ActionHandler = React.memo(({ routeTo, onClick, children, ...props }) => {
  if (routeTo) {
    const history = useHistory()
    onClick = () => history.push(routeTo)
  }

  const child = React.Children.only(children)

  return React.cloneElement(child, { onClick, ...props })
})


const EntityCountCard = React.memo(({ dbEntityType, where, title, icon, descriptor, routeTo, onClick, ...props }) => {
  const dbEntityCfg = dbEntityCfgs[dbEntityType]

  icon = icon || dbEntityCfg.icon
  title = title || dbEntityCfg.labelPlural

  //   COUNT
  //   const [ api, organizationId, appId, appIds ] = useApi_OrgId_AppId_AppIds()
  //   const [ data, setData ] = React.useState()
  //   React.useEffect(() => {
  //     api({
  //       method: 'post',
  //       url: '/saas/v1/stats/v1/count',
  //       data: {
  //         modelName,
  //         match,
  //         organizationId,
  //         appId,
  //         appIds,
  //       },
  //       handler: (res) => {
  //         setData(res.count)
  //       }
  //     })
  //   }, [ modelName, setData, api, match, organizationId, appId, appIds ])

  const [ api, url ] = useApi_AppOrOrgUrl(`/${dbEntityType}`, null, true)
  const [ data, setData ] = React.useState()
  React.useEffect(() => {
    api({
      method: 'getFiltered',
      url,
      data: {
        where,
        fields: [
          'id'
        ]
      },
      multiResTransformer: (res) => res.flat(),
      handler: (res) => {
        setData(res.length)
      }
    })
  }, [ url, setData, api, where ])

  return (
    <ActionHandler routeTo={routeTo} onClick={onClick}>
      <Card
        p='0px'
        minWidth='190px'
        boxShadow_hover='card_hover'
        cursor='pointer'
        variant='raisedBordered'
      >
        <Flex
          p='16px'
          justifyContent='space-between'
          alignItems='center'
        >
          <Flex alignItems='center'>
            <Flex
              height='48px'
              width='48px'
              alignItems='center'
              justifyContent='center'
            >
              <IconResolver icon={icon} fontSize='16px' color='text.disabled'/>
            </Flex>
            <Box ml='16px'>
              <Text color='primary.main' variant='value1'>
                {data?.toLocaleString() || '---'}
              </Text>
              <Text color='inherit' variant='subtitle3'>
                {title}
              </Text>
            </Box>
          </Flex>
          <ChevronRight color='primary'/>
        </Flex>
      </Card>
    </ActionHandler>
  )
})

export default EntityCountCard