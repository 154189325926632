import React from 'react'

// import { Box, Grid, Text } from '../../Base'
import Profile from './Profile'

const EditorContents = React.memo(({ state, methods }) => {
  return (
    <>
    </>
  )
})

export default {
  EditorContents,
  ManagerContents: null,
  DeleterContents: null,
  Profile,
}