import React from 'react'
import { useTheme } from '@material-ui/core/styles'

import { Box, Flex, Text, Grid, } from '../../../../components/Base'
// import useStyles from '../../../../lib/useStyles'
import { useModel_ChartJs } from '../../../../components/Charting/ChartJsModel'
import ChartContainer from '../../../../components/Charting/ChartJsContainer'
import ControlBar, { CohortSelectorSetting } from '../../components/ControlBar'
import Settings from '../../components/Settings'

import { workloadFieldsCfg, datasetColors, ActivityChartModalLink, useEntitiesActivitiesDataAgg, riskCfg, defaultRiskThresholds, useSelectedCohortSetting } from '../../util'

const riskThresholdsCfg = [
  {
    color: 'rgb(21,210,145,0.1)',
    borderColor: 'rgb(21,210,145,0.25)',
    label: 'Low Risk',
  },
  {
    color: 'rgb(254,173,84,0.1)',
    borderColor: 'rgb(254,173,84,0.25)',
    label: 'Medium Risk',
  },
  {
    color: 'rgb(255,0,0,0.1)',
    borderColor: 'rgb(255,0,0,0.25)',
    label: 'High Risk',
  },
]

const Legend = ({ cfg }) => {
  return(
    <Grid
      gridGap='16px'
      gridAutoFlow='column'
      gridAutoColumns='max-content'
    >
      {
        riskThresholdsCfg.map((cfg, i) => {
          return (
            <Flex alignItems='center' key={i}>
              <Box
                height='9px'
                width='18px'
                mr='6px'
                bgcolor={cfg.color}
                border={`1px solid ${cfg.borderColor}`}
              />
              <Text variant='small1'>
                {cfg.label}
              </Text>
            </Flex>
          )
        })
      }
    </Grid>
  )
}

const boxWidth = 0.9 // 1 = no gaps between bar indexes
const useRiskAnnotations = (riskThresholds) => {
  return React.useMemo(() => {
    return {
      motion: workloadFieldsCfg.slice(0, 3).map((cfg, fieldIndex) => {
        const dataField = cfg.dataField
        const _riskThresholds = riskThresholds[dataField]
        return _riskThresholds.map((threshold, thresholdIndex) => {
          return {
            type: 'box',
            xScaleID: 'x-axis-0',
            yScaleID: 'y-axis-0',
            xMin: fieldIndex - (boxWidth/2),
            xMax: fieldIndex + (boxWidth/2),
            yMin: threshold / 60,
            yMax: (_riskThresholds?.[thresholdIndex + 1]) / 60,
            backgroundColor: riskThresholdsCfg[thresholdIndex].color,
            // borderColor: riskThresholdsCfg[thresholdIndex].borderColor,
            borderColor: 'rgb(0,0,0,0)',
            // onClick: (e) => { console.log("Box", e.type, this); },
          }
        })
      }).flat(),
      posture: workloadFieldsCfg.slice(3, 7).map((cfg, fieldIndex) => {
        const dataField = cfg.dataField
        const _riskThresholds = riskThresholds[dataField]
        return _riskThresholds.map((threshold, thresholdIndex) => {
          return {
            type: 'box',
            xScaleID: 'x-axis-0',
            yScaleID: 'y-axis-0',
            xMin: fieldIndex - (boxWidth/2),
            xMax: fieldIndex + (boxWidth/2),
            yMin: threshold / 60,
            yMax: (_riskThresholds?.[thresholdIndex + 1]) / 60,
            backgroundColor: riskThresholdsCfg[thresholdIndex].color,
            // borderColor: riskThresholdsCfg[thresholdIndex].borderColor,
            borderColor: 'rgb(0,0,0,0)',
            // onClick: (e) => { console.log("Box", e.type, this); },
          }
        })
      }).flat(),
    }
  }, [
    riskThresholds,
  ])
}

// const bulkActivitiesDataAgg = {
//   entityCount: entities.length,
//   risks: [0, 0, 0],
//   fields: {
//     f0: {
//       risks: [0, 0, 0],
//       val: 0,
//     }
//   }
// }
const useBulkActivitiesDataAgg = (dateRange, appusersWhere, riskThresholds) => {
  const [ data, setData ] = React.useState()

  const dataHandler = React.useCallback((bulkActivitiesDataAgg) => {
    if (!bulkActivitiesDataAgg) {
      setData(null)
      return
    }

    if (!bulkActivitiesDataAgg.entityCount) {
      setData([])
      return
    }

    // console.log(bulkActivitiesDataAgg)
    
    const vals = Object.entries(bulkActivitiesDataAgg.fields).map(([key, { risks, val }]) => {
      val /= 60 * 60 // seconds to hours
      val /= bulkActivitiesDataAgg.entityCount // average per entity
      return val
    })

    const meta = Object.entries(bulkActivitiesDataAgg.fields).map(([key, { risks, val }]) => {
      return risks.map((count, i) => {
        return {
          label: `${riskCfg[i].label}: ${count}`,
          color: riskCfg[i].color,
        }
      })
    })

    // console.log({ vals, meta })

    setData({ vals, meta })
  },
  [
    setData,
  ])

  useEntitiesActivitiesDataAgg({
    dataHandler,
    dateRange,
    averagePerDay: true,
    appusersWhere,
    bulkAgg: true,
    riskThresholds,
  })

  return data
}

const Workload = ({ dateRange, setDateRange, appId }) => {

  // Data

  const appuserCohorts = [ useSelectedCohortSetting(0, {}), useSelectedCohortSetting(1, null) ]

  const riskThresholds = appuserCohorts[0]?.attributes?.riskSettings || defaultRiskThresholds

  const data_0 = useBulkActivitiesDataAgg(dateRange, appuserCohorts[0], riskThresholds)
  const data_1 = useBulkActivitiesDataAgg(dateRange, appuserCohorts[1], riskThresholds)

  const riskAnnotations = useRiskAnnotations(riskThresholds)

  // Chart

  const [ chartStateMotion, chartMethodsMotion ] = useModel_ChartJs({
    variant: 'workload',
    variantOpts: {
      theme: useTheme(),
      // annotation: {
      //   // events: ['click'],
      //   drawTime: 'beforeDatasetsDraw',
      //   annotations: riskAnnotations.motion,
      // },
    },
  })

  React.useEffect(() => {
    chartMethodsMotion.setOption({
       // events: ['click'],
       drawTime: 'beforeDatasetsDraw',
       annotations: riskAnnotations.motion,
    }, 'annotation')
  }, [ riskAnnotations.motion, chartMethodsMotion ])

  const [ chartStatePosture, chartMethodsPosture ] = useModel_ChartJs({
    variant: 'workload',
    variantOpts: {
      theme: useTheme(),
      // annotation: {
      //   // events: ['click'],
      //   drawTime: 'beforeDatasetsDraw',
      //   annotations: riskAnnotations.posture,
      // },
    },
  })

  React.useEffect(() => {
    chartMethodsPosture.setOption({
       // events: ['click'],
       drawTime: 'beforeDatasetsDraw',
       annotations: riskAnnotations.posture,
    }, 'annotation')
  }, [ riskAnnotations.posture, chartMethodsPosture ])

  React.useEffect(() => {
    chartMethodsMotion.setDataSetsRaw(
      [
        {
          label: appuserCohorts[0].name || 'All Workers',
          backgroundColor: datasetColors[0],
          data: data_0?.vals?.slice(0, 3),
          metadata: data_0?.meta?.slice(0, 3),
          minBarLength: 5,
          maxBarThickness: 30,
          categoryPercentage: 0.5,
          barPercentage: 0.75,
        },
        data_1 && {
          label: appuserCohorts[1].name,
          backgroundColor: datasetColors[1],
          data: data_1?.vals?.slice(0, 3),
          metadata: data_1?.meta?.slice(0, 3),
          minBarLength: 5,
          maxBarThickness: 30,
          categoryPercentage: 0.5,
          barPercentage: 0.75,
        },
      ].filter(o => o),
      {
        labels: workloadFieldsCfg.slice(0, 3).map((cfg) => cfg.label)
      }
    )

    chartMethodsPosture.setDataSetsRaw(
      [
        {
          label: appuserCohorts[0].name || 'All Workers',
          backgroundColor: datasetColors[0],
          data: data_0?.vals?.slice(3, 7),
          metadata: data_0?.meta?.slice(3, 7),
          minBarLength: 5,
          maxBarThickness: 30,
          categoryPercentage: 0.5,
          barPercentage: 0.75,
        },
        data_1 && {
          label: appuserCohorts[1].name,
          backgroundColor: datasetColors[1],
          data: data_1?.vals?.slice(3, 7),
          metadata: data_1?.meta?.slice(3, 7),
          minBarLength: 5,
          maxBarThickness: 30,
          categoryPercentage: 0.5,
          barPercentage: 0.75,
        },
      ].filter(o => o),
      {
        labels: workloadFieldsCfg.slice(3, 7).map((cfg) => cfg.label)
      }
    )
  // Dont want to recreate datasets when appusersCohort.name changes but data has not yet
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartMethodsMotion, chartMethodsPosture, data_0, data_1])

  // Comp

  return (
    <>
      <Grid
        gridAutoColumns='1fr'
        gridGap='32px'
        m='-32px'
        p='32px'
        bgcolor='bg.primary'
      >
        <ControlBar pageTitle='Average Daily Workload' setDateRange={setDateRange} dateRange={dateRange}/>
        <Flex alignItems='center' justifyContent='space-between'>
          <Grid
            gridGap='16px'
            gridAutoFlow='column'
            gridAutoColumns='max-content'
            alignItems='start'
          >
            <Flex>
              <CohortSelectorSetting
                settingsPath={0}
                showAddButton={false}
                noneVal={{}}
                markerColor={datasetColors[0]}
                noneText='All Workers'
                hasNoneOption={!appuserCohorts[0]?.id}
              />
              <Settings cohort={appuserCohorts[0]}/>
            </Flex>
            <Flex>
              <CohortSelectorSetting
                settingsPath={1}
                showAddButton={true}
                noneVal={null}
                markerColor={datasetColors[1]}
                hasNoneOption={true}
              />
              { appuserCohorts[1] &&
                <Settings cohort={appuserCohorts[1]}/>
              }
            </Flex>
          </Grid>
          <Legend cfg={riskThresholdsCfg}/>
        </Flex>
        <Flex flexDirection='row' width='100%'>
          <Box width='100%'>
            <Flex justifyContent='center'>
              <Text variant='h6' my='8px'>
                {'Motion'}
              </Text>
            </Flex>
            <ChartContainer height='385px' state={chartStateMotion} methods={chartMethodsMotion}/>
            <Grid
              gridAutoColumns='1fr'
              gridAutoFlow='column'
              // ml='88px'
              ml='56px'
              mt='8px'
            >
              {
                workloadFieldsCfg.slice(0, 3).map((cfg, i) => (
                  <Flex alignItems='center' justifyContent='center' key={i}>
                    <ActivityChartModalLink
                      label={cfg.label}
                      dbEntityType={'appusers'}
                      dateRange={dateRange}
                      cohort={appuserCohorts[0]}
                      appId={appId}
                      activityCfg={cfg}
                      activityName={cfg.dataField}
                      activityLabel={cfg.label}
                    />
                  </Flex>
                ))
              }
            </Grid>
          </Box>
          <Box width='100%'>
            <Flex justifyContent='center'>
              <Text variant='h6' my='8px'>
                {'Posture'}
              </Text>
            </Flex>
            <ChartContainer height='385px' state={chartStatePosture} methods={chartMethodsPosture}/>
            <Grid
              gridAutoColumns='1fr'
              gridAutoFlow='column'
              // ml='88px'
              ml='56px'
              mt='8px'
            >
              {
                workloadFieldsCfg.slice(3, 7).map((cfg, i) => (
                  <Flex alignItems='center' justifyContent='center' key={i}>
                    <ActivityChartModalLink
                      label={cfg.label}
                      dbEntityType={'appusers'}
                      dateRange={dateRange}
                      cohort={appuserCohorts[1]}
                      appId={appId}
                      activityCfg={cfg}
                      activityName={cfg.dataField}
                      activityLabel={cfg.label}
                    />
                  </Flex>
                ))
              }
            </Grid>
          </Box>
        </Flex>
      </Grid>
    </>
  )
}

export default Workload