import React from 'react'

import { setControlVal } from '../../../lib/state'
import { useApi_OrgId } from '../../../lib/hooks'
import { useDispatch } from 'react-redux'
import { dbEntitiesStoreActions } from '../../../store/dbEntities'


const initEntityModel = (entityCfg) => {
  return {
    dbEntityType: entityCfg.dbEntityType,
    entityMethodsInit(state) {
      return {
        setStatus(val, opts) {
          setControlVal(state, state.controls.status, val)
        },
      }
    },
    getControlsCfg(state, isNew, isClone, isSaveAs) {
      if (isNew) {
        state.defaultIsValid = true
        state._forceHasChanged = true
        state.hasChanged = true
        return undefined
      }

      return {
        status: {
          methodKey: 'setStatus',
          trueText: 'ACTIVE',
          falseText: 'INACTIVE',
          _validator: (val) => {
            return true
          },
          _validateAllowNotChanged: false,
        },
      }
    },
    isPartialEntity(data) {
      return !data.status
    },
    handleSetData(state, data, hasControls) {
      if (hasControls) {
        state.controls.status.defaultVal = data.status === 'ACTIVE'
      }
    },
    buildOutData(state) {
      let ret = {
        status: state.controls?.status.val ? 'ACTIVE' : 'INACTIVE',
      }
      
      return ret
    },
    hookOverrides: {
      create: {
        useHook: (args = {}, cb) => {
          const {
            entity,
            dbEntityType
          } = args

          const [ api, orgId ] = useApi_OrgId()
          const dispatch = useDispatch()

          React.useEffect(() => {
            if (!entity) return
      
            api({
              method: 'post',
              url: `/api/organizations/${orgId}/addKeyPair`,
              handler: (res) => {
                if (cb) cb(res)
                dbEntitiesStoreActions.listUpsert(dispatch, dbEntityType, res)
              },
            })
          }, [
            cb,
            api, orgId, dispatch,
            entity, dbEntityType,
          ])
        },
      },
      update: {
        getHookArgs: (state) => ({
          isPatch: true,
          urlOverride: `/api/keys/${state.initialData.id}`,
        }),
      },
    },
  }
}

export default initEntityModel