import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


// open
// title, size
// passthru (whatever is passed in is passed back out
// children
// onClose( true/false (ok/cancel), passthru )

// sizes: xs, sm, md, lg, xl

const Confirm = (props) => {
  return(
    <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={props.size}
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose(false, props.passthru)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => props.onClose(true, props.passthru)} color="primary" variant='contained' autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Confirm;
