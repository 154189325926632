import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, Route, Switch } from "react-router-dom"
import moment from 'moment-timezone'

import { EventNames, countryCodeToFlagUrl } from '../../lib/utils'
import { useApi_OrgId_AppId_AppIds } from '../../lib/hooks'
import useStyles from '../../lib/useStyles'

import { useTheme, withStyles } from '@material-ui/core/styles'
import { PublicOutlined } from '@material-ui/icons'
import { Tooltip, Select, MenuItem, InputBase } from '@material-ui/core'

import { Grid, Card, Text, Flex, Box } from '../../components/Base'
import { ModalButton } from '../../components/Modal'
import EntityCountCard from '../../components/DbEntities/OtherComps/EntityCountCard'
import { useModel_ChartJs } from '../../components/Charting/ChartJsModel'
import ControlledChartRouted from '../../components/Charting/ControlledChartRouted'
import ChartContainer from '../../components/Charting/ChartJsContainer'
import IconActionCard from '../../components/ActionCard'

import cardsCfg from './cardsCfg.js'

const StyledInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.bg.primary,
    border: '1px solid',
    borderColor: theme.palette.border.card,
    padding: '6px 26px 6px 12px',
    '&:hover': {
      color: theme.palette.primary.main
    },
    '&:focus': {
      backgroundColor: theme.palette.bg.primary,
      borderRadius: 4,
    },
  },
}))(InputBase);

const {
  getName: getCountryName,
} = require('country-list')

const CountryBreakdownContents = React.memo(({ data }) => {
  return (
    <Grid gridAutoFlow='column' gridTemplateRows='repeat(5, 1fr)' gridGap='12px'>
      {
        data && data.map(({ fieldVal, total }) => {
          const countryName = getCountryName(fieldVal) || 'Other'

          return (
            <Flex key={fieldVal} alignItems='center'>
              <Tooltip title={countryName} placement='right-end'>
                <img
                  src={countryCodeToFlagUrl(fieldVal)}
                  alt={fieldVal}
                  style={{ width: '32px', height: '24px', marginRight: '12px', }}
                />
              </Tooltip>
              <Text color='text.primary' variant='value3'>{`${total.toLocaleString()}`}</Text>
            </Flex>
          )
        })
      }
    </Grid>
  )
})

const ActionHandler = React.memo(({ routeTo, onClick, children, ...props }) => {
  if (routeTo) {
    const history = useHistory()
    onClick = () => history.push(routeTo)
  }

  const child = React.Children.only(children)

  return React.cloneElement(child, { onClick, ...props })
})

const CountsStatsCard = React.memo(({ eventName, testData, eventNameDisplay, uniqueByAppuser, eventDescription, routeTo, onClick, additionalDaysAgo, ...props }) => {
  const classes = useStyles()

  const [ data, setData ] = React.useState(testData || {})

  const [ api, organizationId, appId, appIds ] = useApi_OrgId_AppId_AppIds()

  const [ chartState, chartMethods ] = useModel_ChartJs({
    variant: 'dashStackedLine',
    variantOpts: {
      theme: useTheme(),
      zeroCompensationColor: '#8E8E93', // apple grey 2
    },
  })

  React.useEffect(() => {
    api({
      method: 'post',
      url: '/saas/v1/stats/v1/eventNamesCountsStats',
      data: {
        eventName,
        tz: global.TZ,
        organizationId,
        appId,
        appIds,
        start: moment().tz(global.TZ).subtract(additionalDaysAgo, 'days'),
        interval: 'day',
        breakdowns: [
          {
            field: 'attributes.countryCode',
            limit: 20,
            stripPoints: true,
          },
          {
            field: 'attributes.platform',
            limit: 3,
          }
        ],
        uniqueByAppuser,
      },
      handler: (res) => {
        // console.log(JSON.stringify(res, null, 2))
        setData(res)
      },
    })
  }, [
    api,
    organizationId,
    appId,
    appIds,
    eventName,
    additionalDaysAgo,
    uniqueByAppuser,
    chartMethods,
  ])

  React.useEffect(() => {
    if (!data?.breakdowns) return

    const platformData = data.breakdowns['attributes.platform']
    const pointRadius = 1.5
    const pointHoverRadius = 3

    const dataSets = [
      {
        backgroundColor: '#8E8E93', // apple grey 2
        borderColor: '#8E8E93', // apple grey 2
        data: platformData.find((o) => o.fieldVal === 'ios')?.points,
        label: 'iOS',
        fill: 'false',
        borderWidth: 1,
        pointRadius: pointRadius,
        pointHoverRadius: pointHoverRadius
      },
      {
        backgroundColor: '#3DDC84', // android green
        borderColor: '#3DDC84', // android green
        data: platformData.find((o) => o.fieldVal === 'android')?.points,
        label: 'Android',
        fill: 'false',
        borderWidth: 1,
        pointRadius: pointRadius,
        pointHoverRadius: pointHoverRadius
      },
    ]

    const undefinedPlatform = platformData.find((o) => o.fieldVal === 'undefined')
    if (undefinedPlatform) {
      dataSets.splice(0, 0, {
        backgroundColor: '#9F6BC8', // purple
        borderColor: '#9F6BC8', // purple
        data: undefinedPlatform.points,
        label: 'other',
        fill: 'false',
        borderWidth: 1,
        pointRadius: pointRadius,
        pointHoverRadius: pointHoverRadius
      })
    }

    chartMethods.setDataSetsRaw(dataSets, {
      labels: data?.pointLabels.map((iso) => moment(iso).tz(global.TZ).format('ll')),
      fillMissingDatasets: true,
      missingDatasetLength: data.pointLabels.length,
    })
  }, [
    additionalDaysAgo,
    chartMethods,
    data
  ])

  const countriesModalProps = React.useMemo(() => {    
    return {
      variant: 'view',
      variantOpts: {
        title: `Top Countries - ${eventNameDisplay}`,
        TitleIcon: PublicOutlined,
      },
      Content: CountryBreakdownContents,
      contentProps: {
        data: data?.breakdowns?.['attributes.countryCode'],
      }
    }
  }, [
    eventNameDisplay,
    data,
  ])

  return (
    <Card
      p='0px'
      variant='raisedBordered'
      {...props}
    >
      <Grid
        p='16px 0px 8px'
      >
        <Flex
          p='0px 16px'
          justifyContent='space-between'
          alignItems='start'
        >
          <Box>
            <ActionHandler routeTo={routeTo} onClick={onClick}>
              <Flex
                justifyContent='start'
                cursor='pointer'
                alignItems='center'
                className={classes.countStatsCardTitle}
              >
                <Tooltip title={eventDescription} placement='right'>
                  <Text color='inherit' variant='subtitle3' pr='8px'>
                    {eventNameDisplay}
                  </Text>
                </Tooltip>
                <i className='fas fa-chevron-right' style={{fontSize:'9px'}}/>
              </Flex>
            </ActionHandler>
            <Flex>
              <Text color='text.secondary' variant='small3'>
                {moment().tz(global.TZ).subtract(additionalDaysAgo, 'days').format('MMM DD')} - {moment().tz(global.TZ).format('MMM DD YYYY')}
              </Text>
            </Flex>
          </Box>
          <Flex alignItems='baseline'>
            <Text
              color='primary.main'
              variant='value4'
              pr='6px'
            >
              {data?.points?.[data.points.length - 1].toLocaleString() || '-'} 
            </Text>
            <Text color='text.secondary' variant='small3' textAlign='right'>
              {'Today'}
            </Text>
          </Flex>
        </Flex>
        <ChartContainer height='144px' state={chartState} methods={chartMethods}/>
        <Flex justifyContent='center' mt='-12px'>
          <ModalButton
            variant='textual'
            icon={PublicOutlined}
            label='Top Countries'
            size='small'
            modalProps={countriesModalProps}
          />
        </Flex>
      </Grid>
    </Card>    
  )
})

// TODO support multi app id retention/funnels
const cardsCfgMultiApp = cardsCfg.slice(0, 5)

const AnalysisCards = React.memo(() => {
  const multiApps = useSelector((store) => store.auth.app?.apps)
  const _cardsCfg = multiApps ? cardsCfgMultiApp : cardsCfg


  return (
    <Box bgcolor='none'>
      <Grid
        gridGap='8px'
        gridTemplateColumns='repeat(3, 1fr)'
        gridAutoRows='max-content'
        mt='32px'
      >
        {_cardsCfg.map((props, i) => 
          <IconActionCard key={i} {...props}/>
        )}
      </Grid>
    </Box>
  )
})


//////
// Page
//////

const Dashboard = () => {

  const [ additionalDaysAgo, setAdditionalDaysAgo] = React.useState(7)
  const handleSetAdditionalDaysAgo = (event) => {
    setAdditionalDaysAgo(event.target.value)
  }

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between' mb='32px'>
        <Box>
          <Text variant='h5' pb='3px'>
            App Dashboard
          </Text>
          <Text variant='body2'>
            {moment().tz(global.TZ).subtract(additionalDaysAgo, 'days').format('MMM DD')} - {moment().tz(global.TZ).format('MMM DD, YYYY')}
          </Text>
        </Box>
        <Select
          value={additionalDaysAgo}
          onChange={handleSetAdditionalDaysAgo}
          input={<StyledInput />}
        >
          <MenuItem value={7}>
            <Text color='inherit' variant='button'>Previous 7 Days</Text>
          </MenuItem>
          <MenuItem value={14}>
            <Text color='inherit' variant='button'>Previous 14 Days</Text>
          </MenuItem>
          <MenuItem value={30}>
            <Text color='inherit' variant='button'>Previous 30 Days</Text>
          </MenuItem>
        </Select>
      </Flex>
      <Grid
        gridGap='8px'
        gridTemplateColumns='repeat(3 , 1fr)'
      >
        <CountsStatsCard
          eventName={EventNames.INSTALLED}
          eventNameDisplay={'User Activated'}
          eventDescription={'New user created'}
          routeTo='/analysis/chart/userActivated'
          additionalDaysAgo={additionalDaysAgo}
        />
        <CountsStatsCard
          eventName={EventNames.ACTIVATED}
          eventNameDisplay={'Device Paired'}
          eventDescription={'Shoe associated with user'}
          routeTo='/analysis/chart/activation'
          additionalDaysAgo={additionalDaysAgo}
        />
        <CountsStatsCard
          eventName={EventNames.START_ACTIVITY}
          eventNameDisplay={'Activity Started'}
          uniqueByAppuser={true}
          eventDescription={'Activity started by user'}
          routeTo='/analysis/chart/activity'
          additionalDaysAgo={additionalDaysAgo}
        />
      </Grid>
      <Grid
        gridGap='8px'
        gridTemplateColumns='repeat(3, 1fr)'
        gridAutoRows='max-content'
        mt='32px'
      >
        <EntityCountCard
          dbEntityType='appusers'
          routeTo='explore/appusers'
          title='Total Number of Users'
          descriptor='Users'
        />
        <EntityCountCard
          dbEntityType='shoes'
          routeTo='explore/shoes'
          title='Total Number of Devices'
          descriptor='Devices'
        />
      </Grid>
      <Switch>
        <Route exact path='/dashboard'>
          <Box>
            <AnalysisCards/>
          </Box>
        </Route>
        <Route path='/dashboard/chart/:chartVariant'>
          <ControlledChartRouted/>
        </Route>
      </Switch>
    </>
  )
}

export default Dashboard
