import React from 'react'

import TabbedRoutes from '../../components/TabbedRoutes'
import ExploreDbEntity from '../../components/DbEntities/Explore'

import { keyedHooks } from '../../lib/hooks'

const routesCfg = [
  { label: "Users", page: "/explore/appusers", component: () => {
    keyedHooks.useDbApi_getList({
      dbEntityType: 'appusers',
    })
    return (
      <ExploreDbEntity dbEntityType='appusers' basePath='/explore'/>
    )
  }
},
  { label: "Shoes", page: "/explore/shoes", component: () => {
    keyedHooks.useDbApi_getList({
      dbEntityType: 'shoes',
    })
      return (
        <ExploreDbEntity dbEntityType='shoes' basePath='/explore'/>
      )
    }
  },
]
const Explore = () => {
  return (
    <>
      <TabbedRoutes
        items={routesCfg}
        redirectFrom={'/explore'}
      />
    </>
  )
}

export default Explore