import React from 'react';
import {useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// All of this for a Select!!
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Slide from '@material-ui/core/Slide';
import {parseRole} from '../../../lib/utils';
import useStyles from '../../../lib/useStyles';
import { Text, Grid } from '../../../components/Base'

import validate from 'validate.js';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// open: boolean
// onClose: function(data)
// user: the user object
const EditUserDialog = ({open, onClose, user}) => {
  const classes = useStyles();

  const loggedInUser = useSelector(store => store.auth.user);
  const org = useSelector(store => store.auth.organization);
  
  const [role, setRole] = React.useState(user.role);
  const [name, setName] = React.useState(user.name);
  const [formErrors, setFormErrors] = React.useState({});
  
  // Return the form data suitable to return the the caller of this dialog
  const getData = () => {
    return {
      ...user,
      role,
      name,
    };
  }

  // Form contraints and the checker
  //
  const fc = {
    name: { presence: true, length: {minimum: 1} },
    role: { presence: true, length: {minimum: 1, message: "Must specify a role" }},
  };

  const clearError = (fieldName) => {
    if ( ! formErrors[fieldName] ) return;
    setFormErrors({
      ...formErrors,
      [fieldName]: undefined
    });
  }

  const checkForm = () => {

    const reformat = (result) => {
      let e = {};
      Object.keys(result).forEach((k) => {
        e[k] = result[k][0];
      });
      return e;
    }

    let result = validate({name, role}, fc);
    if ( !result ) return true;
    
    setFormErrors({
      ...formErrors,
      ...reformat(result)
    });

    return false;
  }

  // Called when add is clicked.  Check form (and display any errors).
  const checkAndSend = () => {
    if ( ! checkForm() ) return;
    onClose(getData());
  }
  
  // Make sure we start clean
  React.useEffect(() => {
    if (open) {
      setRole(user.role);
      setName(user.name);
      setFormErrors({});
    }
  }, [open, user.name, user.role]);

  // The set of roles available to choose from depend on the role of the logged in user.  If the
  // logged in user is a zhortech:admin, then any role is possible.  If the logged in user is a org:admin,
  // then only org:admin and org:user are possible.
  //
  // If the logged in user is *:user, then they shouldn't even be able to add a user.
  //

  // If sendInvite is set to false, then prompt for name and password.

  const urole = parseRole(loggedInUser.role);
  const roles = [
    { name: "Org Admin", value: "org:admin" },
    { name: "Org User", value: "org:user" },
  ];
  // only if the user is a zhortech admin AND the current organization is Zhortech
  if ( urole.zt && org.name === 'Zhortech' ) {
    roles.push({ name: "ZT Admin", value: "zhortech:admin" });
    roles.push({ name: "ZT User", value: "zhortech:user" });
  }

  return(
    <Dialog open={open} onClose={() => onClose(null)} TransitionComponent={Transition} fullWidth={true} maxWidth="sm">
      <DialogContent>
        <Grid gridGap='24px' mb='16px'>
          <Text variant='h5'>
            Edit Portal User Attributes
          </Text>
          <Text variant='small2'>
            Edit attributes of <strong>{user.email}</strong>.
          </Text>
          <FormControl
            fullWidth
            className={classes.formControl}
            error={formErrors['name'] !== undefined}
          >
            <Text variant='small1' pb='6px'>
              {'Full Name'}
            </Text>
            <TextField
              required
              fullWidth
              variant='outlined'
              type="text"
              value={name}
              onFocus={() => clearError('name')}
              onChange={(e) => setName(e.target.value)}
            />
            <FormHelperText>{formErrors['name']}</FormHelperText>
          </FormControl>
          <FormControl
              fullWidth
              className={classes.formControl}
              error={formErrors['role'] !== undefined}
          >
            <Text variant='small1' pb='6px'>
              {'Role'}
            </Text>
            <Select
              required
              value={role}
              variant='outlined'
              onFocus={() => clearError('role')}
              onChange={(e) => setRole(e.target.value)}
            >
              {roles.map((r) => (
                <MenuItem key={r.value} value={r.value}>{r.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{formErrors['role']}</FormHelperText>
          </FormControl>
        </Grid>
        <DialogActions>
          <Button onClick={() => onClose(null)} color="primary" variant='outlined' size='small'>Cancel</Button>
          <Button onClick={checkAndSend} color="primary" variant='contained' size='small'>Submit</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );  
}

export default EditUserDialog;
