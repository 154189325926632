import React from 'react'

import useModel from './model'

import { Box, Text, Grid } from '../../Base'

import TextField from '../../ControlComps/TextField'

const Comp = React.memo(({ stateCfg, onTriggerSetter, onCompleted, onHasChanged, ...props }) => {
  const [ state, methods ] = useModel(stateCfg)

  React.useEffect(() => {
    onTriggerSetter(() => {
      methods.doUpdateCreds()
    })
  }, [
    methods,
    onTriggerSetter,
  ])

  React.useEffect(() => {
    methods.setOnCompletedCallback(onCompleted)
  }, [
    methods,
    onCompleted,
  ])

  const hasChanged = state.hasChanged
  React.useEffect(() => {
    onHasChanged(hasChanged)
  }, [
    hasChanged,
    onHasChanged,
  ])

  return (
    <>
      <Grid
        gridGap='24px'
        gridTemplateRows='max-content'
        mr='16px'
      >
        {
          state.validationhint &&
            <Text
              variant='small2'
              color={'error.main'}
            >
              {state.validationhint}
            </Text>
        }
        <Box>
          <Text variant='small1' pb='6px'>
            {'Firebase Server Key'}
          </Text>
          <TextField
            fullWidth={true}
            multiline
            rows='2'
            variant='outlined'
            placeholder="Firebase console > Project Settings > Cloud Messaging > Server key"
            state={state.controls.serverKey}
            methods={methods}
          />
        </Box>
      </Grid>
    </>
  )
})

export default Comp