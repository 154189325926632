import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Select, MenuItem, InputBase } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { sortBy } from 'lodash'

import { Box, Flex, Text } from '../../../components/Base'

// import { useSetting, useSettingSetter } from '../../../lib/hooks'

const StyledInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.bg.primary,
    border: '1px solid',
    borderColor: theme.palette.border.card,
    padding: '6px 26px 6px 12px',
    '&:hover': {
      color: theme.palette.primary.main
    },
    '&:focus': {
      backgroundColor: theme.palette.bg.primary,
      borderRadius: 4,
    },
  },
}))(InputBase)

export const EntityDropdown = React.memo(({ entities, selectedEntity, setEntity, markerColor, noneText, noneVal, noneTextColor = 'inherit', hasNoneOption, }) => {
  return (
    <Select
      value={selectedEntity?.id || '__NONE'}
      onChange={(e) => {
        if (e.target.value === '__NONE') setEntity(noneVal)
        else setEntity(entities.filter((c) => c.id === e.target.value)[0])
      }}
      input={<StyledInput/>}
    >
      {
        hasNoneOption && <MenuItem key={-1} value={'__NONE'}>
          <Flex alignItems='center'>
            {
              (!selectedEntity?.id) && markerColor &&
                <Box
                  height='9px'
                  width='9px'
                  mr='8px'
                  bgcolor={markerColor}
                />
            }
            <Text color={noneTextColor} variant='button'>
              {noneText}
            </Text>
          </Flex>
        </MenuItem>
      }
      {
        entities.map((item, i) => {
          const isSelected = selectedEntity?.id === item.id

          return (
            <MenuItem key={i} value={item.id}>
              <Flex alignItems='center'>
                {
                  isSelected && markerColor &&
                    <Box
                      height='9px'
                      width='9px'
                      mr='8px'
                      bgcolor={markerColor}
                    />
                }
                <Text color='inherit' variant='button'>
                  {item.name}
                </Text>
              </Flex>
            </MenuItem>
          )
        })
      }
    </Select>
  )
})

export const CohortDropdown = React.memo(({ selectedEntity, setEntity, noneText = 'All Users', noneVal, hasNoneOption = true, }) => {
  const cohorts = useSelector((store) => {
    let ret = store.dbEntities.lists.cohorts.filter((c) => c.query.target === 'appusers' && c.query.type === 'withEvents') 
    return sortBy(ret, (item) => item.name)
  })

  return (
    <EntityDropdown
      entities={cohorts}
      selectedEntity={selectedEntity}
      setEntity={setEntity}
      noneVal={noneVal}
      noneText={noneText}
      hasNoneOption={hasNoneOption}
    />
  )
})