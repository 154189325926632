export default `<html>
  <head>
    <title></title>
    <style>
      body {
        margin: 0 auto;
        padding: 0px;
        color: #3A3F45;
      }
      table {
        width: 100%;
        font: 13px 'SFNSText','Helvetica Neue', Helvetica, sans-serif;
      }
      td {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
      table.content {
        background-color: white;
        max-width: 550;
      }
      td.logo {
        padding: 12px 0px 12px;
        border-bottom: 1px solid #d8d8d8;
        background-color: {{color_1}};
      }
      td.footer {
        padding: 0px 40px;
        background-color: #f3f3f3;
      }
      table.outer {
        max-width: 720;
      }
      a.content {
        text-decoration: underline;
      }
    </style>
  </head>
  <body bgcolor="#fafafa">
    <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
      <tbody>
        <tr>
          <td bgcolor="#fafafa">
            <table class="outer" cellspacing="0" cellpadding="0" border="0" align="center">
              <tbody>
                <tr>
                  <td>
                    <table width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#fff">
                      <tbody>
                        <tr>
                          <td align="center" valign="top" class="logo">
                            <img style="display: block; min-height: 24px; max-height: 48px;" alt={{orgName}} src={{logoURL}} />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <table class="content" cellspacing="0" cellpadding="0" border="0" align="center">
                              <tbody>
                                <tr>
                                  <td align="left" style="padding: 0px 40px;">
                                    <table cellspacing="0" cellpadding="0" border="0" align="center">
                                      <tbody>
                                        <tr>
                                          <td align="left" style="padding-top: 48px;">
                                            {{salutation}}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <table class="content" cellspacing="0" cellpadding="0" border="0" align="center">
                                              <tbody>
                                                <tr>
                                                  <td align="left">
                                                    <table cellspacing="0" cellpadding="0" border="0" align="center">
                                                      <tbody>
                                                        <tr>
                                                          <td align="left" style="padding-top: 24px;">
                                                            {{body}}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left" style="padding-top: 24px;">
                                            {{farewell}}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left" style="padding-bottom: 48px;">
                                            {{sender_name}}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td class="footer">
                            <table>
                              <tbody>
                                <tr>
                                  <td style="padding-top: 32px; padding-bottom: 48px;">
                                    <table cellspacing="0" cellpadding="0" border="0" style="max-width: 376px;" align="center">
                                      <tbody>
                                        <tr>
                                          <td style="padding-top: 16px; font-size: 10px; color: #666666;" align="center">
                                            © 2020 <b>{{orgName}}</b>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style="padding-top: 16px; font-size: 10px; color: #666666;" align="center">
                                            This email was sent to <b>{{email}}</b>. If you no longer wish to receive these emails you may <a class="content" href={{unsubscribe}}>unsubscribe</a> at any time.
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
</html>`