import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import EditForm from './EditForm';

import Slide from '@material-ui/core/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditDialog = ({open, onClose, org}) => {

  const title = `${org.id ? 'Edit' : 'New'} Account`;
  
  return(
    <Dialog open={open} onClose={() => onClose(null)} TransitionComponent={Transition} fullWidth={true} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <EditForm
          org={org}
          onSubmit={(data) => onClose(data)}
          onCancel={() => onClose(null)}
        />
      </DialogContent>
    </Dialog>
  );
}

export default EditDialog;
