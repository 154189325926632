// import React from 'react'

import {
  timeRangeToReadableStr,
} from '../../../lib/utils'

import { useModel } from '../../../lib/state'
import dbEntityModelFactory from '../entityModelFactory'
import initEntityModel from './initEntityModel'
import ContentComps from './ContentComps'

// import { AsyncExporterIconButton } from '../../AsyncExporter'
// import { RouteMapButton } from '../../RouteMapButton'

let entityCfg = {
  ContentComps,
  dbEntityType: 'chunks',
  label: 'Chunk',
  labelPlural: 'Chunks',
  icon: 'fas fa-hdd',
  relations: {
    belongsTo: {
      organization: false,
      app: false,
      appuser: true,
      shoe: true,
      activity: true,
    },
    hasOne: {
    },
    hasMany: {
    },
  },
  actionsPermissionCfgs: {
    role: {
      // TODO delete all activities, seen in appuser + shoe profile
      anyAdmin: { delete: true },
      anyRole: { export: true, routemap: true },
    },
  },
}

entityCfg = {
  ...entityCfg,
  nameLabel: 'Timestamp',
  nameDataPath: 'timestamp',
  getEntityName(entity) {
    return timeRangeToReadableStr(entity.timestamp)
  },
}

entityCfg = {
  ...entityCfg,
  customActions: {
    // export: {
    //   modifiesState: false,
    //   Comp: React.memo(({ entity, onCompleted, disabled, otherData }) => {

    //     const reqData = React.useMemo(() => {
    //       return {
    //         activityId: entity.id,
    //         filename: [
    //           'rawactivity',
    //           otherData?.primaryEntityNameClean,
    //           timeRangeToFilenameStr(entity.timestamp),
    //         ].filter((str) => str).join('_')
    //       }
    //     }, [entity, otherData])

    //       return (
    //         <AsyncExporterIconButton
    //         onCompleted={onCompleted}
    //         disabled={disabled}
    //         url='/saas/v1/data/v1/rawSegmentData'
    //         title={`Export raw activity data ${timeRangeToReadableStr(entity.timestamp)}`}
    //         filename={reqData.filename}
    //         reqData={reqData}
    //         />
    //       )
    //   })
    // },
  },
}

const entityModel =  dbEntityModelFactory(initEntityModel(entityCfg))
const useEntityModel = (stateInitializerArgs) => useModel(entityModel, stateInitializerArgs)
entityCfg.useEntityModel = useEntityModel

export default entityCfg
