import React from "react";
import { useIntl } from "react-intl";
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// sizes: xs, sm, md, lg, xl

const Alert = (props) => {
  const intl = useIntl();
  const alertOpen = props.open;
  const alertSize = props.size;
  const alertMessage = props.message;
  const alertTitle = props.title;
  const alertClose = props.onClose;
  
  return(
    <Dialog
        open={alertOpen}
        onClose={alertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={alertSize}
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
    >
      <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alertMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={alertClose} color="primary" autoFocus>
          {intl.formatMessage({id: "ZT.OK"})}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Alert;
