import { useModel, objectVariantOrPassthrough } from '../../lib/state'
import stateVariants from './variants'

///////////
// MODEL //
///////////
// TODO setRequireWarnedCancel
const model = {
  ///////////
  // STATE //
  ///////////
  stateInit(args) {
    let {
      variant,
      variantOpts
    } = args
    

    let newState = {
      ...objectVariantOrPassthrough(stateVariants, { variant, variantOpts }),
      isOpen: false,
    }

    newState.controls = {
      cancel: {
        methodKey: 'cancel',
        label: newState.cancelLabel,
        disabled: false,
      },
      confirm: {
        methodKey: 'confirm',
        label: newState.confirmLabel,
        disabled: !newState.confirmAlwaysEnabled,
      }
    }
    
    return newState
  },
  /////////////
  // METHODS //
  /////////////
  methodsInit(state) {
    return {
      open() {
        state.isOpen = true
      },
      close() {
        state.isOpen = false
      },
      // internal controls
      setOnCancel(val, opts) {
        state.onCancel = val
      },
      setOnConfirm(val, opts) {
        state.onConfirm = val
      },
      //
      setConfirmEnabled(val, opts) {
        state.controls.confirm.disabled = !state.confirmAlwaysEnabled && !val
      },
      addControls(val, opts) {
        console.log('MODAL: addControls: TODO')
      }
    }
  },
  ///////////
  // HOOKS //
  ///////////
  // HOOK_EXAMPLES: {
  //   EXAMPLE_methodKey: { // A key to access ./lib/hooks keyedHooks
  //     getHookArgs: (state) => { return {} },
  //     methodKey: 'setFields',
  //   },
  //   EXAMPLE_callback: { // A key to access ./lib/hooks keyedHooks
  //     getHookArgs: (state) => { return {} },
  //     callback: (res, methods) => {}
  //   },
  //   EXAMPLE_localHook: {
  //     useHook: (state, methods) => {},
  //   },
  // }
  hooks: {
    
  }
}

// returns [ state, methods ]
export default (stateInitializerArgs) => {
  return useModel(model, stateInitializerArgs)
}