import { useModel, objectVariantOrPassthrough, initControls, setControlVal } from '../../../../lib/state'
import stateVariants from './variants'

///////////
// MODEL //
///////////

const model = {
  ///////////
  // STATE //
  ///////////
  stateInit(args) {
    let ret = {
      ...objectVariantOrPassthrough(stateVariants, args),
      //
    }

    ret.controls = {
      title: {
        defaultVal: ret.title || '',
        methodKey: 'setTitle',
        _validator: (val) => {
          return (val && val !== '')  || 'Title is required'
        },
        _notChangedValidationHint: 'Title is required',
        _validateAllowNotChanged: true,
      },
      body: {
        defaultVal: ret.body || '',
        methodKey: 'setBody',
        _validator: (val) => {
          return (val && val !== '')  || 'Body is required'
        },
        _notChangedValidationHint: 'Body is required',
        _validateAllowNotChanged: true,
      },
    }

    initControls(ret)
  
    return ret
  },
  /////////////
  // METHODS //
  /////////////
  methodsInit(state) {
    return {
      setTitle(val, opts) {
        if (val.length > 60) val = val.substring(0, 60)
        setControlVal(state, state.controls.title, val)
      },
      setBody(val, opts) {
        setControlVal(state, state.controls.body, val)
      },
      //
      // TODO helper function in state lib to construct methods and spread them
      //
      _setShouldValidate(val, opts) {
        state.shouldValidate = val
        setControlVal(state)
      },
      _buildVal(val, opts) {
        const _val = {
          title: state.controls.title.val,
          body: state.controls.body.val,
        }

        state._builtVal = _val
      },
    }
  },
  hooks: {
    
  }
}

// returns [ state, methods ]
export default (...args) => {
  return useModel(model, ...args)
}