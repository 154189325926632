import React from 'react'

import { timeRangeToReadableStr, timeRangeToFilenameStr } from '../../../lib/utils'

import { useModel } from '../../../lib/state'
import dbEntityModelFactory from '../entityModelFactory'
import initEntityModel from './initEntityModel'
import ContentComps from './ContentComps'

import { AsyncExporterIconButton } from '../../AsyncExporter'
import { RouteMapButton } from '../../RouteMapButton'

let entityCfg = {
  ContentComps,
  dbEntityType: 'activities',
  label: 'Activity',
  labelPlural: 'Activities',
  icon: 'fas fa-hdd',
  relations: {
    belongsTo: {
      organization: true,
      app: true,
      appuser: true,
      shoe: true,
    },
    hasOne: {
      supplimentalData: true,
    },
    hasMany: {
      segments: true,
      comments: true,
    },
  },
  actionsPermissionCfgs: {
    role: {
      // TODO delete all activities, seen in appuser + shoe profile
      anyAdmin: { delete: true },
      anyRole: { export: true, routemap: true },
    },
  },
}

entityCfg = {
  ...entityCfg,
  nameLabel: 'Start - End',
  nameDataPath: null,
  getEntityName(entity) {
    return timeRangeToReadableStr(entity.start, entity.end)
  },
}

entityCfg = {
  ...entityCfg,
  customActions: {
    export: {
      modifiesState: false,
      Comp: React.memo(({ entity, onCompleted, disabled, otherData }) => {

        const reqData = React.useMemo(() => {
          return {
            activityId: entity.id,
            filename: [
              'rawactivity',
              otherData?.primaryEntityNameClean,
              timeRangeToFilenameStr(entity.start),
            ].filter((str) => str).join('_')
          }
        }, [entity, otherData])

          return (
            <AsyncExporterIconButton
            onCompleted={onCompleted}
            disabled={disabled}
            url='/saas/v1/data/v1/rawSegmentData'
            title={`Export raw activity data ${timeRangeToReadableStr(entity.start)}`}
            filename={reqData.filename}
            reqData={reqData}
            />
          )
      })
    },
    routemap: {
      /*
         This feature will call a Zcloud endpoint which will construct a geojson.io url with the route
         data and then open a new tab (window.open(url, _blank)) to geojson.io and display the map.
         This is pretty useful for debugging BUT ... really, in the future Zcloud probably won't have the
         map data ... webhooks will send activity data to a customer and the customer will be annotating
         activities with stuff like maps.  SO THIS WILL PROBABLY BE REMOVED IN A FUTURE VERSION OF THE PORTAL.
       */
      modifiesState: false,
      Comp: React.memo(({ entity, onCompleted, disabled, otherData }) => {
        if (
          entity.activityType.startsWith("Running") ||
          entity.activityType.startsWith("Walking") ||
          entity.activityType.startsWith("Cycling")
        ) {
          return(
            <RouteMapButton
            onCompleted={onCompleted}
            disabled={disabled}
            url={`/saas/v1/data/v1/activity/geojson/${entity.id}`}
            title={`View activity route map`}
            />
          );
        }
        else return null; // no maps for this activity type
      })
    }
  },
}

const entityModel =  dbEntityModelFactory(initEntityModel(entityCfg))
const useEntityModel = (stateInitializerArgs) => useModel(entityModel, stateInitializerArgs)
entityCfg.useEntityModel = useEntityModel

export default entityCfg
