import React from 'react'
import { useApi_apiUrl } from '../../../lib/hooks'
import { setControlVal } from '../../../lib/state'
import { uuidHexStr } from '../../../lib/utils'
import moment from 'moment-timezone'

const errorsPollDelayMs = 1000
const errorPollDurationMs_long = (20 * 1000) + 100
const errorPollDurationMs_short = (5 * 1000) + 100
const errorsLimit = 30

// const exampleErrors = [
//   { 
//     code: 400,
//     message: 'blah',
//     key: '???',
//     eventType: 'INSTALLED',
//     id: 'blah',
//   },
//   { 
//     code: 400,
//     message: 'blah',
//     key: '???',
//     eventType: 'INSTALLED',
//     id: 'blah2',
//   }
// ]

// TODO maybe handle legacy auth

const _errorPollStart = (state, errorPollDurationMs) => {
  state._pollErrorsToken = Math.random()
  state.errorPollingUntill = moment().add(errorPollDurationMs, 'ms')
}

const initEntityModel = (entityCfg) => {
  return {
    dbEntityType: entityCfg.dbEntityType,
    entityMethodsInit(state, { _trySaveOnNextValidate }) {
      return {
        setUrl(val, opts) {
          setControlVal(state, state.controls.url, val)
        },
        setCompress(val, opts) {
          setControlVal(state, state.controls.compress, val)
        },
        setSelectedAuth(val, opts) {
          setControlVal(state, state.controls.selectedAuth, val)
        },
        //
        // Auth Basic
        //
        setAuth_basicUsername(val, opts) {
          setControlVal(state, state.controls.auth_basicUsername, val)
        },
        setAuth_basicPassword(val, opts) {
          setControlVal(state, state.controls.auth_basicPassword, val)
        },
        //
        // Interactions
        //
        setDisabled(val, opts) {
          setControlVal(state, state.controls.disabled, val)
          _trySaveOnNextValidate(state)
        },
        generatePublicKey(val, opts) {
          state._toGeneratePublicKeyId = state.initialData.id
        },
        _generatePublicKeyCb(val, opts) {
          state._toGeneratePublicKeyId = undefined
          state.publicKey = val.publicKey
        },
        //
        // Attributes
        //
        setAttributesBuilderHasChanged(val, opts) {
          state.attributesBuilderHasChanged = val
        },
        setAttributes(val, opts) {
          setControlVal(state, state.controls.attributes, val)
          if(state.controls.attributes.hasChanged && state._triggerAttributesUpdateToken >= 0) {
            _trySaveOnNextValidate(state)
          }
        },
        saveAttributes(val, opts) {
          state._triggerAttributesUpdateToken = Math.random()
        },
        //
        // Error interactions
        //
        errors_resendAll(val, opts) {
          state._errorsToResend = state.errors.map((err) => err.id)
          _errorPollStart(state, errorPollDurationMs_long)
        },
        errors_resendOne(val, opts) {
          state._errorsToResend = [ val.id ]
          _errorPollStart(state, errorPollDurationMs_short)
        },
        _errorResent(val, opts) {
          state._errorsToResend.shift()
        },
        errors_deleteAll(val, opts) {
          state._errorsToDelete = state.errors.map((err) => err.id)
        },
        errors_deleteOne(val, opts) {
          state._errorsToDelete = [ val.id ]
        },
        _errorDeleted(val, opts) {
          state._errorsToDelete.shift()
          const idx = state.errors.findIndex((item) => item.id === val)
          if (idx >= 0) state.errors.splice(idx, 1)
          if (state._errorsToDelete.length === 0) {
            state._pollErrorsToken = undefined
          }
        },
        // polling
        errors_pollStart(val, opts) {
          _errorPollStart(state, errorPollDurationMs_long)
        },
        errors_pollStop(val, opts) {
          state._pollErrorsToken = -1
          state.errorPollingUntill = null
        },
        errors_pollContinue(val, opts) {
          if (state._pollErrorsToken >= 0 && state.errorPollingUntill && state.errorPollingUntill.isAfter(moment())) {
            state._pollErrorsToken = Math.random()
          }
          else {
            state._pollErrorsToken = -1
            state.errorPollingUntill = null
          }
        },
        _setErrors(val, opts) {
          state.errors = val
        },
        //
        // Test
        //
        // TODO move test stuff to nested model
        setTestBody(val, opts) {
          setControlVal(state, state.controls.testBody, val)
        },
        sendTestEvent(val, opts) {
          state.shouldValidate = true
          setControlVal(state, state.controls.testBody, state.controls.testBody.val)
          state._sendTestEventToken = Math.random()
        },
        _sentTestEvent(val, opts) {
          state._sendTestEventToken = null
          _errorPollStart(state, errorPollDurationMs_short)
        },
        //
        // Send Historical Data
        //
        setStartDate(val, opts) {
          setControlVal(state, state.controls.startDate, val)
        },
        setEndDate(val, opts) {
          setControlVal(state, state.controls.endDate, val)
        },
        setDryRun(val, opts) {
          setControlVal(state, state.controls.dryRun, val)
          // _trySaveOnNextValidate(state)
        },
        sendHistoricalData(val, opts) {
          state._sendHistoricalDataToken = Math.random()
        },
        _sentHistoricalData(val, opts) {
          state._sendHistoricalDataToken = null
        },
        _setResponse(val, opts) {
          state.response = val
        }
      }
    },
    getControlsCfg(state, isNew, isClone, isSaveAs) {
      return {
        url: {
          methodKey: 'setUrl',
          _validator: (val) => {
            if (!(val && val !== '')) {
              return 'Url is required'
            }

            const lower = val.toLowerCase()

            if (!lower.startsWith('https://') && !lower.startsWith('http://')) {
              return `Url must start with 'https://' or 'http://'`
            }

            return true
          },
          _validateAllowNotChanged: true,
        },
        compress: {
          methodKey: 'setCompress',
          trueText: 'Enabled',
          falseText: 'Disabled',
          _validateAllowNotChanged: true,
        },
        selectedAuth: {
          methodKey: 'setSelectedAuth',
          menuCfg: {
            itemsCfg: entityCfg.otherData.authTypes,
            firstIsDefault: true,
          },
          _validateAllowNotChanged: true,
        },
        //
        // Auth Basic
        //
        auth_basicUsername: {
          methodKey: 'setAuth_basicUsername',
          _validator: (val, _, parentState) => {
            if (parentState.controls.selectedAuth.val !== 'basic') {
              return true
            }

            if (!(val && val !== '')) {
              return 'Username is required'
            }

            if (val.includes(':')) {
              return `Username must not contain ':'`
            }

            return true
          },
          _validateAllowNotChanged: true,
        },
        auth_basicPassword: {
          methodKey: 'setAuth_basicPassword',
          _validator: (val, _, parentState) => {
            if (parentState.controls.selectedAuth.val !== 'basic') {
              return true
            }

            if (!(val && val !== '')) {
              return 'Password is required'
            }

            // if (val.length < 6) {
            //   return 'Password must be 6 or more characters'
            // }

            return true
          },
          _validateAllowNotChanged: true,
        },
        //
        // Other
        //
        disabled: {
          methodKey: 'setDisabled',
          defaultVal: false,
          trueText: 'Disabled',
          falseText: 'Enabled',
          flipStyle: true,
          variant: 'onOffStatus',
          _validateAllowNotChanged: true,
        },
        attributes: {
          _validateAllowNotChanged: true,
        },
        //
        // Test
        //
        testBody: {
          methodKey: 'setTestBody',
          _validator: (val) => {
            if (!val) return true

            if (!val.trim().startsWith('{')) {
              return 'Must be valid a JSON object'
            }

            try {
              JSON.parse(val)
            }
            catch (e) {
              return 'Invalid JSON'
            }
            return true
          },
          _validateAllowNotChanged: true,
        },
        //
        // Send Historical Data
        //
        startDate: {
          methodKey: 'setStartDate',
          _validateAllowNotChanged: true,
        },
        endDate: {
          methodKey: 'setEndDate',
          _validateAllowNotChanged: true,
        },
        dryRun: {
          methodKey: 'setDryRun',
          trueText: 'True',
          falseText: 'False',
          _validateAllowNotChanged: true,
        },
      }
    },
    isPartialEntity(data) {
      return !data.url
    },
    handleSetData(state, data, hasControls) {
      state.errorPollingUntill = null
      state.errorsLimit = errorsLimit
      state._pollErrorsToken = undefined
      state._triggerAttributesUpdateToken = undefined

      if (hasControls) {
        state.controls.url.defaultVal = data.url || ''
        let compress = data.compress
        if (compress === undefined) compress = true
        state.controls.compress.defaultVal = compress
        state.controls.selectedAuth.defaultVal = Object.keys(data.auth || {})?.[0]

        // Auth Basic
        state.controls.auth_basicUsername.defaultVal = data.auth?.basic?.username || ''
        state.controls.auth_basicPassword.defaultVal = data.auth?.basic?.password || ''

        // Other
        state.controls.disabled.defaultVal = data.disabled || false
        state.controls.attributes.defaultVal = data.attributes
        state.controls.startDate.defaultVal = data.startDate
        state.controls.endDate.defaultVal = data.endDate
        let dryRun = data.dryRun
        if (dryRun === undefined) dryRun = false
        state.controls.dryRun.defaultVal = dryRun
      }

      state.publicKey = data.auth?.signature?.publicKey
      state.errors = data.errors || []
      state.response = data.response || {}
    },
    buildOutData(state) {
      let ret = {
        url: state.controls.url.val,
        compress: state.controls.compress.val,
        auth: {},
        disabled: state.controls.disabled.val,
        attributes: state.controls.attributes.val,
        startDate: state.controls.startDate.val,
        endDate: state.controls.endDate.val,
        dryRun: state.controls.dryRun.val
        
        // CURRENTLY HIDDEN
        // timeout: state.initialData,
        // maxRetries: state.maxRetries,
        // headers: state.headers,
      }

      switch (state.controls.selectedAuth.val) {
        case 'basic':
          ret.auth = {
            basic: {
              username: state.controls.auth_basicUsername.val,
              password: state.controls.auth_basicPassword.val,
            }
          }
          break
        
        case 'signature':
          ret.auth = {
            signature: {
              publicKey: state.initialData?.auth?.signature?.publicKey
            },
          }
          break

        default:
          break
      }

      // console.log(ret)

      return ret
    },
    hookOverrides: {},
    hooks: {
      // Start Hooks
      useGeneratePublicKey: {
        getHookArgs: (state) => ({
          dbEntityType: state.dbEntityType,
          id: state._toGeneratePublicKeyId,
        }),
        callback: (res, methods) => {
          methods._generatePublicKeyCb(res)
        },
        useHook: (args = {}, cb) => {
          const { id, dbEntityType } = args
          const [ api, url ] = useApi_apiUrl(`/${dbEntityType}/${id}/generateKeyPair`)
          React.useEffect(() => {
            if (!id) return
        
            api({
              method: 'get',
              url,
              handler: cb,
            })
          }, [cb, api, url, id])
        }
      },

      useResendErrors: {
        getHookArgs: (state) => ({
          id: state._errorsToResend?.[0],
        }),
        callback: (res, methods) => {
          methods._errorResent(res)
        },
        useHook: (args = {}, cb) => {
          const { id } = args
          const [ api, url ] = useApi_apiUrl(`/webhookerrors/resend/${id}`)
          React.useEffect(() => {
            if (!id) return
        
            api({
              method: 'put',
              url,
              handler: cb,
            })
          }, [cb, api, url, id])
        }
      },

      useDeleteErrors: {
        getHookArgs: (state) => ({
          id: state._errorsToDelete?.[0],
        }),
        callback: (res, methods) => {
          methods._errorDeleted(res)
        },
        useHook: (args = {}, cb) => {
          const { id } = args
          const [ api, url ] = useApi_apiUrl(`/webhookerrors/${id}`)
          React.useEffect(() => {
            if (!id) return
        
            api({
              method: 'delete',
              url,
              handler: (res) => {
                cb(id)
              },
            })
          }, [cb, api, url, id])
        }
      },

      usePollErrors: {
        getHookArgs: (state) => ({
          id: state.initialData.id,
          token: state._pollErrorsToken,
          limit: state.errorsLimit,
        }),
        callback: (res, methods) => {
          methods._setErrors(res)
          const cont = methods.errors_pollContinue
          setTimeout(() => cont(), errorsPollDelayMs)
        },
        useHook: (args = {}, cb) => {
          const { id, token, limit } = args
          const [ api, url ] = useApi_apiUrl(`/webhooks/${id}/errors`)
          React.useEffect(() => {
            if (!id) return

            let isLoading

            if (token !== undefined) {
              if (token < 0) return
              isLoading = () => {}// eat page loading ui
            }
        
            api({
              method: 'getFiltered',
              url,
              handler: cb,
              data: {
                order: 'createdAt DESC',
                limit,
              },
              isLoading,
            })
          }, [cb, api, url, id, token, limit])
        }
      },

      useSendTestEvent: {
        getHookArgs: (state) => ({
          id: state.initialData.id,
          token: state._sendTestEventToken,
          body: state.controls.testBody?.val || '',
          isValid: state.controls.testBody?.isValid || true,
        }),
        callback: (res, methods) => {
          methods._sentTestEvent()
        },
        useHook: (args = {}, cb) => {
          const { id, token, body, isValid } = args
          const [ api, url ] = useApi_apiUrl(`/webhooks/sendTestMessage/${id}/Test`)
          React.useEffect(() => {
            if (!id || !token) return

            if (!isValid) {
              cb()
              return
            }

            let data = JSON.parse(body || '{}')
        
            api({
              method: 'post',
              url,
              data: {
                id: uuidHexStr(),
                ...data,
              },
              handler: cb,
            })
          }, [cb, api, url, token, id, body, isValid])
        }
      },

      useSendHistoricalData: {
        getHookArgs: (state) => ({
          id: state.initialData.id,
          token: state._sendHistoricalDataToken,
          startDate: state.controls.startDate?.val || '',
          endDate: state.controls.endDate?.val || '',
          dryRun: state.controls.dryRun?.val || false,
          // isValid: state.controls.testBody?.isValid || true,
        }),
        callback: (res, methods) => {
          methods._sentHistoricalData()
          methods._setResponse(res)
        },
        useHook: (args = {}, cb) => {
          const { id, token, startDate, endDate, dryRun, isValid } = args
          const [ api, url ] = useApi_apiUrl(`/webhooks/${id}/sendHistorical`)
          React.useEffect(() => {
            if (!id || !token) return

            let data = {
              'startDate': startDate,
              'endDate': endDate,
              'dryRun': dryRun
            }
        
            api({
              method: 'post',
              url,
              data: {
                id: uuidHexStr(),
                ...data,
              },
              handler: cb,
            })
          }, [cb, api, url, token, id, isValid, startDate, endDate, dryRun])
        }
      },
      // End Hooks
    },

    
  }
}

export default initEntityModel