import React from 'react'

import moment from 'moment-timezone'
import { EventNames, parseRole } from '../../../lib/utils'

import * as auth from "../../../store/ducks/auth.duck"

import { useSelector, useDispatch } from 'react-redux'
import useApi from '../../../lib/useApi'
import useService from "../../../lib/useService";
import { useParams, useHistory } from "react-router-dom"

import Button from '@material-ui/core/Button'
import CardMedia from '@material-ui/core/CardMedia'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
import TextField from '@material-ui/core/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Box, Flex, Text, Card } from '../../Base'
import useStyles from '../../../lib/useStyles'

import { PortletBody } from "../../../partials/content/Portlet"
import PerfectScrollbar from "react-perfect-scrollbar"
import Confirm from '../../Confirm'
import AttributeList from '../../AttributeList'

const DEFAULT_IMG = "/media/users/default.jpg"

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
}

const AppusersProfile = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const loggedInUser = useSelector(store => store.auth.user);
  const urole = parseRole(loggedInUser.role);

  const [profile, setProfile] = React.useState({});
  const [userAttrs, setUserAttrs] = React.useState({});
  //const [shoes, setShoes] = React.useState([]);
  const [eventHistory, setEventHistory] = React.useState([]);
  const [expansionState, setExpansionState] = React.useState({});
  const [openDeleteUserConfirm, setOpenDeleteUserConfirm] = React.useState(false);
  const [openDeleteUserActivityConfirm, setOpenDeleteUserActivityConfirm] = React.useState(false);

  // Used for the admin menu, and user start date change
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDatetime, setShowDatetime] = React.useState(false);
  const [startDate, setStartDate] = React.useState();

  // For calling backend apis
  const fetchUserData = useApi();
  const fetchEventData = useApi();
  const api = useApi();
  const {notify} = useService();

  React.useEffect(() => {
    const handler = (rs) => {
      setProfile(rs.profile);
      setUserAttrs(rs.attributes);
      //setShoes(rs.shoes);
      setEventHistory(rs.history);
      // initialize the datetime widget for changing the user's start date from
      // admin menu
      setStartDate(moment(rs.profile.joined, 'lll'));
      dispatch(auth.actions.setCurrentAppuser({ id, ...rs.profile}));
    };

    fetchUserData({method: "post", url: "/saas/v1/data/v1/exploreUser", data: {appuserId: id}, handler});

  }, [id, fetchUserData, dispatch] );

  const handleExpand = (ts, tz) => {
    let idx = eventHistory.findIndex((item) => { return item.timestamp === ts; });
    if ( eventHistory[idx].events.length ) {
      // already fetched
      setExpansionState({
        ...expansionState,
        [ts]: expansionState[ts] === true ? false : true
      });
      return;
    }
    const start = moment(ts).tz(tz);
    const end = moment(start).tz(tz).add(24, 'hours');

    fetchEventData({
      method: "getFiltered",
      url: `/api/AppUsers/${id}/events`,
      data: {where: {timestamp: {between: [start, end]}}, order: 'timestamp DESC'},
      handler: (events) => {
        setEventHistory([
          ...eventHistory.slice(0, idx),
          { timestamp: ts, tz, events },
          ...eventHistory.slice(idx+1)
        ]);
        setExpansionState({
          ...expansionState,
          [ts]: expansionState[ts] === true ? false : true
        });
      }
    });
  }

  const deleteUser = (data) => {
    setOpenDeleteUserConfirm(false);
    if ( ! data ) return;
    api({method: "delete", url: `/api/AppUsers/${id}/everything`, handler: () => {
      history.goBack();
    }});
  }

  const deleteUserActivity = (data) => {
    setOpenDeleteUserActivityConfirm(false);
    if ( ! data ) return;
    api({method: "delete", url: `/api/AppUsers/${id}/activityData`, handler: () => {
      notify("success", "All activity data deleted!");
    }});
  }

  const renderEvent = (ev) => {
    let res;
    switch (ev.name) {
      case EventNames.STOP_ACTIVITY:
        res = `${ev.name} status=${ev.data.status}`;
        break;
      case EventNames.CONNECT:
      case EventNames.SCAN:
      case EventNames.CALIBRATION:
        res = `${ev.name} status=${ev.data.status}`;
        break;
      case EventNames.ADD_SEGMENT:
        res = `${ev.name}`;
        break;
      default:
        res = ev.name;
        break;
    }
    return res;
  }

  // Admin Menu stuff
  const handleAdminMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAdminMenuClose = () => {
    setAnchorEl(null);
  };

  // Change the user's start date from admin menu
  const changeStartDate = (newDate) => {
    setShowDatetime(false);
    if ( ! newDate.toISOString() ) return;
    api({method: "patch", url: `/api/AppUsers/${id}`, data: {createdAt: newDate.toISOString()}, handler: () => {
      setProfile({
        ...profile,
        joined: newDate.format('lll')
      });
    }});
  }

  const userName = userAttrs.name

  return(
    <>
      <Flex>
        <Flex flexDirection='column' mr='32px' style={{ flexBasis:'50%'}}>
          {/* <Flex justifyContent='space-between' m='32px'> */}
          <Card alignItems='start' mb='32px' p='16px' display='flex' justifyContent='space-between'>
            <Flex alignItems='center'>
              <CardMedia className={classes.avatarLarge} image={profile.picture && profile.picture.data ? profile.picture.data : DEFAULT_IMG} title="Title" />
              <Box ml='16px'>
                <Text variant="h6" mb='0.5rem'>{userName || profile.userId}</Text>
                {
                  userName &&
                    <Flex>
                      <Text variant="subtitle2" color="text.secondary" className={classes.tableCell_Head} >User Id </Text>
                      <Text variant="body2" color="text.secondary" className={classes.tableCell_Body2} >{profile.userId}</Text>
                    </Flex>
                }
                <Flex>
                  <Text variant="subtitle2" color="text.secondary" className={classes.tableCell_Head} >Joined </Text>
                  <Text variant="body2" color="text.secondary" className={classes.tableCell_Body2} >{profile.joined}</Text>
                </Flex>
                <Flex>
                  <Text variant="subtitle2" color="text.secondary" className={classes.tableCell_Head}>Last active </Text>
                  <Text variant="body2" color="text.secondary" className={classes.tableCell_Body2} >{profile.lastActive}</Text>
                </Flex>
              </Box>
            </Flex>
            <Box>
                <>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    size='small'
                    variant='outlined'
                    color='primary'
                    endIcon={<MenuIcon />}
                    onClick={handleAdminMenuClick}
                  >
                    Admin
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleAdminMenuClose}
                  >
                    {urole.admin && (<MenuItem onClick={(e) => {handleAdminMenuClose(e); setOpenDeleteUserConfirm(true);}}>Delete User...</MenuItem>)}
                    {urole.admin && (<MenuItem onClick={(e) => {handleAdminMenuClose(e); setOpenDeleteUserActivityConfirm(true);}}>Delete Activity...</MenuItem>)}
                    <MenuItem onClick={(e) => {handleAdminMenuClose(e); history.push(`/logs/${profile.userId}`);}}>View Logs...</MenuItem>
                    <MenuItem onClick={(e) => {handleAdminMenuClose(e); history.push(`/explorer`);}}>View API...</MenuItem>
                    {urole.admin && (<MenuItem onClick={(e) => {handleAdminMenuClose(e); setShowDatetime(true);}}>Change Start Date...</MenuItem>)}
                  </Menu>
                </>
              {showDatetime && (
                <div>
                  <TextField
                    type="datetime-local"
                    label="Start Date"
                    value={startDate.format('YYYY-MM-DDThh:mm')}
                    onChange={(e) => changeStartDate(moment(e.target.value,'YYYY-MM-DDThh:mm'))}
                  />
                </div>
              )}
            </Box>
            {/* </Flex> */}
          </Card>
          <Box>
            <Text variant='h6' mb='8px'>Event History</Text>
            <PortletBody>
              {eventHistory.map((day) => (
                <ExpansionPanel key={day.timestamp} expanded={expansionState[day.timestamp]||false} onChange={() => handleExpand(day.timestamp, day.tz)}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                    <Text variant='body2'>{moment(day.timestamp).tz(day.tz).format('dddd MMMM D, YYYY Z')}</Text>
                  </ExpansionPanelSummary>
                  {day.events.length &&
                    <ExpansionPanelDetails>
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        style={{ maxHeight: "50vh", position: "relative", width: "100%" }}
                      >
                        <div className="kt-timeline-v2 ps ps--active-y">
                          <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
                            {day.events.map((e) => (
                              <div key={`${e.name}-${e.timestamp}`} className="kt-timeline-v2__item">
                                <span className="kt-timeline-v2__item-time">{moment(e.timestamp).tz(e.tz).format('HH:mm')}</span>
                                <div className="kt-timeline-v2__item-cricle">
                                  <i className="fa fa-genderless kt-font-danger" />
                                </div>
                                <div className="kt-timeline-v2__item-text kt-padding-top-5">
                                  {renderEvent(e)}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </ExpansionPanelDetails>
                  }
                </ExpansionPanel>
              ))}
            </PortletBody>
          </Box>
        </Flex>
        <Flex flexDirection='column' style={{ flexBasis:'50%'}}>
          {/* <Text variant='h6' mb='8px'>User Attributes</Text> */}
          <AttributeList title='User Attributes' attrs={userAttrs} />
        </Flex>
      </Flex>
      <Confirm
          size="sm"
          open={openDeleteUserConfirm}
          onClose={deleteUser}
          title="Delete App User"
          passthru={id}
      >
        This development only function will <strong>delete</strong> this user and all of their associated data, like
        shoes, events, activity, etc.  Are you sure you want to do this?
      </Confirm>

      <Confirm
          size="sm"
          open={openDeleteUserActivityConfirm}
          onClose={deleteUserActivity}
          title="Delete User Activity"
          passthru={id}
      >
        This development only function will <strong>delete</strong> this user's activity data.  This is irreversible!
        All past activity data will be deleted.  Use this feature only if you want to start capturing new activity
        data from scratch.
      </Confirm>
    </>
  );
}

export default AppusersProfile
