import React from 'react'

import moment from 'moment-timezone'
import { useTheme } from '@material-ui/core/styles'

import useStyles from '../../../lib/useStyles'
import { keyedHooks, useApi_OrgId_AppId_AppIds } from '../../../lib/hooks'

import { useModel_ChartJs } from '../../../components/Charting/ChartJsModel'
import ChartContainer from '../../../components/Charting/ChartJsContainer'
import Modal from '../../../components/Modal'
import { Grid, Card, Text, Flex } from '../../../components/Base'

import { datasetColors, useActivityChartModalProps } from '../util'
import { queryData_toLoopback } from '../../../components/Query/util'
import { normPointsAndTimeUnit } from '../../../lib/utils'

//
//

export const ExternalFetchStatsCard = React.memo(({ title, routeTo, onClick, variant, data, mainColor, noAnimation, titleColor, entity, entityName, dateRange, cohort, activityLabel, activityName, ...props }) => {
  const classes = useStyles()

  const [ chartState, chartMethods ] = useModel_ChartJs({
    variant: variant,
    variantOpts: {
      theme: useTheme(),
      zeroCompensationColor: '#8E8E93', // apple grey 2
      data,
      datasetLabel: title,
      mainColor,
      animation: noAnimation ? { duration: 0 } : undefined
    },
  })

  React.useEffect(() => {
    if (data?.dataSets) {
      chartMethods.setDataSetsRaw(data.dataSets, data.opts)
    }
    else if (data) {
      chartMethods.setData(data)
    }
  }, [data, chartMethods])

  if (activityName && !Array.isArray(activityName)) {
    const modalProps = useActivityChartModalProps({entity, entityName, dbEntityType: 'appusers', dateRange, cohort, activityLabel, activityName })
    const openModalRef = React.useRef()
    return (
      <Card
        p='0px'
        variant='raisedBordered'
        cursor='pointer'
        className={classes.statsCard}
        onClick={() => openModalRef.current()}
        {...props}
      >
        <Grid
          p='16px'
          gridGap='24px'
        >
          <Flex justifyContent='space-between' alignItems='center'>
            <Text color={titleColor || 'inherit'} variant='subtitle3'>
              {title}
            </Text>
            <i className='fas fa-expand-alt'/>
          </Flex>
          <ChartContainer height='154px' state={chartState} methods={chartMethods}/>
        </Grid>
        <Modal
          {...modalProps}
          // TitleIcon={icon}
          openRef={openModalRef}
        />
      </Card>
    )
  }

  return (
    <Card
      p='0px'
      variant='raisedBordered'
      cursor='pointer'
      className={classes.statsCard}
      {...props}
    >
      <Grid
        p='16px'
        gridGap='24px'
      >
        <Flex justifyContent='space-between' alignItems='center'>
          <Text color={titleColor || 'inherit'} variant='subtitle3'>
            {title}
          </Text>
        </Flex>
        <ChartContainer height='154px' state={chartState} methods={chartMethods}/>
      </Grid>
    </Card>
  )
})

// const EXAMPLE_ActivityDataPropertyChartGrid_cfg = [
//   // seperator
//   {
//     title: 'Posture',
//   },
//   // chart
//   {
//     label: 'Standing Time',
//     dataKey: 'standing',
//   },
// ]

// TODO locally calulated risk colorings
export const ActivityDataPropertyChartGrid = React.memo(({ cfg, mainColor, dateRange, appusersWhere, shoesWhere, averagePerEntity, noAnimation, activitiesDataAgg, entity, entityName, timeUnit = 'minutes', ...props }) => {
  const [ api, organizationId, appId, appIds ] = useApi_OrgId_AppId_AppIds()

  const [ pluginFields, setPluginFields ] = React.useState({})
  keyedHooks.useCurrentPluginFields({ appId }, setPluginFields)

  const [ allData, setAllData ] = React.useState()
  React.useEffect(() => {
    let interval = 'day'
    const numDays = moment(dateRange.end).diff(moment(dateRange.start), 'days') || 1
    if (numDays <= 1) interval = 'hour'
    if (numDays > 30) interval = 'week'

    let _appusersWhere = {}
    if (appusersWhere?.query) {
      _appusersWhere = queryData_toLoopback(appusersWhere?.query) || {}
    }

    api({
      method: 'post',
      url: '/saas/v1/stats/v1/entitiesActivitiesDataAgg',
      data: {
        tz: global.TZ,
        organizationId,
        appId,
        appIds,
        start: dateRange.start,
        end: dateRange.end,
        interval,
        appusersWhere: _appusersWhere,
        shoesWhere,
        activityDataFields: cfg.map((item) => item.dataKey).filter(o => o).flat(),
        aggAcrossEntities: true,
      },
      handler: (res) => {
        // {
        //   entityCount: 10 // only counts entities that had activities
        //   series: [{timestamp: "2020-07-20T07:00:00.000Z", standing: 0, crouch: 0, ...}, ...]
        // }
        setAllData(res)
      },
    })
  }, [
    api,
    organizationId,
    appId,
    appIds,
    dateRange,
    appusersWhere,
    shoesWhere,
    cfg,
  ])

  const [ chartsData, setChartsData ] = React.useState({})
  React.useEffect(() => {
    if (!allData?.series) return
    if (!pluginFields) return

    // TODO clear points if allData.series is not present

    const _chartsData = {}
    const allPointsMap = {}

    const pointLabels = allData.series.map((p) => moment(p.timestamp))

    const calcPointsAndUnit = (p, yUnit) => {
      if (averagePerEntity) {
        p = p.map((val) => val / allData.entityCount)
      }

      // [ p, yUnit ]
      return normPointsAndTimeUnit(p, yUnit, timeUnit) 
    }

    cfg.forEach((item) => {
      const dataKey = item.dataKey
      if (!dataKey) return

      // Multiple datasets
      if (Array.isArray(dataKey)) {
        const dataSets = []

        dataKey.forEach((dk, i) => {
          const yUnit = pluginFields[dataKey[0]]?.unit

          let [ points, _yUnit ] = calcPointsAndUnit(allData.series.map((o) => o[dk] || 0), yUnit)

          allPointsMap[dk] = points

          dataSets.push({
            label: item.dataKeyLabels[i],
            backgroundColor: datasetColors[i],
            borderColor: datasetColors[i],
            data: points,
            fill: false,
            borderWidth: 1,
            pointRadius: 1.5,
            pointHoverRadius: 3,
          })

          if (i === 0) {
            _chartsData[item.localDataKey] = {
              dataSets,
              opts: {
                labels: pointLabels,
                yUnit: _yUnit,
              },
            }
          }
        })
      }
      // Single dataset
      else {
        const yUnit = pluginFields[dataKey]?.unit
        let [ points, _yUnit ] = calcPointsAndUnit(allData.series.map((o) => o[dataKey] || 0), yUnit)

        allPointsMap[dataKey] = points

        _chartsData[dataKey] = {
          points,
          pointLabels,
          yUnit: _yUnit,
        }
      }      
    })

    // Handle locally calculated
    cfg.forEach((item) => {
      if (!item.calc) return
      _chartsData[item.localDataKey] = {
        ...item.calc(allPointsMap),
        pointLabels,
      }
    })

    setChartsData(_chartsData)
  }, [allData, pluginFields, cfg, averagePerEntity, timeUnit])


  return (
    <Grid
      gridTemplateColumns='1fr 1fr 1fr'
      gridAutoRows='max-content'
      gridGap='8px'
    >
      {
        cfg.map((item, i) => {
          if (item.title) {
            return (
              <Text key={item.title} variant='h6' gridColumnStart='span 3' my='8px'>
                {/* {
                  average
                    ? 'Average ' + item.title
                    : item.title
                } */}
                {item.title}
              </Text>
            )
          }
          else if (item.label) {
            return (
              <ExternalFetchStatsCard
                key={i}
                title={
                  averagePerEntity
                    ? item.label + ' (Average)'
                    : item.label
                }
                titleColor={activitiesDataAgg?.meta?.[item.localDataKey || item.dataKey]?.color}
                // routeTo='/analysis/chart/userActivated'
                variant={'safetyLine'}
                data={chartsData?.[item.localDataKey || item.dataKey]}
                mainColor={mainColor}
                noAnimation={noAnimation}
                dateRange={dateRange}
                activityLabel={item.label}
                activityName={item.dataKey}
                entity={entity}
                entityName={entityName}
              />
            )
          }
          else return null
        })
      }
    </Grid>
  )
})