import React from 'react'
import { useApi_OrgId_AppId_AppIds } from '../../lib/hooks'
import moment from 'moment-timezone'
import ControlledChart from '../../components/Charting/ControlledChart'
import { ModalLink } from '../../components/Modal'
import { useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import { queryData_toLoopback } from '../../components/Query/util'
import { useSetting } from '../../lib/hooks'

export const workloadFieldsCfg = [
  {
    label: 'Walking',
    dataField: 'walkingTime',
  },
  {
    label: 'Stairs',
    dataField: 'stairsTime',
  },
  {
    label: 'Other Motion',
    dataField: 'trampling',
  },
  {
    label: 'Standing',
    dataField: 'standing',
  },
  {
    label: 'Kneeling',
    dataField: 'kneeling',
  },
  {
    label: 'Crouching',
    dataField: 'crouch',
  },
  {
    label: 'Tip Toe',
    dataField: 'tiptoe',
  }
]

export const defaultRiskThresholds = {
  walkingTime: [ 0, 240, 480 ], // minutes per day
  stairsTime: [ 0, 120, 240 ],
  trampling: [ 0, 240, 360 ],
  standing: [ 0, 120, 240 ],
  kneeling: [ 0, 60, 120 ],
  crouch: [ 0, 60, 120 ],
  tiptoe: [ 0, 60, 120 ],
}

export const riskCfg = [
  {
    label: 'High Risk',
    color: '#F34D4D',
    dataField: 'highRisk',
  },
  {
    label: 'Medium Risk',
    color: '#FEAD54',
    dataField: 'mediumRisk',
  },
  {
    label: 'Low Risk',
    color: '#2ED786',
    dataField: 'lowRisk',
  },
]

export const datasetColors = [
  '#2AC9FF',
  '#FFBD1A',
]

export const useEntitiesActivitiesDataAgg = ({ dataHandler, dateRange, averagePerDay = true, appusersWhere, bulkAgg, riskThresholds }) => {
  const [ api, organizationId, appId, appIds ] = useApi_OrgId_AppId_AppIds()

  React.useEffect(() => {
    if (!appusersWhere) {
      dataHandler(null)
      return
    }

    const activityDataFields = workloadFieldsCfg.map((o) => o.dataField)

    let _appusersWhere = {}
    if (appusersWhere?.query) {
      _appusersWhere = queryData_toLoopback(appusersWhere?.query) || {}
    }

    api({
      method: 'post',
      url: '/saas/v1/stats/v1/entitiesActivitiesDataAgg',
      data: {
        tz: global.TZ,
        organizationId,
        appId,
        appIds,
        start: dateRange.start,
        end: dateRange.end,
        appusersWhere: _appusersWhere,
        activityDataFields,
      },
      handler: (res) => {

        const numDays = moment(dateRange.end).diff(moment(dateRange.start), 'days') || 1

        const entities = res.entities
        if (!entities) dataHandler([])

        const bulkActivitiesDataAgg = {
          entityCount: entities.length,
          risks: [0, 0, 0],
          fields: {
            // f0: {
            //   risks: [0, 0, 0],
            //   val: 0,
            // }
          }
        }
        workloadFieldsCfg.forEach((fieldCfg) => {
          bulkActivitiesDataAgg.fields[fieldCfg.dataField] = {
            risks: [0, 0, 0],
            val: 0,
          }
        })

        entities.forEach((entity) => {
          entity.activitiesDataAgg = {
            highRisk: 0,
            mediumRisk: 0,
            lowRisk: 0,
            meta: { // TODO reducer
              highRisk: {
                color: riskCfg[0].color
              },
              mediumRisk: {
                color: riskCfg[1].color
              },
              lowRisk: {
                color: riskCfg[2].color
              },
            },
            ...entity.activitiesDataAgg,
          }

          const activitiesDataAgg = entity.activitiesDataAgg

          workloadFieldsCfg.forEach((fieldCfg) => {
            const dataField = fieldCfg.dataField
            const fieldRiskThresholds = riskThresholds[dataField]

            activitiesDataAgg.meta[dataField] = {}
            const dataFieldMeta = activitiesDataAgg.meta[dataField]

            let val = activitiesDataAgg[dataField] || 0
            val /= numDays // average per day
            if (averagePerDay) activitiesDataAgg[dataField] = val // replace total with daily average
            
            let mins = val / 60

            let riskIndex = -1
            if (mins >= fieldRiskThresholds[2]) riskIndex = 0
            else if (mins >= fieldRiskThresholds[1]) riskIndex = 1
            else riskIndex = 2

            // inc risk bucket counter
            activitiesDataAgg[riskCfg[riskIndex].dataField]++

            // populate data field metadata
            dataFieldMeta.riskIndex = riskIndex
            dataFieldMeta.color = riskCfg[riskIndex].color

            // inc bulk aggregation
            bulkActivitiesDataAgg.risks[riskIndex]++
            const bulkFieldAgg = bulkActivitiesDataAgg.fields[dataField]
            bulkFieldAgg.risks[riskIndex]++
            bulkFieldAgg.val += val
          })
        })

        // console.log(entities?.[0].activitiesDataAgg)

        if (bulkAgg) dataHandler(bulkActivitiesDataAgg)
        else dataHandler(entities)
      },
    })
  }, [
    api,
    organizationId,
    appId,
    appIds,
    dateRange,
    dataHandler,
    averagePerDay,
    appusersWhere,
    bulkAgg,
    riskThresholds,
  ])
}

export const useActivityChartModalProps = ({ entity, entityName, dbEntityType, dateRange, cohort, activityName, activityLabel }) => {
  const theme = useTheme()
  const appId = useSelector((store) => store.auth.app && store.auth.app.id)

  return React.useMemo(() => {    
    return {
      variant: 'view',
      variantOpts: {
        confirmLabel: 'Done',
        size: 'full',
        showHeader: false,
      },
      Content: ControlledChart,
      contentProps: {
        chartModelOpts: {
          variant: 'cohortActivity',
          variantOpts: {
            dbEntityType,
            entity,
            entityName,
            cohort,
            genericUsersName: 'Workers',
            activityName, activityLabel,
            appId,
            datePickerOpts: {
              defaultPresetIdx: 1,
              presets_startDaysAgo: [
                1, 7, 30,
                365, //TEST
              ],
              start: dateRange.start,
              end: dateRange.end,
            }
          },
        },
        chartLibOpts: {
          merge: {
            libCfg: {
              type: 'line',
            }
          },
          variantOpts: {
            theme,
          }
        },
      }
    }
  }, [dbEntityType, cohort, activityName, activityLabel, appId, dateRange, theme, entity, entityName])
}

export const ActivityChartModalLink = React.memo(({ entity, label, dbEntityType, dateRange, cohort, activityName, activityLabel }) => {
  return (
    <>
    <img src={`/media/workloadIcons/${activityName}.png`} alt='icon' style={{marginRight:'12px', opacity:'0.75'}} height='24px'/>
    <ModalLink
      label={label}
      size='small'
      modalProps={useActivityChartModalProps({ entity, dbEntityType, dateRange, cohort, activityName, activityLabel })}
      // image={entity.picture.data}
    />
    </>
  )
})

export const useSelectedCohortSetting = (i, def) => {
  let ret = useSetting(`safety.selectedCohorts[${i}]`, def, undefined, (store, val) => {
    if (val) {
      return store.dbEntities.lists.cohorts.filter((item) => item.id === val)?.[0]
    }

    if (def) {
      return store.dbEntities.lists.cohorts.filter((item) => item.attributes?.isDefault)?.[0]
    }
  })
  
  return ret
}