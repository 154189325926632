import React from 'react';

import { styled, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Button, IconButton, Tooltip } from "@material-ui/core";

import { Flex, IconResolver } from '../Base';

const StyledButtonBase = styled(Button)({
  fontSize: (props) => props.fontSize || '0.75rem',
  fontWeight: '500',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  boxShadow: (props) => props.bshadow,
  padding: (props) => props.padding || '10px 16px',
  width: (props) => props.width || '100%',
  height: (props) => props.height,
  maxWidth: (props) => props.maxwidth,
  minWidth: (props) => props.minwidth,
  lineHeight: (props) => props.lineheight || '1.125rem',
  borderRadius: (props) => props.borderradius,
  '&:hover': {
    transition: (props) => props.theme.transitions.buttonHover,
  },
})
// NOTE color: alpha is overriden for some reason by material ui
const StyledButtonSolid = styled(StyledButtonBase)({
  color: (props) => props.disabled ? fade('#000000', 0.26) : props.bgcolor.contrastText || props.textcolor,
  backgroundColor: (props) => props.disabled ? fade('#000000', 0.12) : props.bgcolor.main || props.bgcolor,
  '&:hover': {
    backgroundColor: (props) => props.bgcolor.dark || props.bgcolor_hover,
  },
})
const StyledButtonSolidSmall = styled(StyledButtonSolid)({
  // fontSize: (props) => props.fontSize || '0.75rem',
  padding: (props) => props.padding || '6px 16px',
  height: (props) => props.height || '32px',
  borderRadius: (props) => props.borderradius || '4px',
})

const StyledButtonOutlined = styled(StyledButtonBase)({
  // color: (props) => props.textcolor || props.bgcolor.main || props.bgcolor,
  // border: (props) => props.disabled ? `1px solid ${fade('#000000', 0.26)}` : props.border || `1px solid ${fade(props.bgcolor.main || props.bgcolor, 0.5)}`,
  color: (props) => props.textcolor || '#455B63',
  border: (props) => props.disabled ? `1px solid ${fade('#000000', 0.26)}` : props.border || `1px solid ${fade('#7D9EAA', 0.24)}`,
  '&:hover': {
    // border: (props) => props.border || `1px solid ${props.bgcolor.main || props.bgcolor}`,
    // backgroundColor: (props) => fade(props.bgcolor.main || props.bgcolor, props.hoveropacity || props.theme.palette.action.hoverOpacity),
    border: (props) => props.border || `1px solid #7D9EAA`,
    backgroundColor: (props) => fade('#7D9EAA', 0.04),
  },
})
const StyledButtonOutlinedDefault = styled(StyledButtonOutlined)({
  color: (props) => props.textcolor || props.theme.palette.text.secondary,
  border: (props) => props.border || `1px solid ${fade(props.theme.palette.border.active, .5)}`,
  '&:hover': {
    border: (props) => props.border || `1px solid ${props.theme.palette.border.active}`,
    backgroundColor: (props) => fade(props.theme.palette.border.active, props.hoveropacity || props.theme.palette.action.hoverOpacity),
  },
})
const StyledButtonOutlinedSmall = styled(StyledButtonOutlined)({
  // fontSize: (props) => props.fontSize || '0.75rem',
  padding: (props) => props.padding || '6px 16px',
  height: (props) => props.height || '32px',
  borderRadius: (props) => props.borderradius || '4px',
})

const StyledButtonTextual = styled(StyledButtonBase)({
  color: (props) => props.textcolor || props.theme.palette.text.secondary,
  '&:hover': {
    color: (props) => props.bgcolor.main,
    backgroundColor: (props) => fade(props.bgcolor.main, props.hoveropacity || props.theme.palette.action.hoverOpacity),
  },
  "&:focus": {
    background: 'none',
  },
})
const StyledButtonTextualSmall = styled(StyledButtonTextual)({
  padding: '6px 8px',
})

const StyledButtonControl = styled(StyledButtonBase)({
  color: (props) => props.textcolor || fade(props.bgcolor.main || props.bgcolor, 0.5),
  border: (props) => props.border || `1px solid ${fade(props.bgcolor.main || props.bgcolor, 0.5)}`,
  '&:hover': {
    color: (props) => props.textcolor || props.bgcolor.main || props.bgcolor,
    border: (props) => props.border || `1px solid ${props.bgcolor.main || props.bgcolor}`,
    background: 'none',
  },
})

// const StyledButtonIcon = styled(StyledButtonBase)({
//   color: (props) => props.textcolor || fade(props.bgcolor.main || props.bgcolor, 0.5),
//   // border: (props) => props.border || `1px solid ${fade(props.bgcolor.main || props.bgcolor, 0.5)}`,
//   padding: '4px',
//   marginRight: '-16px',
//   // flexGrow: 0,
//   '&:hover': {
//     color: (props) => props.textcolor || props.bgcolor.main || props.bgcolor,
//     // border: (props) => props.border || `1px solid ${props.bgcolor.main || props.bgcolor}`,
//     background: 'none',
//   },
// })


const StyledIconButton = styled(IconButton)({
  padding: (props) => props.padding || '4px', // default for small is '3px'
  height: 'min-content',
})

const variants = {
  solid: StyledButtonSolid,
  outlined: StyledButtonOutlined,
  textual: StyledButtonTextual,
  control: StyledButtonControl,
  solidSmall: StyledButtonSolidSmall,
  outlinedSmall: StyledButtonOutlinedSmall,
  textualSmall: StyledButtonTextualSmall,
  default: StyledButtonOutlinedDefault,
}

const StyledButton = React.memo(({ variant='solid', color, isWithInputs, startIcon, endIcon, icon, iconProps, title, ...props }) => {
  const theme = useTheme()

  if (variant ==='icon') {
    let fontSize = 'small'
    if (props.size === 'medium') {
      fontSize = 'default'
    }
    return (
      <Flex alignItems='center'>
        <Tooltip title={title} enterDelay={500} arrow>
          <span>
            <StyledIconButton
              color={color || 'primary'}
              size='small'
              {...props}
            >
              {
                (icon || startIcon || endIcon) &&
                  <IconResolver
                    icon={icon || startIcon || endIcon}
                    fontSize={fontSize}
                    {...iconProps}
                  />
              }
            </StyledIconButton>
          </span>
        </Tooltip>
      </Flex>
    )
  }

  const CompVariant = variants[variant]
  return (
    <Flex>
      <CompVariant
        theme={theme}
        bgcolor={theme.palette[color] || color}
        startIcon={(startIcon || icon) && <IconResolver icon={startIcon || icon} style={{ fontSize:'1rem' }} {...iconProps}/>}
        endIcon={endIcon && <IconResolver icon={endIcon} style={{ fontSize:'1rem' }} {...iconProps}/>}
        title={title}
        {...props}
      />
    </Flex>
  )
})

export default StyledButton



