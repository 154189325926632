import React from 'react';
import { useParams } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { Card } from '../Base'

import ControlledChart from './ControlledChart'

const ControlledChartRouted = React.memo((props) => {
  let {
    chartVariant,
    chartVariantOpts,
    chartLibVariant,
    chartLibVariantOpts,
    ...other
  } = {
    ...props,
    ...useParams(),
  }

  chartVariantOpts = {
    ...chartVariantOpts,
    ...other,
  }

  if (!chartVariant) return null // TODO error page or something

  return (
    <Card>
      <ControlledChart
        key={chartVariant + chartLibVariant}
        chartModelOpts={{
          variant: chartVariant,
          variantOpts: chartVariantOpts,
        }}
        chartLibOpts={{
          variant: chartLibVariant,
          variantOpts: {
            ...chartLibVariantOpts,
            theme: useTheme(), // TODO put in useModel func
          },
        }}
      />
    </Card>
  )
})

export default ControlledChartRouted