import React, { useContext } from 'react'
import DefaultContext from './ServiceContext'

import { timezone } from './utils'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'

import { useAppIds } from './hooks'

// Pull just the serviceHandler hook out of the ServiceProvider
// and make it available.

const useApi = Context => {
  const serviceContext = useContext(Context || DefaultContext)
  return serviceContext.current.useServiceHandler()
}

export default useApi

export const useApiCall = (
  {
    url = '',
    method,
    urlBase = '',
    responseTransformer,
    useOrgId = true,
    useAppId = true,
    includeAppIds = false,
    useTimezone = false,
    debug = false,
    reqDataBase,
    reqDataFunc, // TODO rename to reqDataTransformer
    reqDataArgs, // TODO rename to reqData
    nullOnCall = false,
    handler,
    manualTriggerToken,
    requireManualTriggerToken = false,
    setIsLoading,
    opts,
  },
) => {
  const api = useApi()

  const orgId = useSelector(store => (store.auth.organization && store.auth.organization.id) || null)
  const appId = useSelector(store => (store.auth.app && store.auth.app.id) || null)
  const appIds = useAppIds()

  const localArgs = [ manualTriggerToken || 'ENSURE_FIRST_CALL', orgId, appId ]
  const lastLocalArgs = React.useRef(null)

  const [ response, setResponse ] = React.useState(null)

  React.useEffect(() => {
    // TODO_MAYBE still auto execute when orgId or appId changes?

    let shouldBail = isEqual(localArgs, lastLocalArgs.current)
      || (requireManualTriggerToken && manualTriggerToken == null)

    if (shouldBail) {
      return
    }

    let reqData = { ...reqDataBase }
    if (useOrgId) reqData.organizationId = orgId
    if (useAppId && !reqData.appId) reqData.appId = appId
    if (useTimezone) reqData.tz = timezone()

    let _reqDataArgs = reqDataArgs
    if (reqDataFunc) {
      _reqDataArgs = reqDataFunc(reqDataArgs)
    }

    reqData = {
      ...reqData,
      ..._reqDataArgs,
    }
  
    const apiArgs = {
      method,
      url: (urlBase && urlBase + url) || url,
      data: reqData,
      opts,
    }

    if (debug) console.log('useApiCall', apiArgs, `manualTriggerToken: ${manualTriggerToken}`)

    lastLocalArgs.current = localArgs

    if (includeAppIds) {
      apiArgs.data.appIds = appIds
    }

    api({
      ...apiArgs,
      isLoading: setIsLoading,
      handler: (res) => {
        if (debug) console.log('useApiCall response', res, `manualTriggerToken: ${manualTriggerToken}`)
        setResponse((responseTransformer && responseTransformer(res, reqData)) || res)
      }
    })

  }, [
    api,
    appId,
    orgId,
    appIds,
    localArgs,
    lastLocalArgs,
    reqDataBase,
    reqDataFunc,
    reqDataArgs,
    url,
    method,
    urlBase,
    responseTransformer,
    useOrgId,
    useAppId,
    includeAppIds,
    useTimezone,
    debug,
    nullOnCall,
    manualTriggerToken,
    setIsLoading,
    requireManualTriggerToken,
    opts,
  ])

  // Handler in case we don't want to use response directly
  React.useEffect(
    () => {
      if (handler && response != null) {
        handler(response)
      }
    },
    [handler, response]
  )

  return response
}
