import React from 'react';

import StyledButton from './StyledButton'

import { styled, withStyles, useTheme } from '@material-ui/core/styles';
import { MenuItem, Menu } from "@material-ui/core";

import { Flex } from '../Base';

const StyledMenu = withStyles({
  paper: {
    //border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  />
))


const StyledMenuItem = styled(MenuItem)({
  fontSize: (props) => props.fontSize || '0.875rem',
})

const MenuButton = React.memo(({ id, state, methods, fontSize, ...props }) => {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null)

  const { val, items, methodKey: _methodKey, methodOpts: _methodOpts, disabled } = state;
  
  return (
    <Flex>
      <StyledButton
        onClick={handleClick}
        aria-controls={`${id}-menu`}
        aria-haspopup="true"
        fontSize={fontSize}
        disabled={disabled}
        {...props}
      >
        {val || ''}
      </StyledButton>
      <StyledMenu
        id={`${id}-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(items).map((val) => {
          const {
            label,
            methodKey = _methodKey,
            methodOpts = _methodOpts,
            isSpecial,
          } = items[val]

          const handleClickItem = () => {
            setAnchorEl(null)
            methods[methodKey](val, methodOpts, id)
          }
          return (
            <StyledMenuItem
              key={val}
              onClick={handleClickItem}
              fontSize={fontSize}
              color={isSpecial ? theme.palette.primary.main : theme.palette.text.primary}
            >
                {label || ''}
            </StyledMenuItem>
          )
        })}
      </StyledMenu>
    </Flex>
  )
})

export default MenuButton;
