import React from 'react'

import { Box, Flex } from '../../Base'
import  ControlButton from '../../ControlComps/ControlButton'

import Tree from '../../Tree/Tree'
import { useModel as useModel_Tree } from '../../Tree/TreeModel'

import { DbEntitySaveAsButton } from '../../DbEntities/Manage'

import builderModel from './TreeBuilderModel'
// import { AssignmentReturn } from '@material-ui/icons'



// TODO_MAYBE do not useEffect, supply callback in treeModelOpts, running into issues with useEffect always triggering on mount?
// but what if we ant that sometimes?
const TreeBuilder = React.memo((props) => {
  let {
    builderOpts,
    //
    initialData,
    onMethods,
    updateDataTrigger,
    onDataUpdate,
    setHasChanged,
    setHasItems,
    id,
    ...otherProps
  } = props

  if (!builderOpts) return
  
  // eslint-disable-next-line no-unused-vars
  const [ builderState, builderMethods ] = builderModel(builderOpts)

  const [ editorState, editorMethods ] = builderState.editorModel(builderState.editorOpts)

  const Editor = builderState.Editor

  const [ treeState, treeMethods ] = useModel_Tree(builderState.treeModelOpts)

  const controlButtonHeight = '32px'

  React.useEffect(() => {
    // TODO handle inside of tree
    if (initialData) {
      treeMethods.setInitalData(initialData)
    }
  }, [
    initialData,
    treeMethods,
  ])

  React.useEffect(() => {
    if (onMethods) {
      onMethods(builderMethods, treeMethods)
    }
  }, [
    onMethods,
    treeMethods,
    builderMethods,
  ])
  
  // Hook up Editor execution to add a Tree item
  React.useEffect(() => {
    editorMethods.setOnExecute(treeMethods.add)
  }, [
    editorMethods,
    treeMethods,
  ])

  // Hook up external updateDataTrigger to tree
  React.useEffect(() => {
    if (!updateDataTrigger) return
    treeMethods.executeToDataTransformer()
  }, [
    updateDataTrigger,
    treeMethods,
  ])
  
  // Hook up tree data to external onDataUpdate
  React.useEffect(() => {
    if (!treeState.data) return
    onDataUpdate(treeState.data, null, id)
  }, [
    onDataUpdate,
    treeState.data,
    id,
  ])

  // Hook up tree hasChanged to external setHasChanged
  React.useEffect(() => {
    if (!setHasChanged) return
    setHasChanged(treeState.hasChanged, null, id)
  }, [
    setHasChanged,
    treeState.hasChanged,
    id,
  ])

  // Hook up tree hasItems to external setHasItems
  React.useEffect(() => {
    if (!setHasItems) return
    setHasItems(treeState.hasItems, null, id)
  }, [
    setHasItems,
    treeState.hasItems,
    id,
  ])

  const additionControls = (
    <>
      {
        builderState.controls.save &&
          <Flex mr='8px' height={controlButtonHeight}>
            <DbEntitySaveAsButton
              {...builderState.controls.save}
              disabled={!(treeState.hasItems && !treeState.hasChanged)}
              entity={React.useMemo(() => builderState.controls.save.transformer(treeState.data), [ builderState.controls.save, treeState.data ])}
            />
          </Flex>
      }
    </>
  )

  return (
    <Box>
      <Flex
        {...otherProps}
        justifyContent='space-between'
      >
        <Flex alignItems='center'>
          <Editor
            state={editorState}
            methods={editorMethods}
          />
          {
            builderState.controls.revert &&
              <Flex mr='8px' height={controlButtonHeight}>
                <ControlButton
                  state={builderState.controls.revert}
                  methods={treeMethods}
                  methodKey='revert'
                  disabled={!treeState.hasChanged}
                />
              </Flex>
          }
          {
            additionControls
          }
          {
            builderState.controls.clear &&
              <Flex mr='8px' height={controlButtonHeight}>
                <ControlButton
                  state={builderState.controls.clear}
                  methods={treeMethods}
                  methodKey='clear'
                  disabled={!treeState.hasItems}
                />
              </Flex>
          }
          {
            builderState.controls.execute &&
              <Flex mr='8px' height={controlButtonHeight}>
                <ControlButton
                  state={builderState.controls.execute}
                  methods={treeMethods}
                  methodKey='executeToDataTransformer'
                  disabled={!treeState.hasChanged}
                />
              </Flex>
          }
        </Flex>
        
      </Flex>
      <Flex mt='24px'>
        <Tree
          methods={treeMethods}
          state={treeState}
        />
      </Flex>
    </Box>
  )
})

export default TreeBuilder