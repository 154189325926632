import React from 'react'

import MaterialTextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/core/styles'
import { isObject } from 'lodash'

const StyledTextField = withStyles({
  root: {
    minWidth: (props) => props.minwidth,
    // height: (props) => props.height || '32px',
    // padding: (props) => props.padding || '0px !important',
    // fontSize: (props) => props.fontSize || '0.75rem',
  },
})(MaterialTextField)

// This is a compoenents that should work with ({ state, method, ...other}) model props...
// OR with a parent using setState(val), setState(isValid) and passing in ({ onChange, isValid, val, ...other })
const TextField = React.memo((props) => {
  const {
    methodKey,
    methodOpts,
    methods,
    state,
    id,
    // unused but might be present
    defaultVal,
    hasChanged,
    _validateAllowNotChanged,
    _notChangedValidationHint,
    _validator,
    _other,
    //
    ...nonModelProps
  } = {
    ...props.state,
    ...props,
  }

  const {
    inputType = 'text',
    min,
    max,
    val,
    placeholder,
    isValid,
    label,
    helperText,
    onChange,
    errorText,
    // Autcomplete
    options,
    autoComplete,
    restrictToOptions = false,
    optionTransformer,
    disableClearable,
    //
    ...otherProps
  } = nonModelProps

  let _onChange = onChange
  if (methods) {
    _onChange = React.useCallback((event, val) => {
      methods[methodKey || onChange](val || (event && event.target.value) || '', methodOpts, id)
    }, [id, methodKey, methodOpts, methods, onChange])
  }

  const isError = isValid !== undefined && !isValid

  const renderInput = (props = {}) => {
    return (
      <StyledTextField
        {...props} // Autocomplete props
        inputProps={{
          ...props.inputProps,
          // https://material-ui.com/components/autocomplete/#autocomplete-autofill
          // 'new-password' disables browser based autocomplete
          autoComplete: autoComplete || 'new-password',
        }}
        label={label}
        type={inputType}
        value={val || ''}
        placeholder={placeholder}
        onChange={_onChange}
        error={isError}
        helperText={(isError && errorText) || helperText}
        {...otherProps}
      />
    )
  }

  if (!options) {
    return renderInput()
  }
  else {
    const [ autocompleteValue, setAutocompleteValue ] = React.useState(val)
    const [ inputValue, setInputValue ] = React.useState((val != null && (val.inputVal || val.label)) || val || '')
    
    if (val === null || val === '') {
      if (autocompleteValue !== null || inputValue !== '') {
        setAutocompleteValue(null)
        setInputValue('')
      }
    }

    React.useEffect(() => {
      if (restrictToOptions && autocompleteValue && val !== inputValue) {
        _onChange(null, autocompleteValue)
      }
    }, [_onChange, autocompleteValue, inputValue, restrictToOptions, val])

    const optionsAreObjects = isObject(options[0])
    const [
      onAutoCompleteChange,
      onInputChange,
      filterOptions,
      getOptionInputValue,
      renderOption,
    ] = React.useMemo(() => {
      return [
        ...(
          restrictToOptions
            ? [
              (event, newValue) => {
                setAutocompleteValue(newValue)
                _onChange(null, newValue)
              },
              (event, newInputValue) => {
                setInputValue(newInputValue)
              }
            ]
            : [
              (event, newValue) => {
                setAutocompleteValue(newValue)
              },
              (event, newInputValue) => {
                setInputValue(newInputValue)
                _onChange(null, newInputValue)
              }
            ]
        ),
        createFilterOptions({
          limit : 100,
          stringify: optionsAreObjects
            ? (option) => option.label || option.inputVal || option.val || option
            : undefined,
        }),
        optionsAreObjects
          ? (option) => option.inputVal || option.label || option.val || option
          : undefined,
        optionsAreObjects
          ? (option) => <span>{option.label || option.inputVal || option.val || option}</span>
          : undefined,
      ]
    }, [
      _onChange,
      restrictToOptions,
      optionsAreObjects,
    ])

    return (
      <Autocomplete
        value={autocompleteValue}
        onChange={onAutoCompleteChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        freeSolo={!restrictToOptions}
        filterOptions={filterOptions}
        getOptionLabel={getOptionInputValue}
        options={options}
        renderInput={renderInput}
        renderOption={renderOption}
        disableClearable={disableClearable}
        {...otherProps}
      />
    )
  }
})

export default TextField