import React from 'react'
import { useModel, objectVariantOrPassthrough, initControls, setControlVal } from '../../../lib/state'
import stateVariants from './variants'

import { useApi_OrgId_AppId } from '../../../lib/hooks'

///////////
// MODEL //
///////////

const model = {
  ///////////
  // STATE //
  ///////////
  stateInit(args) {
    const _ = objectVariantOrPassthrough(stateVariants, args)

    let ret = {
      ..._,
      _doUpdateCredsToken: undefined,
      pushPlatform: 'apns',
      _afterValidateAll: (state) => {
        if (state.trySaveOnNextValidate) {
          state.trySaveOnNextValidate = false
          if (state.isValid) {
            state._doUpdateCredsToken = Math.random()
          }
        }
      },
    }

    ret.controls = {
      p12: {
        defaultVal: '',
        methodKey: 'setP12',
        _validator: (val) => {
          return (val && val !== '')  || '.p12 certificate is required'
        },
        _notChangedValidationHint: '.p12 certificate is required',
        _validateAllowNotChanged: false,
      },
      password: {
        defaultVal: '',
        methodKey: 'setPassword',
        _validator: (val) => {
          return (val && val !== '')  || 'Password is required'
        },
        _notChangedValidationHint: 'Password is required',
        _validateAllowNotChanged: false,
      },
    }

    initControls(ret)
  
    return ret
  },
  /////////////
  // METHODS //
  /////////////
  methodsInit(state) {
    return {
      setP12(val, methods) {
        if (!val.type === 'application/x-pkcs12') {
          state.validationhint = 'File must be a .p12'
          return
        }

        let reader = new FileReader()
        reader.readAsDataURL(val)
        reader.onload = function () {
          methods._setP12(reader.result)
        }
      },
      _setP12(val, opts) {
        setControlVal(state, state.controls.p12, val)
      },
      setPassword(val, opts) {
        setControlVal(state, state.controls.password, val)
      },
      doUpdateCreds(val, opts) {
        state.shouldValidate = true
        state.trySaveOnNextValidate = true
        state.data = {
          p12Data: state.controls.p12.val,
          password: state.controls.password.val
        }
        setControlVal(state)
      },
      setOnCompletedCallback(val, opts) {
        state._onCompleted = val
      },
    }
  },
  ///////////
  // HOOKS //
  ///////////
  hooks: {
    useUpdateApplicationPushCredentials: {
      useHook: (state, methods) => {
        const {
          _doUpdateCredsToken,
          _onCompleted,
          data,
          pushPlatform,
        } = state
  
        const [ api, organizationId, appId ] = useApi_OrgId_AppId()
  
        React.useEffect(() => {
          if (_doUpdateCredsToken >= 0) {
            api({
              method: 'post',
              url: '/saas/v1/notifications/v1/updateApplicationPushCredentials',
              data: {
                pushPlatform,
                appId,
                organizationId,
                data,
              },
              handler: (res) => {
                if (_onCompleted) _onCompleted()
              },
            })
          }
          
        }, [
          _doUpdateCredsToken,
          _onCompleted,
          pushPlatform,
          data,
          api,
          organizationId,
          appId,
        ])
      },
    }
  }
}
export default (stateInitializerArgs) => {
  return useModel(model, stateInitializerArgs)
}