import React, { Suspense } from "react";
// import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../layout/LayoutContext";

import AuthorizedRoute from '../../router/AuthorizedRoute';
// import {parseRole} from '../../lib/utils';
import useGlobalStateHooks from '../../lib/useGlobalStateHooks'
import { useAppPlugin } from '../../lib/hooks'

// import Permitted from '../../components/Permitted'

import Dashboard from "../dashboard";
import Test from '../test';
import Logs from '../logs';
import Orgs from '../orgs';
import Analysis from '../analysis';
import Contact from '../contact';
import Account from '../account';
import Cohorts from '../cohorts';
import Explore from '../explore';
import Shoes from '../shoes';
import Users from '../appusers';
import Campaigns from '../campaigns'
import Tools from '../tools';
import Explorer from "../explorer"

// import Workload from '../../plugins/safety/pages/workload'
// import Incidents from '../../plugins/safety/pages/incidents'
// import Diagnostics from '../../plugins/safety/pages/diagnostics'
// import Profile from '../../plugins/safety/pages/profile'

import SafetyPlugin from '../../plugins/safety/pages'

import StringifyStore from '../../components/Debug/StringifyStore';

// The SplashScreen is in public/index.html and is displayed or hidden with
// css classes by code in LayoutContext.js

const HomePage = () => {
  // const user = useSelector(store => store.auth.user);
  // const urole = parseRole(user.role)

  const appPlugin = useAppPlugin()

  useGlobalStateHooks()

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* { urole.zt && <Redirect exact from="/" to="/dashboard" /> }
        { !urole.zt && <Redirect exact from="/" to="/dashboard" /> } */}
        {
          appPlugin.name !== 'safety'
          ? <Redirect exact from="/" to="/dashboard" />
          : <Redirect exact from='/' to='/safety/workload'/>
        }

        <Route exact path="/dashboard" component={Dashboard} />
        <Route path="/analysis" component={Analysis} />


        <Route path="/cohorts" component={Cohorts} />
        <Route path="/campaigns" component={Campaigns} />
        <Route path="/explore" component={Explore} />
        <Route path="/shoes" component={Shoes} />
        <Route path="/appusers" component={Users} />

        <Route path="/safety" component={SafetyPlugin} />
        <Route path="/tools" component={Tools} />

        <Route path="/logs/:q" component={Logs} />
        <Route path="/logs" component={Logs} />
        <Route path="/explorer" component={Explorer} />
        <Route path="/account" component={Account} />
        <Route path="/contact" component={Contact} />

        <Route path="/test" component={Test} />

        {/* <Route path="/workload" component={Workload} />
        <Route path="/incidents" component={Incidents} />
        <Route path="/diagnostics" component={Diagnostics} />
        <Route path="/profile" component={Profile} /> */}

        {/* <Route path="/orgs2">
          <Permitted
            notPermitted={() => <Redirect to={{ pathname: '/'}} />}
            permsCfg={{
              role: {
                superAny: true,
                orgAdmin: true,
              }
            }}
          >
            <Orgs/>
          </Permitted>
        </Route> */}

        <AuthorizedRoute path="/orgs" roles={["zhortech:admin", "org:admin", "zhortech:user"]} component={Orgs} />

        <AuthorizedRoute path={["/_store/:path", "/_store"]} roles={["zhortech:admin", "zhortech:user"]} component={StringifyStore} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}

export default HomePage;
