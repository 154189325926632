import React from 'react'

import { fade } from '@material-ui/core/styles/colorManipulator'

import { Text, Flex, Grid } from '../../../Base'
import ControlButton from '../../../ControlComps/ControlButton'

import { DeleteOutline } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'

import useStyles from '../../../../lib/useStyles'

const ItemCapsule = (props) => {
    const classes = useStyles()
  return (
    <Grid
      className={classes.listItem}
      gridGap='8px'
      gridTemplateColumns='max-content max-content 1fr'
      maxWidth='552px'
      padding='4px 2px 4px 8px'
      {...props}
    />
  )
}

//
// Main Comps
//

export const Group = React.memo(({ methods, id, __isObject, __isArray, __key, __val, hasBrace = false, children, ...props }) => {
  return (
    <Flex
      width='100%'
      maxWidth='569px'
      flexDirection='column'
      mt='-16px'
      borderLeft={`1px solid ${fade('#000000', 0.1)}`}
      maxHeight='120px'
      overflow='auto'
      {...props}
    >
      {children}
    </Flex>
  )
})

export const Item = React.memo(({ theme, methods, id, __isObject, __isArray, __key, __val, textColor, controls, spacing, ...props }) => {
  textColor = textColor || theme.palette.primary.main

  return (
    <ItemCapsule
      {...props}
    >
      <Flex maxWidth='314px'>
        <Tooltip title={__key} arrow>
          <Text
            color={textColor}
            variant='subtitle2'
            style={{overflow:'hidden', whiteSpace:'no-wrap', textOverflow:'ellipsis'}}
          >
            {__key}:
          </Text>
        </Tooltip>
      </Flex>
      <Flex maxWidth='160px'>
        <Text
          color={textColor}
          variant='body2' 
          style={{overflow:'hidden', whiteSpace:'no-wrap', textOverflow:'ellipsis'}}
        >
          {__val.toString()}
        </Text>
      </Flex>
      {
        <Flex justifySelf='end'>
          <ControlButton
            methods={methods}
            methodKey='remove'
            title='Remove'
            id={id}
            color={'default'}
            variant='icon'
            icon={DeleteOutline}
          />
        </Flex>
      }
    </ItemCapsule>
  )
})