import React from 'react';

import MaterialTextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import useApi from '../../../lib/useApi';
import useStyles from '../../../lib/useStyles'

import semver from 'semver';
import _ from 'lodash';

import TreeBuilder from '../../../components/Query/Builder/TreeBuilder'
import { Box, Flex, Text, Grid, } from '../../../components/Base'

const EditForm = ({app, onSubmit, onCancel}) => {
  const classes = useStyles();
  const api1 = useApi();
  const api2 = useApi();

  onSubmit = React.useMemo(() => onSubmit, [onSubmit])
  
  // form state
  const [name, setName] = React.useState(app.name);
  const [description, setDescription] = React.useState(app.description);
  const [version, setVersion] = React.useState(app.latest);
  
  const [formErrors, setFormErrors] = React.useState({});

  const [pluginName, setPluginName] = React.useState(app.pluginName || '');
  const [pluginVersion, setPluginVersion] = React.useState(app.pluginVersion);

  // Temporarily set a plugin if we know the pluginName, to prevent warnings
  // in React because we don't yet know the menu items for the select
  const [plugins, setPlugins] = React.useState(app.pluginName ? [{name: app.pluginName, description: '', version: ''}] : []);

  const [fwVersions, setFwVersions] = React.useState(app.fwVersion ? [app.fwVersion] : []);
  const [fwVersion, setFwVersion] = React.useState(app.fwVersion || '');

  const [wantsToSaveToken, setWantsToSaveToken] = React.useState(null);

  const [attributes, setAttributes] = React.useState(null);
  const [waitingForAttributes, setWaitingForAttributes] = React.useState(false);
  const [preparedData, setPreparedData] = React.useState(null);

  // console.log(app);
  
  React.useEffect(() => {
    let active = true;

    if ( pluginName ) {
      api2({method: 'get', url: `/saas/v1/fw/v1/versionsFor/${pluginName}`, handler: (versions) => {
        if (active) {
          setFwVersions(versions);
        }
      }});
    }
    else {
      setFwVersions([]);
    }

    api1({method: 'get', url: '/saas/v1/plugin/plugins', handler: (data) => {
      if (active) {
        setPlugins(data);
      }
    }});

    return () => {
      active = false;
    };
  }, [api1, api2, pluginName]);

  // Put the form data together to send back to the caller
  const prepareData = React.useCallback(() => {
    setPreparedData({
      id: app.id,
      appId: app.appId,
      name,
      description,
      latest: version,
      pluginName,
      pluginVersion,
      fwVersion,
      fwVersions
    })
  }, [app.appId, app.id, description, fwVersion, fwVersions, name, pluginName, pluginVersion, version])

  const checkForm = React.useCallback(() => {
    setFormErrors({});
    let errors = {};

    if ( name.length < 4 ) errors.name = "app name should be four or more characters";
    if ( description.length < 4 ) errors.description = "app description should be four or more characters";
    if ( ! semver.valid(version) ) errors.version = "app version must be a valid 'semver'. See http://semver.org";

    if ( ! pluginName ) errors.pluginName = "must choose an app type";
    if ( ! fwVersion ) errors.fwVersion = "must choose a firmware version";

    let valid = Object.keys(errors).length ? false : true;

    if ( ! valid ) setFormErrors(errors);

    return valid;
  }, [description.length, fwVersion, name.length, pluginName, version])

  const clearError = (fieldName) => {
    if ( ! formErrors[fieldName] ) return;
    setFormErrors({
      ...formErrors,
      [fieldName]: undefined
    });
  }

  const getFwVersions = (name) => {
    return fwVersions;
  }

  // Efects

  React.useEffect(() => {
    if (wantsToSaveToken !== null) {
      if ( ! checkForm() ) {
        setWantsToSaveToken(null)
        return
      }
      prepareData()
    }
  }, [ wantsToSaveToken, checkForm, prepareData ])

  React.useEffect(() => {
    if ((wantsToSaveToken !== null) && !waitingForAttributes && preparedData) {
      setWantsToSaveToken(null)
      onSubmit({
        ...preparedData,
        attributes,
      })
    }
  }, [ wantsToSaveToken, preparedData, attributes, onSubmit, waitingForAttributes ])

  React.useEffect(() => {
    setWaitingForAttributes(false)
  }, [ attributes ])

  return(
    <Flex justifyContent='space-between' flexDirection='column' minHeight='628px'>
      <Grid gridGap='24px'>
        <FormControl fullWidth className={classes.formControl} error={formErrors['name'] !== undefined}>
          <Text variant='small1' pb='6px'>
            {'Name'}
          </Text>
          <MaterialTextField
            autoFocus
            required
            type="text"
            variant='outlined'
            value={name}
            onFocus={() => clearError('name')}
            onChange={(e) => setName(e.target.value)}
          />
          <FormHelperText>{formErrors['name'] || ''}</FormHelperText>
        </FormControl>
        <FormControl fullWidth className={classes.formControl} error={formErrors['description'] !== undefined}>
          <Text variant='small1' pb='6px'>
            {'Description'}
          </Text>
          <MaterialTextField
            required
            type="text"
            variant='outlined'
            value={description}
            onFocus={() => clearError('description')}
            onChange={(e) => setDescription(e.target.value)}
          />
          <FormHelperText>{formErrors['description'] || ''}</FormHelperText>
        </FormControl>
        <FormControl fullWidth className={classes.formControl} error={formErrors['version'] !== undefined}>
          <Text variant='small1' pb='6px'>
            {'Version'}
          </Text>
          <MaterialTextField
            required
            type="text"
            variant='outlined'
            value={version}
            onFocus={() => clearError('version')}
            onChange={(e) => setVersion(e.target.value)}
          />
          <FormHelperText>{formErrors['version'] || ''}</FormHelperText>
        </FormControl>
        <FormControl required fullWidth className={classes.formControl} error={formErrors['pluginName'] !== undefined}>
          <Text variant='small1' pb='6px'>
            {'App Type'}
          </Text>
          <Select
            labelId="pluginName"
            value={pluginName}
            variant='outlined'
            onFocus={() => clearError('pluginName')}
            onChange={(e) => {
              const name = e.target.value;
              const v = _.find(plugins, {name});
              setPluginName(v ? v.name : '');
              setPluginVersion(v ? v.version : '');
              setFwVersion('');
              setFwVersions(
                getFwVersions(name)
              );
            }}
          >
            {plugins.map((plugin) => (
              <MenuItem key={plugin.name} value={plugin.name}>{`${plugin.name} - ${plugin.description}`}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{formErrors['pluginName'] || 'Choose a supported app type'}</FormHelperText>
        </FormControl>
        <FormControl required fullWidth className={classes.formControl} error={formErrors['fwVersion'] !== undefined}>
          <Text variant='small1' pb='6px'>
            {'Shoes Firmware Version'}
          </Text>
          <Select
            labelId="fwVersion"
            value={fwVersion}
            variant='outlined'
            onFocus={() => clearError('fwVersion')}
            onChange={(e) => setFwVersion(e.target.value)}
          >
            {
              pluginName &&
              <Text
                variant='small1'
                color='text.secondary'
                p='8px 0px 12px 16px'
                borderBottom='1px solid #E8E8E8'
              >
                {pluginName.toUpperCase()} FIRMWARE (sorted by most recent version)
              </Text>
            }
            {fwVersions.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formErrors['fwVersion'] || 'Choose a firmware version for the shoes used by this app'}</FormHelperText>
        </FormControl>
        <FormControl fullWidth className={classes.formControl} error={formErrors['attributes'] !== undefined}>
          <Box>
            <Text variant='small1' pb='6px'>
              {'Attributes'}
            </Text>
            <TreeBuilder
              initialData={React.useMemo(() => app.attributes || {}, [app.attributes])}
              updateDataTrigger={wantsToSaveToken}
              builderOpts={React.useMemo(() => {
                return {
                  variant: 'object',
                }
              }, [])}
              onDataUpdate={setAttributes}
              // setHasChanged={methods.setQueryHasChanged}
              // setHasItems={methods.setQueryHasItems}
            />
          </Box>
        </FormControl>
      </Grid>
      <Box className={classes.formActions}>
        <Button
          variant='outlined'
          size='small'
          color='primary'
          style={{marginRight:"16px", textTransform:"none"}}
          onClick={() => onCancel(null)}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setWantsToSaveToken(Math.random())
            setWaitingForAttributes(true)
          }}
          variant='contained'
          size='small'
          color="primary"
          style={{textTransform:"none"}}
        >
          {app.id ? 'Confirm' : 'Create'}
        </Button>
      </Box>
    </Flex>
  );
}

export default EditForm;
