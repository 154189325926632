import React from 'react';
import { Card, Flex } from './Base'
import { useHistory } from "react-router-dom"
import { 
  ChevronRight,
} from '@material-ui/icons'

// TODO accept any component in place of LabeledIcon, and maybe inplace of ActionIconComp?
// TODO hovering
// TODO background image


const ActionHandler = React.memo(({ routeTo, onClick, children, ...props }) => {
  if (routeTo) {
    const history = useHistory()
    onClick = () => history.push(routeTo)
  }

  const child = React.Children.only(children)

  return React.cloneElement(child, { onClick, ...props })
})

const DefaultActionIcon = React.memo(() => <ChevronRight color='primary'/>)

const IconActionCard = React.memo(({ ActionIconComp = DefaultActionIcon, ContentComp, contentProps, ...props }) => {
  const { routeTo, onClick, ...otherProps } = props
  return(
    <ActionHandler routeTo={routeTo} onClick={onClick}>
      <Card cursor='pointer' variant='raisedBordered' p='16px' {...otherProps}>
        <Flex
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          height='100%'
        >
          <ContentComp {...contentProps}/>
          <ActionIconComp/>
        </Flex>
      </Card>
    </ActionHandler>
  );
})

export default IconActionCard
