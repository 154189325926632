import React from "react";
import { withRouter } from "react-router-dom";
import { Flex, Box, } from '../components/Base'

import MenuFooterGrid from "./MenuFooterGrid";
import MenuList from "./MenuList";
import MenuItemSeparator from "./MenuItemSeparator";
import AppPicker from "./AppPicker";

// From the original metronic implementation
import menuConfig from './MenuConfig';
class Menu extends React.Component {
  
  asideMenuRef = React.createRef();

  get currentUrl() {
    return this.props.location.pathname.split(/[?#]/)[0];
  }
  render() {
    const {
      location: { pathname }
    } = this.props;

    return (
      <Flex
        width='100%'
        position='sticky'
        height='100vh'
        top='0px'
        left='0px'
        flexShrink='0'
        flexDirection='column'
        justifyContent='space-between'
      >
        <Box>
          <AppPicker />
          <MenuList
            currentUrl={ pathname }
            menuConfig={ menuConfig }
          />
        </Box>
        <Box>
          <MenuItemSeparator />
          <MenuFooterGrid />
        </Box>
      </Flex>
    );
  }
}

export default withRouter(Menu);
