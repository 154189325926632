// import deepMerge from '../../lib/deepMerge'

//////////
// Util //
//////////

//////////
// Base //
//////////

const base = (variantOpts) => {
  return {}
}

//////////////
// Variants //
//////////////

const stateVariants = {}

stateVariants.default = (variantOpts) => {
  return {}
}

////////////
// Export //
////////////

export default {
  base,
  stateVariants,
}