import React from 'react'
// Base Components
import { Box, Flex, Grid, Text } from '../Base'
// Controls Components
import SelectorButton from '../ControlComps/SelectorButton'
import MenuButton from '../ControlComps/MenuButton'

// Model Components
import PresetDateRangePicker from '../PresetDateRangePicker'
import ChartContainer from './ChartJsContainer'
import QueryBuilder from '../Query/Builder/TreeBuilder'
// Models
import controlledChartModel from './ControlledChartModel'
import { useModel_ChartJs } from './ChartJsModel'
import { useModel_PresetDateRangePicker } from '../PresetDateRangePickerModel'
// Utils
import { charts_reqDataFunc } from './chartApi'
import { useApiCall } from '../../lib/useApi'
//

const ControlledChart = React.memo(({ chartModelOpts, chartLibOpts, queryOpts, datePickerOpts, ...props }) => {
  const [ chartState, chartMethods ] = controlledChartModel({ chartModelOpts, chartLibOpts, queryOpts })
  const { title, chartControls } = chartState
  
  // Child models
  const [ chartLibState, chartLibMethods ] = useModel_ChartJs(chartState.chartLibOpts)
  const [ dateRangePickerState, dateRangePickerMethods ] = useModel_PresetDateRangePicker(chartState.datePickerOpts)

  const queryCompsOptKeys = Object.keys(chartState.queryOpts)

  // Hook up chartLibModel, TODO eliminate this
  React.useEffect(
    () => {
      chartMethods.setChartLibModel(chartLibState, chartLibMethods)
    },
    [ chartMethods, chartLibState, chartLibMethods ]
  )

  // Trigger Query Builder data update + chart data api call
  React.useEffect(
    () => {
      chartMethods.setWillTriggerToken()
    },
    [
      chartMethods,
      dateRangePickerState.onCloseState.start,
      dateRangePickerState.onCloseState.end,
      chartState.aggInterval,
    ]
  )

  React.useEffect(
    () => {
      chartMethods.setWillTriggerTokenIfChanged()
    },
    [
      chartMethods,
      chartLibState.libCfg.type,
    ]
  )

  useApiCall({
    handler: chartLibMethods.setData,
    method: 'post',
    urlBase: chartState.chartApiType === 'eventStats'
    ? '/saas/v1/stats/v1/'
    : '/saas/v1/charts/v1/',
    url: chartState.chartApiType === 'eventStats'
      ? 'eventNamesCountsStats'
      : chartState.chartApiType,
    useTimezone: true,
    includeAppIds: chartState.chartApiType === 'eventStats',
    reqDataFunc: charts_reqDataFunc,
    reqDataArgs: {
      chartApiType: chartState.chartApiType,
      aggInterval: chartState.aggInterval,
      start: dateRangePickerState.onCloseState.start,
      end: dateRangePickerState.onCloseState.end,
      query: chartState.query,
      supplementalData: chartState.supplementalData,
    },
    manualTriggerToken: chartState.triggerToken,
    requireManualTriggerToken: true,
  })

  return(
    <Grid gridRowGap='24px' gridTemplateRows='min-content 428px min-content min-content' width='100%'>
      <Flex justifyContent='space-between' flexWrap='wrap'>
        <Flex alignItems='stretch' height='40px'> 
          <Flex alignItems='center' lineHeight={0}>
            <Text variant='h5'>{title}</Text>
          </Flex>
          {
            queryCompsOptKeys.map((key) => {
              const item = chartState.queryOpts[key]

              if (item.type !== 'Selector') return null
              
              return (
                <Flex key={key} ml='15px'>
                  <SelectorButton
                    state={item.state} methods={chartMethods} id={key}
                    variant='outlined2'
                    color='primary'
                    fontSize='0.875rem'
                    lineHeight='1.125rem'
                  />
                </Flex>
              )
            })
          }
        </Flex>
        <Grid gridColumnGap='8px' gridAutoFlow='column' gridTemplateRows='40px' alignItems='center'>
          <SelectorButton
            state={chartControls.chartType} val={chartLibState.libCfg.type} methods={chartLibMethods}
            variant='outlined2'
            color='primary'
            fontSize='0.875rem'
            lineHeight='1.125rem'
          />
          {chartControls.aggInterval && <SelectorButton
            state={chartControls.aggInterval} methods={chartMethods}
            variant='outlined2'
            color='primary'
            fontSize='0.875rem'
            lineHeight='1.125rem'
          />}
          <PresetDateRangePicker
            state={dateRangePickerState} methods={dateRangePickerMethods}
            variant='outlined2'
          />
          <MenuButton
            state={chartControls.export} methods={chartMethods}
            variant='outlinedSmall'
            color='primary'
            id='export'
          />
        </Grid>
      </Flex>
      <ChartContainer
        state={chartLibState} methods={chartLibMethods}
        onRef={chartLibMethods.setChartCanvasRef}
      />
        {
          queryCompsOptKeys.map((key) => {
            const item = chartState.queryOpts[key]

            if (item.type !== 'Builder') return null

            return (
              <Grid gridGap='16px'>
                <Box key={key}>
                  <Text variant='small1' color='text.secondary' mb='8px'>{item.title}</Text>
                  <QueryBuilder
                    {...item.state}
                    id={key}
                    updateDataTrigger={chartState.willTriggerToken}
                    onDataUpdate={chartMethods.setQueryData}
                    setHasChanged={chartMethods.setQueryDataHasChanged}
                  />
                </Box>
              </Grid>
            )
          })
        }
    </Grid>
  );
})


export default ControlledChart
