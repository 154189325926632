export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboards",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD"
      },
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Safety",
        root: true,
        icon: "fas fa-hard-hat",
        page: "safety",
        sidebar: true,
        pluginName: 'safety',
        submenu: [
          {
            title: "Workload",
            // icon: "fas fa-chart-bar",
            page: "safety/workload",
          },
          {
            title: "Incidents",
            // icon: "fas fa-chart-bar",
            page: "safety/incidents",
            pluginName: 'safety',
          },
          {
            title: "Diagnostics",
            // icon: "fas fa-chart-bar",
            page: "safety/diagnostics",
            pluginName: 'safety',
          },
        ]
      },
      {
        title: "Dashboard",
        root: true,
        icon: "fas fa-layer-group",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot",
        sidebar: true,
      },
      {
        title: "Analysis",
        root: true,
        icon: "fas fa-chart-bar",
        page: "analysis",
        // pages can be conditional based on roles
        // roles: ["zhortech:admin", "zhortech:user"],
        sidebar: true,
        hidden: true
      },
      {
        title: "Cohorts",
        root: true,
        icon: "fas fa-chart-pie",
        page: "cohorts",
        sidebar: true,
      },
      {
        title: "Campaigns",
        root: true,
        icon: "fas fa-flag-checkered",
        page: "campaigns",
        sidebar: true,
        requiresApp: true
      },
      {
        title: "Explore",
        root: true,
        icon: "fas fa-search",
        page: "explore",
        sidebar: true,
      },
      {
        title: "Tools",
        root: true,
        icon: "fas fa-tools",
        page: "tools",
        sidebar: true,
        // pluginName: 'safety',
        submenu: [
          {
            title: "Activity Exporter",
            root: true,
            // icon: "fas fa-download",
            page: "tools/activitydata",
            sidebar: true,
            requiresApp: true, // Requires that an app is selected
          },
          {
            title: "Webhooks",
            root: true,
            // icon: "fas fa-share-alt",
            page: "tools/webhooks",
            sidebar: true,
            requiresApp: true,
            roles: ["org:admin", "zhortech:admin"],
          },
        ]
      },
      {
        separator: true,
        roles: ["org:admin", "zhortech:admin", "zhortech:user"]
      },
      {
        title: "Organization",
        root: true,
        icon: "fas fa-cog",
        page: "orgs",
        sidebar: true,
        roles: ["org:admin", "zhortech:admin", "zhortech:user"]
      },

      // Zhortech admin stuff
      // {
      //   separator: true,
      //   roles: ["zhortech:admin"]
      // },
      // {
      //   title: "Firmware",
      //   root: true,
      //   icon: "fas fa-microchip",
      //   sidebar: true,
      //   page: "firmware",
      //   roles: ["zhortech:admin"]
      // },
      {
        title: "Logs",
        root: true,
        icon: "fas fa-bug",
        sidebar: true,
        page: "logs",
        roles: ["zhortech:admin", "zhortech:user"]
      },
      {
        title: "API",
        root: true,
        icon: "fas fa-play",
        sidebar: true,
        page: "explorer",
        roles: ["zhortech:admin", "org:admin", "zhortech:user"]
      },
      // Hidden children can be navigated to, and will still have the side bar, but
      // they do not have a link inside the side bar.  Other parts of the app will
      // navigate to them.
      {
        title: "Org Settings",
        root: true,
        page: "settings",
        sidebar: true,
        hidden: true,
      },
      {
        title: "Styles",
        root: true,
        page: "styles",
        sidebar: true,
        hidden: true,
      },
      {
        title: "Logs",
        root: true,
        page: "logs",
        sidebar: true,
        hidden: true,
      },
      {
        title: "Account",
        root: true,
        page: "account",
        sidebar: true,
        hidden: true,
      },
      {
        title: "App Data Store",
        root: true,
        page: "_store",
        sidebar: true,
        hidden: true,
      },
      {
        title: "Settings",
        root: true,
        icon: "flaticon2-settings",
        page: "settings",
        sidebar: true,
        roles: ["zhortech:admin", "org:admin"],
        hidden: true,
      },
      {
        title: "Contact",
        root: true,
        page: "contact",
        sidebar: true,
        hidden: true,
      },
      {
        title: "Documentation",
        root: true,
        //page: "test",
        sidebar: true,
        roles: ["zhortech:admin", "org:admin"],
        hidden: true,
      },
      {
        title: "Shoes",
        root: true,
        icon: "fas fa-shoe-prints",
        page: "shoes",
        hidden: true,
        sidebar: true,
        requiresApp: true, // Requires that an app is selected
      },
      {
        title: "Users",
        root: true,
        icon: "fas fa-user-friends",
        page: "appusers",
        hidden: true,
        sidebar: true,
      },
    ]
  }
};
