import React from "react";
import { Box } from '../components/Base'

export default class MenuItemSeparator extends React.Component {
  render() {
    return (
      <Box
        width='100%'
        height='1px'
        bgcolor='primary.dark'
      />
    );
  }
}
