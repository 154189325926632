import React from 'react'

import { ModalIcon } from '../../../components/Modal'
import Permitted from '../../../components/Permitted'

import * as dbEntityCfgs from '../../../components/DbEntities/allDbEntities'
import { useEntityModalProps } from '../../../components/DbEntities/util'

import { Flex } from '../../../components/Base'

const defaultCohortOpts = {
  name: 'All Workers',
  target: 'appusers',
  isDefault: true,
}

const Settings = React.memo(({ cohort }) => {
  const dbEntityType = 'cohortAttributes'

  return (
    <Flex>
      <Permitted
        path='new'
        permsCfg={dbEntityCfgs[dbEntityType].actionsPermissionCfgs}
      >
        <ModalIcon
          hidden={cohort.name}
          icon='fas fa-cog'
          modalProps={
            useEntityModalProps('editNew', dbEntityType, { newEntityOpts: defaultCohortOpts }, null)
          }
        />
      </Permitted>
      <Permitted
        path='edit'
        permsCfg={dbEntityCfgs[dbEntityType].actionsPermissionCfgs}
      >
        <ModalIcon
          hidden={!cohort.name}
          icon='fas fa-cog'
          modalProps={
            useEntityModalProps('edit', dbEntityType, { entity: cohort }, null)
          }
        />
      </Permitted>
    </Flex>
  )

  // if (!cohort.name) {
  //   return (
  //     <Permitted
  //       path='new'
  //       permsCfg={dbEntityCfgs[dbEntityType].actionsPermissionCfgs}
  //     >
  //       <ModalButton
  //         label={'Settings'}
  //         size='small'
  //         variant='outlinedSmall'
  //         modalProps={
  //           useEntityModalProps('editNew', dbEntityType, { newEntityOpts: defaultCohortOpts }, null)
  //         }
  //       />
  //     </Permitted>
  //   )
  // }

  // return (
  //   <Permitted
  //     path='edit'
  //     permsCfg={dbEntityCfgs[dbEntityType].actionsPermissionCfgs}
  //   >
  //     <ModalButton
  //       label={'Settings'}
  //       size='small'
  //       variant='outlinedSmall'
  //       modalProps={
  //         useEntityModalProps('edit', dbEntityType, { entity: cohort }, null)
  //       }
  //     />
  //   </Permitted>
  // )
})

export default Settings