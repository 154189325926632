import React from 'react'

import { Box, Grid, Text } from '../../Base'
import TextField from '../../ControlComps/TextField'
import QueryBuilder from '../../Query/Builder/TreeBuilder'
import QueryWithEvents from '../campaigns/Audience'

const EditorContents = React.memo(({ state, methods }) => {

  const target = state.controls.target.val

  const builderOpts = React.useMemo(() => {
    return {
      variant: 'cohortQueryEditor',
      variantOpts: {
        target,
      }
    }
  }, [ target ])


  return (
    <>
      <Grid
        gridGap='24px'
        gridAutoFlow='column'
        gridTemplateColumns='50%'
      >
        <Box>
          {
            state.validationhint &&
              <Text
                variant='small3'
                color={'error.main'}
                mb='16px'
              >
                {state.validationhint}
              </Text>
          }
          <Text
            variant='small1'
            color='text.primary'
            pb='6px'
          >
            Name
          </Text>
          <TextField
            state={state.controls.name}
            methods={methods}
            variant='outlined'
            placeholder='Give your new cohort a title...'
            fullWidth
          />
        </Box>
      </Grid>
      <Box>
        <Text
          variant='small3'
          color={state.controls.query.isValid ? 'text.primary' : 'error.main'}
          pb='6px'
        >
          {
            !state.controls.query.isValid && state.controls.query.validationhint
              ? `${state.controls.query.validationhint}`
              : ''
          }
        </Text>
        {
          state.queryType === 'withEvents'
            ? <QueryWithEvents
              stateCfg={state.controls.query.val}
              shouldValidate={state.shouldValidate}
              onBuiltVal={methods[state.controls.query.methodKey]}
              showValidationHint={false}
              Title={
                <Text
                  variant='small1'
                  color='text.primary'
                  pb='6px'
                >
                  Create cohort by definition
                </Text>
              }
            />
            : <>
                <Text
                  variant='small1'
                  color='text.primary'
                  pb='6px'
                >
                  Create cohort by definition
                </Text>
                <QueryBuilder
                  initialData={state.controls.query.defaultVal}
                  updateDataTrigger={state.wantsToSaveToken}
                  builderOpts={builderOpts}
                  onDataUpdate={methods[state.controls.query.methodKey]}
                  setHasChanged={methods.setQueryHasChanged}
                />
              </>
        }
        
      </Box>
    </>
  )
})

export default {
  EditorContents,
  ManagerContents: null,
  DeleterContents: null,
  Profile: null,
}