import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from "react-router-dom"
import * as auth from "../../../store/ducks/auth.duck"

import useApi from '../../../lib/useApi'
import useStyles from '../../../lib/useStyles'
import { parseRole } from '../../../lib/utils'

import { Grid, Text, Flex, Box, IconResolver } from '../../../components/Base'
import Confirm from '../../../components/Confirm'

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  Button,
  Checkbox,
} from "@material-ui/core"

const EditForm = ({org, onSubmit, onCancel}) => {
  const inputRef = React.createRef()

  const classes = useStyles();
  const user = useSelector(store => store.auth.user)
  const urole = parseRole(user.role)
  const api = useApi()
  const dispatch = useDispatch()
  const {setOrganization} = auth.actions;
 
  const PROD = ( process.env.REACT_APP_BRANCH === 'prod' )
  const [openRemoveOrg, setOpenRemoveOrg] = React.useState(false);
  const [gotoOrgs, setGotoOrgs] = React.useState(false);

  // form state
  const [name, setName] = React.useState(org.name || '');
  const [description, setDescription] = React.useState(org.description || '');
  const [formErrors, setFormErrors] = React.useState({});
  const [active, setActive] = React.useState(org.status === "INACTIVE" ? false : true);

  // permissions config states
  const [isParent, setIsParent] = React.useState(org.isParent)
  const [canHaveSuborgs, setCanHaveSuborgs] = React.useState(org.canHaveSuborgs)
  const [canCreateApps, setCanCreateApps] = React.useState(org.canCreateApps)
  const [canCreateAdminUsers, setCanCreateAdminUsers] = React.useState(org.canCreateAdminUsers)
  const [hasApiKeys, setHasApiKeys] = React.useState(org.hasApiKeys)

  const [imgPreview, setImgPreview] = React.useState()

  const isDisabled = (urole.zt ? false : true)
  
  // Put the form data together to send back to the caller
  const prepareData = () => {
    return {
      id: org.id,
      name,
      description,
      status: active ? "ACTIVE" : "INACTIVE",
      isParent,
      canHaveSuborgs,
      canCreateApps,
      canCreateAdminUsers,
      hasApiKeys,
      logo: inputRef.current?.files[0] || null
    };
  }

  const checkForm = () => {
    setFormErrors({});
    let errors = {};

    if ( name.length < 4 ) errors.name = "Account name should be four or more characters";
    if ( description.length < 4 ) errors.description = "Description should be four or more characters";

    let valid = Object.keys(errors).length ? false : true;

    if ( ! valid ) setFormErrors(errors);

    return valid;
  }

  const clearError = (fieldName) => {
    if ( ! formErrors[fieldName] ) return;
    setFormErrors({
      ...formErrors,
      [fieldName]: undefined
    });
  }

  const checkAndSend = () => {
    if ( ! checkForm() ) return;
    onSubmit(prepareData());
  }

  const startRemove = () => {
    setOpenRemoveOrg(true);
  }

  const onRemoveOrg = (okied) => {
    setOpenRemoveOrg(false);
    if ( ! okied ) return;
    api({ method: "delete", url: `/api/Organizations/${org.id}`, handler: () => { 
      api({ method: "get", url: `/api/Organizations/${org.organizationId}`, handler: (parent) => {
        dispatch(setOrganization(parent));
        setGotoOrgs(true);
      }});
    }});
  }

  const imageHandler = (event) => {
    let formData = new FormData()
    formData.append("file", event.target.files[0])
    let file = event.target.files[0]
    if (file && file.size < 500000) {
      file.src = URL.createObjectURL(file)
      setImgPreview(file.src)
    }
    else if ( file.size >= 500000 ) {
      file = null
      alert("File is too big.")
    }
  }

  const removeSelectedImage = () => {
    setImgPreview(null)
  }

  if ( gotoOrgs === true ) return <Redirect to="/orgs" />

  return(
    <>
      <Grid
        gridGap='24px'
      >
        <FormControl fullWidth className={classes.formControl} error={formErrors['name'] !== undefined}>
          <Text variant='small1' color='text.primary' pb='6px'>
            Account Name *
          </Text>
          <TextField
            variant='outlined'
            autoFocus
            required
            type="text"
            value={name}
            onFocus={() => clearError('name')}
            onChange={(e) => setName(e.target.value)}
          />
          <FormHelperText>{formErrors['name'] || null }</FormHelperText>
        </FormControl>
        <FormControl fullWidth className={classes.formControl} error={formErrors['description'] !== undefined}>
          <Text variant='small1' color='text.primary' pb='6px'>
            Account Description *
          </Text>
          <TextField
            variant='outlined'
            required
            type="text"
            value={description}
            onFocus={() => clearError('description')}
            onChange={(e) => setDescription(e.target.value)}
          />
          <FormHelperText>{formErrors['description'] || null }</FormHelperText>
        </FormControl>
        <Box>
          <Text
            variant='small1'
            color='text.primary'
            pb='6px'
          >
            Logo
          </Text>
          <Text
            variant='small3'
            color='text.secondary'
            pb='6px'
          >
            For best results, upload a .png image smaller than 500 KB and at least 40 x 40 pixels.
          </Text>
          <Flex alignItems='center'>
            <Flex height='40px'>
              <input
                type='file'
                ref={inputRef}
                name='filename'
                id='file'
                className={classes.inputfile}
                onChange={imageHandler}
                accept='image/*'
              />
              <label
                htmlFor='file'
                className={classes.inputfileLabel}
              >
                Upload
              </label>
            </Flex>
            <Flex
              className={ imgPreview && classes.inputFilePreview}
              onClick={removeSelectedImage}
              height='58px'
              alignItems='center'
              mb='0.5rem'
              ml='24px'
            >
              <Flex
                maxWidth='128px'
                maxHeight='40px'
              >
                <img
                  src={imgPreview}
                  alt='No file selected.'
                  width='100%'
                  style={{fontSize: '12px', objectFit: 'contain'}}
                />
              </Flex>
            </Flex>
            {
              imgPreview &&
              <div id='show'>
                <IconResolver
                  icon={'fas fa-times'}
                  fontSize='12px'
                  color='danger.main'
                />
              </div>
            }
          </Flex>
        </Box>
        {
          urole.zt &&
          <Box>
            <Text variant='small1' color='text.primary' pb='6px'>
              Account Options
            </Text>
            <Grid gridTemplateColumns='1fr 1fr'>
              <FormControlLabel
                label={<Text variant='small1'>{'Is Parent Organization'}</Text>}
                control={
                  <Checkbox
                    checked={isParent}
                    disabled={isDisabled}
                    onChange={(e) => setIsParent(e.target.checked)}
                    color='primary'
                    size='small'
                  />
                }
              />
              <FormControlLabel
                label={<Text variant='small1'>{'Can Create Applications'}</Text>}
                control={
                  <Checkbox
                    checked={canCreateApps}
                    disabled={isDisabled}
                    onChange={(e) => setCanCreateApps(e.target.checked)}
                    color='primary'
                    size='small'
                  />
                }
              />
              <FormControlLabel
                label={<Text variant='small1'>{'Allow Sub-Organizations'}</Text>}
                control={
                  <Checkbox
                    checked={canHaveSuborgs}
                    disabled={isDisabled}
                    onChange={(e) => setCanHaveSuborgs(e.target.checked)}
                    color='primary'
                    size='small'
                  />
                }
              />
              <FormControlLabel
                label={<Text variant='small1'>{'Can Create Admin Users'}</Text>}
                control={
                  <Checkbox
                    checked={canCreateAdminUsers}
                    disabled={isDisabled}
                    onChange={(e) => setCanCreateAdminUsers(e.target.checked)}
                    color='primary'
                    size='small'
                  />
                }
              />
              <FormControlLabel
                label={<Text variant='small1'>{'Can Manage API Keys'}</Text>}
                control={
                  <Checkbox
                    checked={hasApiKeys}
                    disabled={isDisabled}
                    onChange={(e) => setHasApiKeys(e.target.checked)}
                    color='primary'
                    size='small'
                  />
                }
              />
            </Grid>
          </Box>
        }
        {
          org.organizationId &&
          <Box>
            <Text variant='small1' pb='6px'>
              Account Status
            </Text>
            <Flex
              justifyContent='space-between'
              alignItems='center'
            >
              <FormControlLabel
                label={<Text variant='small1'>Active</Text>}
                control={
                  <Checkbox
                    color='primary'
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                    value="active"
                    size='small'
                  />
                }
              />
              {
                urole.zt && !PROD &&
                <Button
                  variant='outlined'
                  size='small'
                  onClick={startRemove}
                >
                  Remove Account
                </Button>
              }
            </Flex>
          </Box>
        }
        <Flex
          width='100%'
          justifyContent='flex-end'
          alignItems='center'
          mb='8px'
        >
          <Button
            onClick={() => onCancel(null)}
            variant='outlined'
            size='small'
            style={{ marginRight: "16px", }}
          >
            Cancel
          </Button>
          <Button
            onClick={checkAndSend}
            variant='contained'
            size='small'
            color="primary"
          >
            {org.id ? 'Save' : 'Add'}
          </Button>
        </Flex>
      </Grid>
      <Confirm
        size="sm"
        open={openRemoveOrg}
        onClose={onRemoveOrg}
        title="Remove Account"
      >
        This function is not enabled in production.  This is a test only feature.  If you
        confirm, this organization will be deleted on the server, but any associated objects
        like SaaS users and apikeys will NOT be deleted.  In the production system, organizations
        will only be INACTIVATED.
      </Confirm>
    </>
  );
}

export default EditForm;
