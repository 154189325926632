import { useModel } from '../../../lib/state'
import dbEntityModelFactory from '../entityModelFactory'
import initEntityModel from './initEntityModel'
import ContentComps from './ContentComps'

let entityCfg = {
  ContentComps,
  dbEntityType: 'appusers',
  label: 'User',
  labelPlural: 'Users',
  icon: 'fas fa-user-friends',
  relations: {
    belongsTo: {
      organization: true,
      app: true,
    },
    hasOne: {
      profileImage: true,
      history: true,
    },
    hasMany: {
      shoes: true,
      apps: true,
      events: true,
      activities: true,
      chunks: true,
    },
  },
  actionsPermissionCfgs: {
    role: {
      // TODO delete activities, maybe in activities table
      superAdmin: { edit: true, delete: true, devLogs: true },
    },
  },
  managementTabs: null,
  explore: {
    root: {
      lastEventsTable: {
        connectedEntityType: 'shoes',
        showCohortDropdown: true,
      },
    },
    profile: true,
    activities: {
      chart: true,
      table: true,
    },
    chunks: {
      table: true,
    },
    relations: {
      shoes: true,
    },
  },
}

entityCfg = {
  ...entityCfg,
  nameLabel: 'User Id',
  nameExportKey: 'userId',
  nameDataPath: 'userId',
  getEntityName(entity) {
    return entity.userId
  },
  getAttributesViewObj(entity) {
    const ret = { ...entity.attributes }
    delete ret.name
    return ret
  },
  getAttributesConnectedEntities(entity) {
    return {
      shoes: {
        id: entity.attributes.lastShoeId,
        code: entity.attributes.lastShoeCode,
      }
    }
  },
}

const entityModel =  dbEntityModelFactory(initEntityModel(entityCfg))
const useEntityModel = (stateInitializerArgs) => useModel(entityModel, stateInitializerArgs)
entityCfg.useEntityModel = useEntityModel

export default entityCfg