import React from 'react'

import useModel from './model'

import { Box, Flex, Grid, Text } from '../../../../Base'
import SelectorButton from '../../../../ControlComps/SelectorButton'
import ControlButton from '../../../../ControlComps/ControlButton'

import SearchProperty from '../SearchProperty'
import SearchEventsAgg from '../SearchEventsAgg'

const Comp = React.memo(({ stateCfg, shouldValidate, onBuiltVal, onBuiltValOpts, isFirst, ...props }) => {
  const [ state, methods ] = useModel(stateCfg, shouldValidate, onBuiltVal, onBuiltValOpts)

  // negation and conjuction
  let NegationConjuctionComp = null
  if (isFirst) {
    NegationConjuctionComp = (
      <Text variant='small1' pt='8px'>
        {'The Users who'}
      </Text>
    )
  }
  else {
    NegationConjuctionComp = (
      <SelectorButton
        state={state.controls.negate}
        methods={methods}
        variant='outlined2'
        color='primary'
        methodKey='setNegate'
        fontSize='0.875rem'
      />
    )
  }

  // search
  let SearchComp = null
  if (!state.searchType) {
    SearchComp = (
      <Box>
        <Grid
          gridGap='8px'
          gridAutoFlow='column'
          height='32px'
          alignItems='center'
          gridAutoColumns='max-content'
        >
          <ControlButton
            variant='outlinedSmall'
            color='secondary'
            methods={methods}
            state={state.controls.searchType_eventsAgg}
          />
          <ControlButton
            variant='outlinedSmall'
            color='secondary'
            methods={methods}
            state={state.controls.searchType_property}
          />
        </Grid>
      </Box>
    )
  }
  else {
    if (state.searchType === 'eventsAgg') {
      SearchComp = (
        <SearchEventsAgg
          stateCfg={state.controls.search.val}
          shouldValidate={state.shouldValidate}
          onBuiltVal={methods.setSearch}
        />
      )
    }
    else if (state.searchType === 'properties') {
      SearchComp = (
        <SearchProperty
          stateCfg={state.controls.search.val}
          shouldValidate={state.shouldValidate}
          onBuiltVal={methods.setSearch}
        />
      )
    }
  }

  return (
    <>
     <Flex>
        <Box
          borderRight='1px solid #e3e3e3'
          width='185px'
          // pt='8px'
        >
          { NegationConjuctionComp }
        </Box>
        <Flex
          // alignItems='center'
          justifyContent='space-between'
          pl='24px'
          width='100%'
        >
          { SearchComp }
          {
            state.searchType && (
              <ControlButton
                variant='outlinedSmall'
                color='secondary'
                methods={methods}
                state={state.controls.clear}
                text='Clear'
              />
            )
          }
        </Flex>
      </Flex>
    </>
  )
})

export default Comp