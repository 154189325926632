import React from 'react'

import useModel from './model'

import { Box, Flex, Grid, Text } from '../../../Base'
import TextField from '../../../ControlComps/TextField'
import SelectorButton from '../../../ControlComps/SelectorButton'

import { Event } from '@material-ui/icons'

const Comp = React.memo(({ stateCfg, shouldValidate, onBuiltVal, ...props }) => {
  const [ state, methods ] = useModel(stateCfg, shouldValidate, onBuiltVal)

  let SchedulingTypedControls = null
  if (state.controls.schedulingType.val === 'once') {
    SchedulingTypedControls = (
      <Box>
        <Text
          variant='small1'
          pb='6px'
        >
          On
        </Text>
        <TextField
          state={state.controls.on}
          methods={methods}                
          variant='outlined'
          inputType='datetime-local'
          fullWidth
          />
      </Box>
    )
  }
  else if (state.controls.schedulingType.val === 'repeating') {
    SchedulingTypedControls = (
      <>
        <Box>
          <Text
            variant='small1'
            pb='6px'
          >
            Start date
          </Text>
          <TextField
            state={state.controls.startDate}
            methods={methods}                
            variant='outlined'
            inputType='date'
            fullWidth
            />
        </Box>
        <Box>
          <Text
            variant='small1'
            pb='6px'
          >
            Time
          </Text>
          <TextField
            state={state.controls.time}
            methods={methods}
            variant='outlined'
            inputType='time'
            fullWidth
            />
        </Box>
        <Box>
          <Text
            variant='small1'
            pb='6px'
          >
            End date
          </Text>
          <TextField
            state={state.controls.endDate}
            methods={methods}
            variant='outlined'
            inputType='date'
            fullWidth
            />
        </Box>
        <Box>
          <Text
            variant='small1'
            pb='6px'
          >
            Interval
          </Text>
          <Flex height='32px' bgcolor='white' borderRadius='8px'>
            <SelectorButton
              state={state.controls.interval}
              methods={methods}
              variant='outlined2'
              color='default'
              width='100%'
            />
          </Flex>
        </Box>
      </>
    )
  }

  return (
    <>
      <Flex alignItems='center' mb='16px' justifyContent='start'>
        <Event color='primary'/>
        <Text ml='8px' variant='subtitle1' color='primary.main'>
          Scheduling
        </Text>
      </Flex>
      {
        state.validationhint &&
          <Text
            variant='small3'
            color={'error.main'}
            mb='16px'
          >
            {state.validationhint}
          </Text>
      }
      <Flex flexDirection='column'>
        <Grid
          gridAutoFlow='column'
          gridAutoColumns='1fr'
          gridColumnGap='24px'
        >
          <Box>
            <Text
              variant='small1'
              pb='6px'
            >
              Send
            </Text>
            <Flex height='32px' bgcolor='white' borderRadius='4px'>
              <SelectorButton
                state={state.controls.schedulingType}
                methods={methods}
                variant='outlined2'
                color='default'
                width='100%'
              />
            </Flex>
          </Box>
          {SchedulingTypedControls}
        </Grid>
      </Flex>
    </>
  )
})

export default Comp