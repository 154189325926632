import React from 'react'
import { StringifyProps } from '../Base'
import deepMerge from '../../lib/deepMerge'
import { useTheme } from '@material-ui/core/styles'
// import { inspect } from 'util'

// MODEL TREE
//
// <Tree
//   state={treeState}
//   methods={treeMethods}
// />

// STATIC TREE
//
// <Tree
//   item={{ _treeChildren: [], ... }} 
//   isBranchFunc={func}
//   compResolver={func} // optional ONLY IF all branches and leaves contain Comp { Comp, ... }
//   treeCompProps={all: {}, branch: {}, leaf: {}, root: {}} //optional
//   {...otherPassedToAllComps}
// />

// TODO do we want rootProps? we would then remove isRoot from the Comp (still used in compResolver)
const Tree = React.memo((inProps) => {
  // Static Cfg
  let {
    // Model
    state,
    // Other
    item,
    isRoot = true,
    debug,
    // Recursive
    isBranchFunc,
    compResolver,
    treeCompProps,
    ...otherIn
  } = inProps

  // Model overrides
  if (state && state.root) {
    isBranchFunc = state.isBranchFunc
    debug = debug || state.debug
    compResolver = compResolver || state.compResolver
    if (treeCompProps) treeCompProps = deepMerge(state.treeCompProps, treeCompProps)
    else treeCompProps = state.treeCompProps

    item = state.root
    isRoot = true
  }

  // Store recursive tree props
  const recursiveTreeProps = {
    isBranchFunc,
    compResolver,
    treeCompProps,
    ...otherIn,
  }

  // Destructure 'Tree Wide' Comp props
  let {
    all: allTreeWideCompProps,
    leaf: allLeafProps,
    branch: allBranchProps,
    root: rootProps
  } = treeCompProps || {}

  allTreeWideCompProps = allTreeWideCompProps || {}

  // Destructure item
  let {
    // Cfged comp
    Comp,
    // generic tree item properties
    _treeChildren,
    _treeId,
    // other
    ...otherItem
  } = item

  const isBranch = isBranchFunc(item)
  let hasChildren = _treeChildren && _treeChildren.length > 0

  // Construct Comp props coming from 'Tree Wide' cfg
  let selfTreeWideProps = isBranch ? allBranchProps : allLeafProps
  if (isRoot) selfTreeWideProps = { ...rootProps, ...selfTreeWideProps }

  // Resolve Comp if not cfged
  if (!Comp) {
    Comp = compResolver(item, hasChildren, isRoot)
  }

  // Debug
  if (debug) {
    if (isRoot) {
      React.useEffect(() => {
        console.log('TREE_STATE_CHANGE root:', item)
      }, [item])
    }
    if (Comp === undefined && debug) {
      Comp = StringifyProps
      otherItem.debug = true
    }
  }

  if (!allTreeWideCompProps.theme) {
    allTreeWideCompProps.theme = useTheme()
  }

  if (!Comp) return null

  // Render
  return (
    <Comp
      {...otherIn}
      {...allTreeWideCompProps}
      {...selfTreeWideProps}
      {...otherItem} // TODO use _item prop instead
      key={_treeId}
      id={_treeId}
    >
      {
        isBranch && hasChildren && _treeChildren.map((child) => {
          return (
            <Tree
              {...otherIn}
              {...recursiveTreeProps}
              key={child._treeId}
              item={child}
              isRoot={false}
            />
          )
        })
      }
    </Comp>
  )
})

export default Tree