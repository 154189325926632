import moment from 'moment-timezone'
import useMethods from 'use-methods'

const startDaysAgoToPreset = (val) => {
  if (val === 0) {
    return {
      label: `Today`,
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    }
  }

  return {
    label: `Last ${val} Days`,
    start: moment().startOf('day').subtract(val, 'days'),
    end: moment().endOf('day'),
  }
}

const handleDefaultPresetIdx = (state) => {
  let { presets, defaultPresetIdx } = state
  if (presets && presets.length && defaultPresetIdx >= 0) {
    state.start = presets[state.defaultPresetIdx].start
    state.end = presets[state.defaultPresetIdx].end
  }
  state.presets = presets
}

const handleOnClose = (state) => {
  state.onCloseState.start = state.start.startOf('day')
  state.end = state.end || state.start
  state.onCloseState.end = state.end.endOf('day')
}

const stateInitializer = (stateIntializerArgs) => {
  
  let {
    min, max,
    start, end,
    presets_startDaysAgo, presets, defaultPresetIdx = 0,
    readOnly = true,
    focusedInput,
    minimumNights = 0,
  } = {
    min: moment('2018-01-01'),
    max: moment().endOf('day'),
    defaultPresetIdx: 2,
    presets_startDaysAgo: [0, 7, 30, 90],
    ...stateIntializerArgs,
  }

  if (start && !start.startOf) start = moment(start)
  if (end && !end.startOf) end = moment(end)

  const state = {
    min,
    max,
    start,
    end,
    presets: presets || (presets_startDaysAgo && presets_startDaysAgo.map(startDaysAgoToPreset)) || null,
    defaultPresetIdx,
    methodKey: 'onDatesChange',
    onCloseKey: 'onClose',
    onCloseState: {},
    focusedInput,
    readOnly,
    minimumNights,
  }

  if (!state.start && !state.end) {
    handleDefaultPresetIdx(state)
  }

  handleOnClose(state)
  
  return state
}

const _methods = state => {
  const _setPresets = (presets, presetIdx) => {
    state.presets = presets
    if (presetIdx || presetIdx === 0) {
      state.defaultPresetIdx = presetIdx
    }
    handleDefaultPresetIdx(state)
  }

  return {
    setFocusedInput(val, opts) {
      state.focusedInput = val
    },
    onDatesChange({ startDate, endDate }, opts) {
      state.start = startDate
      state.end = endDate
    },
    onClose() {
      handleOnClose(state)
    },
    setPresets(...args) {
      _setPresets(...args)
    },
    setPresets_startDaysAgo(startDaysAgo, presetIdx) {
      _setPresets(startDaysAgo.map(startDaysAgoToPreset), presetIdx)
    },
  }
}

const useModel = (stateIntializerArgs) => {
  return useMethods(_methods, stateIntializerArgs, stateInitializer)
}

export {
  useModel as useModel_PresetDateRangePicker
}

// The folowing can be put in _methods as an escape hatch
// to access state without subscribing to it updating in useEffect
// this seems rather dirty, but might be the cleaner ways to do this sort of thing without
// more sophisticated model creation that can handle model nesting

// withState(func) {
//   func(state)
// }

// EXAMPLE USE

// React.useEffect(() => {
//   dateRangePickerMethods.withState((state) => {
//     chartMethods.setTimeframeBoundsSettings({startDate: state.start, endDate: state.end})
//   })
// }, [
//   dateRangePickerState.onCloseToken,
//   dateRangePickerMethods,
//   chartMethods,
// ])