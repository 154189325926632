import { uniq } from 'lodash'

const opTofieldValFunc_Loopback = {
  '_': (field, val) => ({ [field]: val }),
  '=': (field, val) => ({ [field]: val }),
  '==': (field, val) => ({ [field]: val }),
  '!=': (field, val) => ({ [field]: { neq: val } }),
  '<': (field, val) => ({ [field]: { lt: val } }),
  '>': (field, val) => ({ [field]: { gt: val } }),
  '<=': (field, val) => ({ [field]: { lte: val } }),
  '>=': (field, val) => ({ [field]: { gte: val } }),

  // Probably want to...
  // - TODO escape ., (), [], ^, $, ?, +, *, {}, \, |
  // DOES NOT WORK WITH LOOPBACK-FILTERS (A library seperate to loopback) - (must use regex), should work when used inside an actual loopback query
  'contains': (field, val) => ({ [field]: { like: val, options: 'i' } }),
  '!contains': (field, val) => ({ [field]: { nlike: val, options: 'i' } }),

  // loopback 'like' uses $regex w/ mongodb, but 'regexp' in loopback does not support options!
  // 'regex': (field, val) => ({ [field]: { like: val, options: 'i' } }), 
}

const boolStringsDefault = {
  true: [
    'true',
    'True',
    'TRUE',
  ],
  false: [
    'false',
    'False',
    'FALSE',
  ],
}

export function tryTransformToPrimitive(val, boolStrings = boolStringsDefault) {
  if (val == null) return val

  if (!isNaN(val)) {
    val = Number(val)
  }
  else if (boolStrings) {
    if (boolStrings.true.indexOf(val) >= 0) {
      val = true
    }
    else if (boolStrings.false.indexOf(val) >= 0) {
      val = false
    }
  }

  return val
}

export function queryData_toLoopback(data, boolStrings = null, isRoot = true) {
  if (!data) return undefined

  if (isRoot) {
    data = tryExpandCohort(data)
  }

  let children = (data.groupOp && data.children) || data.and || data.or // TODO do we use and/or here?
  if (children) {
    if (!children.length) return undefined
    let groupOp = data.groupOp || (data.and && 'and') || (data.or && 'or')
    return {
      [groupOp]: children.map((o) => queryData_toLoopback(o, boolStrings, false)) // recurse
    }
  }
  else if (data.field) {
    let { field, op, val } = data

    val = tryTransformToPrimitive(val, boolStrings)

    return opTofieldValFunc_Loopback[op](field, val)
  } 
}

export function tryExpandCohort(data) {
  if (!data) return undefined

  if (data._query) {
    data = data._query
  }
  else if (data._cohort) {
    data = data._cohort.query
  }

  if (!data) return undefined

  let children = (data.groupOp && data.children) || data.and || data.or // TODO do we use and/or here?
  if (children) {
    data = { ...data }
    data.children = children.map(tryExpandCohort) // recurse
  }

  return data
}

export function queryData_fields(data, acc = [], isRoot = true) {
  let children = data.groupOp && data.children

  if (children && children.length) {
    children.forEach((item) => queryData_fields(item, acc, false)) // recurse
  }
  else if (data.field) {
    acc.push(data.field)
  }

  if (isRoot) {
    acc = uniq(acc)
    acc.sort()
  }

  return acc
}