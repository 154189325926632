import React from "react"
import axios from 'axios'

import { useApi_OrgId_AppId } from '../../lib/hooks'
import { useSelector } from 'react-redux'

// This is the OpenAPI component
// https://mrin9.github.io/RapiDoc/api.html
import 'rapidoc'

/*
  For the explorer component to work, the portal user will have to select an organization and select an app.  They will
  probably also want to go to the user Explore page and select an appuser.

  The App selected needs to have some attributes set in order for this page to work; specifically

  explorerServer: The host that serves up the OpenAPI specification and the actual endpoints, eg "http://localhost:6060"
  explorerSpecPath: The endpoint that serves the OpenAPI spec, eq "/zc/openapi/spec"
  explorerAuthPath: The endpoint used to exchange Organization apikey/secret for authorization beaer token, eg "/zc/auth/authenticate"

  This should then be theoretically compatible with old Zcloud, if old Zcloud eventually implements an OpenAPI spec for the
  custom mobile endpoints.
*/

const Explorer = React.memo(() => {
  const appl = useSelector(store => store.auth.app)
  const appuser = useSelector(store => store.auth.appuser); // x-userid
  const user = useSelector(store => store.auth.user);       // footprints user

  const [ api, organizationId, appId ] = useApi_OrgId_AppId()
  const explorer = React.useRef()

  const [ isLoading, setIsLoading ] = React.useState(false);

  // In case we want to display these anywhere...
  // let [headers, setHeaders] = React.useState('');
  // let [userId, setUserId] = React.useState('');

  if (!organizationId) {
    return(<h2>You must select an organization first.</h2>)
  }

  if (!appl) {
    return(<h2>You must select an App under this Organization first.</h2>)
  }

  if (!(appl && appl.attributes && appl.attributes.explorerServer && appl.attributes.explorerAuthPath && appl.attributes.explorerSpecPath)) {
    return (
      <>
        <h2>This Organization/App does not support api exploration!</h2>
        To support API exploration, your App must have the following attributes configured properly:
        <p/>
        <ul>
          <li>explorerServer: The server that returns an OpenAPI specification and serves the API endpoints.</li>
          <li>explorerSpecPath: The path on the server above that returns the OpenAPI specification.</li>
          <li>explorerAuthPath: The path on the server above that imeplements API authentication.</li>
        </ul>
      </>
    )
  }

  // common path ... include the common rest and data(ingest) sections
  let specUrlPath = appl.attributes.explorerSpecPath;
  let sections = ["rest", "data"];
  // if there is an app, then include the app pluginName as a section in the openapi spec
  if (appl && appl.pluginName) {
    sections.push(appl.pluginName);
  }
  let specUrlHost = appl.attributes.explorerServer;
  if (sections.length) specUrlPath = specUrlPath + "?section=" + sections.join(",");

  React.useEffect(() => {
    if (!organizationId) return; // need a selected organization!
    if (!(explorer && explorer.current)) return; // need the explorer ref!
    if (!appl) return; // need a selected app!

    // When mounting, fetch the apikey/secret from the current Organization and use this
    // to get JWT token from the backend.
    setIsLoading(true);
    api({
      method: 'get',
      url: `/api/Organizations/${organizationId}/apikeys`,
      handler: apikeys => {
        if (apikeys.length===0) return;
        let {apikey, secret} = apikeys[0];
        // Got the keys, now get auth token
        axios.post(`${appl.attributes.explorerServer}${appl.attributes.explorerAuthPath}`, {apikey, secret}).then(({data}) => {
          // Add a custom authentication handler.  This is called prior to a TRY, gets the request
          // object and can monkey wit the headers...
          explorer.current.addEventListener("before-try", (e) => {
            // authorization token
            e.detail.request.headers.append("Authorization", `Bearer ${data.accessToken}`);
            // if an app is selected, add the x-appid
            if (appId) {
              e.detail.request.headers.append("x-appid", appl.appId);
            }
            // and if there is a app user selected (by visting Explore and clicking on a user), then
            // add the x-userid header
            if (appuser) {
              e.detail.request.headers.append("x-userid", appuser.userId);
              // setUserId(appuser.userId);
            }
            // Add the footprints user role, so backend can filter openapi spec endpoints based on role
            e.detail.request.headers.append("x-fp-role", user.role);

            /* uncomment if we want to capture these somewhere...
            let headers = [];
            e.detail.request.headers.forEach((value, key) => {
              headers.push(`-H "${key}=${e.detail.request.headers.get(key)}"`);
            });
            setHeaders(headers.join(' '));
            */
          });
          axios({
            method: "get",
            url: `${specUrlHost}${specUrlPath}`,
            headers: {
              'x-fp-role': user.role
            }
          }).then(({data}) => {
            setIsLoading(false);
            explorer.current.loadSpec(data);
          });
        })
      }
    });
  }, [api, organizationId, appId, appuser, appl, user, specUrlHost, specUrlPath] )

  return (
    <>
      { isLoading && <div>Loading APIs ...</div> }
      <rapi-doc
        ref={explorer}
        theme="light"
        render-style="view"
        allow-server-selection={false}
        style = {{ height: "100vh", width: "100%" }}
        allow-authentication={false}
        allow-spec-url-load={false}
        allow-spec-file-load={false}
      >
      </rapi-doc>
    </>
  )
})

export default Explorer;

