import React from 'react'

import { useSelector } from 'react-redux'

import useModel from './model'
import useStyles from '../../../../lib/useStyles'

import { Box, Flex, Grid, Text } from '../../../Base'
import TextField from '../../../ControlComps/TextField'
import SelectorButton from '../../../ControlComps/SelectorButton'

import Mustache from 'mustache'
import emailTemplates from './Templates'

import { MailOutline } from '@material-ui/icons'

const RenderMustache = React.memo(({ template, mustacheProps = {}, ...props }) => {
  const __html = Mustache.render(template, mustacheProps)

  return (
    <div dangerouslySetInnerHTML={{__html}}/>
  )
})

const MessageCompositionEmail = React.memo(({ stateCfg, shouldValidate, onBuiltVal, ...props }) => {
  const classes = useStyles()
  const [ state, methods ] = useModel(stateCfg, shouldValidate, onBuiltVal)

  const orgName = useSelector((store) => store.auth.organization.name)

  let EmailContentControls = null
  let PreviewComp = null

  if (state.controls.template.val === 'custom') {
    EmailContentControls = (
      <Box>
        <Text
          variant='small1'
          pb='6px'
        >
          Custom HTML
        </Text>
        <TextField
          state={state.controls.html}
          methods={methods} 
          multiline
          placeholder='<html/>'
          variant='outlined'
          fullWidth
          rows={16}
        />
      </Box>
    )

    PreviewComp = (
      <RenderMustache
        template={state.controls.html}
      />
    )
  }
  else {
    PreviewComp = (
      <RenderMustache
        template={emailTemplates[state.controls.template.val]}
        mustacheProps={React.useMemo(() => {
          return {
            ...state._content,
            orgName: orgName,
            email: 'example@user.com',
            sender_name: state.controls.fromName.val,
          }
        }, [state._content, orgName, state.controls.fromName.val])}
      />
    )
  }

  if (state.controls.template.val === 'basic') {
    EmailContentControls = (
      <>
        <Grid
          gridGap='24px'
          gridAutoFlow='column'
        >
          {
            state.validationhint &&
              <Text
                variant='small2'
                color={'error.main'}
              >
                {state.validationhint}
              </Text>
          }
          <Box>
            <Text
              variant='small1'
              pb='6px'
            >
              Header Logo URL 
            </Text>
            <TextField
              state={state.controls.logoURL}
              methods={methods}
              placeholder='https://domain.com/my-logo.png'
              variant='outlined'
              fullWidth
            />
            {/* <img
              src={state.controls.logoURL.val}
              alt=''
              style={{
                objectFit: 'contain',
                width: '100%',
                maxHeight: '40em',
                marginTop: '12px',
                objectPosition: 'left',
              }}
            /> */}
          </Box>
          <Box>
            <Text
              variant='small1'
              pb='6px'
            >
              Header Color
            </Text>
            <Flex flexDirection='row'>
              <TextField
                state={state.controls.color_1}
                methods={methods}
                placeholder='#FFFFFF'
                variant='outlined'
              />
              {/* <Flex ml='12px' alignItems='center'>
                <Box
                  borderRadius='50%'
                  height='2em'
                  width='2em'
                  bgcolor={state.controls.color_1.val}
                />
              </Flex> */}
            </Flex>
          </Box>
        </Grid>
        <Box>
          <Text
            variant='small1'
            pb='6px'
          >
            Salutation
          </Text>
          <TextField
            state={state.controls.salutation}
            methods={methods} 
            placeholder='Salutation message'
            variant='outlined'
            fullWidth
          />
        </Box>
        <Box>
          <Text
            variant='small1'
            pb='6px'
          >
            Body
          </Text>
          <TextField
            state={state.controls.body}
            methods={methods} 
            multiline
            placeholder='Email body content'
            variant='outlined'
            fullWidth
            rows={3}
            rowsMax={10}
          />
        </Box>
        <Box>
          <Text
            variant='small1'
            pb='6px'
          >
            Farewell
          </Text>
          <TextField
            state={state.controls.farewell}
            methods={methods} 
            placeholder='Farewell message'
            variant='outlined'
            fullWidth
          />
        </Box>
      </>
    )
  }

  return (
    <>
      <Flex alignItems='center' mb='16px' justifyContent='start'>
        <MailOutline color='primary'/>
        <Text ml='8px' variant='subtitle1' color='primary.main'>
          Email Composition
        </Text>
      </Flex>
      <Flex flexDirection='column'>
        <Grid
          gridGap='24px'
        >
          <Grid
            gridGap='24px'
            gridAutoFlow='column'
          >
            <Box>
              <Text
                variant='small1'
                pb='6px'
              >
                From Email
              </Text>
              <TextField
                state={state.controls.fromEmail}
                methods={methods}
                placeholder='example@domain.com'
                variant='outlined'
                fullWidth
              />
            </Box>
            <Box>
              <Text
                variant='small1'
                pb='6px'
              >
                From Name
              </Text>
              <TextField
                state={state.controls.fromName}
                methods={methods}
                placeholder='Your name'
                variant='outlined'
                fullWidth
              />
            </Box>
            <Box>
              <Text
                variant='small1'
                pb='6px'
              >
                Reply-To Email
              </Text>
              <TextField
                state={state.controls.replyTo}
                methods={methods}              
                placeholder='example@domain.com'
                variant='outlined'
                fullWidth
              />
            </Box>
            <Box>
              <Text
                variant='small1'
                pb='6px'
              >
                BCC Email
              </Text>
              <TextField
                state={state.controls.bcc}
                methods={methods} 
                placeholder='example@domain.com'
                variant='outlined'
                fullWidth
              />
            </Box>
          </Grid>
          <Box>
            <Text
              variant='small1'
              pb='6px'
            >
              Subject
            </Text>
            <TextField
              state={state.controls.subject}
              methods={methods} 
              placeholder='Email subject'
              variant='outlined'
              fullWidth
            />
          </Box>
          <Box className={classes.divider}/>
          <Grid
            gridGap='24px'
            gridAutoFlow='column'
            alignItems='center'
            justifyContent='start'
          >
            <Box>
              <Text
                variant='small1'
                pb='6px'
              >
                Template
              </Text>
              <Flex height='32px' bgcolor='white' borderRadius='4px'>
                <SelectorButton
                  state={state.controls.template}
                  methods={methods} 
                  placeholder='Select a template'
                  variant='outlined2'
                  color='default'
                  width='100%'
                />
              </Flex>
            </Box>
          </Grid>
          <Box className={classes.divider}/>
          {
            EmailContentControls
          }
          <Box className={classes.divider}/>
          <Box>
            <Text
              variant='small1'
              pb='6px'
            >
              Preview
            </Text>
            <Box border='1px solid #DBDDE0'>
              {
                PreviewComp
              }
            </Box>
          </Box>
        </Grid>
      </Flex>
    </>
  )
})

export default MessageCompositionEmail