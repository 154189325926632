/*
   Meant to be an "invisible" wrapper around something that needs to display a
   loading indicator.
 */
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const Segment = ({minHeight, minWidth, busy, children}) => {
  return(
    <>
    {busy && (
      <div
        style={{
          minWidth,
          minHeight,
          position: "absolute",
          top: 0,
          left: 0,
          textAlign: "center",
          verticalAlign: "center",
          zIndex: 9000,
        }}
      >
        <CircularProgress
          color="primary"
          style={{
            position: "absolute",
            top: "50%",
          }}
        />
      </div>
    )}
    {children}
    </>
  )
}

export default Segment
