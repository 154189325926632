import React from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import { Box, Flex, Text, IconResolver } from '../../../components/Base';
import { EditOutlined, MoreVertOutlined, FileCopyOutlined, } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles'

import {CopyToClipboard} from 'react-copy-to-clipboard';

import moment from 'moment-timezone';

import useApi from '../../../lib/useApi';
import {EventNames} from '../../../lib/utils';

import Segment from '../../../components/Segment';

import { useModel_ChartJs } from '../../../components/Charting/ChartJsModel'
import ChartContainer from '../../../components/Charting/ChartJsContainer'
import useStyles from '../../../lib/useStyles'

const AppCard = ({urole, app, onEdit, onRemove, onToggleActivation, onSelect, notify, onEditWebhooks }) => {
  const classes = useStyles();
  const isAdmin = urole.admin;
  const [ menuAnchor, setMenuAnchor ] = React.useState(null);
  const [ isLoading, setIsLoading ] = React.useState(false);
  
  const handleAction = (op) => {
    setMenuAnchor(null);
    if ( op === 'edit' ) onEdit(app);
    if ( op === 'remove' ) onRemove(app);
    if ( op === 'activation' ) onToggleActivation(app);
    if ( op === 'webhooks' ) onEditWebhooks(app);
  }

  const [ totalUsers, setTotalUsers ] = React.useState(0);

  const api = useApi();
  const api2 = useApi();

  const [ chartState, chartMethods ] = useModel_ChartJs({
    variant: 'appCard',
    variantOpts: {
      theme: useTheme(),
    },
  })

  React.useEffect(() => {
    api({
      method: "post",
      url: "/saas/v1/stats/v1/eventNamesCountsStats",
      data: {
        appId: app.id,
        start: moment.tz(global.TZ).subtract(6, 'days').startOf('day'),
        end: moment().tz(global.TZ),
        tz: global.TZ,
        interval: 'day',
        unique: true,
        eventName: EventNames.ACTIVATED,
        uniqueByAppuser: true,
      },
      isLoading: setIsLoading,
      handler: (res) => {
        setTotalUsers(res.total)
        chartMethods.setData(res)
      }
    })
  }, [api, chartMethods, app.id])

  const isProd = process.env.REACT_APP_BRANCH === 'prod' ? true : false;
  const isActive = (app.status === 'ACTIVE');
  
  ///////////////////////////////////////
  // Check if app has current firmware //
  ///////////////////////////////////////
  const [fwVersions, setFwVersions] = React.useState(app.fwVersion ? [app.fwVersion] : []);
  const plugin = app.pluginName
  const currentFwVersion = app.fwVersion
  
  React.useEffect(() => {

    if ( plugin ) {
      api2({method: 'get', url: `/saas/v1/fw/v1/versionsFor/${plugin}`, handler: (versions) => {
          setFwVersions(versions);
      }});
    }
    else {
      setFwVersions([]);
    }
  }, [api2, plugin]);

  let hasLatestFw = false
  if (currentFwVersion === fwVersions[0]) {
    hasLatestFw = true
  }

  return(
    <Card className={classes.appCard}>
      <Segment minWidth={225} minHeight={262} busy={isLoading}>
        <CardActionArea  onClick={() => onSelect(app)}>
          <CardContent className={classes.cardContent}>
            <>
              <Box mb='8px'>
                <Text variant='subtitle1'>{app.name}</Text>
                {
                  app.description &&
                  <Text
                    variant='small3'
                    color='text.disabled'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                  >
                    {app.description}
                  </Text>
                }
              </Box>
              <Box mb='4px'>
                <Flex
                  alignItems='center'
                >
                  <Flex>
                    <Text variant='small1' color='text.secondary' mr='6px'>{"Firmware:"}</Text>
                    <Text
                      variant='small2'
                      color={ hasLatestFw ? 'success.main' : 'warning.main'}
                    >
                      {app.fwVersion}
                    </Text>
                  </Flex>
                  <Flex>
                    <Flex ml='6px'>
                      {
                        hasLatestFw
                          ? <IconResolver icon={'fas fa-check'} fontSize='12px' color='success.main'/>
                          : <IconResolver icon={'fas fa-exclamation-triangle'} fontSize='12px' color='warning.main'/>
                      }
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
              <Box mb='8px'>
                <Flex justifyContent='space-between' alignItems='center'>
                  <Flex alignItems='baseline'>
                    <Text variant='small1' color='text.secondary'>{"Devices Paired"}</Text>
                    <Text variant='small3' color='text.disabled' ml='4px'>{'(Last 7 days)'}</Text>
                  </Flex>
                  <Text variant='value2' color='primary.main'>{totalUsers.toLocaleString()}</Text>
                </Flex>
              </Box>
              <ChartContainer height='112px' state={chartState} methods={chartMethods}/>
            </>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          <>
          {isActive && (
            <div>
              <Tooltip title="Copy app ID" arrow>
                <CopyToClipboard text={app.appId} onCopy={() => notify("success", `App ID ${app.appId} copied to clipboard`)}>
                  <IconButton> <FileCopyOutlined color='disabled' className={classes.actionButton}/> </IconButton>
                </CopyToClipboard>
              </Tooltip>
            </div>
          )}
          {!isActive && (
            <span className={classes.appStatusInactive}>INACTIVE</span>
          )}
        {isAdmin && (
          <>
          <Tooltip title="Edit app" arrow>
            <IconButton onClick={() => handleAction('edit')}> <EditOutlined color='disabled' className={classes.actionButton}/> </IconButton>
          </Tooltip>
          <Tooltip title="More actions" arrow>
            <IconButton aria-label="more options" onClick={e => setMenuAnchor(e.currentTarget)}> <MoreVertOutlined color='disabled' className={classes.actionButton}/> </IconButton>
          </Tooltip>
          <Menu
              id="actions"
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => setMenuAnchor(null)}
              keepMounted
          >
            <MenuItem onClick={() => handleAction('activation')}>{isActive ? 'Deactivate app' : 'Activate app'}</MenuItem>
            {!isProd && <MenuItem onClick={() => handleAction('remove')}>Remove app</MenuItem>}
          </Menu>
          </>
        )}
          </>
        </CardActions>
      </Segment>
    </Card>
  );
}

export default AppCard;


  
