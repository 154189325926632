import React from 'react'

import { useModel, objectVariantOrPassthrough, initControls, setControlVal } from '../../../../lib/state'
import { useApi_AppId } from '../../../../lib/hooks'
import stateVariants from './variants'

import { KeyboardReturn } from '@material-ui/icons'

import { range } from 'lodash'

//
// const query = {
//   type: 'withEvents',
//   target: 'appusers',
//   and: [
//     {
//       searchType: 'eventsAgg',
//       search: {
//         name: null,
//         agg: 'count',
//         op: '>=',
//         val: 1000,
//         time: {
//           start: null,
//           end: null,
//           lastDays: 600,
//           offsetDays: 0,
//         },
//       },
//       negate: false,
//     },
//     {
//       searchType: 'properties',
//       search: {
//         field: 'attributes.activityCount',
//         op: '==',
//         val: 4,
//       },
//       negate: false,
//     },
//   ],
// }

const updateFirstQueryItemWithoutSearchType = (state) => {
  let firstQueryItemWithoutSearchType = -1
  range(6).forEach((i) => {
    if ((!state.controls[i].val.searchType) && firstQueryItemWithoutSearchType < 0) {
      firstQueryItemWithoutSearchType = i
    }
  })
  state.firstQueryItemWithoutSearchType = firstQueryItemWithoutSearchType
}

///////////
// MODEL //
///////////

const model = {
  ///////////
  // STATE //
  ///////////
  stateInit(args) {
    let ret = {
      ...objectVariantOrPassthrough(stateVariants, args),
      //
    }

    ret.controls = {}

    ret.otherControls = {
      test: {
        title: 'Test',
        icon: KeyboardReturn,
        variant: 'icon',
        color: 'primary',
      },
    }

    const and = ret.and || []
    
    range(6).forEach((i) => {
      if (and.length > i) {
        ret.controls[i] = { val: and[i] }
        ret.controls[i].target = ret.controls[i].target || ret.target
      }
      else {
        ret.controls[i] = { val: { target: ret.target } }
      }
    })

    initControls(ret)

    ret._testAudienceToken = undefined
  
    return ret
  },
  /////////////
  // METHODS //
  /////////////
  methodsInit(state) {
    return {
      testAudience(val, opts) {
        if(!state._testAudienceToken){
          state._testAudienceToken = Math.random()
          state.otherControls.test.disabled = true
          state._testRes = undefined
        }
      },
      setQueryItem(val, opts) {
        // console.log('set query item', opts, val)
        setControlVal(state, state.controls[opts], val)
        updateFirstQueryItemWithoutSearchType(state)
      },
      _testAudienceCb(val, opts) {
        state._testAudienceToken = undefined
        state.otherControls.test.disabled = false
        state._testRes = val || []
      },
      _setShouldValidate(val, opts) {
        state.shouldValidate = val
        setControlVal(state)
      },
      _buildVal(val, opts) {
        const and = []
        range(6).forEach((i) => {
          if (state.controls[i].val.searchType) {
            and.push(state.controls[i].val)
          }
        })

        const _val = {
          type: 'withEvents',
          target: state.target || 'appusers',
          and,
        }

        state._builtVal = _val

        //
        state._testRes = undefined
      },
    }
  },
  ///////////
  // HOOKS //
  ///////////
  hooks: {
    useTestAudience: {
      getHookArgs: (state) => ({
        token: state._testAudienceToken,
        query: state._builtVal,
      }),
      callback: (res, methods) => {
        methods._testAudienceCb(res)
      },
      useHook: (args = {}, cb) => {
        const { token, query } = args
        const [ api, appId ] = useApi_AppId()
        React.useEffect(() => {
          if (!token) return
      
          api({
            method: 'post',
            url: `/saas/v1/Cohorts/v1/queryWithEvents`,
            data: {
              appId,
              query,
              idsOnly: true,
            },
            handler: cb,
          })
        }, [cb, api, appId, token, query])
      }
    },
  },
}

// returns [ state, methods ]
export default (...args) => {
  return useModel(model, ...args)
}