import { useModel } from '../../../lib/state'
import dbEntityModelFactory from '../entityModelFactory'
import initEntityModel from './initEntityModel'
import ContentComps from './ContentComps'

import { SyncOutlined } from "@material-ui/icons"

let entityCfg = {
  ContentComps,
  dbEntityType: 'webhooks',
  label: 'Webhook',
  labelPlural: 'Webhooks',
  icon: SyncOutlined, // fas fa-share-alt
  relations: {
    belongsTo: {
      organization: true,
      app: true,
    },
    hasMany: {},
  },
  actionsPermissionCfgs: {
    role: {
      anyAdmin: { edit: true, delete: true, new: true },
    },
    entity: {},
  },
  modalOpts: {
    base: {
      creator: {
        size: 'large',
      },
      editor: {
        size: 'large',
        variant: 'interact',
      },
      deleter: null,
    },
    form: {
      // creator: {

      // },
      editor: {
        saveOnClose: false,
      }
    },
  },
  manage: {
    // TODO_MAYBE list/editor dual pane view
  },
}

entityCfg = {
  ...entityCfg,
  otherData: {
    authTypes: {
      basic: {
        label: 'Basic'
      },
      signature: {
        label: 'Signature'
      },
    },
    testMethods: {
      get: {
        label: 'Get'
      },
      post: {
        label: 'Post'
      },
      put: {
        label: 'Put'
      },
    },
    testContentTypes: {
      app: {
        label: 'App'
      },
      json: {
        label: 'JSON'
      },
    },
  },
}

entityCfg = {
  ...entityCfg,
  nameLabel: 'URL',
  nameDataPath: 'url',
  getEntityName(entity) {
    return entity.url
  },
  getNewEntityBase(newEntityOpts = {}) {
    return {}
  },
}

const entityModel =  dbEntityModelFactory(initEntityModel(entityCfg))
const useEntityModel = (stateInitializerArgs) => useModel(entityModel, stateInitializerArgs)
entityCfg.useEntityModel = useEntityModel

export default entityCfg