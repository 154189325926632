import React from 'react'

import ManageDbEntity from '../../components/DbEntities/Manage'
// import CohortBuilder from './CohortBuilder'

const CohortsPage = () => {
  return (
    <>
      <ManageDbEntity
        dbEntityType='cohorts'
        basePath='/cohorts'
      />
      {/* <CohortBuilder/> */}
    </>
  )
}

export default CohortsPage



