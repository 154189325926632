import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import produce from 'immer'
import { set, get } from 'lodash'
import { useSelector } from 'react-redux'

const initialState = {
  lists: {
    cohorts: [],
    latestFirmwares: [],
    plugins: [],
    supportedArches: [],
    apikeys: [],
    campaigns: [],
    webhooks: [],
  },
  dynamic: {},
}

const mutators = {
  //
  // Any list
  //
  listSet(state, listKey, val, allowListCreation) {
    if (!state.lists[listKey] && !allowListCreation) return

    state.lists[listKey] = val || []
  },
  listUpsert(state, listKey, items, allowListCreation) {
    let storeList = state.lists[listKey]
    if (!storeList) {
      if (!allowListCreation) return

      storeList = []
      state.lists[listKey] = storeList
    }

    if(!Array.isArray(items)) items = [ items ]
    
    items.forEach((item) => {
      const idx = storeList.findIndex((storeItem) => storeItem.id === item.id)
      if (idx >= 0) storeList[idx] = item
      else storeList.splice(0, 0, item)
    })
  },
  listRemove(state, listKey, items) {
    const storeList = state.lists[listKey]
    if (!storeList) return

    if(!Array.isArray(items)) items = [ items ]
    
    items.forEach((item) => {
      const idx = storeList.findIndex((storeItem) => storeItem.id === item.id)
      if (idx >= 0) storeList.splice(idx, 1)
    })
  },
  //
  // Other
  //
  setAtDynamicPath(state, path, val) {
    set(state.dynamic, path, val)
  },
}

const helpers = {
  useDynamicPathSelector(path, def, isIdOfEntityType, resolver) {
    return useSelector((store) => {
      let ret = get(store.dbEntities.dynamic, path)

      if (isIdOfEntityType) {
        ret = store.dbEntities.lists?.[isIdOfEntityType].filter((item) => item.id === ret)?.[0]
      }

      if (resolver) {
        ret = resolver(store, ret)
      }

      if (ret === undefined) return def

      return ret
    })
  }
}

export const dbEntitiesStoreActions = {
  ...Object.keys(mutators).reduce((accum, type) => {
    accum[type] = (dispatch, ...payload) => dispatch({ type, payload });
    return accum;
  }, {}),
  ...helpers,
}

export const reducer = persistReducer(
  {
    storage,
    key: 'zt-saas-dbEntities',
    whitelist: [],
  },
  (state = initialState, action) => {
    if (!mutators[action.type]) return state
    return produce(state, (draft) => mutators[action.type](draft, ...action.payload))
  },
)

// const initListMethods = (listKey, whitelist = false) => {
//   initialState.lists[listKey] = []
//   if (whitelist) {
//     persistWhiteList.push('lists.listKey')
//   }
  
//   return {
//     [`${listKey}SetList`]: (state, val) => {
//       state.lists[listKey] = val || []
//     },

//     [`${listKey}Upsert`]: (state, items) => {
//       const storeList = state.lists[listKey]
//       if(!Array.isArray(items)) items = [ items ]
      
//       items.forEach((item) => {
//         const idx = storeList.findIndex((storeItem) => storeItem.id === item.id)
//         if (idx >= 0) storeList[idx] = item
//         else storeList.push(item)
//       })
//     },

//     [`${listKey}Remove`]: (state, items) => {
//       const storeList = state.lists[listKey]
//       if(!Array.isArray(items)) items = [ items ]
      
//       items.forEach((item) => {
//         const idx = storeList.findIndex((storeItem) => storeItem.id === item.id)
//         if (idx >= 0) storeList.splice(idx, 1)
//       })
//     },
//   }
// }

