import React from 'react'

import { styled } from '@material-ui/core/styles';
import { style, compose } from '@material-ui/system';

import { Box as _Box, Icon } from '@material-ui/core';

import { isString } from 'lodash'

import { Tooltip } from '@material-ui/core'

//
// Util
//

const create_style_selector = ({ selector, propSuffix }) => ({ prop, themeKey, cssProperty }) => {
  return style({
    prop: `${prop}${propSuffix}`,
    cssProperty: false,
    themeKey,
    transform: (val) => {
      return {
        [selector]: {
          [cssProperty]: val
        }
      }
    },
  })
}

//
// Style Creators
//

const style_variant = (themeKey) => style({
  prop: 'variant',
  cssProperty: false,
  themeKey,
})



//
// Styles
//
// Style Functions - https://material-ui.com/system/basics/#custom-style-props
//  - See https://material-ui.com/system/api/ for list of props included with Box
//  - NOTE: typography is not fully listed in aggregate api https://material-ui.com/system/typography/

// Image
const bggradient = style({
  prop: 'bggradient',
  cssProperty: 'backgroundImage',
  themeKey: 'gradients',
})
const bgimage = style({
  prop: 'bgimage',
  cssProperty: 'backgroundImage',
  themeKey: 'images',
})
const backgroundRepeat = style({ prop: 'backgroundRepeat' })
const backgroundPosition = style({ prop: 'backgroundPosition' })

// Hover
const style_hover = create_style_selector({
  selector: '&:hover',
  propSuffix: '_hover',
})

const hover = compose(
  style_hover({
    prop: 'bgimage',
    cssProperty: 'backgroundImage',
    themeKey: 'images',
  }),
  style_hover({
    prop: 'boxShadow',
    cssProperty: 'boxShadow',
    themeKey: 'shadowsKeyed',
  }),
  style_hover({
    prop: 'color',
    cssProperty: 'color',
    themeKey: 'palette',
  }),
  style_hover({
    prop: 'bgcolor',
    cssProperty: 'backgroundColor',
    themeKey: 'palette',
  }),
  style_hover({
    prop: 'borderColor',
    cssProperty: 'borderColor',
    themeKey: 'palette',
  }),
  style_hover({
    prop: 'transition',
    cssProperty: 'transition',
    themeKey: 'transitions',
  }),
)

// Grid
const gridGap = style({ prop: 'gridGap' })
const gridColumnGap = style({ prop: 'gridColumnGap' })
const gridRowGap = style({ prop: 'gridRowGap' })
const gridColumn = style({ prop: 'gridColumn' })
const gridAutoFlow = style({ prop: 'gridAutoFlow' })
const gridAutoColumns = style({ prop: 'gridAutoColumns' })
const gridAutoRows = style({ prop: 'gridAutoRows' })
const gridTemplateColumns = style({ prop: 'gridTemplateColumns' })
const gridTemplateRows = style({ prop: 'gridTemplateRows' })
const gridTemplateAreas = style({ prop: 'gridTemplateAreas' })
const gridArea = style({ prop: 'gridArea' })

const grid = compose(
  gridGap,
  gridColumnGap,
  gridRowGap,
  gridColumn,
  gridAutoFlow,
  gridAutoColumns,
  gridAutoRows,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  gridArea,
)

// Grid placement
const gridColumnStart = style({ prop: 'gridColumnStart' })
const gridColumnEnd = style({ prop: 'gridColumnEnd' })
const gridRowStart = style({ prop: 'gridColumnStart' })
const gridRowEnd = style({ prop: 'gridColumnEnd' })

const gridPlacement = compose(
  gridColumnStart,
  gridColumnEnd,
  gridRowStart,
  gridRowEnd,
)

// Components
export const Box = styled(_Box)(compose(
  gridPlacement,
  bggradient,
  bgimage,
  backgroundRepeat,
  backgroundPosition,
  hover,
  style({ prop: 'cursor' }),
  style({
    prop: 'transition',
    themeKey: 'transitions',
  }),
))

// Box.defaultProps = {
// }

export const Flex = styled(Box)({})
Flex.defaultProps = {
  display: 'flex',
}

export const Grid = styled(Box)(grid)
Grid.defaultProps = {
  display: 'grid',
}

const StyledText = styled(Box)(compose(
  style({ prop: 'textTransform' }),
  style_variant('variantsText'),
  style({ prop: 'lineHeight' }),
  style({ prop: 'fontWeight' }),
  style({ prop: 'wordBreak' }),
))
StyledText.defaultProps = {
  color: 'text.primary',
}

export const Text = StyledText

export const Card = styled(Box)(style_variant('variantsCard'))
Card.defaultProps = {
  bgcolor: 'bg.primary',
  borderRadius: '8px',
  boxShadow: 1,
  p: '24px',
}

export const Button = styled(Box)({})
Button.defaultProps = {
  bgcolor: 'primary.main',
  borderRadius: '8px',
  boxShadow: 'shadowsKeyed.button',
  px: '24px',
  py: '11px',
}

// TODO Material UI does not properly support polymorphism like Styled-Components
export const Image = styled(Box)({})
Image.defaultProps = {
  maxWidth: '100%',
  height: 'auto',
  // component: 'img',
  // as: 'img',
  m: 0,
}

export const StringifyProps = ({ methods, children, debug, __textProps, ...props }) => {
  // const str = inspect(props, { compact: false, depth: 2, breakLength: 150 })
  if (debug) {
    props.__methodsKeys = Object.keys(methods)
    props.__childrenLength = React.Children.count(children)
  }
    
  const str = JSON.stringify(props, null, 2)
  return (
    <Text whiteSpace='pre' {...__textProps}>
      {str}
    </Text>
  )
}


export const TextHandler = React.memo(({ text, label, val, children, title, hasTooltip, tooltipProps, ...props }) => {
  let _val = text || label || val || children
  if (!_val) {
    return null
  }
  else if (title || hasTooltip) {
    return (
      <Tooltip title={title || text || label || val} arrow {...tooltipProps}>
        <StyledText {...props}>
          {_val}
        </StyledText>
      </Tooltip>
    )
  }
  else {
    return (
      <StyledText {...props}>
        {_val}
      </StyledText>
    )
  }
})

export const RenderConfig = React.memo(({ renderCfg, ...inProps }) => {
  if (!(renderCfg && renderCfg.Comp)) return null
  const {
    Comp,
    props,
  } = renderCfg
  return (
    <Comp
      {...props}
      {...inProps}
    />
  )
})

const FaStyledIcon = styled(Icon)({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  height: '100%',
})

export const IconResolver = React.memo(({ icon, fontSize, color, ...props }) => {
  if (!icon) {
    return null
  }
  
  if (isString(icon)) {
    if (icon.startsWith('fa')) {
      return (
        <Box fontSize={fontSize} color={color}>
          <FaStyledIcon fontSize='inherit' {...props} className={icon}/>
        </Box>
      )
    }
  }
  else if (React.isValidElement(icon)) {
    return icon
  }
  else {
    const Comp = icon
    return (
      <Box fontSize={fontSize} color={color}>
        <Comp {...props}/>
      </Box>
    )
  }
})


// <Text variant="h1">
//   variant=h1
// </Text>

// UTIL

// Unused, can achieve the same thing with a grid, though gridGap is not always ideal
// const Gapper = React.memo(({ gapX, gapY, children }) => {
//   let spacer = (<Box height={gapY || '100%'} width={gapX || '100%'}/>)
//   let c = React.Children.toArray(children);
//   if (c.length === 1) return c;
//   let cc = [];
//   for (let i = 1; i < c.length; i++) {
//     cc.push(c[i - 1]);
//     cc.push(spacer);
//   }
//   cc.push(c[c.length - 1])
//   for (let i = 0; i < cc.length; i++) {
//     cc[i] = React.cloneElement(cc[i], {key: cc[i].props.key || i})
//   }
//   return cc;
// })
