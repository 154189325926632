import React from 'react'
import { useSelector } from 'react-redux'

import TabbedRoutes from '../../components/TabbedRoutes'
import Apps from './apps'
import Team from '../../components/Tables/NonModel/TeamTable'
import OrganizationProfile from './groups'

import ManageDbEntity from '../../components/DbEntities/Manage'

import { keyedHooks } from '../../lib/hooks'
import { parseRole } from '../../lib/utils'

const routesCfg = [
  { label: "Account Management", page: "/orgs/account", component: OrganizationProfile },
  { label: "Applications", page: "/orgs/apps", component: Apps },
]
const adminRoutesCfg = [
  ...routesCfg,
  { label: "Team Members", page: "/orgs/team", component: Team },
]

const _hasApiKeys = [
  { label: "API Keys", page: "/orgs/apikeys", component: () => {
      keyedHooks.useDbApi_getList({
        dbEntityType: 'apikeys',
      })
      return (
        <ManageDbEntity dbEntityType='apikeys' basePath='/orgs/apikeys'/>
      )
    }
  },
]

const Orgs = () => {
  const user = useSelector(store => store.auth.user)
  const urole = parseRole(user.role)
  const organization = useSelector(store => store.auth.organization)

  let items = []
  if (urole.admin) { items = adminRoutesCfg }
  else { items = routesCfg }
  if (organization?.hasApiKeys) { items = [...items, ..._hasApiKeys] }
  return (
    <>
      <TabbedRoutes
        items={ items }
        redirectFrom={'/orgs'}
      />
    </>
  )
}

export default Orgs
