import React from 'react'

import { Grid } from '../../../components/Base'
import ControlBar from './ControlBar'
import { ActivityDataPropertyChartGrid } from './StatsCard'
import { workloadFieldsCfg } from '../util'

import { useParams } from 'react-router-dom'

const dataPropertyChartGridCfg = [
  //
  {
    title: 'Workload',
  },
  ...workloadFieldsCfg.map((item) => {
    return {
      label: item.label,
      dataKey: item.dataField,
    }
  }),
  {
    label: 'Distance Covered',
    dataKey: 'distance',
  },
  {
    label: 'Number of Steps',
    dataKey: 'stepsCount',
  },
  //
  {
    title: 'Incident Detection',
  },
  //
  {
    label: 'Potential Slip Events',
    dataKey: 'slip',
  },
  
  // //
  // {
  //   title: 'Gait',
  // },
  // //
  // {
  //   label: 'Speed',
  //   dataKey: 'walkingSpeed',
  // },
  // {
  //   label: 'Cadence',
  //   dataKey: 'cadence',
  // },
  // {
  //   label: 'Stride Length',
  //   dataKey: 'strideLength',
  // },
  // {
  //   label: 'Symmetry',
  //   calc: ((allData) => {
  //     const {
  //       contactTimeR,
  //       flightTimeR,
  //       contactTimeL,
  //       flightTimeL,
  //     } = allData

  //     const points = contactTimeR.map((_, i) => {
  //       const L = contactTimeL[i] + flightTimeL[i]
  //       const R = contactTimeR[i] + flightTimeR[i]

  //       if (L === 0 || R === 0) return 0

  //       let ratio = L/R

  //       // Normalize to percentage
  //       if (ratio < 1) ratio = (1 / ratio) * -1

  //       // Center around 0
  //       if (ratio < 0) ratio += 1
  //       else ratio -= 1

  //       return ratio * 100
  //     })

  //     return {
  //       points,
  //       yUnit: '% Difference L vs R',
  //       ticks: {
  //         min: -30,
  //         max: 30,
  //       }
  //     }
  //   }),
  //   localDataKey: 'symmetry',
  // },
  // {
  //   label: 'Contact Time',
  //   dataKey: ['contactTimeL', 'contactTimeR'],
  //   dataKeyLabels: [ 'Left', 'Right '],
  //   localDataKey: 'contactTime',
  // },
  // {
  //   label: 'Flight Time',
  //   dataKey: ['flightTimeL', 'flightTimeR'],
  //   dataKeyLabels: [ 'Left', 'Right '],
  //   localDataKey: 'flightTime'
  // },
]

const Profile = React.memo(({ entity, entityName, dateRange }) => {
  const [ localDateRange, setLocalDateRange ] = React.useState(dateRange)

  const { id } = useParams()

  const appusersWhere = React.useMemo(() => {
    return {
      id: entity?.id || id,
    }
  }, [ id, entity ])

  // console.log(entity)

  let pageTitle = entityName || id || 'Username'

  // if (entity?.attributes?.lastShoeCode) {
  //   pageTitle += ' - ' + entity.attributes.lastShoeCode
  // }

  return (
    <Grid
      gridAutoColumns='1fr'
      gridGap='32px'
    >
      <ControlBar pageTitle={pageTitle} setDateRange={setLocalDateRange} dateRange={localDateRange} labelSecondary={`Device ID: ${entity.attributes.lastShoeCode}`}/>
      <ActivityDataPropertyChartGrid
        cfg={dataPropertyChartGridCfg}
        dateRange={dateRange}
        appusersWhere={appusersWhere}
        noAnimation={true}
        activitiesDataAgg={entity.activitiesDataAgg}
        entity={entity}
        entityName={entityName}
      />
    </Grid>
  )
})

export default Profile