import modelVariants from './ControlledChartModelStateVariants'
import { useModel, objectVariantOrPassthrough } from '../../lib/state'
import deepMerge from '../../lib/deepMerge'
import { some } from 'lodash'

//  TODO DateRangePicker variant based off of aggInterval?

//
// SUB STATES INITIALIZERS (for useMethods)
//

const stateInitializer_controls = (controlsOpts) => {
  if (controlsOpts === null) return null

  let ret = {}

  if(controlsOpts.chartType !== null) {
    ret.chartType = {
      isValid: true,
      methodKey: 'setChartType',
      items: {
        bar: {
          label: 'Bar Chart',
        },
        line: {
          label: 'Line Chart',
        },
      }
    }
  }

  if(controlsOpts.aggInterval !== null) {
    ret.aggInterval = {
      isValid: true,
      val: 'day',
      methodKey: 'setAggInterval',
      items: {
        day: {
          label: 'Daily',
        },
        week: {
          label: 'Weekly',
        },
        month: {
          label: 'Monthly',
        },
      },
    }
  }

  if(controlsOpts.export !== null) {
    ret.export = {
      val: 'Export',
      methodKey: 'exportAs',
      items: {
        csv: {
          label: 'CSV',
          methodOpts: {
            exportType: 'data',
          }
        },
        png: {
          label: 'Image',
          methodOpts: {
            exportType: 'image',
          }
        },
      }
    }
  }

  if (controlsOpts.refresh !== null) {
    ret.refresh = {

      val: 'Apply',
      methodKey: 'setWillTriggerToken',
    }
  }

  return ret
}

function _setQueryByDropdown (state, val, opts, id) {
  const dropDownState = state.queryOpts[id].state
  const item = dropDownState.items[val]

  state.query[id] = item.query
  dropDownState.val = val
  dropDownState.isValid = !item.isInvalid

  if (item.unit) {
    state.chartLibModel.methods.setYUnit(item.unit)
  }
}

//
// MODEL
//

const model = {
  //
  // STATE
  //
  stateInit({ chartModelOpts, chartLibOpts, queryOpts, datePickerOpts }) {
    // Chart Model Variant
    const {
      chartLibOpts: _chartLibOpts,
      queryOpts: _queryOpts,
      datePickerOpts: _datePickerOpts,
      title,
      aggInterval,
      chartApiType,
      controlsOpts,
      query = {},
      _hooks,
      supplementalData = {},
    } = objectVariantOrPassthrough(modelVariants, chartModelOpts)
  
    const chartControls = stateInitializer_controls(controlsOpts)
    const ret = {
      title,
      chartApiType,
      chartControls,
      aggInterval: (chartControls && chartControls.aggInterval && chartControls.aggInterval.val) || aggInterval || 'day',
      query,
      _queryBuilderHasChanged: {},
      _querySelectorKeys: [],
      hasChanged: false,
      // child models opts
      queryOpts: deepMerge(queryOpts, _queryOpts),
      datePickerOpts: deepMerge(datePickerOpts, _datePickerOpts),
      chartLibOpts: deepMerge(chartLibOpts, _chartLibOpts),
      // wrapped models
      chartLibModel: {},
      //
      _hooks,
      //
      supplementalData
    }

    Object.entries(ret.queryOpts).forEach(([key, qOpt]) => {
      if (qOpt.type === 'Selector') {
        qOpt.state.methodKey = 'setQueryByDropdown'
        _setQueryByDropdown(ret, qOpt.state.val, null, key)
        ret._querySelectorKeys.push(key)
      }
    })

    ret.queryBuilderCount = Object.values(ret.queryOpts).filter((item) => item.type === 'Builder').length
    ret.queryBuildersUpdated = 0
  
    return ret
  },
  //
  // METHODS
  //
  methodsInit(state) {
    const chartFileName = () => {
      let ret = `${state.title}`
      return ret
    }
  
    const _setTriggerToken = () => {
      const unsetQueryDropdownCount = state._querySelectorKeys.filter((key) => !state.query[key]).length
  
      if (unsetQueryDropdownCount > 0)
        return
  
      state.triggerToken = Math.random()
    }
  
    const handleQueryUpdateCounting = () => {
      state.queryBuildersUpdated += 1
      if (state.queryBuildersUpdated === state.queryBuilderCount) {
        _setTriggerToken()
        state.queryBuildersUpdated = 0
      }
    }
  
    const _setWillTriggerToken = () => {
      if (state.queryBuilderCount === 0) {
        _setTriggerToken()
      } else {
        state.willTriggerToken = Math.random()
      }
    }
  
    return {
      setTitle(val, opts) {
        state.title = val
      },
      setAggInterval(val, opts) {
        state.chartControls.aggInterval.val = val
        state.aggInterval = val
      },
      // Query Builder
      setQueryData(val, opts, id) {
        if (!(opts && opts.skipCounting)) {
          handleQueryUpdateCounting()
        }
        state.query[id] = val
      },
      setQueryDataHasChanged(val, opts, id) {
        state._queryBuilderHasChanged[id] = val
        state.hasChanged = some(Object.values(state._queryBuilderHasChanged))
      },
      // Query Dropdown
      setQueryByDropdown(val, opts, id) {
        _setQueryByDropdown(state, val, opts, id)
        _setWillTriggerToken()
      },
      setQueryDropdownState(val, opts, id) {
        state.queryOpts[id].state = {
          ...state.queryOpts[id].state,
          ...val,
        }
        _setQueryByDropdown(state, state.queryOpts[id].state.val, opts, id)
        _setWillTriggerToken()
      },
      // Effect Tokens
      setWillTriggerToken(val, opts) {
        _setWillTriggerToken()
      },
      setWillTriggerTokenIfChanged(val, opts) {
        if (state.hasChanged) _setWillTriggerToken()
      },
      //
      // CHART LIB WRAPPERS
      //
      setChartLibModel(_state, _methods) {
        state.chartLibModel.state = _state
        state.chartLibModel.methods = _methods
      },
      exportAs(val, opts) {
        state.chartLibModel.methods.exportAs(val, {
          fileName: chartFileName(),
          ...opts,
        })
      },
      setDisplayTimezone(val, opts) {
        state.chartLibModel.methods.setTimezone(val, opts)
      },
      setSupplementalData(val, opts) {
        if (opts.key) {
          state.supplementalData[opts.key] = val
        }
        else {
          state.supplementalData = val
        }
      },
    }
  },
}
export default (stateInitializerArgs) => {
  return useModel(model, stateInitializerArgs)
}