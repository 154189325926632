import React from 'react'

import useApi from '../lib/useApi'

import { Flex } from './Base'
import ControlButton from './ControlComps/ControlButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import RoomIcon from '@material-ui/icons/Room'

export const RouteMapButton = React.memo(({ title, url, disabled, onCompleted }) => {  
  const api = useApi()

  const [ isLoading, setIsLoading ] = React.useState(false)

  const doFetch = React.useCallback(() => {
    api({
      method: "get",
      url,
      isLoading: setIsLoading,
      handler: (data) => {
        onCompleted();
        window.open(data, "_blank");
      }
    })
  }, [api, url, onCompleted])

  const isInProgress = isLoading;
  
  return (
    <>
      {
        !isInProgress &&
          <ControlButton
            variant='icon'
            color='primary'
            disabled={disabled}
            title={title}
            startIcon={RoomIcon}
            onClick={doFetch}
          />
      }
      {
        isInProgress &&
          <Flex width='32px 'alignItems='center' justifyContent='center'>
            <CircularProgress size={16}/>
          </Flex>
      }
    </>
  )
})
