import { setControlVal, checkHasChanged } from '../../../lib/state'
import { mapValues } from 'lodash'

const initEntityModel = (entityCfg) => {
  return {
    dbEntityType: entityCfg.dbEntityType,
    hasWantsToSaveToken: true,
    entityMethodsInit(state) {
      return {
        setTarget(val, opts) {
          // TODO move to QueryEditor or QueryBuilder
          setControlVal(state, state.controls.target, val)
        },
        setName(val, opts) {
          setControlVal(state, state.controls.name, val)
        },
        setDescription(val, opts) {
          setControlVal(state, state.controls.description, val)
        },
        setQuery(val, opts) {
          setControlVal(state, state.controls.query, val)
        },
        // only v1
        setQueryHasChanged(val, opts) {
          // TODO check has changed enitrely inside of tree
          state.controls.query._forceHasChanged = val
          checkHasChanged(state)
        },
      }
    },
    getControlsCfg(state, isNew, isClone, isSaveAs, isEdit) {
      return {
        target: {
          methodKey: 'setTarget',
          menuCfg: {
            itemsCfg: mapValues(entityCfg.otherData.targets, (item) => {
              return {
                label: item.labelPlural,
              }
            }),
            firstIsDefault: false,
          },
          _validateAllowNotChanged: true,
        },
        name: {
          methodKey: 'setName',
          _validator: (val) => {
            return (val && val !== '') || 'Required'
          },
          _validateAllowNotChanged: isEdit,
        },
        description: {
          methodKey: 'setDescription',
          _validateAllowNotChanged: true,
        },
        query: {
          methodKey: 'setQuery',
          _validator: (val) => {
            if (val && val.and && val.and.length) return true
            if (val && val.children && val.children.length) return true // only v1
            return 'Must have at least one condition'
          },
          _validateAllowNotChanged: isSaveAs || isEdit,
        },
      }
    },
    isPartialEntity(data) {
      return !data.name
    },
    handleSetData(state, data, hasControls) {
      if (hasControls) {
        state.controls.name.defaultVal = data.name || ''
        state.controls.description.defaultVal = data.description || ''
        state.controls.query.defaultVal = data.query
        state.controls.target.defaultVal = (data.query && data.query.target) || undefined // TODO move to QueryEditor or QueryBuilder
      }
      state.queryType = data.query?.type
    },
    buildOutData(state) {
      let ret = {
        name: state.controls.name.val,
        description: state.controls.description.val,
        query: state.controls.query.val,
      }
      ret.query.target = state.controls.target.val // TODO move to QueryEditor or QueryBuilder
      ret.query.type = state.queryType

      return ret
    },
  }
}

export default initEntityModel