import React from 'react'

import { Grid, Flex } from '../../Base'

import ControlButton from '../../ControlComps/ControlButton'
import TextField from '../../ControlComps/TextField'

const QueryEditor = React.memo(({ state, methods, ...props }) => {

  return (
    <Flex height='max-content'>
      <Grid
        gridAutoFlow='column'
        gridTemplateColumns='min-content min-content'
        justifyContent='left'
        // px='8px'
        alignItems='center'
        // border='1px solid #DBDDE0' // TODO theme
        borderRadius='4px'
        gridGap='8px'
        {...state.gridProps}
      >
        <TextField
          state={state.controls.newKey} 
          methods={methods}
          height='100%'
          variant='outlined'
        />
        <TextField
          state={state.controls.newValue} 
          methods={methods}
          height='100%'
          variant='outlined'
        />
      </Grid>
      <Flex mx='8px' height='32px' alignItems='center'>
        <ControlButton
          state={state.controls.execute}
          methods={methods}
        />
      </Flex>
    </Flex>
  )
})

export default QueryEditor
