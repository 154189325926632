import { useModel, objectVariantOrPassthrough, initControls, setControlVal } from '../../../../lib/state'
import stateVariants from './variants'

///////////
// MODEL //
///////////

const model = {
  ///////////
  // STATE //
  ///////////
  stateInit(args) {
    let ret = {
      ...objectVariantOrPassthrough(stateVariants, args),
      //
    }

    ret.controls = {
      schedulingType: {
        defaultVal: ret.schedulingType || 'once',
        methodKey: 'setSchedulingType',
        items: {
          once: {
            label: 'Once'
          },
          // repeating: {
          //   label: 'Repeating'
          // },
        },
        _validator: (val) => {
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      on: {
        defaultVal: ret.on,
        methodKey: 'setOn',
        _validator: (val, _, parentState) => {
          if (parentState.controls.schedulingType.val !== 'once') return true
          return (val && val !== '') || 'Scheduling On is required'
        },
        _notChangedValidationHint: 'Scheduling On is required',
        _validateAllowNotChanged: true,
      },
      startDate: {
        defaultVal: ret.startDate,
        methodKey: 'setStartDate',
        _validator: (val, _, parentState) => {
          if (parentState.controls.schedulingType.val !== 'repeating') return true
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      time: {
        defaultVal: ret.time,
        methodKey: 'setTime',
        _validator: (val, _, parentState) => {
          if (parentState.controls.schedulingType.val !== 'repeating') return true
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      endDate: {
        defaultVal: ret.endDate,
        methodKey: 'setEndDate',
        _validator: (val, _, parentState) => {
          if (parentState.controls.schedulingType.val !== 'repeating') return true
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
      interval: {
        defaultVal: ret.interval || 'weekly',
        methodKey: 'setInterval',
        items: {
          daily: {
            label: 'Daily',
          },
          weekly: {
            label: 'Weekly',
          },
          biweekly: {
            label: 'Biweekly',
          },
          monthly: {
            label: 'Monthly',
          },
          quarterly: {
            label: 'Quarterly',
          },
          yearly: {
            label: 'Yearly',
          },
        },
        _validator: (val, _, parentState) => {
          if (parentState.controls.schedulingType.val !== 'repeating') return true
          return (val && val !== '')
        },
        _validateAllowNotChanged: true,
      },
    }

    initControls(ret)
  
    return ret
  },
  /////////////
  // METHODS //
  /////////////
  methodsInit(state) {
    return {
      setSchedulingType(val, opts) {
        setControlVal(state, state.controls.schedulingType, val)
      },
      setOn(val, opts) {
        setControlVal(state, state.controls.on, val)
      },
      setStartDate(val, opts) {
        setControlVal(state, state.controls.startDate, val)
      },
      setTime(val, opts) {
        setControlVal(state, state.controls.time, val)
      },
      setEndDate(val, opts) {
        setControlVal(state, state.controls.endDate, val)
      },
      setInterval(val, opts) {
        setControlVal(state, state.controls.interval, val)
      },
      _setShouldValidate(val, opts) {
        state.shouldValidate = val
        setControlVal(state)
      },
      _buildVal(val, opts) {
        const _val = {
          schedulingType: state.controls.schedulingType.val,
          on: state.controls.on.val,
          startDate: state.controls.startDate.val,
          time: state.controls.time.val,
          endDate: state.controls.endDate.val,
          interval: state.controls.interval.val,
        }

        state._builtVal = _val
      },
    }
  },
  ///////////
  // HOOKS //
  ///////////
  // HOOK_EXAMPLES: {
  //   EXAMPLE_callback: { // A key to access ./lib/hooks keyedHooks
  //     getHookArgs: (state) => { return {} },
  //     callback: (res, methods) => {}
  //   },
  //   EXAMPLE_localHook: {
  //     useHook: (stateOrArgs, methods) => {},
  //   },
  // }
  hooks: {
    
  },
}

// returns [ state, methods ]
export default (...args) => {
  return useModel(model, ...args)
}