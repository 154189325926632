import { queryData_toLoopback } from '../Query/util'
import { mapValues } from 'lodash'

export const charts_reqDataFunc = (reqDataArgs) => {
  let {
    chartApiType,
    aggInterval,
    aggOp,
    start,
    end,
    query,
    supplementalData,
  } = reqDataArgs

  let reqData = null
  const { AppUser, Event, AppPlugin, Shoe } = mapValues(query, (val) => queryData_toLoopback(val))

  switch (chartApiType) {
    case 'eventStats':
      reqData = {
        eventName: Event.name,
        tz: global.TZ,
        start: start.toISOString(),
        end: end.toISOString(),
        interval: aggInterval,
        appusersWhere: AppUser,
        uniqueByAppuser: true,
        // TODO_MAYBE
        // breakdowns: [
        //   {
        //     field: 'attributes.platform',
        //     limit: 3,
        //   }
        // ],
      }

      return reqData

    case 'retention':
      if (!Event) return
      reqData = {
        ...reqData,
        usersWhere: AppUser,
        // eventsWhere: Event,
        eventName: Event.name, // Shouldnt this take eventsWhere?
      }
      break

    case 'funnel':
      aggInterval = null
      aggOp = null
      reqData = {
        ...reqData,
        usersWhere: AppUser,
      }
      break

    case 'activityAnalysis':
      let properties

      if (AppPlugin.field) {
        properties = [ AppPlugin.field ]
      }
      else if (AppPlugin.and) {
        properties = AppPlugin.and.map((item) => item.field)
      }
      reqData = {
        ...reqData,
        usersWhere: AppUser || {},
        properties,
        appId: supplementalData.appId,
      }
      break

    default:
      break
  }

  if (AppUser) {
    reqData.usersWhere = AppUser
  }

  if (Shoe) {
    reqData.shoesWhere = Shoe
  }

  reqData.start = start.format()
  reqData.end = end.format()

  if (aggOp) reqData.fcn = aggOp
  if (aggInterval) reqData.interval = aggInterval

  return reqData
}