import React from 'react'

// import { setControlVal } from '../../../lib/state'
import useApi from '../../../lib/useApi'

const initEntityModel = (entityCfg) => {
  return {
    dbEntityType: entityCfg.dbEntityType,
    entityMethodsInit(state) { return {} },
    getControlsCfg(state, isNew, isClone, isSaveAs) { return {}},
    isPartialEntity(data) { return !data.data},
    handleSetData(state, data, hasControls) { },
    hookOverrides: {
      deleteById: {
        useHook: (args = {}, cb) => {
          const {
            id,
            dbEntityType,
          } = args

          const api = useApi()
          // const dispatch = useDispatch()

          React.useEffect(() => {
            if (!id) return
      
            api({
              method: 'delete',
              url: `/api/${entityCfg.dbEntityType}/${id}/segments`,
              handler: (res1) => {
                api({
                  method: 'delete',
                  url: `/api/${entityCfg.dbEntityType}/${id}`,
                  handler: (res2) => {
                    // dbEntitiesStoreActions.listRemove(dispatch, dbEntityType, { id })
                    if (cb) cb([ res1, res2 ])
                  },
                })
              },
            })
          }, [
            cb,
            api,
            dbEntityType, id
          ])
        },
      }
    },
  }
}

export default initEntityModel