import React from 'react'

import moment from 'moment-timezone'
import deepMerge from '../../lib/deepMerge'
import { get, isObject } from 'lodash'
import { useApi_AppOrOrgUrl, useApi_apiUrl } from '../../lib/hooks'
import { timeRangeToFilenameStr } from '../../lib/utils'

import { Flex } from '../Base'
import { SaveAlt, ViewWeek } from '@material-ui/icons'

import * as dbEntityCfgs from '../DbEntities/allDbEntities'
import { ExploreEntityLink } from '../DbEntities/util'
import { DbEntityManagerActions } from '../DbEntities/Manage'

// TODO split up variants into nested folder

//
// Util
//

const sizes = {
  small(variantOpts) {
    return {
      optsMUIT: {
        pageSize: 5,
        pageSizeOptions: [5, 10, 15, 20],
      },
    }
  },
  full(variantOpts) {
    return {
      optsMUIT: {
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 50],
      },
    }
  },
  none() {
    return {
      optsMUIT: {
        paging: false,
      },
    }
  }
}

const nestedTableStyle = {
  marginLeft: 30,
  marginTop: 16,
  marginBottom: 33,
  boxShadow: '0px 0px 0px rgba(0,0,0,0.0)',
  backgroundColor:'transparent',
}

const handleShowManagementActions = (variantOpts, dbEntityType, entityPath, forceShow) => {
  if (!forceShow && !variantOpts.showRowManagementActions) return {}

  return {
    actions: [{
      icon: 'save',
      tooltip: '',
      onClick: () => {},
    }],
    components: {
      Action: ({ data, action }) => {
        return (
          <Flex whiteSpace='normal'>
            <DbEntityManagerActions
              dbEntityType={dbEntityType}
              entity={(entityPath && get(data, entityPath)) || data}
              otherData={data}
              onEdited={variantOpts.onActionCompleted || variantOpts.onEdited}
              onCloned={variantOpts.onActionCompleted || variantOpts.onCloned}
              onDeleted={variantOpts.onActionCompleted || variantOpts.onDeleted}
              onActionCompleted={variantOpts.onActionCompleted}
              computedRolePerms={variantOpts.computedRolePerms}
              allowedActions={variantOpts.allowedActions}
            />
          </Flex>
        )
      }
    }
  }
}

const exploreModelLinkCol = (path, dbEntityType, isConnected) => {
  const entityCfg = dbEntityCfgs[dbEntityType]

  let dataKey = entityCfg.nameExportKey
  if (isConnected) {
    dataKey = `${entityCfg.label.toLowerCase()}.${dataKey}` // TODO entity key a better way
  }

  return {
    path,
    field: `${path}.${entityCfg.nameDataPath}`,
    dataKey,
    exportRelPath: entityCfg.nameDataPath,
    renderCfg: {
      Comp: ExploreEntityLink,
      props: {
        dbEntityType,
      },
      pathedProps: {
        entity: null // null means to use the value from top level 'path'
      },
    },
    colMUIT: {
      title: entityCfg.nameLabel,
    },
  }
}

const fieldTitleWithShortUnit = (key, fieldsData) => {
  const suffix = fieldsData?.[key]?.unitShort

  if (suffix) {
    return `${key} - ${suffix}`
  }

  return key
}

//
// Base
//

const base = (variantOpts) => {
  let {
    // Our state/model only
    size = 'full',
    resTransformer,
    showCohortDropdown,
    showDateRangePicker,
    showDynamicKeysToggle,
    showQueryBuilder,
    showCount = true,
    dataRangePickerOpts,
    builderOpts,
    reqData = {},
    baseReqData = {},
    dataOpts,
    // Both
    exportOpts,
    showTitle = true,
    // MUIT only
    icons = {},
    title,
    titlePrefix,
    titleSuffix,
    // MUIT opts
    showSearch = false,
    cellPadding = 'dense',
    debug,
    debugTransform,
    DetailPanelComp,
    getDetailPanelProps,
    optsMUIT,
    supplementalData = {},
  } = variantOpts

  const hasOtherComps = showDateRangePicker || showDynamicKeysToggle || showQueryBuilder

  const showMUITToolbar = Boolean(showSearch || exportOpts)
  const showMUITTitle = !hasOtherComps && showTitle

  if (exportOpts) {
    icons.Export = () => <SaveAlt color='primary'/>
  }
  icons.ViewColumn = () => <ViewWeek color='primary'/>

  let ret = {
    debug,
    debugTransform,
    reqData,
    baseReqData,
    resTransformer,
    title,
    titlePrefix,
    titleSuffix,
    icons,
    exportOpts,
    hasOtherComps,
    showTitle,
    optsMUIT: deepMerge(
      {
        search: showSearch,
        showTitle: showMUITTitle,
        ...exportOpts,
        toolbar: showMUITToolbar,
        pageSize: 0,
        pageSizeOptions: [],
        rowStyle: {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        actionsCellStyle: {
          paddingLeft: '16px',
        },
        headerStyle: {
          whiteSpace: 'nowrap',
          backgroundColor: '#F3F3F3',
          borderTop: '1px solid #E8E8E8',
          padding: '8px 16px'
        },
        // paginationType: 'stepped',
        // grouping: true,
        padding: cellPadding,
      },
      optsMUIT,
    ),
    dataOpts: {
      showDynamicKeys: true,
      numberType: 'numeric',
      ...dataOpts,
    },
    showCohortDropdown,
    showDateRangePicker,
    showDynamicKeysToggle,
    showQueryBuilder,
    showCount,
    dataRangePickerOpts,
    builderOpts,
    DetailPanelComp,
    getDetailPanelProps,
    supplementalData,
  }

  return deepMerge(
    ret,
    sizes[size](variantOpts),
  )
}

//
// State Variants
//

// stateVariants.TEMPLATE = (variantOpts) => {
//   const {
//   } = variantOpts
//
//   return {
//     title: 'PLACEHOLDER',
//     useApiAndUrl: () => useApi_AppOrOrgUrl(`/PLACEHOLDER`),
//     baseReqData: {
//       include: [
//         'PLACEHOLDER',
//       ],
//     },
//     reqDataTransformer({ start, end, where }) {
//       return { start, end, where }
//     },
//     dateRangePickerOpts: {
//       defaultPresetIdx: 0,
//     },
//     builderOpts: {
//       variant: 'default',
//       variantOpts: {
//         target: 'PLACEHOLDER',
//       }
//     },
//     resTransformer: (items) => {
//       return items.map((item, i) => {
//         return 'PLACEHOLDER'
//       })
//     },
//     dynamicPathsInfoFunc: (item, dataOpts, setDynamicSample) => {
//       const attributes = item.attributes
//    
//       Object.entries(attributes).forEach(([key, val]) => {
//         setDynamicSample(
//           'attributes.' + key,
//           key,
//           val,
//         )
//       })
//    
//       return item
//     },
//     staticColumnsInfoFunc: (dataOpts) => {
//       const ret = [
//         {
//           path: 'PLACEHOLDER',
//           dataKey: 'OPTIONAL',
//           exportRelPath: 'OPTIONAL',
//           renderCfg: {
//             Comp: null,
//             props: {},
//             pathedProps: {},
//           },
//           colMUIT: {
//             title: 'PLACEHOLDER',
//           },
//         },
//       ]
//
//       return ret
//     },
//     staticColsInfoAfterDynamic: null,
//   }
// }

const stateVariants = {}

stateVariants.entityActivities = (variantOpts) => {
  const {
    entityType,
    connectedEntityType,
    id,
    entity,
    showDateRangePicker,
  } = variantOpts

  const connectedEntityTypeSingular = connectedEntityType.slice(0, connectedEntityType.length - 1) // TODO handle this with an activities entityCfg and hasOne relation

  const entityCfg = dbEntityCfgs[entityType]

  // TODO_MAYBE precalc duration so we can export it

  return {
    ...handleShowManagementActions(variantOpts, 'activities', 'activity'),
    title: `${entityCfg.label} Activities`,
    useApiAndUrl: () => useApi_apiUrl(`/${entityType}/${id || entity.id}`),
    reqDataTransformer({ start, end, where }) {
      if (showDateRangePicker && (!start || !end)) {
        return undefined
      }

      const activitiesWhereSpread = {}
      if (start && end) {
        activitiesWhereSpread.start = {
          between:[ start, end ]
        }
      }

      return {
        include: [
          {
            relation: "activities",
            scope: {
              where: {
                ...where,
                status: 'COMPLETE',
                ...activitiesWhereSpread,
              },
              order: "start DESC",
              include: connectedEntityTypeSingular, // TODO only code || attributes.name + userId
            }
          },
        ],
      }
    },
    dateRangePickerOpts: {
      defaultPresetIdx: 1,
    },
    resTransformer: (items, state) => {
      if (!items.activities) return []

      const primaryEntity = items

      const entityName = entityCfg.getEntityName(primaryEntity)
      state.title = entityName

      const activities = primaryEntity.activities

      const cleanEntityName = entityName.replace(/\s+/, '-')

      if (activities.length > 0) {
        const mostRecent = activities[0]
        state.supplementalData.appId = mostRecent.appId
        const leastRecent = activities[activities.length - 1]
        
        state.exportOpts.exportFileName = [
          'activities',
          cleanEntityName,
          timeRangeToFilenameStr(leastRecent.start, mostRecent.start),
        ].join('_')
      }
     
      return activities.map((activity) => {
        const connectedEntity = activity[connectedEntityTypeSingular]

        delete activity.data.timestamp // This is always equal to the activity start, so remove it

        return {
          primaryEntityName: entityName,
          primaryEntityNameClean: cleanEntityName,
          primaryEntity,
          connectedEntity,
          activity,
        }
      })
    },
    dynamicPathsInfoFunc: (item, dataOpts, supplementalData, setDynamicSample) => {
      Object.entries(item.activity.data).forEach(([key, val]) => {
        setDynamicSample({
          path: `activity.data.${key}`,
          dataKey: key,
          sample: val,
          colMUIT: {
            title: fieldTitleWithShortUnit(key, supplementalData.activityDataFields),
          },
        })
      })
    },
    dataOpts: {
      numberType: {
        customType: 'numericFixed',
      },
    },
    staticColumnsInfoFunc: (dataOpts) => {
      const ret = [
        {
          path: 'activity.start',
          dataKey: 'start',
          renderCfg: {
            Comp: React.memo(({ ts, tz }) => {
              let val = moment(ts)
              if (tz) val = val.tz(tz)
              return (
                <span>{val.format('lll')}</span>
              )
            }),
            props: {},
            pathedProps: {
              ts: 'activity.start',
              tz: 'activity.tz',
            },
          },
          colMUIT: {
            title: 'Start',
          },
        },
        {
          path: 'activity.end',
          dataKey: 'end',
          colMUIT: {
            hidden: true,
          },
        },
        {
          renderCfg: {
            Comp: React.memo(({ start, end }) => {
              return (
                <span>{moment.duration(moment(end)-moment(start)).humanize()}</span>
              )
            }),
            props: {},
            pathedProps: {
              start: 'activity.start',
              end: 'activity.end',
            },
          },
          colMUIT: {
            title: 'Duration',
            customSort: (a, b) => {
              let aDur = moment(a.activity.end) - moment(a.activity.start)
              let bDur = moment(b.activity.end) - moment(b.activity.start)
              return aDur - bDur
            }
          },
        },
        exploreModelLinkCol('connectedEntity', connectedEntityType, true),
        {
          path: 'activity.activityIdentifier',
          dataKey: 'activityIdentifier',
          colMUIT: {
            title: 'Activity Id'
          }
        },
        {
          path: 'activity.data.chunk',
          dataKey: 'chunk',
          colMUIT: {
            title: 'Chunks',
          },
        },
      ]

      return ret
    },
    staticColsInfoAfterDynamic: (dataOpts) => {
      const ret = [
        {
          path: 'activity.tz',
          dataKey: 'tz',
          colMUIT: {
            title: 'Timezone',
          },
        },
      ]

      return ret
    },
    getDetailPanelProps: (item) => ({
      tableModelOpts: {
        variant: 'activitySegments',
        variantOpts: {
          entity: item.activity,
          // general
          size: 'none',
        },
      },
      style: nestedTableStyle,
    }),
    _hooks: {
      useCurrentPluginFields: {
        getHookArgs: (state) => {
          return {
            appId: state.supplementalData.appId,
          }
        },
        callback: (res, methods) => {
          methods.setSupplementalData(res, { key: 'activityDataFields'})
        },
      },
    },
  }
}

stateVariants.activitySegments = (variantOpts) => {
  // TODO mess with 'ch' based column width
  const {
    id,
    entity,
  } = variantOpts

  return {
    // ...handleShowManagementActions(variantOpts, 'segments', ''),
    title: `${dbEntityCfgs.activities.label} Segments`,
    useApiAndUrl: () => useApi_apiUrl(`/activities/${id || entity.id}/segments`),
    baseReqData: {
      order: 'timestamp ASC',
    },
    dynamicPathsInfoFunc: (item, dataOpts, supplementalData, setDynamicSample) => {
      Object.entries(item.data).forEach(([key, val]) => {
        setDynamicSample({
          path: `data.${key}`,
          dataKey: key,
          sample: val,
          colMUIT: {
            title: fieldTitleWithShortUnit(key, supplementalData.activityDataFields),
            sorting: false,
          },
        })
      })
    },
    dataOpts: {
      numberType: {
        customType: 'numericFixed',
      },
    },
    staticColumnsInfoFunc: (dataOpts) => {
      const ret = [
        {
          path: 'data.chunk',
          dataKey: 'chunk',
          colMUIT: {
            title: 'Chunk',
            sorting: false,
          },
        },
        {
          path: 'timestamp',
          dataKey: 'timestamp',
          renderCfg: {
            Comp: React.memo(({ ts, tz }) => {
              let val = moment(ts)
              if (tz) val = val.tz(tz)
              return (
                <span>{val.format('MM/DD HH:mm:ss')}</span>
              )
            }),
            props: {},
            pathedProps: {
              ts: 'timestamp',
              tz: 'tz',
            },
          },
          colMUIT: {
            title: 'Timestamp',
            sorting: false,
          },
        },
      ]

      return ret
    },
    staticColsInfoAfterDynamic: (dataOpts) => {
      const ret = [
        {
          path: 'tz',
          dataKey: 'tz',
          colMUIT: {
            title: 'Timezone',
            sorting: false,
          },
        },
      ]

      return ret
    },
    _hooks: {
      useCurrentPluginFields: {
        getHookArgs: (state) => {
          return {
            appId: entity.appId,
          }
        },
        callback: (res, methods) => {
          methods.setSupplementalData(res, { key: 'activityDataFields'})
        },
      },
    },
  }
}

stateVariants.entityChunks = (variantOpts) => {
  const {
    entityType,
    connectedEntityType,
    id,
    entity,
    showDateRangePicker,
  } = variantOpts

  const connectedEntityTypeSingular = connectedEntityType.slice(0, connectedEntityType.length - 1) // TODO handle this with an activities entityCfg and hasOne relation

  const entityCfg = dbEntityCfgs[entityType]

  // TODO_MAYBE precalc duration so we can export it

  return {
    ...handleShowManagementActions(variantOpts, 'chunks', 'chunk'),
    title: `${entityCfg.label} Chunks`,
    useApiAndUrl: () => useApi_apiUrl(`/${entityType}/${id || entity.id}`),
    reqDataTransformer({ start, end, where }) {
      if (showDateRangePicker && (!start || !end)) {
        return undefined
      }

      const whereSpread = {}
      if (start && end) {
        whereSpread.timestamp = {
          between:[ start, end ]
        }
      }

      return {
        include: [
          {
            relation: "chunks",
            scope: {
              where: {
                ...where,
                ...whereSpread,
              },
              order: "timestamp DESC",
              include: connectedEntityTypeSingular, // TODO only code || attributes.name + userId
            }
          },
        ],
      }
    },
    dateRangePickerOpts: {
      defaultPresetIdx: 1,
    },
    resTransformer: (items, state) => {
      if (!items.chunks) return []

      const primaryEntity = items

      const entityName = entityCfg.getEntityName(primaryEntity)
      state.title = entityName

      const chunks = primaryEntity.chunks

      const cleanEntityName = entityName.replace(/\s+/, '-')

      if (chunks.length > 0) {
        const mostRecent = chunks[0]
        state.supplementalData.appId = mostRecent.appId
        const leastRecent = chunks[chunks.length - 1]
        
        state.exportOpts.exportFileName = [
          'chunks',
          cleanEntityName,
          timeRangeToFilenameStr(leastRecent.timestamp, mostRecent.timestamp),
        ].join('_')
      }
     
      return chunks.map((chunk) => {
        const connectedEntity = chunk[connectedEntityTypeSingular]

        return {
          primaryEntityName: entityName,
          primaryEntityNameClean: cleanEntityName,
          primaryEntity,
          connectedEntity,
          chunk,
        }
      })
    },
    dynamicPathsInfoFunc: (item, dataOpts, supplementalData, setDynamicSample) => {
      Object.entries(item.chunk.data).forEach(([key, val]) => {
        setDynamicSample({
          path: `chunk.data.${key}`,
          dataKey: key,
          sample: val,
          colMUIT: {
            // title: fieldTitleWithShortUnit(key, supplementalData.activityDataFields),
            title: key,
          },
        })
      })
    },
    dataOpts: {
      numberType: {
        customType: 'numericFixed',
      },
    },
    staticColumnsInfoFunc: (dataOpts) => {
      const ret = [
        {
          path: 'chunk.timestamp',
          dataKey: 'timestamp',
          renderCfg: {
            Comp: React.memo(({ ts, tz }) => {
              let val = moment(ts)
              if (tz) val = val.tz(tz)
              return (
                <span>{val.format('MM/DD HH:mm:ss')}</span>
              )
            }),
            props: {},
            pathedProps: {
              ts: 'chunk.timestamp',
              tz: 'chunk.tz',
            },
          },
          colMUIT: {
            title: 'Timestamp',
          },
        },
        exploreModelLinkCol('connectedEntity', connectedEntityType, true),
      ]

      return ret
    },
    staticColsInfoAfterDynamic: (dataOpts) => {
      const ret = [
        {
          path: 'chunk.tz',
          dataKey: 'tz',
          colMUIT: {
            title: 'Timezone',
          },
        },
      ]

      return ret
    },
    _hooks: {
      // useCurrentPluginFields: {
      //   getHookArgs: (state) => {
      //     return {
      //       appId: state.supplementalData.appId,
      //     }
      //   },
      //   callback: (res, methods) => {
      //     methods.setSupplementalData(res, { key: 'activityDataFields'})
      //   },
      // },
    },
  }
}

stateVariants.entitesThatHaveEvents = (variantOpts) => {
  const {
    primaryEntityType,
    connectedEntityType,
    includeAppName,
    showDateRangePicker,
    onlyActivities,
  } = variantOpts

  const primaryEntityCfg = dbEntityCfgs[primaryEntityType]

  const timestampKey = onlyActivities
    ? 'lastActivityOn'
    : 'mostRecentEventOn'

  return {
    ...handleShowManagementActions(variantOpts, primaryEntityType, 'primaryEntity'),
    title: primaryEntityCfg.labelPlural,
    useApiAndUrl: () => useApi_AppOrOrgUrl(`/${primaryEntityType}`, null, true),
    reqDataTransformer({ start, end, where }) {
      if (showDateRangePicker && (!start || !end)) {
        return undefined
      }

      if (start && end) {
        where = {
          ...where,
          [`attributes.${timestampKey}`]: {
            between: [ start, end ]
          },
        }
      }

      let ret = {
        where,
      }

      // if (primaryEntityType === 'shoes') {
      //   ret.include = {
      //     relation: 'apps',
      //     scope: {
      //       fields: [ 'id' ]
      //     },
      //   }
      // }

      return ret
    },
    builderOpts: {
      variant: 'cohortQuery',
      variantOpts: {
        target: primaryEntityType,
      }
    },
    resTransformer: (items, state) => {
      const selectedApps = state.supplementalData.selectedApps
      let appDataById
      if (selectedApps) {
        appDataById = {}
        selectedApps.forEach((app) => {
          appDataById[app.id] = {
            fullName: `${app.organization.name} - ${app.name}`
          }
        })
      }

      let ret = items.map((item, i) => {
        const primaryEntity = item
        
        const connectedEntity = primaryEntityCfg.getAttributesConnectedEntities?.(primaryEntity)[connectedEntityType]

        if (appDataById) {
          primaryEntity._subAppName = appDataById[primaryEntity.appId || primaryEntity.apps?.[0].id]?.fullName
        }
        
        const app = item.app
        if (app) {
          delete item.app
        }

        return {
          primaryEntity,
          connectedEntity,
          app,
        }
      }).filter((item) => Boolean(item))
      return ret
    },
    dynamicPathsInfoFunc: (item, dataOpts, supplementalData, setDynamicSample) => {
      Object.entries(item.primaryEntity.attributes).forEach(([key, val]) => {
        if (isObject(val)) {
          return
        }
        setDynamicSample({
          path: `primaryEntity.attributes.${key}`,
          dataKey: key,
          sample: val,
        })
      })
    },
    staticColumnsInfoFunc: (dataOpts, state) => {
      let ret = [
        exploreModelLinkCol('primaryEntity', primaryEntityType),
      ]

      if (state.supplementalData.selectedApps) {
        ret = [
          ...ret,
          {
            path: 'primaryEntity._subAppName',
            dataKey: 'subAppName',
            colMUIT: {
              title: 'App',
            },
          },
        ]
      }

      if (primaryEntityType === 'appusers') {
        ret = [
          ...ret,
          {
            path: 'primaryEntity.attributes.name',
            dataKey: 'name',
            colMUIT: {
              title: 'Name',
            },
          },
          {
            path: [
              'primaryEntity.attributes.email',
              'primaryEntity.attributes.phone',
            ],
            dataKey: 'contact',
            colMUIT: {
              title: 'Email/Phone',
            },
          },
        ]
      }

      ret = [
        ...ret,
        ...(
          onlyActivities
            ? []
            : [
                {
                  path: 'primaryEntity.attributes.mostRecentEvent',
                  dataKey: 'mostRecentEvent',
                  colMUIT: {
                    title: 'Last Event',
                  },
                },
              ]
        ),
        {
          path: `primaryEntity.attributes.${timestampKey}`,
          dataKey: timestampKey,
          type: {
            customType: 'materialTableIso',
            opts: {
              format: 'FROM_NOW',
            }
          },
          colMUIT: {
            title: 'When',
            defaultSort: 'desc',
          },
        },
        exploreModelLinkCol('connectedEntity', connectedEntityType, true),
        {
          path: 'primaryEntity.attributes.activityCount',
          dataKey: 'activityCount',
          type: 'numeric',
          colMUIT: {
            title: 'Activity Count',
          },
        },
      ]

      if (includeAppName) {
        ret.push({
          path: 'app.name',
          dataKey: 'lastApp',
          colMUIT: {
            title: 'Last App',
          },
        }) 
      }

      return ret
    },
    _hooks: {
      useSelectedApps: {
        callback: (res, methods) => {
          methods.setSupplementalData(res, { key: 'selectedApps'})
        },
      },
    },
  }
}

stateVariants.manage = (variantOpts) => {
  const {
    dbEntityType,
    staticCols,
    dynamicPathRoot,
  } = variantOpts

  const entityCfg = dbEntityCfgs[dbEntityType]

  let dynamicPathsInfoFunc = null
  if (dynamicPathRoot) {
    dynamicPathsInfoFunc = (item, dataOpts, supplementalData, setDynamicSample) => {
      const dynamicData = get(item, dynamicPathRoot)
      Object.entries(dynamicData).forEach(([key, val]) => {
        setDynamicSample({
          path: `${dynamicPathRoot}.${key}`,
          dataKey: key,
          sample: val,
        })
      })
    }
  }

  return {
    ...handleShowManagementActions(variantOpts, dbEntityType, null),
    optsMUIT: {
      showTitle: false,
    },
    dataOpts: {
      numberType: {
        customType: 'numericFixed',
      },
    },
    dynamicPathsInfoFunc,
    staticColumnsInfoFunc: (dataOpts) => {
      const ret = [
        {
          path: '',
          dataKey: entityCfg.nameExportKey,
          exportRelPath: entityCfg.nameDataPath,
          renderCfg: {
            valToStr: entityCfg.getEntityName,
          },
          colMUIT: {
            title: entityCfg.nameLabel,
          },
        },
        ...staticCols,
      ]

      return ret
    },
    staticColsInfoAfterDynamic: (dataOpts) => {
      const ret = [
        {
          path: 'updatedAt',
          dataKey: 'updatedAt',
          type: {
            customType: 'materialTableIso',
          },
          colMUIT: {
            defaultSort: 'desc',
            title: 'Updated',
          },
        },
      ]

      return ret
    },
  }
}

stateVariants.entitiesActivityDataAgg = (variantOpts) => {
  const {
    dbEntityType,
    fieldsCfg,
    entityColComp,
    entityColTitle,
    entityColRenderProps,
    entityColPathedProps,
  } = variantOpts

  const entityCfg = dbEntityCfgs[dbEntityType]

  return {
    optsMUIT: {
      showTitle: false,
    },
    dataOpts: {},
    staticColumnsInfoFunc: (dataOpts) => {
      let ret = []

      if (dbEntityType === 'appusers') {
        ret = [
          ...ret,
          {
            path: '',
            field: entityCfg.nameDataPath,
            dataKey: entityCfg.nameExportKey,
            exportRelPath: entityCfg.nameDataPath,
            renderCfg: {
              Comp: entityColComp || ExploreEntityLink,
              props: entityColRenderProps,
              pathedProps: {
                entity: null, // null means to use the value from top level 'path'
                entityName: [
                  'attributes.name',
                  'attributes.email',
                  'userId',
                ],
                label: [
                  'attributes.name',
                  'attributes.email',
                  'userId',
                ],
                ...entityColPathedProps,
              },
            },
            colMUIT: {
              title: entityColTitle,
            },
          },
        ]
      }

      ret = [
        ...ret,
        ...fieldsCfg.map((cfg, i) => {
          const col = {
            dataKey: cfg.dataField,
            path: `activitiesDataAgg.${cfg.dataField}`,
            renderCfg: {
              Comp: React.memo(({ val, color }) => {
                let text = val

                if (cfg.isTime) {
                  text = (val / (60 * 60)).toFixed(1) + ' h'
                }

                return (
                  <span style={{ color }}>{text}</span>
                )
              }),
              pathedProps: {
                val: `activitiesDataAgg.${cfg.dataField}`,
                color: `activitiesDataAgg.meta.${cfg.dataField}.color`,
              },
            },
            colMUIT: {
              title: cfg.label,
            },
          }

          if (i === 0) {
            col.colMUIT.defaultSort = 'desc'
          }

          return col
        }),
      ]

      return ret
    },
  }
}

export default {
  base,
  stateVariants,
}
